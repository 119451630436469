export const initialState = {
  error: false,
  loading: false,
  dialogMessages: [],
  interlocutorNickname: null,
  currentUserId: null,
  hasMore: true,
  hasMoreTop: true,
  hasMoreBottom: true,
  targetMessagePage: null,
  messagesPage: 1,
  messagesPageTop: 1,
  messagesPageBottom: 1,
  messagePrice: null,
  paymentData: null,
  reactedMessages: {},
  messageData: null,
  reactedMessageLoading: false,
  isOnMessagePage: false,
  firstLoading: true,
  lastReadMessageId: null,
  adjacentPageWithScrollUpdate: null,
};
