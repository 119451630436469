import propTypes from 'prop-types';
import styled from 'styled-components';

import { MCardLinkStyles, MCardStyles } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import { Avatar } from '../../avatar';
import { MBox } from '../../common';
import { Text } from '../../texts';

const NewMessageCardContentStyled = styled.section`
  ${props =>
    props.multipleFans &&
    `
    article:last-child {
      border-bottom: none;
    }
  `};
`;

const NewMessageCardStyled = styled.article`
  max-width: 100%;
  ${props => MCardStyles({ multipleFans: props.twoColumns })};
  padding-top: ${theme.space.sm};
  padding-bottom: ${theme.space.sm};
`;

const NewMessageCardWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NewMessageCardLinkStyled = styled.button`
  color: ${theme.colors.black};
  ${MCardLinkStyles('0')};
`;

const MassMessageChatCard = ({ items, title }) => {
  return (
    <>
      <MBox
        bg="grayCloud"
        py="xxs"
        px="primaryMd"
        mt="xs"
        mb="xxs"
        mx={`-${theme.space.primaryMd}`}
      >
        <Text type="subtitle2" as="h3">
          {title}
        </Text>
      </MBox>
      <NewMessageCardContentStyled multipleFans>
        {items.map(item => (
          <NewMessageCardStyled multipleFans key={item.id}>
            <Avatar
              allFans={item.avatarAllFans}
              boostFans={item.avatarBoostFans}
              bestFans={item.avatarBestFans}
              type="xs"
            />
            <NewMessageCardWrapperStyled>
              <Text
                type="label2"
                color="black"
                lineHeight="md"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                as="h4"
                mb="xxxs"
              >
                <NewMessageCardLinkStyled
                  title={item.multipleTitle}
                  onClick={item.onClick}
                >
                  {item.multipleTitle}
                </NewMessageCardLinkStyled>
              </Text>
              <Text
                type="body3"
                color="grayMedium"
                lineHeight="sm"
                lineClamp="2"
                wordBreak="break-word"
              >
                {item.multipleDescription}
              </Text>
              <Text $isGradient type="label3" mt="xxxs">
                {item.multipleQuantityFans}&nbsp;fans
              </Text>
            </NewMessageCardWrapperStyled>
          </NewMessageCardStyled>
        ))}
      </NewMessageCardContentStyled>
    </>
  );
};

MassMessageChatCard.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      link: propTypes.string,
      avatarAllFans: propTypes.bool, // for multipleFans
      avatarBoostFans: propTypes.bool, // for multipleFans
      avatarBestFans: propTypes.bool, // for multipleFans
      multipleDescription: propTypes.string, // for multipleFans
      multipleTitle: propTypes.string, // for multipleFans
      multipleQuantityFans: propTypes.oneOfType([
        // for multipleFans
        propTypes.string,
        propTypes.number,
      ]),
    }).isRequired,
  ).isRequired,
  title: propTypes.string,
};

export default MassMessageChatCard;
