import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from 'translations/translationsEN.json';
import translationsFR from 'translations/translationsFR.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationsEN,
      },
      fr: {
        translation: translationsFR,
      },
    },
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

export default i18n;
