import propTypes from 'prop-types';

const IconTiktok = ({ palette, size }) => {
  return (
    <svg width={size} viewBox="0 0 20 20" aria-hidden="true" fill="none">
      <path
        fill={palette}
        d="M16.735 4.277a4.545 4.545 0 0 1-.393-.229 5.536 5.536 0 0 1-1.012-.859A4.77 4.77 0 0 1 14.19.838h.005C14.108.329 14.145 0 14.149 0h-3.434v13.282c0 .178 0 .354-.008.529l-.003.065c0 .01 0 .02-.002.03v.007a2.916 2.916 0 0 1-1.468 2.314 2.866 2.866 0 0 1-1.42.375 2.906 2.906 0 0 1-2.898-2.916 2.906 2.906 0 0 1 2.897-2.916c.303 0 .604.047.892.14l.004-3.497a6.38 6.38 0 0 0-4.916 1.439 6.74 6.74 0 0 0-1.47 1.813c-.146.25-.693 1.255-.76 2.885-.04.925.237 1.884.37 2.28v.009c.083.233.406 1.03.932 1.7a6.978 6.978 0 0 0 1.488 1.404v-.008l.008.008C6.024 20.073 7.868 20 7.868 20c.319-.013 1.388 0 2.602-.575 1.347-.638 2.113-1.589 2.113-1.589.49-.568.88-1.215 1.152-1.913.31-.817.415-1.797.415-2.189V6.687c.041.025.596.392.596.392s.8.512 2.047.846c.895.238 2.1.288 2.1.288v-3.41c-.422.046-1.28-.088-2.158-.526Z"
      />
    </svg>
  );
};

IconTiktok.defaultProps = {
  palette: '#EE1D51',
  size: '20',
};

IconTiktok.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
};

export default IconTiktok;
