import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { MBox } from 'components/common';
import { Text, TextLabel } from 'components/texts';

import { InputFocusVisible, Transition } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

const MRadioWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${theme.colors.grayTertiary};
  ${props =>
    props.isSmall
      ? `height: ${theme.sizes.radioPrimaryInputSmall}; border-radius: ${theme.radii.sm}`
      : `height: ${theme.sizes.radioPrimaryInput}; border-radius: ${theme.radii.xs}`};
  ${space};
`;

const MRadioItemStyled = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: ${props => (props.isThreeItem ? '33.3%' : '50%')};
  margin: ${theme.space.xxs};
  ${props =>
    props.active &&
    `background: ${theme.colors.white}; 
    box-shadow: ${theme.shadows.secondary};
  `};
  ${props =>
    props.active &&
    !props.isSmall &&
    `border-radius: ${theme.radii.xs};
  `};
  ${props =>
    props.active &&
    props.isSmall &&
    `border-radius: ${theme.radii.xsSecondary};
  `};
  ${props => props.isSmall && `padding: ${theme.space.sm} ${theme.space.xxs}`};
  ${Transition('background')};
`;

const MRadioInputStyled = styled.input`
  ${InputFocusVisible};
`;

const MRadioPrimary = ({
  name,
  items,
  value,
  label,
  onChange,
  ariaLabelledby,
  isThreeItem,
  isSmall,
  ...props
}) => {
  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );
  return (
    <MBox maxWidth={breakpoints.md} mx="auto" width="100%">
      {label && <TextLabel id={ariaLabelledby}>{label}</TextLabel>}
      <MRadioWrapperStyled isSmall={isSmall} {...props}>
        {items.map(item => (
          <MRadioItemStyled
            active={value === item.value}
            htmlFor={`${name}-${item.id}`}
            key={item.text}
            isThreeItem={isThreeItem}
            isSmall={isSmall}
          >
            <MRadioInputStyled
              onChange={changeHandler}
              id={`${name}-${item.id}`}
              type="radio"
              name={name}
              value={item.value}
              aria-labelledby={`${ariaLabelledby} ${ariaLabelledby}${item.id}`}
            />
            <Text
              as="span"
              $isGradient={!isSmall && value === item.value}
              color={isSmall && value === item.value ? 'black' : 'graySecodary'}
              type={value === item.value ? 'label3' : 'body3'}
              id={`${ariaLabelledby}${item.id}`}
            >
              {item.text}
            </Text>
          </MRadioItemStyled>
        ))}
      </MRadioWrapperStyled>
    </MBox>
  );
};

MRadioPrimary.defaultProps = {
  value: '',
  onChange: noop,
  isThreeItem: true,
};

MRadioPrimary.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string,
  ariaLabelledby: propTypes.string,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
      text: propTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  value: propTypes.string,
  onChange: propTypes.func,
  isThreeItem: propTypes.bool,
  isSmall: propTypes.bool,
};

export default MRadioPrimary;
