import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { routes } from 'constants/routes';

import { formatPrice } from 'tools/stringFormatter';

import { MCardLinkStyles, MCardStyles } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import { Avatar } from '../../avatar';
import { Badge } from '../../badge';
import { Text } from '../../texts';

const NewMessageCardStyled = styled.article`
  max-width: 100%;
  ${props => MCardStyles({ multipleFans: props.twoColumns })};
  padding-top: ${theme.space.sm};
  padding-bottom: ${theme.space.sm};
`;

const NewMessageCardWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NewMessageCardLinkStyled = styled(Link)`
  color: ${theme.colors.black};
  ${MCardLinkStyles('0')};
`;

const BestFansOfMounthCard = ({ item }) => {
  const pathToDialog = useMemo(() => {
    if (item?.id) {
      return generatePath(routes.MESSENGER_DIALOG, {
        id: item?.id,
      });
    }
    return '#';
  }, [item?.id]);

  return (
    <NewMessageCardStyled>
      <Avatar
        $src={item?.receiverAvatar?.squarePreviewUrl}
        $srcSetAvif={item?.receiverAvatar?.squarePreviewAvifUrl}
        $srcSetWebp={item?.receiverAvatar?.squarePreviewWebpUrl}
        personName={item.receiverName}
        type="xs"
      />
      <NewMessageCardWrapperStyled>
        <Text
          type="label2"
          color="black"
          lineHeight="md"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          as="h4"
          mb="xxxs"
        >
          <NewMessageCardLinkStyled
            to={pathToDialog}
            title={item?.receiverName}
          >
            {item?.receiverName}
          </NewMessageCardLinkStyled>
        </Text>
      </NewMessageCardWrapperStyled>
      {!!item?.income && (
        <Badge unansweredBoost>{formatPrice(item?.income, true)}</Badge>
      )}
    </NewMessageCardStyled>
  );
};

BestFansOfMounthCard.propTypes = {
  item: propTypes.shape({
    id: propTypes.string.isRequired,
    receiverName: propTypes.string,
    receiverAvatar: propTypes.string,
    prioritizedMessage: propTypes.shape({
      price: propTypes.number,
      receiverName: propTypes.string,
    }),
  }).isRequired,
};

export default BestFansOfMounthCard;
