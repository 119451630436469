import propTypes from 'prop-types';

import { theme } from 'styles/Theme';

const IconPercent = ({ palette }) => {
  return (
    <svg width="23" viewBox="0 0 23 22" fill="none" aria-hidden="true">
      <path
        fill={palette}
        fillRule="evenodd"
        d="M11.75 2C6.77944 2 2.75 6.02944 2.75 11C2.75 15.9706 6.77944 20 11.75 20C16.7206 20 20.75 15.9706 20.75 11C20.75 6.02944 16.7206 2 11.75 2ZM0.75 11C0.75 4.92487 5.67487 0 11.75 0C17.8251 0 22.75 4.92487 22.75 11C22.75 17.0751 17.8251 22 11.75 22C5.67487 22 0.75 17.0751 0.75 11ZM15.0429 6.29289C15.4334 5.90237 16.0666 5.90237 16.4571 6.29289C16.8476 6.68342 16.8476 7.31658 16.4571 7.70711L8.45711 15.7071C8.06658 16.0976 7.43342 16.0976 7.04289 15.7071C6.65237 15.3166 6.65237 14.6834 7.04289 14.2929L15.0429 6.29289ZM7.25 8C7.25 7.17157 7.92157 6.5 8.75 6.5C9.57843 6.5 10.25 7.17157 10.25 8C10.25 8.82843 9.57843 9.5 8.75 9.5C7.92157 9.5 7.25 8.82843 7.25 8ZM13.25 14C13.25 13.1716 13.9216 12.5 14.75 12.5C15.5784 12.5 16.25 13.1716 16.25 14C16.25 14.8284 15.5784 15.5 14.75 15.5C13.9216 15.5 13.25 14.8284 13.25 14Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconPercent.defaultProps = {
  palette: theme.colors.black,
};

IconPercent.propTypes = {
  palette: propTypes.string,
};

export default IconPercent;
