import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { position, space, system } from 'styled-system';

import { theme } from 'styles/Theme';

const ButtonGoBackStyled = styled.button`
  padding: 1rem;
  margin: -1rem;
  position: relative;
  z-index: 9;
  ${system({
    transform: true,
  })};
  ${space};
  ${position};
`;

const ButtonGoBack = ({ onClick, palette, size, ...props }) => {
  const history = useHistory();
  const goBack = useCallback(() => history.goBack(), [history]);

  return (
    <ButtonGoBackStyled
      type="button"
      aria-label="Revenir"
      onClick={onClick || goBack}
      {...props}
    >
      <svg width={size} viewBox="0 0 12 22" fill="none" aria-hidden="true">
        <path
          d="M11 1L1 11L11 21"
          stroke={palette}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ButtonGoBackStyled>
  );
};

ButtonGoBack.defaultProps = {
  palette: theme.colors.black,
  size: '12',
};

ButtonGoBack.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
};

export default ButtonGoBack;
