import { generatePath } from 'react-router-dom';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getPaymentMethodsCollection = page => {
  return request.get(apiRoutes.PAYMENT_METHODS.COLLECTION, {
    params: { page },
  });
};

export const getPaymentMethod = id => {
  return request.get(generatePath(apiRoutes.PAYMENT_METHODS.ID, { id }));
};

export const savePaymentMethod = paymentMethodId => {
  return request.post(apiRoutes.PAYMENT_METHODS.SAVE, {
    paymentMethodId,
  });
};

export const setupPaymentMethod = () => {
  return request.post(apiRoutes.PAYMENT_METHODS.SETUP);
};

export const patchPaymentMethod = ({ id, isDefault }) => {
  return request.patch(generatePath(apiRoutes.PAYMENT_METHODS.ID, { id }), {
    default: isDefault,
  });
};

export const deletePaymentMethod = id => {
  return request.delete(generatePath(apiRoutes.PAYMENT_METHODS.ID, { id }));
};
