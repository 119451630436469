const IconGradientShare = () => {
  return (
    <svg width="21" viewBox="0 0 21 20" fill="none" aria-hidden="true">
      <path
        fill="url(#iconGradientShare)"
        fillRule="evenodd"
        d="M8.33726 1.13927C8.63036 0.779635 9.07339 0.575872 9.5372 0.587376C9.9438 0.597462 10.2509 0.801233 10.4055 0.911292C10.5703 1.02862 10.758 1.1895 10.9441 1.34906C10.9532 1.35688 10.9623 1.36471 10.9714 1.37252L19.4422 8.63314C19.4486 8.63869 19.4552 8.64431 19.4618 8.64998C19.5662 8.73944 19.6875 8.84333 19.7853 8.94288C19.8959 9.05558 20.0507 9.23459 20.1434 9.4927C20.2611 9.82051 20.2611 10.1791 20.1434 10.5069C20.0507 10.765 19.8959 10.944 19.7853 11.0567C19.6875 11.1563 19.5662 11.2602 19.4618 11.3497C19.4552 11.3553 19.4486 11.3609 19.4422 11.3665L10.9441 18.6505C10.758 18.8101 10.5704 18.971 10.4055 19.0883C10.2509 19.1984 9.9438 19.4021 9.5372 19.4122C9.07339 19.4237 8.63037 19.22 8.33726 18.8603C8.0803 18.5451 8.03518 18.1793 8.01811 17.9903C7.99991 17.7888 7.99995 17.5416 8 17.2964L8 14.1962C7.0578 14.3591 6.14001 14.6516 5.27329 15.0673C3.92197 15.7156 2.72673 16.6483 1.76944 17.8015C1.5003 18.1257 1.05681 18.2464 0.66047 18.1034C0.264132 17.9603 0 17.5841 0 17.1628V16.5508C0.00136238 13.93 0.967538 11.401 2.71418 9.4472C4.1266 7.86724 5.9694 6.75251 8 6.22833V2.73918C8 2.72718 8 2.71517 8 2.70316C7.99995 2.45803 7.99991 2.21085 8.01811 2.00934C8.03518 1.82031 8.0803 1.45455 8.33726 1.13927ZM10 3.17403V7.03444C10 7.52024 9.65086 7.93576 9.17233 8.01947C7.25852 8.35429 5.50691 9.32407 4.20524 10.7801C3.22531 11.8763 2.54774 13.1951 2.22184 14.6075C2.90026 14.0868 3.63269 13.6362 4.40823 13.2641C5.82872 12.5827 7.36428 12.179 8.93232 12.0726C9.20866 12.0539 9.48039 12.1506 9.68276 12.3397C9.88513 12.5288 10 12.7934 10 13.0703V16.8256L17.9634 9.9998L10 3.17403Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="iconGradientShare"
          x1="20.2317"
          y1="19.4127"
          x2="-0.572686"
          y2="18.757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconGradientShare;
