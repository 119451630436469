/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'server';

export const serverSlice = createSlice({
  name,
  initialState,
  reducers: {
    getServerInfoRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getServerInfoSuccess(state, { payload }) {
      state.loading = false;
      state.error = false;
      state.serverInfo = payload;
    },
    getServerInfoFailure(state) {
      state.loading = false;
      state.error = true;
    },
    resetServerInfoState: () => initialState,
  },
});

export const {
  getServerInfoRequest,
  getServerInfoSuccess,
  getServerInfoFailure,
  resetServerInfoState,
} = serverSlice.actions;

export default serverSlice.reducer;
