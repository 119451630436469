import request from 'helpers/requests';

import { apiRoutes } from 'constants/apiRoutes';

export const getShareList = ({ publicName, page }) => {
  return request.get(apiRoutes.SHARE.SHAREABLES, {
    params: {
      publicName,
      page,
    },
  });
};

export const createShareDetails = creator => {
  return request.post(apiRoutes.SHARE.SHARE, { creator });
};
