export const getFormData = object =>
  Object.keys(object).reduce((formData, key) => {
    if (key === 'xAmzMetaNickname') {
      formData.append('x-amz-meta-nickname', object[key]);
    } else {
      formData.append(key, object[key]);
    }
    return formData;
  }, new FormData());

export const amazonCredentialsMapper = (payload, file) => {
  const { id, ...rest } = payload;
  const result = { ...rest, file };
  return [id, result];
};
