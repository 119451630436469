import propTypes from 'prop-types';
import styled from 'styled-components';
import { position, space } from 'styled-system';

import noop from 'tools/noop';

import { InputFocusVisible, Transition } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const MSwitchStyled = styled.label`
  position: relative;
  display: block;
  max-height: ${theme.sizes.switchMaxHeight};
  width: ${theme.sizes.switchWidth};
  flex-shrink: 0;
  ${space};
  ${position};

  input[type='checkbox'] {
    ${InputFocusVisible};
  }
`;

const MSwitchCircleStyled = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  padding: ${theme.space.xxxs};
  ${props =>
    props.active
      ? `background: ${gradients.primary};`
      : `background-color: ${theme.colors.grayMedium};`};
  border-radius: ${theme.radii.xl};

  &:after {
    display: block;
    content: '';
    width: ${theme.sizes.switchCircleSize};
    height: ${theme.sizes.switchCircleSize};
    border-radius: ${theme.radii.circle};
    background-color: ${theme.colors.white};
    ${props => props.active && `transform: translateX(2.2rem);`}
    ${Transition('transform')};
  }
`;

const MSwitch = ({
  checked,
  onChange,
  ariaLabelledby,
  isRequired,
  active,
  type,
  stateStatus,
  errorText,
  ...props
}) => (
  <MSwitchStyled active={checked} {...props}>
    <input
      aria-labelledby={ariaLabelledby}
      onChange={onChange}
      checked={checked}
      type={type || 'checkbox'}
      role="switch"
      aria-checked={checked}
      required={isRequired}
      {...props}
    />
    <MSwitchCircleStyled active={checked} />
  </MSwitchStyled>
);

MSwitch.defaultProps = {
  checked: false,
  onChange: noop,
  isRequired: true,
  active: false,
};

MSwitch.propTypes = {
  checked: propTypes.bool,
  onChange: propTypes.func,
  ariaLabelledby: propTypes.string.isRequired,
  isRequired: propTypes.bool,
  active: propTypes.bool,
  type: propTypes.string,
};

export default MSwitch;
