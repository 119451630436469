import propTypes from 'prop-types';

const IconIncomeActive = ({ uniqueId }) => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18">
      <g clipPath={`url(#income${uniqueId})`}>
        <path
          fill={`url(#income2${uniqueId})`}
          d="M2.25 9a.9.9 0 0 0-.9.9v7.2a.9.9 0 0 0 1.8 0V9.9a.9.9 0 0 0-.9-.9Zm4.5-9a.9.9 0 0 0-.9.9v16.2a.9.9 0 0 0 1.8 0V.9a.9.9 0 0 0-.9-.9Zm9 12.6a.9.9 0 0 0-.9.9v3.6a.9.9 0 1 0 1.8 0v-3.6a.9.9 0 0 0-.9-.9Zm-4.5-7.2a.9.9 0 0 0-.9.9v10.8a.9.9 0 1 0 1.8 0V6.3a.9.9 0 0 0-.9-.9Z"
        />
      </g>
      <defs>
        <linearGradient
          id={`income2${uniqueId}`}
          x1="16.65"
          x2=".911"
          y1="18"
          y2="17.608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id={`income${uniqueId}`}>
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconIncomeActive.defaultProps = {
  uniqueId: '',
};

IconIncomeActive.propTypes = {
  uniqueId: propTypes.string,
};

export default IconIncomeActive;
