export const initialState = {
  error: false,
  loading: false,
  currentUser: null,
  anotherUser: null,
  initialLoading: true,
  topCreators: null,
  topCreatorsLoading: false,
  countries: null,
  creators: [],
  hasMore: true,
  hasMoreNewCreators: true,
  creatorExplorePage: 1,
  newCreatorsPage: 1,
  isSearchLoading: false,
  newCreators: [],
  isWelcomeAudioLoading: false,
  isMailingSettingsLoading: false,
  newAvatarUrl: null,
};
