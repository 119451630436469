/* eslint-disable no-param-reassign */
import axios from 'axios';
import qs from 'query-string';

import { STRIKE_AMAZON_API_BASE_URL } from '../config';

const strikeAmazonApiRequest = axios.create({
  baseURL: STRIKE_AMAZON_API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    /* 'Cache-Control': 'no-cache', */
  },
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    });
  },
});

function strikeAmazonApiResponseHandler(response) {
  return response.data;
}

function strikeAmazonApiRequestMapper(config) {
  return config;
}

function strikeAmazonApiErrorHandler({ response }) {
  const newError = {
    statusCode: response.status,
    ...(response.message && { message: response.message }),
    ...(response.detail && { detail: response.detail }),
    ...(response.violations && { violations: response.violations }),
  };

  return Promise.reject(newError);
}

strikeAmazonApiRequest.interceptors.request.use(strikeAmazonApiRequestMapper);

strikeAmazonApiRequest.interceptors.response.use(
  strikeAmazonApiResponseHandler,
  strikeAmazonApiErrorHandler,
);

export default strikeAmazonApiRequest;
