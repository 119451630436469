const IconDialog = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <path
        fill="#000"
        d="M15.3 5.4h-.9V2.7A2.7 2.7 0 0 0 11.7 0h-9A2.7 2.7 0 0 0 0 2.7v10.8a.9.9 0 0 0 .558.828.901.901 0 0 0 .981-.189l2.529-2.538H5.4v1.296a2.7 2.7 0 0 0 2.7 2.7h6.228l2.133 2.142a.9.9 0 0 0 1.385-.14A.9.9 0 0 0 18 17.1v-9a2.7 2.7 0 0 0-2.7-2.7ZM5.4 8.1v1.701H3.699a.9.9 0 0 0-.639.261l-1.26 1.27V2.7a.9.9 0 0 1 .9-.9h9a.9.9 0 0 1 .9.9v2.7H8.1a2.7 2.7 0 0 0-2.7 2.7Zm10.8 6.831-.9-.9a.901.901 0 0 0-.639-.27H8.1a.9.9 0 0 1-.9-.9v-4.76a.9.9 0 0 1 .9-.9h7.2a.9.9 0 0 1 .9.9v6.83Z"
      />
    </svg>
  );
};

export default IconDialog;
