import { all, call, debounce, put, takeLatest } from 'redux-saga/effects';

import { MIN_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';

import * as services from './services';
import {
  createShareFailure,
  createShareRequest,
  createShareSuccess,
  getShareListFailure,
  getShareListPaginationResetRequest,
  getShareListPaginationResetSuccess,
  getShareListRequest,
  getShareListSuccess,
  incrementShareListPage,
} from './slice';

function* createShareSaga({ payload }) {
  try {
    const shareDetails = yield call(services.createShareDetails, payload);

    yield put(createShareSuccess(shareDetails));
  } catch (error) {
    yield put(createShareFailure());
  }
}

function* getShareListRequestSaga({ payload }) {
  try {
    const shareList = yield call(services.getShareList, payload);
    yield put(getShareListSuccess(shareList));
    if (shareList.length === MIN_ITEMS_PER_PAGE_VALUE) {
      yield put(incrementShareListPage());
    }
  } catch (error) {
    yield put(getShareListFailure());
  }
}

function* getShareListPaginationResetSaga({
  payload: { publicName, setSearchNameValue },
}) {
  try {
    const shareList = yield call(services.getShareList, {
      page: 1,
      publicName,
    });

    if (!shareList.length) {
      yield call(setSearchNameValue, '');
      yield put(
        getShareListPaginationResetRequest({
          name: '',
        }),
      );
    } else {
      if (shareList.length === MIN_ITEMS_PER_PAGE_VALUE) {
        yield put(incrementShareListPage());
      }
      yield put(getShareListPaginationResetSuccess(shareList));
    }
  } catch (error) {
    yield put(getShareListFailure());
  }
}

export default function* watchShare() {
  yield all([
    debounce(300, getShareListRequest, getShareListRequestSaga),
    takeLatest(createShareRequest, createShareSaga),
    takeLatest(
      getShareListPaginationResetRequest,
      getShareListPaginationResetSaga,
    ),
  ]);
}
