export const dialogCreatorListValues = {
  ALL: 'chronological',
  BY_URGENT: 'urgent',
  BY_INCOME: 'revenue',
};

export const dialogCreatorListTypes = [
  {
    id: '1',
    text: 'Tout',
    value: dialogCreatorListValues.ALL,
  },
  {
    id: '2',
    text: 'Urgent',
    value: dialogCreatorListValues.BY_URGENT,
  },
  {
    id: '3',
    text: 'Revenus',
    value: dialogCreatorListValues.BY_INCOME,
  },
];

export const dialogFanListValues = {
  DISCUSSIONS: 'discussions',
  MEDIA: 'media',
};

export const dialogFanListTypes = [
  {
    text: 'discussions',
    value: dialogFanListValues.DISCUSSIONS,
  },
  {
    text: 'media',
    value: dialogFanListValues.MEDIA,
  },
];
