import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, matchPath, useLocation } from 'react-router-dom';

import MessengerRedirect from 'containers/messages/MessengerRedirect';
import {
  resetRequestErrors,
  selectError400,
  selectError500,
} from 'ducks/requestsErrors';
import {
  initializeUser,
  selectIsCreator,
  selectIsFan,
  selectIsInitialLoading,
  selectUser,
} from 'ducks/user';
import useReCAPTCHA from 'hooks/useReCAPTCHA';
import PrivateCreatorRoute from 'layout/PrivateCreatorRoute';
import PrivateFanRoute from 'layout/PrivateFanRoute';
import PrivateRoute from 'layout/PrivateRoute';
import PublicRoute from 'layout/PublicRoute';

import { routes } from 'constants/routes';

import EmptyComponent from 'tools/emptyComponent';
import lazyWithRetry from 'tools/lazyWithRetry';

import LoadingSpinner from 'components/common/LoadingSpinner';

const ModalRoot = lazyWithRetry(() =>
  import('containers/modals/ModalControl' /* webpackChunkName: "modalRoot" */),
);
const CustomVideoRecord = lazyWithRetry(() =>
  import('containers/test/VideoRecordModalTest'),
);
const WelcomePage = lazyWithRetry(() => import('pages/landings/WelcomePage'));
const LoginPage = lazyWithRetry(() => import('pages/login/LoginPage'));
const SignupCreator = lazyWithRetry(() =>
  import('containers/creator/Signup/Signup'),
);
const SubscribeCreator = lazyWithRetry(() =>
  import('containers/creator/Signup/Subscribe'),
);
const ForgotPassword = lazyWithRetry(() =>
  import('containers/ForgotPasswordContainer'),
);
const ChangePassword = lazyWithRetry(() =>
  import('containers/ChangePasswordContainer'),
);
const SettingsProfile = lazyWithRetry(() =>
  import('containers/creator/profile/SettingsProfileContainer'),
);
const SettingsPublicInfo = lazyWithRetry(() =>
  import('containers/creator/profile/SettingsPublicInfoContainer'),
);

const PersonalInformation = lazyWithRetry(() =>
  import('containers/creator/profile/PersonalInformation'),
);
const Monetization = lazyWithRetry(() =>
  import('containers/creator/profile/Monetization'),
);
const PremiumShareContainer = lazyWithRetry(() =>
  import('containers/creator/share/PremiumShareContainer'),
);
const SignupFan = lazyWithRetry(() =>
  import('containers/fan/signup/SignupFan'),
);
const ProfileFan = lazyWithRetry(() =>
  import('containers/fan/profile/ProfileFan'),
);
const SettingsInfoFan = lazyWithRetry(() =>
  import('containers/fan/profile/SettingsInfoFan'),
);
const SettingsPasswordEdit = lazyWithRetry(() =>
  import('containers/creator/profile/SettingsPasswordEditContainer'),
);
const ProfileCreator = lazyWithRetry(() =>
  import('containers/PublicProfileCreatorContainer'),
);
const MessengerCreator = lazyWithRetry(() =>
  import('containers/messages/creator/MessengerCreator'),
);
const MessengerFan = lazyWithRetry(() =>
  import('containers/messages/fan/MessengerFan'),
);
const PaymentHistoryPage = lazyWithRetry(() =>
  import('containers/fan/profile/PaymentHistoryPageContainer'),
);
const ChatCreator = lazyWithRetry(() =>
  import('containers/messages/creator/ChatCreator'),
);
const ChatFan = lazyWithRetry(() => import('containers/messages/fan/ChatFan'));
const NewChatRedirectContainer = lazyWithRetry(() =>
  import('containers/messages/fan/NewChatRedirectContainer'),
);
const SettingsVoiceMessage = lazyWithRetry(() =>
  import('containers/creator/profile/SettingsVoiceMessageContainer'),
);
const DiscoverPage = lazyWithRetry(() =>
  import('containers/fan/discover/DiscoverPageContainer'),
);
const IncomePage = lazyWithRetry(() =>
  import(
    'containers/creator/income/IncomePageContainer' /* webpackChunkName: "revenue" */
  ),
);
const IncomeDetailPage = lazyWithRetry(() =>
  import('containers/creator/income/IncomeDetailPageContainer'),
);
const Page404 = lazyWithRetry(() => import('pages/errors/Page404'));

const SettingsPaymentRedirectContainer = lazyWithRetry(() =>
  import('containers/creator/profile/SettingsPaymentRedirectContainer'),
);
const PaymentMethodsPage = lazyWithRetry(() =>
  import('containers/fan/paymentMethod/PaymentMethodPageContainer'),
);
const EmailNotifications = lazyWithRetry(() =>
  import('containers/creator/profile/EmailNotifications'),
);

const App = ({ stripePromise }) => {
  const IsInitialLoading = useSelector(selectIsInitialLoading);
  const user = useSelector(selectUser);
  const isUserCreator = useSelector(selectIsCreator);
  const isUserFan = useSelector(selectIsFan);
  const error400 = useSelector(selectError400);
  const error500 = useSelector(selectError500);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeUser());
  }, [dispatch]);
  const { i18n } = useTranslation();

  const location = useLocation();

  useReCAPTCHA();

  useEffect(() => {
    if (error400 || error500) {
      dispatch(resetRequestErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  useEffect(() => {
    if (
      location.pathname !== routes.SHARE &&
      !matchPath(location.pathname, {
        path: routes.PREMIUM_PROFILE,
        exact: true,
        strict: true,
      })?.isExact
    ) {
      window.localStorage.removeItem('share_search_value');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== routes.WELCOME) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute('content', '#fcfcfc');
    } else {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute('content', '#EDECFE');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      matchPath(location.pathname, {
        path: routes.MESSENGER_DIALOG,
        exact: true,
        strict: true,
      })?.isExact
    ) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname !== routes.DISCOVER &&
      !matchPath(location.pathname, {
        path: routes.PREMIUM_PROFILE,
        exact: true,
        strict: true,
      })?.isExact
    ) {
      window.localStorage.removeItem('discover_search_value');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !matchPath(location.pathname, {
        path: routes.MESSENGER_NEW_DIALOG,
        exact: true,
        strict: true,
      })?.isExact &&
      !matchPath(location.pathname, {
        path: routes.PREMIUM_PROFILE,
        exact: true,
        strict: true,
      })?.isExact &&
      location.pathname !== routes.LOGIN &&
      !matchPath(location.pathname, {
        path: routes.SIGNUP_FAN,
        exact: true,
        strict: true,
      })?.isExact &&
      location.pathname !== routes.WELCOME &&
      location.pathname !== routes.SUBSCRIBE
    ) {
      window.localStorage.removeItem('share_code');
      window.localStorage.removeItem('share_creator_id');
      window.localStorage.removeItem('share_creator_username');
    }
    if (
      location.pathname !== routes.MESSAGES_FAN &&
      !matchPath(location.pathname, {
        path: routes.MESSAGES_CREATOR,
        exact: true,
        strict: true,
      })?.isExact
    ) {
      window.localStorage.removeItem('dialogs_search_state');
    }
  }, [location.pathname]);

  useEffect(() => {
    const url = window.location.href;
    const isLocalhost = !!url.match(':3000');
    const isHttps = !!url.match(/^https:\/\//);
    if (!(isHttps || isLocalhost)) {
      const httpsLink = url.replace(/^http:\/\//, 'https://');
      window.location.replace(httpsLink);
    }
  }, []);

  useEffect(() => {
    if (user?.locale) {
      i18n.changeLanguage(user.locale);
    } else {
      i18n.changeLanguage(window.navigator.languages[0]);
    }
  }, [i18n, user?.locale]);

  if (IsInitialLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ModalRoot />
      <Switch>
        <Redirect exact from="/" to={routes.MESSAGES} />
        {user?.certified && (
          <Redirect from={routes.SIGNUP_CREATOR} to={routes.MESSAGES} />
        )}
        {isUserFan && !user?.verifiedEmail && (
          <Redirect from={routes.SUBSCRIBE} to={routes.SIGNUP_FAN} />
        )}
        {isUserCreator && !user?.verifiedEmail && (
          <Redirect from={routes.SIGNUP_FAN} to={routes.SUBSCRIBE} />
        )}
        {!user && <Redirect from={routes.SIGNUP_CREATOR} to={routes.WELCOME} />}
        {isUserCreator && user?.verifiedEmail && (
          <PublicRoute path={routes.SIGNUP_CREATOR} component={SignupCreator} />
        )}
        <PublicRoute path={routes.SUBSCRIBE} component={SubscribeCreator} />
        <PublicRoute path={routes.SIGNUP_FAN} component={SignupFan} />
        <PublicRoute path={routes.LOGIN} component={LoginPage} />
        <PublicRoute
          path={routes.WELCOME}
          component={WelcomePage}
          isNotAuthenticatedOnly
        />
        <PublicRoute path="/video-record" component={CustomVideoRecord} />
        <PrivateRoute
          exact
          path={routes.SETTINGS}
          component={isUserCreator ? SettingsProfile : ProfileFan}
        />
        <PrivateRoute
          path={routes.SETTINGS_INFO}
          component={isUserCreator ? SettingsPublicInfo : SettingsInfoFan}
        />
        <PrivateRoute
          path={routes.SETTINGS_PASSWORD_EDIT}
          component={SettingsPasswordEdit}
        />
        <PublicRoute
          path={routes.CHECK_EMAIL}
          component={ForgotPassword}
          isNotAuthenticatedOnly
        />
        <PublicRoute
          path={routes.PASSWORD_RESET}
          component={ChangePassword}
          isNotAuthenticatedOnly
        />
        <PublicRoute path={routes.NOT_FOUND} component={Page404} />
        <PrivateCreatorRoute
          path={routes.PERSONAL_INFORMATION}
          component={PersonalInformation}
        />
        <PrivateCreatorRoute
          path={routes.MONETIZATION}
          component={Monetization}
        />
        <PrivateCreatorRoute
          path={routes.SHARE}
          component={PremiumShareContainer}
        />
        <PrivateRoute path={routes.MESSAGES} component={MessengerRedirect} />
        {isUserCreator && (
          <PrivateRoute
            path={routes.MESSAGES_CREATOR}
            component={MessengerCreator}
          />
        )}
        {!isUserCreator && (
          <PrivateRoute path={routes.MESSAGES_FAN} component={MessengerFan} />
        )}
        <PrivateRoute
          path={routes.MESSENGER_DIALOG}
          component={isUserCreator ? ChatCreator : ChatFan}
          stripePromise={stripePromise}
        />
        <PublicRoute
          path={routes.MESSENGER_NEW_DIALOG}
          component={NewChatRedirectContainer}
          stripePromise={stripePromise}
        />
        <PrivateFanRoute path={routes.DISCOVER} component={DiscoverPage} />
        <PrivateCreatorRoute
          exact
          path={routes.REVENUE}
          component={IncomePage}
        />
        <PublicRoute path={routes.PREMIUM_PROFILE} component={ProfileCreator} />
        <PrivateFanRoute
          path={routes.PAYMENT_HISTORY}
          component={PaymentHistoryPage}
        />
        <PrivateCreatorRoute
          path={routes.WELCOME_AUDIO}
          component={SettingsVoiceMessage}
        />
        <PublicRoute
          path={routes.SETTINGS_PAYMENTS}
          component={SettingsPaymentRedirectContainer}
        />
        <PrivateFanRoute
          path={routes.SETTINGS_PAYMENT_METHODS}
          component={PaymentMethodsPage}
          stripePromise={stripePromise}
        />
        <PrivateCreatorRoute
          path={routes.REVENUE_DETAILS}
          component={IncomeDetailPage}
        />
        <PrivateRoute
          path={routes.EMAIL_NOTIFICATIONS}
          component={EmailNotifications}
        />
        <PublicRoute path={routes.APPLE_DOMAIN} component={EmptyComponent} />
        <Redirect from="*" to={routes.NOT_FOUND} />
      </Switch>
    </Suspense>
  );
};

export default App;
