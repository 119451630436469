import propTypes from 'prop-types';
import styled from 'styled-components';
import { position, space } from 'styled-system';

import noop from 'tools/noop';

import { VisuallyHidden } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

import MSwitch from './MSwitch';

const LabelSwitchStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${breakpoints.md};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: ${theme.space.primaryMd};
  border-radius: ${theme.radii.xs};
  background: ${gradients.primary};
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  ${props =>
    props.isError &&
    `box-shadow: inset 0 0 0 2px ${theme.colors.error};
    color: ${theme.colors.error}`};
  ${space};
  ${position};
`;

const LabelSwitch = ({
  isError,
  checked,
  onChange,
  ariaLabelledby,
  fieldname,
  stateStatus,
  type,
  ...props
}) => {
  return (
    <LabelSwitchStyled isError={isError} {...props}>
      <div id={ariaLabelledby}>{props.labelContent}</div>
      {isError && (
        <VisuallyHidden role="alert" aria-live="polite">
          Ce champ est obligatoire.
        </VisuallyHidden>
      )}
      <MSwitch
        active={checked}
        ariaLabelledby={ariaLabelledby}
        onChange={onChange}
        checked={checked}
        ml="sm"
        {...(fieldname ? { fieldname } : {})}
      />
    </LabelSwitchStyled>
  );
};

LabelSwitch.defaultProps = {
  isError: false,
  checked: false,
  onChange: noop,
};

LabelSwitch.propTypes = {
  labelContent: propTypes.node.isRequired,
  isError: propTypes.bool,
  checked: propTypes.bool,
  onChange: propTypes.func,
  ariaLabelledby: propTypes.string.isRequired,
  type: propTypes.string,
};

export default LabelSwitch;
