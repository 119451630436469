import { matchPath } from 'react-router-dom';

import { routes } from 'constants/routes';

const getPageTitle = (route = '') => {
  if (
    matchPath(route, {
      path: routes.MESSAGES_CREATOR,
      exact: true,
      strict: true,
    })?.isExact
  ) {
    return 'Conversations';
  }
  if (
    matchPath(route, {
      path: routes.MESSAGES_FAN,
      exact: true,
      strict: true,
    })?.isExact
  ) {
    return 'Conversations';
  }
  if (
    matchPath(route, {
      path: routes.SETTINGS_PAYMENTS,
      exact: true,
      strict: true,
    })?.isExact
  ) {
    return 'Facturation & paiements';
  }
  if (
    matchPath(route, {
      path: routes.PREMIUM_PROFILE,
      exact: true,
      strict: true,
    })?.isExact
  ) {
    return 'Profil premium';
  }
  if (
    matchPath(route, {
      path: routes.MESSENGER_DIALOG,
      exact: true,
      strict: true,
    })?.isExact
  ) {
    return 'Chat';
  }
  if (
    matchPath(route, {
      path: routes.MESSENGER_NEW_DIALOG,
      exact: true,
      strict: true,
    })?.isExact
  ) {
    return 'Chat';
  }
  switch (route) {
    case routes.WELCOME:
      return 'Welcome';
    case routes.LOGIN:
      return 'Sign In';
    case routes.SUBSCRIBE:
      return 'Sign Up';
    case routes.SIGNUP_CREATOR:
      return 'Sign Up';
    case routes.SIGNUP_FAN:
      return 'Sign Up';
    case routes.CHECK_EMAIL:
      return 'Mot de passe oublié';
    case routes.PASSWORD_RESET:
      return 'Modifier votre mot de passe';
    case routes.REVENUE:
      return 'Revenus';
    case routes.REVENUE_DETAILS:
      return 'Détail des revenus';
    case routes.SETTINGS:
      return 'Profil';
    case routes.SETTINGS_INFO:
      return 'Vos profil Premium';
    case routes.MONETIZATION:
      return 'Paramètres de votre monétisation';
    case routes.PERSONAL_INFORMATION:
      return 'Mes informations personnelles';
    case routes.WELCOME_AUDIO:
      return 'Modifier votre vocal d’accueil';
    case routes.SETTINGS_PASSWORD_EDIT:
      return 'Modifier votre mot de passe';
    case routes.EMAIL_NOTIFICATIONS:
      return 'Notifications e-mails';
    case routes.SHARE:
      return 'Premium Share';
    case routes.DISCOVER:
      return 'Discover';
    case routes.PAYMENT_HISTORY:
      return 'Historique de paiement';
    case routes.SETTINGS_PAYMENT_METHODS:
      return 'Moyens de paiements';
    default:
      return null;
  }
};

export default getPageTitle;
