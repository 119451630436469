import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';

import { CustomScrollBar } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const ToastContainerStyled = styled(ToastContainer).attrs({
  toastClassName: 'toast',
  bodyClassName: 'toast-body',
})`
  .toast {
    padding: ${theme.space.sm} ${theme.space.primaryMd};
    color: ${theme.colors.white};
    border-radius: ${theme.radii.xs};
    margin-bottom: 0;
    margin-top: ${theme.space.primarySm};
    min-height: ${theme.sizes.toastMinHeight};

    &.Toastify__toast--success {
      background: ${gradients.valid};
    }

    &.Toastify__toast--error {
      background: ${gradients.error};
    }

    .Toastify__close-button {
      opacity: 1;
      margin-left: ${theme.space.md};

      & > svg {
        fill: ${theme.colors.white};
      }
    }
  }

  .toast-body {
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.sm};
    line-height: ${theme.lineHeights.md};
    padding: 0;
    margin: 0;

    .Toastify__toast-icon {
      margin-right: ${theme.space.md};

      & > svg {
        fill: ${theme.colors.white};
      }

      & + div {
        max-height: ${theme.sizes.toastMaxHeight};
        overflow-y: auto;
        ${CustomScrollBar};
      }
    }
  }

  &.Toastify__toast-container--top-center {
    padding: 0 ${theme.space.primaryMd};

    @media only screen and (min-width: 481px) {
      min-width: ${theme.sizes.toastDesktopMinWidth};
      top: 0;
      padding: 0;
    }
  }

  &.Toastify__toast-container {
    z-index: 99999;
  }
`;

const Toast = ({ ...props }) => (
  <ToastContainerStyled
    transition={Slide}
    autoClose={5000} /* TODO: change to false for development */
    hideProgressBar
    {...props}
  />
);

export default Toast;
