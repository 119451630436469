import { all, call, put, takeLatest } from 'redux-saga/effects';

import { DEFAULT_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';

import * as services from './services';
import {
  getCategoriesFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  incrementCategoriesPage,
} from './slice';

function* getCategoriesSaga({ payload: { page } }) {
  try {
    const categories = yield call(services.getCategories, page);
    yield put(getCategoriesSuccess(categories));
    if (categories.length === DEFAULT_ITEMS_PER_PAGE_VALUE) {
      yield put(incrementCategoriesPage());
    }
  } catch (error) {
    yield put(getCategoriesFailure());
  }
}

export default function* watchCategories() {
  yield all([takeLatest(getCategoriesRequest, getCategoriesSaga)]);
}
