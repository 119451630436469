/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'modal';

export const modalSlice = createSlice({
  name,
  initialState,
  reducers: {
    showModal(state, { payload: { modalType, modalProps } }) {
      state.modalType = modalType;
      state.modalProps = modalProps;
      state.isOpen = true;
    },
    hideModal() {
      document.body.classList.remove('no-scroll');
      return initialState;
    },
    setModalOpenState(state, { payload }) {
      state.isOpen = payload;
    },
  },
});

export const { showModal, hideModal, setModalOpenState } = modalSlice.actions;

export default modalSlice.reducer;
