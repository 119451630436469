import {
  getNewUnreadMessage,
  selectDialogId,
  selectIsGuaranteedResponsePending,
  setGuaranteedAnswerUntil,
  setIsGuaranteedResponsePending,
  setIsVideoResponseRequested,
} from 'ducks/dialog';
import { selectAnotherUserId, selectCurrentUserId } from 'ducks/user';
import openUrlInSameTab from 'helpers/openUrlInSameTab';
import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { serverEventsTypes } from 'constants/serverEventsTypes';

import noop from 'tools/noop';

import { selectDialogMessages, selectIsOnMessagePage } from './selectors';
import * as services from './services';
import {
  clearCurrentDialogMessagesData,
  createMassMessageFailure,
  createMassMessageRequest,
  createMassMessageSuccess,
  findLastReadMessageId,
  generatePaymentFailure,
  generatePaymentRequest,
  generatePaymentSuccess,
  getCreatorDialogMessagesRequest,
  getDialogMessagesByIdRequest,
  getDialogMessagesFailure,
  getDialogMessagesReverseSuccess,
  getDialogMessagesSuccess,
  getFanDialogMessagesRequest,
  getMessageFailure,
  getMessagePriceFailure,
  getMessagePriceRequest,
  getMessagePriceSuccess,
  getMessageRequest,
  getMessageSuccess,
  getNewDialogMessagesSuccess,
  incrementMessagesPage,
  patchMessageFailure,
  patchMessageRequest,
  patchMessageSuccess,
  postMassMessageFailure,
  postMassMessageRequest,
  postMassMessageSuccess,
  postMessageFailure,
  postMessageRequest,
  postMessageSuccess,
  readLastMessageFailure,
  readLastMessageRequest,
  readLastMessageSuccess,
  setAdjacentPageWithScrollUpdate,
  setHasMoreBottom,
  setInterlocutorNickname,
  setMessagePageBottom,
  setMessagePageTop,
  setTargetMessagePage,
  updateGuaranteedMessageIsNotPending,
  updateMessageList,
} from './slice';

function* postMessageSaga({
  payload: { values, onSuccess = noop, onFail = noop },
}) {
  try {
    const messageData = yield call(services.postMessage, values);
    yield put(postMessageSuccess(messageData));
    yield call(onSuccess, messageData);
  } catch (error) {
    yield call(onFail);
    yield put(postMessageFailure());
  }
}

function* postMassMessageSaga({
  payload: { values, onSuccess = noop, onFail = noop },
}) {
  try {
    const messageData = yield call(services.postMessage, values);
    yield put(postMassMessageSuccess(messageData));
    yield call(onSuccess, messageData);
  } catch (error) {
    yield call(onFail);
    yield put(postMassMessageFailure());
  }
}

function* patchMessageSaga({ payload: { id, body, onSuccess } }) {
  try {
    const messageData = yield call(services.patchMessage, { id, body });
    yield put(patchMessageSuccess());
    yield call(onSuccess, messageData);
  } catch (error) {
    yield put(patchMessageFailure());
  }
}

function* readLastMessageSaga({ payload }) {
  try {
    const currentUserId = yield select(selectCurrentUserId);
    const messages = yield select(selectDialogMessages);
    if (payload?.length) {
      const lastMessage = payload.find(msg => msg.receiverId === currentUserId);
      if (lastMessage && !lastMessage.read) {
        yield call(services.patchMessage, {
          id: payload.find(msg => msg.receiverId === currentUserId)?.id,
          body: { read: true },
        });
      }
    } else if (messages?.length) {
      const lastMessage = messages.find(
        msg => msg.receiverId === currentUserId,
      );
      if (lastMessage && !lastMessage.read) {
        yield call(services.patchMessage, {
          id: messages.find(msg => msg.receiverId === currentUserId)?.id,
          body: { read: true },
        });
      }
    }
    yield put(readLastMessageSuccess());
  } catch (error) {
    yield put(readLastMessageFailure());
  }
}

function* getFanDialogMessagesSaga({ payload: { nickname, page, onFail } }) {
  try {
    yield put(setInterlocutorNickname(nickname));
    const dialogMessages = yield call(services.getFanDialogMessages, {
      nickname,
      page,
    });
    yield put(incrementMessagesPage());
    if (page === 1) {
      yield put(getNewDialogMessagesSuccess(dialogMessages));
    } else {
      yield put(getDialogMessagesSuccess(dialogMessages));
    }
  } catch (error) {
    yield call(onFail);
    yield put(getDialogMessagesFailure());
  }
}

function* getCreatorDialogMessagesSaga({
  payload: { nickname, page, onFail },
}) {
  try {
    yield put(setInterlocutorNickname(nickname));
    const dialogMessages = yield call(services.getCreatorDialogMessages, {
      nickname,
      page,
    });
    yield put(incrementMessagesPage());
    if (page === 1) {
      yield put(getNewDialogMessagesSuccess(dialogMessages));
    } else {
      yield put(getDialogMessagesSuccess(dialogMessages));
    }
  } catch (error) {
    yield call(onFail);
    yield put(getDialogMessagesFailure());
  }
}

function* getDialogMessagesByIdSaga({
  payload: {
    id,
    page,
    startPage,
    isReverse = false,
    onFail = noop,
    onSuccess = noop,
    targetMessageId = null,
  },
}) {
  try {
    let currentPage = null;
    if (+page) {
      if (isReverse) {
        if (page > 1) {
          currentPage = +page - 1;
        }
      } else {
        currentPage = +page + 1;
      }
    }
    const {
      data: dialogMessages,
      pagination: { page: targetMessagePage },
    } = yield call(services.getDialogMessagesById, {
      id,
      page: startPage || currentPage,
      targetMessageId,
    });
    yield call(onSuccess, dialogMessages);
    if (targetMessageId && dialogMessages?.length) {
      const targetMessageIndex = dialogMessages.findIndex(
        msg => msg.id === targetMessageId,
      );
      if (targetMessageIndex > -1) {
        if (targetMessageIndex < 9) {
          yield put(setAdjacentPageWithScrollUpdate(targetMessagePage - 1));
        }
        if (targetMessageIndex > 15) {
          yield put(setAdjacentPageWithScrollUpdate(targetMessagePage + 1));
        }
      }
    }
    yield put(
      findLastReadMessageId({ payloadMessagesList: dialogMessages, isReverse }),
    );
    if (+currentPage === 1 || +startPage === 1 || +targetMessagePage === 1) {
      yield put(readLastMessageRequest(dialogMessages));
      yield put(setHasMoreBottom(false));
    }
    if (targetMessageId && targetMessagePage) {
      yield put(setTargetMessagePage(targetMessagePage));
      yield put(setMessagePageBottom(targetMessagePage));
      yield put(setMessagePageTop(targetMessagePage));
    } else if (startPage) {
      yield put(setMessagePageBottom(startPage));
      yield put(setMessagePageTop(startPage));
    } else if (isReverse) {
      yield put(setMessagePageBottom(currentPage));
    } else {
      yield put(setMessagePageTop(currentPage));
    }
    if (isReverse) {
      yield put(
        getDialogMessagesReverseSuccess({
          messages: dialogMessages,
          page: targetMessagePage || startPage || currentPage,
        }),
      );
    } else {
      yield put(getDialogMessagesSuccess(dialogMessages));
    }
  } catch (error) {
    yield call(onFail);
    yield put(getDialogMessagesFailure());
  }
}

function* clearCurrentDialogMessagesDataSaga({ payload }) {
  if (payload?.onClearSuccess) {
    yield call(payload.onClearSuccess);
  }
}

function* getMessagePriceSaga({ payload: { values, onSuccess } }) {
  try {
    const messagePrice = yield call(services.getMessagePrices, values);
    yield put(getMessagePriceSuccess(messagePrice));
    yield call(onSuccess);
  } catch (error) {
    yield put(getMessagePriceFailure(error));
  }
}

function* generatePaymentSaga({ payload: { values, onSuccess } }) {
  try {
    const paymentData = yield call(services.postGeneratePayment, values);
    yield put(generatePaymentSuccess(paymentData));
    yield call(onSuccess);
    yield call(openUrlInSameTab(paymentData.stripeSessionUrl));
  } catch (error) {
    yield put(generatePaymentFailure());
  }
}

function* getMessageSaga({ payload: { values, onSuccess } }) {
  try {
    const message = yield call(services.getMessage, values);
    yield put(getMessageSuccess(message));
    yield call(onSuccess);
  } catch (error) {
    yield put(getMessageFailure());
  }
}

function* receiveMessageSaga({ payload }) {
  const isOnMessagePage = yield select(selectIsOnMessagePage);
  const currentUserId = yield select(selectCurrentUserId);
  const dialogId = yield select(selectDialogId);
  const anotherUserId = yield select(selectAnotherUserId);
  const isGuaranteedResponsePending = yield select(
    selectIsGuaranteedResponsePending,
  );
  if (currentUserId !== payload.authorId && !payload.read) {
    yield put(getNewUnreadMessage());
  }
  if (
    isOnMessagePage &&
    (dialogId === payload.dialogId ||
      anotherUserId === payload.authorId ||
      anotherUserId === payload.receiverId)
  ) {
    yield put(updateMessageList(payload));
    if (
      isGuaranteedResponsePending &&
      (payload.mediaObject?.type === 'video' ||
        payload.mediaObject?.type === 'audio')
    ) {
      yield put(updateGuaranteedMessageIsNotPending());
      yield put(setIsGuaranteedResponsePending(false));
      yield put(setIsVideoResponseRequested(false));
      yield put(setGuaranteedAnswerUntil(null));
    }
  }
}

function* messagePaymentPaidSaga({ payload }) {
  const isOnMessagePage = yield select(selectIsOnMessagePage);
  if (isOnMessagePage && payload.message) {
    if (payload.message.answerUntil) {
      yield put(setIsGuaranteedResponsePending(true));
      yield put(setGuaranteedAnswerUntil(payload.message.answerUntil));
      if (payload.message.type === 'video_response') {
        yield put(setIsVideoResponseRequested(true));
      }
    }
    yield put(updateMessageList(payload.message));
  }
}

function* createMassMessageSaga({ payload: { body } }) {
  try {
    yield call(services.createMassMessage, body);
    yield put(createMassMessageSuccess());
  } catch (error) {
    yield put(createMassMessageFailure());
  }
}

export default function* watchMessages() {
  yield all([
    takeLatest(postMessageRequest, postMessageSaga),
    takeLatest(postMassMessageRequest, postMassMessageSaga),
    takeLatest(patchMessageRequest, patchMessageSaga),
    debounce(1000, readLastMessageRequest, readLastMessageSaga),
    takeLatest(getFanDialogMessagesRequest, getFanDialogMessagesSaga),
    takeLatest(getCreatorDialogMessagesRequest, getCreatorDialogMessagesSaga),
    takeLatest(getDialogMessagesByIdRequest, getDialogMessagesByIdSaga),
    takeLatest(
      clearCurrentDialogMessagesData,
      clearCurrentDialogMessagesDataSaga,
    ),
    takeLatest(getMessagePriceRequest, getMessagePriceSaga),
    takeLatest(generatePaymentRequest, generatePaymentSaga),
    takeLatest(getMessageRequest, getMessageSaga),
    takeEvery(serverEventsTypes.MESSAGE, receiveMessageSaga),
    takeEvery(serverEventsTypes.PAYMENT_PAID, messagePaymentPaidSaga),
    takeEvery(createMassMessageRequest, createMassMessageSaga),
  ]);
}
