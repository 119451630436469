import propTypes from 'prop-types';
import styled from 'styled-components';
import { grid, layout } from 'styled-system';

import { theme } from 'styles/Theme';

import Text from './Text';

const MListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  ${layout};
  ${grid};
`;

const MListItemStyled = styled.li`
  ${props =>
    props.bulletMarker &&
    `
      color: ${theme.colors.grayMedium};
      line-height: ${theme.lineHeights.xxs};
      margin-left: 0.8rem;
      &:before {
        content: '•';
        font-size: 70%;
        display: inline-block;
        margin-right: 0.5rem;
      }
  `};
`;

const MList = ({
  items,
  textType = 'body3',
  bulletMarker,
  $isGradient,
  ...props
}) => {
  return (
    <MListStyled {...props}>
      {items.map(item => (
        <MListItemStyled key={item.id} bulletMarker={bulletMarker}>
          <Text
            $isGradient={$isGradient}
            type={textType}
            as="span"
            color={item.active && 'valid'}
            opacity={item.opacity}
          >
            {item.text}
          </Text>
        </MListItemStyled>
      ))}
    </MListStyled>
  );
};

MList.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      text: propTypes.node.isRequired,
      active: propTypes.bool,
      opacity: propTypes.string,
    }).isRequired,
  ).isRequired,
  textType: propTypes.string,
  bulletMarker: propTypes.bool,
  $isGradient: propTypes.bool,
};

export default MList;
