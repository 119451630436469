/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getUserSuccess } from 'ducks/user';

import { serverEventsTypes } from 'constants/serverEventsTypes';

import { initialState } from './initialState';

const name = 'message';

export const messageSlice = createSlice({
  name,
  initialState,
  reducers: {
    postMessageRequest(state) {
      state.loading = true;
    },
    postMessageSuccess(state) {
      state.loading = false;
    },
    postMessageFailure(state) {
      state.loading = false;
    },
    patchMessageRequest(state) {
      state.loading = true;
    },
    patchMessageSuccess(state) {
      state.loading = false;
    },
    patchMessageFailure(state) {
      state.loading = false;
    },
    readLastMessageRequest(state) {
      state.loading = true;
    },
    readLastMessageSuccess(state) {
      state.loading = false;
    },
    readLastMessageFailure(state) {
      state.loading = false;
    },
    getFanDialogMessagesRequest(state) {
      state.loading = true;
    },
    getCreatorDialogMessagesRequest(state) {
      state.loading = true;
    },
    getDialogMessagesByIdRequest(state) {
      state.loading = true;
    },
    getDialogMessagesSuccess(state, { payload }) {
      state.loading = false;
      state.dialogMessages =
        payload?.length !== 0
          ? [...state.dialogMessages, ...payload]
          : state.dialogMessages;
      state.hasMoreTop = payload?.length === 20;
      state.firstLoading = false;
    },
    getDialogMessagesReverseSuccess(state, { payload: { messages, page } }) {
      state.loading = false;
      state.dialogMessages =
        messages?.length !== 0
          ? [...messages, ...state.dialogMessages]
          : state.dialogMessages;
      state.hasMoreBottom = page > 1;
      state.firstLoading = false;
    },
    getNewDialogMessagesSuccess(state, { payload }) {
      state.loading = false;
      state.firstLoading = false;
      state.dialogMessages = payload;
      state.hasMore = payload?.length === 20;
    },
    getDialogMessagesFailure(state) {
      state.loading = false;
      state.firstLoading = false;
      state.hasMore = false;
      state.error = false;
    },
    setHasMoreBottom(state, { payload }) {
      state.hasMoreBottom = !!payload;
    },
    clearCurrentDialogMessagesData(state) {
      state.dialogMessages = [];
      state.messagesPageTop = 1;
      state.messagesPageBottom = 1;
      state.hasMoreTop = true;
      state.hasMoreBottom = true;
      state.targetMessagePage = null;
      state.interlocutorNickname = null;
      state.isVideoResponseRequested = false;
      state.firstLoading = true;
      state.guaranteedAnswerUntil = null;
    },
    setInterlocutorNickname(state, { payload }) {
      state.interlocutorNickname = payload;
    },
    resetError(state) {
      state.error = null;
    },
    setAdjacentPageWithScrollUpdate(state, { payload }) {
      state.adjacentPageWithScrollUpdate = payload;
    },
    setMessagePage(state, { payload }) {
      state.messagesPage = payload;
    },
    setMessagePageTop(state, { payload }) {
      state.messagesPageTop = payload;
    },
    setMessagePageBottom(state, { payload }) {
      state.messagesPageBottom = payload;
    },
    setTargetMessagePage(state, { payload }) {
      state.targetMessagePage = payload;
    },
    incrementMessagesPage(state, { payload: startPage }) {
      if (+startPage) {
        state.messagesPage = +startPage + 1;
      } else {
        state.messagesPage += 1;
      }
    },
    decrementMessagesPage(state, { payload: startPage }) {
      if (+startPage) {
        if (startPage > 1) {
          state.messagesPage = startPage - 1;
        }
      } else if (state.messagesPage > 1) {
        state.messagesPage -= 1;
      }
    },
    setMessagesPage(state, { payload: page }) {
      state.messagesPage = page;
    },
    setToInitialMessagesPage(state) {
      state.messagesPage = 1;
    },
    resetHasMore(state) {
      state.hasMore = true;
      state.hasMoreTop = true;
      state.hasMoreBottom = true;
    },
    getMessagePriceRequest(state) {
      state.loading = true;
    },
    getMessagePriceSuccess(state, { payload }) {
      state.loading = false;
      state.messagePrice = payload;
    },
    getMessagePriceFailure(state, { payload }) {
      state.loading = false;
      state.messagePrice = null;
      if (payload.statusCode === 400) {
        state.error = payload;
      }
    },
    generatePaymentRequest(state) {
      state.loading = true;
    },
    generatePaymentSuccess(state, { payload }) {
      state.loading = false;
      state.paymentData = payload;
    },
    generatePaymentFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    getReactedMessageRequest(state) {
      state.reactedMessageLoading = true;
      state.error = false;
    },
    setIsReactedMessageLoading(state, { payload }) {
      state.reactedMessages[`loading${payload}`] = true;
      state.error = false;
    },
    getReactedMessageSuccess(state, { payload: { index, body } }) {
      state.reactedMessageLoading = false;
      state.error = false;
      state.reactedMessages[`loading${index}`] = false;
      state.reactedMessages[`publication${index}`] = {
        ...state.reactedMessages[`publication${index}`],
        ...body,
      };
    },
    getReactedMessageFailure(state, { payload }) {
      state.reactedMessages[`loading${payload}`] = false;
      state.loading = false;
      state.error = true;
    },
    resetReactedMessages(state) {
      state.reactedMessages = {};
    },
    getMessageRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getMessageSuccess(state, { payload }) {
      state.loading = false;
      state.messageData = payload;
      state.error = false;
    },
    getMessageFailure(state) {
      state.loading = false;
      state.error = true;
    },
    clearCurrentMessageData(state) {
      state.messageData = null;
      state.error = false;
      state.loading = false;
    },
    resetMessageState: () => initialState,
    updateMessageList(state, { payload }) {
      if (payload) {
        if (
          !state.dialogMessages?.length ||
          !state.dialogMessages.find(message => payload.id === message.id)
        ) {
          state.dialogMessages.unshift(payload);
        } else if (state.dialogMessages?.length) {
          state.dialogMessages = state.dialogMessages.map(message => {
            if (payload.id === message.id) {
              return payload;
            }
            return message;
          });
        }
      }
    },
    createMassMessageRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createMassMessageSuccess(state) {
      state.loading = false;
      state.error = false;
    },
    createMassMessageFailure(state) {
      state.loading = false;
      state.error = true;
    },
    setIsOnMessagePage(state, { payload }) {
      state.isOnMessagePage = payload;
    },
    updateGuaranteedMessageIsNotPending(state) {
      if (state.dialogMessages?.length) {
        state.dialogMessages = state.dialogMessages.map(message => {
          if (message.answerUntil) {
            return {
              ...message,
              answerUntil: null,
            };
          }
          return message;
        });
      }
    },
    postMassMessageRequest(state) {
      state.loading = true;
    },
    postMassMessageSuccess(state) {
      state.loading = false;
    },
    postMassMessageFailure(state) {
      state.loading = false;
    },
    findLastReadMessageId(
      state,
      { payload: { payloadMessagesList, isReverse } },
    ) {
      if (!(isReverse && state.lastReadMessageId)) {
        if (
          isReverse &&
          state.dialogMessages?.length &&
          payloadMessagesList?.length &&
          !payloadMessagesList[payloadMessagesList?.length - 1]?.read &&
          state.currentUserId !==
            payloadMessagesList[payloadMessagesList?.length - 1]?.authorId &&
          state.dialogMessages[0]?.read
        ) {
          state.lastReadMessageId = state.dialogMessages[0].id;
        } else if (
          state.dialogMessages?.length &&
          payloadMessagesList?.length &&
          !state.dialogMessages[state.dialogMessages?.length - 1]?.read &&
          state.currentUserId !==
            state.dialogMessages[state.dialogMessages?.length - 1]?.authorId &&
          payloadMessagesList[0]?.read
        ) {
          state.lastReadMessageId = payloadMessagesList[0].id;
        } else if (!payloadMessagesList[0]?.read) {
          const lastIncomingUnreadMessageIndex =
            payloadMessagesList?.findLastIndex(
              msg => state.currentUserId !== msg?.authorId && !msg.read,
            );
          if (
            lastIncomingUnreadMessageIndex > -1 &&
            payloadMessagesList[lastIncomingUnreadMessageIndex + 1]
          ) {
            state.lastReadMessageId =
              payloadMessagesList[lastIncomingUnreadMessageIndex + 1].id;
          }
        }
      }
    },
    clearLastReadMessageId(state) {
      state.lastReadMessageId = null;
    },
  },
  /* extraReducers: {
    [serverEventsTypes.DIALOG_READ]: (state, { payload }) => {
      if (
        !!state.dialogMessages?.length &&
        payload.dialogId === state.dialogMessages[0]?.dialogId
      ) {
        state.dialogMessages = state.dialogMessages.map(message => {
          if (!message.read) {
            return {
              ...message,
              read: true,
            };
          }
          return message;
        });
      }
    },
    [getUserSuccess]: (state, { payload }) => {
      state.currentUserNickname = payload.nickname;
    },
  }, */
  extraReducers: builder => {
    builder
      .addCase(serverEventsTypes.DIALOG_READ, (state, { payload }) => {
        if (
          !!state.dialogMessages?.length &&
          payload.dialogId === state.dialogMessages[0]?.dialogId
        ) {
          state.dialogMessages = state.dialogMessages.map(message => {
            if (!message.read) {
              return {
                ...message,
                read: true,
              };
            }
            return message;
          });
        }
      })
      .addCase(getUserSuccess, (state, { payload }) => {
        state.currentUserId = payload.id;
      });
  },
});

export const {
  postMessageRequest,
  postMessageSuccess,
  postMessageFailure,
  getFanDialogMessagesRequest,
  getCreatorDialogMessagesRequest,
  getDialogMessagesByIdRequest,
  getDialogMessagesFailure,
  getDialogMessagesSuccess,
  getDialogMessagesReverseSuccess,
  clearCurrentDialogMessagesData,
  setInterlocutorNickname,
  patchMessageRequest,
  patchMessageSuccess,
  patchMessageFailure,
  resetError,
  incrementMessagesPage,
  decrementMessagesPage,
  setMessagesPage,
  setMessagePageTop,
  setMessagePageBottom,
  setTargetMessagePage,
  setToInitialMessagesPage,
  resetHasMore,
  getMessagePriceRequest,
  getMessagePriceSuccess,
  getMessagePriceFailure,
  generatePaymentRequest,
  generatePaymentSuccess,
  generatePaymentFailure,
  getNewDialogMessagesSuccess,
  getReactedMessageRequest,
  setIsReactedMessageLoading,
  getReactedMessageSuccess,
  getReactedMessageFailure,
  resetReactedMessages,
  clearCurrentMessageData,
  getMessageRequest,
  getMessageSuccess,
  getMessageFailure,
  resetMessageState,
  updateMessageList,
  createMassMessageRequest,
  createMassMessageSuccess,
  createMassMessageFailure,
  readLastMessageRequest,
  readLastMessageSuccess,
  readLastMessageFailure,
  setIsOnMessagePage,
  updateGuaranteedMessageIsNotPending,
  postMassMessageRequest,
  postMassMessageSuccess,
  postMassMessageFailure,
  setMessagePage,
  findLastReadMessageId,
  clearLastReadMessageId,
  setHasMoreBottom,
  setAdjacentPageWithScrollUpdate,
} = messageSlice.actions;

export default messageSlice.reducer;
