/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'mediaObject';

export const mediaObjectSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFileSuccess(state, { payload }) {
      state.file = payload;
    },
    setFileRequest(state) {
      state.error = false;
    },
    setFileFailure(state) {
      state.error = true;
    },
    resetFile(state) {
      state.file = null;
    },
    setPreviewUrl(state, { payload }) {
      state.previewUrl = payload.previewUrl;
      state.blurredPreviewUrl = payload.blurredPreviewUrl;
    },
    setSrcUrl(state, { payload }) {
      state.src = payload;
    },
    resetPreviewUrl(state) {
      state.previewUrl = null;
      state.blurredPreviewUrl = null;
    },
    resetSrcUrl(state) {
      state.src = null;
    },
    uploadMediaRequest(state) {
      state.loading = true;
      state.error = false;
    },
    uploadAudioRequest(state) {
      state.loading = true;
      state.error = false;
    },
    uploadMediaSuccess(state) {
      state.loading = false;
      state.error = false;
    },
    uploadMediaFailure(state) {
      state.loading = false;
      state.error = true;
    },
    setMediaId(state, { payload }) {
      state.mediaId = payload;
    },
    resetMediaId(state) {
      state.mediaId = null;
    },
    getMediaRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getMediaSuccess(state) {
      state.loading = false;
      state.error = false;
      state.isMediaObjectUploaded = false;
    },
    getMediaFailure(state) {
      state.loading = false;
      state.error = true;
      state.isMediaObjectUploaded = false;
    },
    setMediaType(state, { payload }) {
      state.mediaType = payload;
    },
    clearMediaObjectData(state) {
      state.mediaId = null;
      state.previewUrl = null;
      state.blurredPreviewUrl = null;
      state.src = null;
      state.mediaType = null;
    },
    getMediaMessagesRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getMediaMessagesSuccess(state, { payload: { data, hasMore } }) {
      state.loading = false;
      state.error = false;
      state.mediaMessages = [...state.mediaMessages, ...data];
      state.hasMore = hasMore;
    },
    getMediaMessagesFailure(state) {
      state.loading = false;
      state.error = true;
      state.hasMore = false;
    },
    resetMediaObjectState: () => initialState,
    incrementMediaMessagesPage(state) {
      state.page += 1;
    },
    resetMediaMessagessPage(state) {
      state.page = 1;
    },
    resetMediaMessagessHasMore(state) {
      state.hasMore = true;
    },
    requestMediaDetails(state) {
      state.loading = true;
      state.error = false;
    },
    requestMediaDetailsSuccess(state, { payload: { data } }) {
      state.loading = false;
      state.error = false;
      state.mediaDetails = data;
    },
    requestMediaDetailsFailure(state) {
      state.loading = false;
      state.error = true;
    },
    clearMediaDetails(state) {
      state.loading = false;
      state.error = false;
      state.mediaDetails = null;
    },
    setIsMediaLoadingStarted(state, { payload }) {
      state.isMediaLoadingStarted = payload;
    },
    setIsMediaObjectUploaded(state, { payload }) {
      state.isMediaObjectUploaded = payload;
    },
    setPlayingVoiceMessageId(state, { payload }) {
      state.playingVoiceMessageId = payload;
    },
    setDebugData(state, { payload }) {
      state.debugData = payload;
    },
    setDebugData2(state, { payload }) {
      state.debugDataSecond = payload;
    },
    setDebugData3(state, { payload }) {
      state.debugDataThird = payload;
    },
    setDebugData4(state, { payload }) {
      state.debugData4 = payload;
    },
    setIsFileLoading(state) {
      state.isFileLoading = true;
    },
    resetIsFileLoading(state) {
      state.isFileLoading = false;
    },
    setAudioPlayingNow(state, { payload }) {
      state.audioPlayingNow = payload;
    },
  },
});

export const {
  clearMediaDetails,
  resetMediaMessagessHasMore,
  requestMediaDetails,
  requestMediaDetailsSuccess,
  requestMediaDetailsFailure,
  resetMediaMessagessPage,
  incrementMediaMessagesPage,
  setFileSuccess,
  setFileRequest,
  setFileFailure,
  resetFile,
  setPreviewUrl,
  resetPreviewUrl,
  uploadMediaRequest,
  uploadMediaSuccess,
  uploadMediaFailure,
  setMediaId,
  resetMediaId,
  getMediaRequest,
  getMediaSuccess,
  getMediaFailure,
  setMediaType,
  clearMediaObjectData,
  getMediaMessagesRequest,
  getMediaMessagesSuccess,
  getMediaMessagesFailure,
  resetMediaObjectState,
  setSrcUrl,
  resetSrcUrl,
  setIsMediaLoadingStarted,
  setIsMediaObjectUploaded,
  setPlayingVoiceMessageId,
  setDebugData,
  setDebugData2,
  setDebugData3,
  setDebugData4,
  setIsFileLoading,
  resetIsFileLoading,
  uploadAudioRequest,
  setAudioPlayingNow,
} = mediaObjectSlice.actions;

export default mediaObjectSlice.reducer;
