import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { theme } from 'styles/Theme';

const IconPhotoMediaStyled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconPhotoMedia = ({ palette, size, ...props }) => {
  return (
    <IconPhotoMediaStyled
      width={size}
      viewBox="0 0 18 17"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.168.75H10.832a2.25 2.25 0 0 1 2.25 1.621l.004.017c.035.104.043.126.05.142a.75.75 0 0 0 .65.469l.16.001h.042c.236 0 .41 0 .558.015a3 3 0 0 1 2.69 2.69c.014.148.014.314.014.539V12.181c0 .604 0 1.102-.033 1.508-.035.421-.108.809-.294 1.173a3 3 0 0 1-1.311 1.311c-.364.186-.752.26-1.173.294-.406.033-.904.033-1.508.033H5.069c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.108-1.173-.294a3 3 0 0 1-1.311-1.311c-.185-.364-.26-.752-.294-1.173C.75 13.283.75 12.785.75 12.18V6.244c0-.225 0-.391.015-.54a3 3 0 0 1 2.69-2.69C3.602 3 3.775 3 4.011 3h.042l.16-.001a.75.75 0 0 0 .65-.47 3.14 3.14 0 0 0 .05-.141l.005-.017A2.25 2.25 0 0 1 6.945.754c.069-.004.14-.004.223-.004ZM9 7.125a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.75 2.25a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z"
        fill={palette}
      />
    </IconPhotoMediaStyled>
  );
};

IconPhotoMedia.defaultProps = {
  size: '18',
  palette: theme.colors.white,
};

IconPhotoMedia.propTypes = {
  size: propTypes.string,
  palette: propTypes.string,
};

export default IconPhotoMedia;
