import { generatePath } from 'react-router-dom';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getCategories = page => {
  return request.get(apiRoutes.CATEGORY.CATEGORIES, { params: { page } });
};

export const getCategory = ({ id }) => {
  return request.get(generatePath(apiRoutes.CATEGORY.CATEGORY_DATA, { id }));
};
