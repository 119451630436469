import { Suspense, useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { validateStatus } from 'constants/validateStatus';

import lazyWithRetry from 'tools/lazyWithRetry';

import { LoadingSpinner, MBox } from 'components/common';
import { IconCheckInput } from 'components/icons';
import { Text, TextLabel } from 'components/texts';

import {
  CustomScrollBar,
  GradientBorderRadius,
  InputBaseStyles,
  InputIconStyles,
  Transition,
} from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const PhoneInput = lazyWithRetry(() =>
  import('react-phone-input-2' /* webpackChunkName: "phoneInput" */),
);

const PhoneSelectWrapperStyled = styled.div`
  position: relative;

  .react-tel-input {
    font-family: ${theme.fonts.main};

    &.error .form-control {
      border-width: ${theme.borderWidths.sm};
      border-color: ${theme.colors.error};
    }

    .form-control {
      ${InputBaseStyles};
      height: ${theme.sizes.inputHeight};
      border-color: ${theme.colors.grayLight};
      padding-left: 8rem;

      &:focus,
      &.open {
        outline: none;
        ${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.xs}`)};
      }
    }

    .flag-dropdown {
      background-color: transparent;
      border: none;

      .selected-flag {
        position: relative;
        background-color: transparent;
        padding-left: ${theme.space.primaryMd};
        width: 7rem;

        &:after {
          content: '';
          display: block;
          width: 0.1rem;
          height: 2.2rem;
          background-color: ${theme.colors.grayLight};
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        .arrow {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 12 8' fill='none'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M0.410704 0.910704C0.736141 0.585267 1.26378 0.585267 1.58921 0.910704L5.99996 5.32145L10.4107 0.910704C10.7361 0.585267 11.2638 0.585267 11.5892 0.910704C11.9147 1.23614 11.9147 1.76378 11.5892 2.08921L6.58921 7.08921C6.26378 7.41465 5.73614 7.41465 5.4107 7.08921L0.410704 2.08921C0.0852667 1.76378 0.0852667 1.23614 0.410704 0.910704Z' fill='%23999999' /%3E%3C/svg%3E");
          width: 1.2rem;
          height: 0.8rem;
          border: none;
          margin-top: 0;
          transform: translateY(-50%);
          left: 3rem;
          ${Transition('transform')};

          &.up {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }

      &.open,
      &.open .selected-flag {
        background-color: transparent;
      }

      .country-list {
        ${CustomScrollBar};
      }
    }
  }
`;

const MInputCheckMarkStyled = styled.span`
  ${InputIconStyles};
  right: ${theme.space.primaryMd};
`;

const PhoneSelect = ({
  label,
  isRequired,
  stateStatus,
  errorText,
  helperText,
  idLabel,
  fieldName,
  onChange,
  ...props
}) => {
  const { SUCCESS, ERROR, LOADING } = validateStatus;
  const getIconStatus = useCallback(
    status => {
      switch (status) {
        case SUCCESS:
          return <IconCheckInput />;
        case ERROR:
          return null;
        case LOADING:
          return <LoadingSpinner my="0" size="16" position="relative" />;
        default:
          return null;
      }
    },
    [ERROR, LOADING, SUCCESS],
  );
  return (
    <MBox>
      <div>
        {label && (
          <TextLabel id={idLabel} isRequired={isRequired}>
            {label}
          </TextLabel>
        )}
        <PhoneSelectWrapperStyled>
          <Suspense fallback={<LoadingSpinner position="relative" />}>
            <PhoneInput
              country="fr"
              inputProps={{
                name: idLabel,
                required: isRequired,
                'aria-labelledby': `${idLabel} ${idLabel}Error`,
              }}
              {...(stateStatus === ERROR ? { className: 'error' } : {})}
              onChange={onChange}
              {...props}
            />
          </Suspense>
          {(stateStatus === SUCCESS || stateStatus === LOADING) && (
            <MInputCheckMarkStyled
              aria-label={`La valeur d'entrée est ${stateStatus}`}
            >
              {getIconStatus(stateStatus)}
            </MInputCheckMarkStyled>
          )}
        </PhoneSelectWrapperStyled>
      </div>
      {stateStatus === ERROR && errorText && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          id={`${idLabel}Error`}
          mt="xxs"
          color="error"
        >
          {errorText}
        </Text>
      )}
      {helperText && (
        <Text type="body2" color="grayMedium" mt="xxs" lineHeight="sm">
          {helperText}
        </Text>
      )}
    </MBox>
  );
};

PhoneSelect.defaultProps = {
  label: null,
  stateStatus: null,
  errorText: null,
  isRequired: true,
  helperText: null,
};

PhoneSelect.propTypes = {
  idLabel: propTypes.string.isRequired,
  label: propTypes.string,
  stateStatus: propTypes.string,
  errorText: propTypes.string,
  isRequired: propTypes.bool,
  helperText: propTypes.string,
  fieldName: propTypes.string,
  onChange: propTypes.func,
};

export default PhoneSelect;
