const IconImageGradient = () => {
  return (
    <svg width="25" viewBox="0 0 25 24" fill="none" aria-hidden="true">
      <path
        fill="url(#IconImageGradient)"
        fillOpacity=".3"
        fillRule="evenodd"
        d="M7.592 2h10.483c.805 0 1.469 0 2.01.044.562.046 1.079.145 1.564.392a4 4 0 0 1 1.748 1.748c.248.485.346 1.002.392 1.564.044.541.044 1.206.044 2.01V16.242c0 .805 0 1.47-.044 2.01-.046.563-.144 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.205.044-2.01.044H7.592c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.391-1.564-.045-.541-.045-1.206-.045-2.01V7.758c0-.805 0-1.47.045-2.01.045-.563.144-1.08.391-1.565a4 4 0 0 1 1.748-1.748c.486-.247 1.002-.346 1.564-.392C6.123 2 6.787 2 7.592 2Zm14.241 10.586V7.8c0-.857 0-1.439-.037-1.889-.036-.438-.101-.663-.18-.819a2 2 0 0 0-.875-.874c-.156-.08-.38-.145-.819-.18C19.472 4 18.89 4 18.033 4h-10.4c-.856 0-1.439 0-1.889.038-.438.035-.662.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.144.38-.18.819-.037.45-.038 1.032-.038 1.889v8.4c0 .857.001 1.439.038 1.889.036.438.1.663.18.819a2 2 0 0 0 .692.768l6.274-6.274a9.18 9.18 0 0 1 .512-.488c.175-.148.397-.308.686-.402a2 2 0 0 1 1.236 0c.29.094.512.254.687.402.159.135.333.31.511.488l.184.184 2.184-2.184a9.18 9.18 0 0 1 .512-.488c.175-.148.397-.308.686-.402a2 2 0 0 1 1.236 0c.29.094.512.254.687.402.159.135.333.31.511.488l1.184 1.184Zm-8.575 2.252a8.23 8.23 0 0 0-.414-.4c-.004-.002-.008-.005-.01-.008l-.01.009a8.14 8.14 0 0 0-.415.4l-5.161 5.16.385.001H18.42l-5.161-5.162Zm7.666 4.838L16.247 15l2.162-2.162c.21-.21.325-.323.414-.4l.01-.008.01.009c.09.076.205.19.415.4l2.575 2.575v.786c0 .857 0 1.439-.037 1.889-.036.438-.101.663-.18.819a2 2 0 0 1-.692.768ZM8.834 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="IconImageGradient"
          x1="23.833"
          x2="1.212"
          y1="22"
          y2="21.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconImageGradient;
