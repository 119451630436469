import propTypes from 'prop-types';
import styled from 'styled-components';

const IconPauseStyled = styled.svg`
  flex-shrink: 0;
`;

const IconPause = ({ size }) => {
  return (
    <IconPauseStyled
      fill="none"
      width={size}
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M1.333 16C1.333 7.9 7.9 1.333 16 1.333S30.667 7.9 30.667 16 24.1 30.667 16 30.667 1.333 24.1 1.333 16Zm11.334-5.333c.736 0 1.333.597 1.333 1.333v8a1.333 1.333 0 1 1-2.667 0v-8c0-.736.597-1.333 1.334-1.333Zm6.666 0c.737 0 1.334.597 1.334 1.333v8A1.333 1.333 0 1 1 18 20v-8c0-.736.597-1.333 1.333-1.333Z"
        clipRule="evenodd"
      />
    </IconPauseStyled>
  );
};

IconPause.defaultProps = {
  size: '32',
};

IconPause.propTypes = {
  size: propTypes.string,
};

export default IconPause;
