import { useCallback } from 'react';

import { MButton } from 'components/buttons';
import { Icon500 } from 'components/icons';
import { EmptyMainLayout, MLayout } from 'components/layouts';
import { Text } from 'components/texts';

import { VisuallyHidden } from 'styles/MixinStyle';

const Page500 = () => {
  const reloadPage = useCallback(() => {
    window.location.reload(false);
  }, []);
  return (
    <MLayout
      hasBackButton={false}
      hasNavigation
      ariaLabelledby="page500"
      isCenteredWithHeader
      main={
        <>
          <VisuallyHidden as="h1" id="page500">
            Page d&apos;erreur 500
          </VisuallyHidden>
          <EmptyMainLayout
            title="Oups, on a un problème"
            subtitle={
              <Text as="span" type="label1">
                Erreur 500
              </Text>
            }
            icon={<Icon500 />}
            my="auto"
          >
            <MButton
              type="button"
              display="inline-block"
              primary
              onClick={reloadPage}
            >
              Réessayer
            </MButton>
          </EmptyMainLayout>
        </>
      }
    />
  );
};

export default Page500;
