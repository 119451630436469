export const routes = {
  MESSAGES: '/messages',
  SUBSCRIBE: '/signup/subscribe',
  LOGIN: '/login',
  SIGNUP_FAN: '/signup/fan',
  SIGNUP_CREATOR: '/signup/creator',
  WELCOME: '/welcome',
  SETTINGS: '/settings',
  SETTINGS_INFO: '/settings/information',
  SETTINGS_PASSWORD_EDIT: '/settings/password-edit',
  SETTINGS_PAYMENTS: '/settings/payments/:tab(my-company|payment-info)',
  SETTINGS_PAYMENT_METHODS: '/settings/payment-methods',
  CHECK_EMAIL: '/check-email',
  PASSWORD_RESET: '/password-reset',
  NOT_FOUND: '/not-found',
  PERSONAL_INFORMATION: '/settings/personal-information',
  MONETIZATION: '/settings/monetization',
  SHARE: '/share',
  MESSAGES_CREATOR: '/messenger/:tab(chronological|urgent|revenue)',
  MESSAGES_FAN: '/messenger',
  MESSENGER_DIALOG: '/dialog/:id',
  MESSENGER_NEW_DIALOG: '/new-dialog/:id',
  DISCOVER: '/discover',
  REVENUE: '/revenue',
  PREMIUM_PROFILE: '/@:nickname',
  PAYMENT_HISTORY: '/settings/payment-history',
  WELCOME_AUDIO: '/settings/welcome-audio',
  REVENUE_DETAILS: '/revenue/details',
  SERVICE_UNAVAILIBLE: '/service-unavailible',
  EMAIL_NOTIFICATIONS: '/settings/email-notifications',
  APPLE_DOMAIN: '/.well-known/apple-developer-merchantid-domain-association',
};
