import { generatePath } from 'react-router-dom';

import restCountriesRequest from 'helpers/restCountriesRequests';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getUserData = () => {
  return request.get(apiRoutes.USER.CURRENT_USER_DATA);
};

export const deleteUserData = () => {
  return request.post(apiRoutes.USER.DELETE);
};

export const restoreUserData = () => {
  return request.post(apiRoutes.USER.RESTORE_USER);
};

export const updateUserData = body => {
  return request.patch(apiRoutes.USER.CURRENT_USER_DATA, body);
};

export const getCountries = () => {
  return restCountriesRequest.get(apiRoutes.ALL_COUNTRIES, {
    params: { fields: 'name,cca3' },
  });
};

export const getCreatorDataById = id => {
  return request.get(generatePath(apiRoutes.USER.CREATOR_DATA, { id }));
};

export const getFanDataById = id => {
  return request.get(generatePath(apiRoutes.USER.FAN_DATA, { id }));
};

export const getCreatorDataByNickname = nickname => {
  return request.get(
    generatePath(apiRoutes.USER.CREATOR_DATA, { id: nickname }),
    {
      params: { input: 'username' },
    },
  );
};

export const getFanDataByNickname = nickname => {
  return request.get(generatePath(apiRoutes.USER.FAN_DATA, { id: nickname }));
};

export const getTopCreators = () => {
  return request.get(apiRoutes.USER.TOP_CREATORS);
};

export const getCreators = () => {
  return request.get(apiRoutes.USER.CREATORS);
};

export const getCreatorsBySearch = ({ page, name, sort }) => {
  return request.get(apiRoutes.USER.CREATORS_SEARCH, {
    params: {
      page,
      publicName: name,
      sort,
    },
  });
};

export const getMailingSettings = () => {
  return request.get(apiRoutes.MAILING_SETTINGS.WITHOUT_ID);
};

export const patchMailingSettings = ({
  id,
  body: { notifyGuaranteedMessage, notifyRefund },
}) => {
  return request.patch(
    generatePath(apiRoutes.MAILING_SETTINGS.WITH_ID, { id }),
    {
      notifyGuaranteedMessage,
      notifyRefund,
    },
  );
};
