import propTypes from 'prop-types';

import { theme } from 'styles/Theme';

const IconTrash = ({ size, palette }) => {
  return (
    <svg width={size} viewBox="0 0 17 16" fill="none" aria-hidden="true">
      <path
        fill={palette}
        fillRule="evenodd"
        d="M7.941.667h1.118c.351 0 .655 0 .904.02.264.021.526.069.778.197a2 2 0 0 1 .874.875c.129.252.176.514.198.777.018.224.02.491.02.797H14.5a.667.667 0 1 1 0 1.334h-.667v6.827c0 .537 0 .98-.03 1.34-.03.375-.096.72-.26 1.043-.256.502-.664.91-1.166 1.165-.323.165-.668.231-1.043.262-.36.03-.803.03-1.34.03H7.006c-.537 0-.98 0-1.34-.03-.375-.03-.72-.097-1.043-.261a2.666 2.666 0 0 1-1.166-1.166c-.165-.323-.23-.668-.26-1.043-.03-.36-.03-.803-.03-1.34V4.667H2.5a.667.667 0 0 1 0-1.334h2.667c0-.306.002-.573.02-.797.022-.263.07-.525.198-.777a2 2 0 0 1 .874-.875c.252-.128.514-.176.778-.197.25-.02.553-.02.904-.02Zm-3.441 4v6.8c0 .57 0 .959.025 1.259.024.292.067.442.12.546.128.25.332.455.583.582.104.054.254.097.546.12.3.025.688.026 1.26.026h2.933c.57 0 .959 0 1.259-.025.292-.024.442-.067.546-.12.25-.128.455-.332.583-.583.053-.104.096-.254.12-.546.024-.3.025-.688.025-1.26v-6.8h-8Zm6-1.334h-4c0-.31.002-.522.016-.688.015-.181.04-.248.057-.281a.667.667 0 0 1 .291-.292c.033-.016.1-.041.281-.056C7.335 2 7.582 2 7.967 2h1.066c.385 0 .633 0 .822.016.18.015.248.04.281.056a.667.667 0 0 1 .291.292c.017.033.042.1.057.281.014.166.016.378.016.688ZM7.167 7c.368 0 .666.298.666.667V11A.667.667 0 1 1 6.5 11V7.667c0-.369.298-.667.667-.667Zm2.666 0c.368 0 .667.298.667.667V11a.667.667 0 0 1-1.333 0V7.667c0-.369.298-.667.666-.667Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconTrash.defaultProps = {
  palette: theme.colors.white,
  size: '17',
};

IconTrash.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
};

export default IconTrash;
