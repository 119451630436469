export const initialState = {
  payments: [],
  error: false,
  loading: false,
  isPaymentsLoading: false,
  paymentsPage: 1,
  hasMore: true,
  revenue: [],
  hasMoreRevenueItems: true,
  revenuePage: 1,
  revenueAmount: null,
  paydMessageId: null,
};
