const IconChat = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9 2a7 7 0 0 0-4.95 11.95 1 1 0 0 1 0 1.414L3.414 16H9A7 7 0 0 0 9 2ZM0 9a9 9 0 1 1 9 9H1a1 1 0 0 1-.707-1.707l1.675-1.675A8.965 8.965 0 0 1 0 9Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconChat;
