import propTypes from 'prop-types';
import styled from 'styled-components';

const IconStopStyled = styled.svg`
  flex-shrink: 0;
`;

const IconStop = ({ size }) => {
  return (
    <IconStopStyled
      fill="white"
      width={size}
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM10 10h12v12h-12z" />
    </IconStopStyled>
  );
};

IconStop.defaultProps = {
  size: '32',
};

IconStop.propTypes = {
  size: propTypes.string,
};

export default IconStop;
