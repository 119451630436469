import { push } from 'connected-react-router';
import {
  getAnotherUserFailure,
  getAnotherUserSuccess,
  getCreatorByIdRequest,
  replaceWelcomeAudioUrl,
  resetIsWelcomeAudioLoading,
} from 'ducks/user';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { routes } from 'constants/routes';
import { serverEventsTypes } from 'constants/serverEventsTypes';

import { amazonCredentialsMapper, getFormData } from './helpers';
import { selectMediaMessagesPage, selectMediaType } from './selectors';
import * as services from './services';
import {
  getMediaFailure,
  getMediaMessagesFailure,
  getMediaMessagesRequest,
  getMediaMessagesSuccess,
  getMediaRequest,
  getMediaSuccess,
  incrementMediaMessagesPage,
  requestMediaDetails,
  requestMediaDetailsFailure,
  requestMediaDetailsSuccess,
  resetPreviewUrl,
  setFileFailure,
  setFileRequest,
  setFileSuccess,
  setIsMediaObjectUploaded,
  setMediaId,
  setMediaType,
  setPreviewUrl,
  setSrcUrl,
  uploadAudioRequest,
  uploadMediaFailure,
  uploadMediaRequest,
  uploadMediaSuccess,
} from './slice';

function* uploadMediaSaga({
  payload: { file, fileName, fileType, onSuccess, onFail },
}) {
  try {
    yield put(setMediaType(file.type.split('/')[0] || fileType));
    const amazonCredentials = yield call(services.uploadMedia, {
      filename: String(fileName).toLowerCase(),
      type: file.type.split('/')[0] || fileType,
    });
    const [id, credentials] = amazonCredentialsMapper(amazonCredentials, file);
    yield call(services.amazonMedia, getFormData(credentials));
    // yield put(setDebugData(id));
    // yield put(setDebugData2(JSON.stringify(credentials)));
    yield put(setMediaId(id));
    yield put(resetPreviewUrl());
    yield put(uploadMediaSuccess());
    if (onSuccess) {
      yield call(onSuccess, id);
    }
  } catch (error) {
    yield put(uploadMediaFailure());
    if (onFail) {
      yield call(onFail);
    }
  }
}

function* uploadAudioSaga({ payload: { blob, fileName, onSuccess } }) {
  try {
    const { default: convertBlobToMP3 } = yield import(
      'tools/convertBlobToMP3' /* webpackChunkName: "convertBlobToMP3" */
    );
    const audioFile = yield call(convertBlobToMP3, blob, fileName);
    const amazonCredentials = yield call(services.uploadMedia, {
      filename: fileName,
      type: 'audio',
    });
    const [id, credentials] = amazonCredentialsMapper(
      amazonCredentials,
      audioFile,
    );
    yield call(services.amazonMedia, getFormData(credentials));
    yield put(setMediaId(id));
    yield put(uploadMediaSuccess());
    if (onSuccess) {
      yield call(onSuccess, id);
    }
  } catch (error) {
    yield put(uploadMediaFailure());
  }
}

function* getMediaSaga({ payload }) {
  try {
    const mediaType = yield select(selectMediaType);
    if (mediaType !== 'audio') {
      const mediaObject = yield call(services.getMediaObjectById, payload);
      // yield put(setMediaId(mediaObject.id));
      yield put(
        setPreviewUrl({
          previewUrl:
            mediaObject.squarePreviewUrl || mediaObject.blurredSquarePreview,
          blurredPreviewUrl: mediaObject.blurredSquarePreview,
        }),
      );
      yield put(setMediaType(mediaObject.type));
      yield put(setSrcUrl(mediaObject.originalUrl));
      if (
        mediaObject.purpose === 'welcome_audio' &&
        window.location.pathname === routes.WELCOME_AUDIO
      ) {
        yield put(replaceWelcomeAudioUrl(mediaObject.originalUrl));
        yield put(resetIsWelcomeAudioLoading());
        yield put(push(routes.SETTINGS));
      }
    }
    yield put(getMediaSuccess());
    // yield put(removeIsMediaLoading());
  } catch (error) {
    yield put(getMediaFailure());
  }
}

function* getMediaDetailsSaga({ payload: { userId, mediaId } }) {
  try {
    yield put(getCreatorByIdRequest(userId));

    const [mediaObject] = yield all([
      call(services.getMediaObjectById, mediaId),
      race([take(getAnotherUserSuccess), take(getAnotherUserFailure)]),
    ]);

    yield put(requestMediaDetailsSuccess({ data: mediaObject }));
    yield put(
      setPreviewUrl({
        previewUrl:
          mediaObject.squarePreview || mediaObject.blurredSquarePreview,
        blurredPreviewUrl: mediaObject.blurredSquarePreview,
      }),
    );
  } catch (error) {
    yield put(requestMediaDetailsFailure());
  }
}

function* mediaObjectUploadedSaga({ payload }) {
  try {
    yield put(setIsMediaObjectUploaded(true));
    yield delay(200);
    yield put(getMediaRequest(payload?.id));
  } catch (error) {
    yield put(getMediaFailure());
  }
}

function* getMediaMessagesSaga({ payload }) {
  try {
    const widthPagination = payload?.widthPagination;
    const page = yield select(selectMediaMessagesPage);
    const mediaMessages = yield call(services.getMediaObjects, {
      usageFilter: 'messages',
      page: widthPagination ? page : undefined,
    });
    if (widthPagination) {
      yield put(incrementMediaMessagesPage());
    }

    // 10 is page size for this request
    yield put(
      getMediaMessagesSuccess({
        data: mediaMessages,
        hasMore: widthPagination ? mediaMessages.length === 10 : true,
      }),
    );
  } catch (error) {
    yield put(getMediaMessagesFailure());
  }
}

function* setFileSaga({ payload: { file, onSuccess } }) {
  try {
    yield put(setFileSuccess(file));
    yield call(onSuccess);
  } catch (error) {
    yield put(setFileFailure());
  }
}

export default function* watchMediaObject() {
  yield all([takeLatest(uploadMediaRequest, uploadMediaSaga)]);
  yield all([takeLatest(uploadAudioRequest, uploadAudioSaga)]);
  yield all([takeLatest(getMediaRequest, getMediaSaga)]);
  yield all([
    takeLatest(serverEventsTypes.MEDIA_OBJECT, mediaObjectUploadedSaga),
  ]);
  yield all([takeLatest(getMediaMessagesRequest, getMediaMessagesSaga)]);
  yield all([takeLatest(requestMediaDetails, getMediaDetailsSaga)]);
  yield all([takeLatest(setFileRequest, setFileSaga)]);
}
