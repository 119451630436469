/* eslint-disable no-return-assign */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import autosize from 'autosize';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { ButtonCircle } from 'components/buttons';
import { LoadingSpinner } from 'components/common';
import InputUpload from 'components/forms/helpers/InputUpload';
import { IconPhotoMedia, IconPhotoMedia2 } from 'components/icons';
import { MiniMediaUploaded } from 'components/media';
import { Text, TextLabel } from 'components/texts';

import {
  ButtonDisabledStyled,
  CustomScrollBar,
  GradientBorderRadius,
} from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const MessageTextareaWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: flex-end;
  width: 100%;
  max-width: ${breakpoints.md};
  padding: ${theme.space.xs} ${theme.space.primaryMd} ${theme.space.xs}
    ${theme.space.xs};
  border: ${theme.borderWidths.xs} solid ${theme.colors.grayLighter};
  color: ${theme.colors.black};
  border-radius: ${theme.radii.md};
  overflow: hidden;
  ${props =>
    props.isFocused &&
    `${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.md}`)};
  margin: -${theme.borderWidths.xs};
  `};
  ${props =>
    props.messageBoost && `margin-bottom: ${theme.space.secondaryMd};`};
`;

const MessageTextareaStyled = styled.textarea`
  display: block;
  background: ${theme.colors.white};
  border: none;
  padding: 0;
  max-height: ${theme.sizes.textareaMessageHeightMax};
  height: ${theme.sizes.textareaMessageHeight};
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.md};
  width: 100%;
  line-height: ${theme.lineHeights.xs};
  overflow-y: auto !important;
  align-self: ${props => (props.messageBoost ? 'flex-start' : 'center')};
  ${props => props.messageBoost && 'margin-top: 0.4rem'};
  ${CustomScrollBar};
  ${props => props.isFocused && `animation: focusTextarea 1s;`};
  /* stylelint-disable */
  height: ${props => `${props.textareaHeightMessage}px`};
  &::placeholder {
    color: ${theme.colors.grayMedium};
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
  ${props => props.disabled && ButtonDisabledStyled};

  @keyframes focusTextarea {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TextareaSendButtonStyled = styled.button`
  padding-left: ${theme.space.xxxs};
  padding-bottom: 0.9rem;
`;

const MessageTextarea = ({
  placeholder,
  isUploadMedia,
  maxLength,
  children,
  messageValue,
  messageBoost,
  onChange,
  onSendClick,
  rows,
  onMediaChange,
  onClearUploadedMedia,
  mediaObject,
  isMediaLoadingStarted,
  isAccountDelete,
  ...props
}) => {
  const textareaRef = useRef(null);

  const [isFocused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const [textareaHeight, setTextareaHeight] = useState(22);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      autosize(textareaRef.current);
      // eslint-disable-next-line
      textareaRef.current.addEventListener('autosize:resized', function () {
        const infiniteScroll = document.querySelector('#infiniteScroll');
        const textareaHeightValue = textareaRef.current.offsetHeight;
        setTextareaHeight(textareaHeightValue);
        if (infiniteScroll) {
          const infiniteScrollHeight = infiniteScroll.scrollHeight;
          setTimeout(
            () =>
              infiniteScroll.scrollTo({
                top: infiniteScrollHeight,
                left: 0,
                behavior: 'smooth',
              }),
            50,
          );
        }
      });
    }
  }, []);

  const onFocusTextareaWrapper = useCallback(() => {
    onFocus();
    textareaRef.current.focus();
  }, []);

  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );

  const sendMessageHandler = useCallback(() => {
    onSendClick();
    onChange('');
    textareaRef.current.value = '';
    autosize.update(textareaRef.current);
  }, [onChange, onSendClick]);

  const isMobile = useMemo(
    () =>
      /iphone|ipod|android|ie|blackberry|fennec/.test(
        navigator.userAgent.toLowerCase(),
      ),
    [],
  );

  const enterPressHandler = useCallback(
    event => {
      if (event.key === 'Enter' && !event.shiftKey && !isMobile) {
        event.preventDefault();
        if (messageValue) {
          if (document.getElementById('sendMessageButton')) {
            document.getElementById('sendMessageButton').click();
          } else if (document.getElementById('sendBoostMessageButton')) {
            document.getElementById('sendBoostMessageButton').click();
          }
        }
      }
    },
    [isMobile, messageValue],
  );
  useEffect(() => {
    textareaRef?.current?.addEventListener('keypress', enterPressHandler);
    const testAreaRef = textareaRef?.current;
    return () => {
      testAreaRef?.removeEventListener('keypress', enterPressHandler);
    };
  }, [enterPressHandler, textareaRef]);

  return (
    <>
      {messageBoost && (
        <TextLabel htmlFor="boostMessage" mb="primarySm">
          Votre message
        </TextLabel>
      )}
      <MessageTextareaWrapperStyled
        onClick={onFocusTextareaWrapper}
        isFocused={isFocused}
        messageBoost={messageBoost}
      >
        {isMediaLoadingStarted || mediaObject?.preview ? (
          <>
            {isMediaLoadingStarted ? (
              <LoadingSpinner size="20" position="relative" mr="primarySm" />
            ) : (
              mediaObject &&
              mediaObject?.mediaObject &&
              mediaObject?.preview && (
                <MiniMediaUploaded
                  $src={mediaObject.preview}
                  onClick={onClearUploadedMedia}
                />
              )
            )}
            {/* {isMediaLoadingStarted ? (
              <LoadingSpinner size="20" position="relative" mr="primarySm" />
            ) : (
              <MiniMediaUploaded $src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwWQio6pRKWgAnzcSvWH0q3XwPyGQXRwI66g87Ws35tBK51llvo5hPNrQ6l5UFPHs2EKs&usqp=CAU" />
            )} */}
          </>
        ) : (
          <>
            {messageBoost ? (
              <ButtonCircle
                scale="xs"
                mr="sm"
                position="relative"
                bg="black"
                primary={false}
              >
                <IconPhotoMedia2 size="18" />
                <InputUpload
                  accept="image/png, image/jpeg, image/jpg"
                  aria-label="Ajoutez votre photo"
                  onChange={onMediaChange}
                />
              </ButtonCircle>
            ) : (
              <ButtonCircle
                mr="sm"
                position="relative"
                disabled={isAccountDelete}
              >
                <IconPhotoMedia size="18" />
                <InputUpload
                  accept="image/png, image/jpeg, image/jpg"
                  aria-label="Ajoutez votre photo"
                  onChange={onMediaChange}
                  disabled={isAccountDelete}
                />
              </ButtonCircle>
            )}
          </>
        )}
        <MessageTextareaStyled
          rows={rows}
          placeholder={placeholder}
          maxLength={maxLength}
          ref={textareaRef}
          onFocus={onFocus}
          value={messageValue}
          onBlur={onBlur}
          disabled={isAccountDelete}
          messageValue={messageValue}
          onChange={changeHandler}
          autoFocus={!isMobile}
          height={textareaHeight}
          messageBoost={messageBoost}
          id={messageBoost ? 'boostMessage' : null}
          {...props}
        />
        {(messageValue || isUploadMedia) && !messageBoost && (
          <TextareaSendButtonStyled
            onClick={sendMessageHandler}
            id="sendMessageButton"
          >
            <Text as="span" type="label2" $isGradient>
              Envoyer
            </Text>
          </TextareaSendButtonStyled>
        )}
      </MessageTextareaWrapperStyled>
      {!messageValue && !messageBoost && <>{props.rightWidget}</>}
    </>
  );
};

MessageTextarea.defaultProps = {
  messageBoost: false,
  messageValue: '',
  onChange: noop,
  onSendClick: noop,
  rows: '1',
  onMediaChange: noop,
  isMediaLoadingStarted: false,
  onClearUploadedMedia: noop,
  maxLength: 3000,
};

MessageTextarea.propTypes = {
  rightWidget: propTypes.node,
  placeholder: propTypes.string.isRequired,
  isUploadMedia: propTypes.bool,
  maxLength: propTypes.oneOfType([propTypes.string, propTypes.number]),
  messageValue: propTypes.string,
  messageBoost: propTypes.bool,
  onChange: propTypes.func,
  onSendClick: propTypes.func,
  rows: propTypes.string,
  onMediaChange: propTypes.func,
  isMediaLoadingStarted: propTypes.bool,
  onClearUploadedMedia: propTypes.func,
  isAccountDelete: propTypes.bool,
};

export default MessageTextarea;
