const IconSearch = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#iconSearch)">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M1.903 8.1a6.3 6.3 0 1 1 12.6 0 6.3 6.3 0 0 1-12.6 0Zm6.3-8.1a8.1 8.1 0 1 0 5.056 14.429l3.308 3.307a.9.9 0 1 0 1.273-1.272l-3.308-3.308A8.1 8.1 0 0 0 8.203 0Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="iconSearch">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSearch;
