import propTypes from 'prop-types';
import styled from 'styled-components';

import { IconCalendar, IconCalendarArrow } from 'components/icons';
import { Text } from 'components/texts';

import { theme } from 'styles/Theme';

const ButtonPeriodStyled = styled.button`
  display: block;
  height: ${theme.sizes.buttonPeriodHeight};
  width: 100%;
  border-radius: ${theme.radii.xs};
  border: ${theme.borderWidths.xs} solid ${theme.colors.grayLight};
  padding: ${theme.space.primaryMd} ${theme.space.secondaryMd}
    ${theme.space.primaryMd} ${theme.space.primaryMd};
`;

const ButtonPeriod = ({ buttonText, ...props }) => (
  <ButtonPeriodStyled type="button" aria-label="Choisir la période" {...props}>
    <Text display="flex" alignItems="center" as="span">
      <Text as="span" display="flex" alignItems="center" mr="sm">
        <IconCalendar />
      </Text>
      <Text
        as="span"
        type="body1"
        lineHeight="md"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        mr="md"
        color="black"
      >
        {buttonText}
      </Text>
      <Text as="span" display="flex" alignItems="center" ml="auto">
        <IconCalendarArrow />
      </Text>
    </Text>
  </ButtonPeriodStyled>
);

ButtonPeriod.propTypes = {
  buttonText: propTypes.string,
};

export default ButtonPeriod;
