import InfiniteScroll from 'react-infinite-scroller';

import propTypes from 'prop-types';

import noop from 'tools/noop';

import LoadingSpinner from 'components/common/LoadingSpinner';

const InfiniteScroller = ({
  pageStart,
  loadMore,
  hasMore,
  useWindow,
  getScrollParent,
  children,
  isReverse,
  loaderSpinnerMt,
}) => {
  return (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <LoadingSpinner position="relative" key={0} mt={loaderSpinnerMt} />
      }
      style={{ overflow: 'unset' }}
      useWindow={useWindow}
      getScrollParent={getScrollParent}
      isReverse={isReverse}
    >
      {children || []}
    </InfiniteScroll>
  );
};

InfiniteScroller.defaultProps = {
  pageStart: 0,
  useWindow: true,
  getScrollParent: noop,
  isReverse: false,
  loaderSpinnerMt: 'sm',
};

InfiniteScroller.propTypes = {
  pageStart: propTypes.number,
  loadMore: propTypes.func.isRequired,
  hasMore: propTypes.bool.isRequired,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
  useWindow: propTypes.bool,
  getScrollParent: propTypes.func,
  isReverse: propTypes.bool,
  loaderSpinnerMt: propTypes.string,
};

export default InfiniteScroller;
