import { useEffect } from 'react';

import getPageTitle from 'tools/getPageTitle';

const TitlesContainer = ({ pathname }) => {
  useEffect(() => {
    document.title = `${getPageTitle(pathname)} - Premium`;
  }, [pathname]);
};

export default TitlesContainer;
