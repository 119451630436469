import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'paymentMethods';

export const paymentMethodsSlice = createSlice({
  name,
  initialState,
  reducers: {
    getPaymentMethodsCollectionRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getPaymentMethodsCollectionSuccess(state, { payload }) {
      state.paymentMethodsCollection = payload;
      state.loading = false;
      state.error = false;
    },
    getPaymentMethodsCollectionFailure(state) {
      state.loading = false;
      state.error = true;
    },
    getPaymentMethodRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getPaymentMethodSuccess(state, { payload }) {
      state.paymentMethod = payload;
      state.loading = false;
      state.error = false;
    },
    getPaymentMethodFailure(state) {
      state.loading = false;
      state.error = true;
    },
    patchPaymentMethodRequest(state) {
      state.loading = true;
      state.error = false;
    },
    patchPaymentMethodSuccess(
      state,
      { payload: { id, patchedPaymentMethod } },
    ) {
      state.paymentMethodsCollection = state.paymentMethodsCollection.map(
        method =>
          method.id === id
            ? patchedPaymentMethod
            : { ...method, default: false },
      );
      state.loading = false;
      state.error = false;
    },
    patchPaymentMethodFailure(state) {
      state.loading = false;
      state.error = true;
    },
    savePaymentMethodRequest(state) {
      state.loading = true;
      state.error = false;
    },
    savePaymentMethodSuccess(state, { payload }) {
      state.paymentMethodsCollection = payload;
      state.loading = false;
      state.error = false;
    },
    savePaymentMethodFailure(state) {
      state.loading = false;
      state.error = true;
    },
    setupPaymentMethodRequest(state) {
      state.loading = true;
      state.error = false;
    },
    setupPaymentMethodSuccess(state, { payload }) {
      state.stripeToken = payload;
      state.loading = false;
      state.error = false;
    },
    setupPaymentMethodFailure(state) {
      state.loading = false;
      state.error = true;
    },
    resetPaymentMethods(state) {
      state.paymentMethodsCollection = [];
    },
    deletePaymentMethodRequest(state) {
      state.loading = true;
      state.error = false;
    },
    deletePaymentMethodSuccess(state, { payload }) {
      state.paymentMethodsCollection = payload;
      state.loading = false;
      state.error = false;
    },
    deletePaymentMethodFailure(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  getPaymentMethodRequest,
  getPaymentMethodSuccess,
  getPaymentMethodFailure,
  getPaymentMethodsCollectionFailure,
  getPaymentMethodsCollectionRequest,
  getPaymentMethodsCollectionSuccess,
  patchPaymentMethodFailure,
  patchPaymentMethodRequest,
  patchPaymentMethodSuccess,
  savePaymentMethodFailure,
  savePaymentMethodRequest,
  savePaymentMethodSuccess,
  resetPaymentMethods,
  resetPaymentMethodsPage,
  setupPaymentMethodFailure,
  setupPaymentMethodRequest,
  setupPaymentMethodSuccess,
  deletePaymentMethodFailure,
  deletePaymentMethodRequest,
  deletePaymentMethodSuccess,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
