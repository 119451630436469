import propTypes from 'prop-types';
import styled from 'styled-components';

const HeaderStyled = styled.header``;

const Header = ({ children, ...props }) => {
  return <HeaderStyled {...props}>{children}</HeaderStyled>;
};

Header.propTypes = {
  children: propTypes.node,
};

export default Header;
