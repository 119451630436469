import propTypes from 'prop-types';
import styled from 'styled-components';
import { color, flexbox, layout, position, space } from 'styled-system';

import noop from 'tools/noop';

import { VisuallyHidden } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

const PrimaryStyles = `
  width: ${theme.sizes.crossButtonSize};
  height: ${theme.sizes.crossButtonSize};
  background-color: ${theme.colors.grayLight};
  &:before,
  &:after {
    background-color: ${theme.colors.white};
  }
`;

const SecondaryStyles = `
  width: ${theme.sizes.crossButtonSizeSm};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.primary};
  height: ${theme.sizes.crossButtonSizeSm};
  &:before,
  &:after {
    background-color: ${theme.colors.black};
  }
`;

const CrossButtonStyled = styled.button`
  border-radius: ${theme.radii.circle};
  position: relative;
  ${layout};
  ${space};
  ${position};
  ${color};
  ${flexbox};

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${theme.sizes.crossButtonIconWidth};
    height: ${theme.sizes.crossButtonIconHeight};
    border-radius: ${theme.radii.lg};
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  ${props => props.primary && PrimaryStyles};
  ${props => props.secondary && SecondaryStyles};
`;

const CrossButton = ({ type, onClick, primary, secondary, ...props }) => (
  <CrossButtonStyled
    type={type}
    primary={primary}
    secondary={secondary}
    onClick={onClick}
    {...props}
  >
    {type !== null && <VisuallyHidden>Supprimer</VisuallyHidden>}
  </CrossButtonStyled>
);

CrossButton.defaultProps = {
  onClick: noop,
  type: 'button',
  primary: true,
};

CrossButton.propTypes = {
  onClick: propTypes.func,
  type: propTypes.string,
  primary: propTypes.bool,
  secondary: propTypes.bool,
};

export default CrossButton;
