import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { theme } from 'styles/Theme';

const IconWarningStyled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconWarning = ({ isGradient, size, palette, ...props }) => {
  return (
    <IconWarningStyled
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <g clipPath="url(#IconWarning)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Zm7.333-6A7.333 7.333 0 1 1 .667 8a7.333 7.333 0 0 1 14.666 0Zm-6.666 2.667a.667.667 0 0 1-.667.666h-.007a.667.667 0 0 1 0-1.333H8c.368 0 .667.299.667.667Zm-.667-2A.667.667 0 0 1 7.333 8V5.333a.667.667 0 0 1 1.334 0V8A.667.667 0 0 1 8 8.667Z"
          fill={isGradient ? 'url(#IconWarning2)' : palette}
        />
      </g>
      <defs>
        <linearGradient
          id="IconWarning2"
          x1=".667"
          y1=".667"
          x2="15.75"
          y2="1.109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id="IconWarning">
          <path fill="#fff" transform="rotate(-180 8 8)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconWarningStyled>
  );
};

IconWarning.defaultProps = {
  isGradient: false,
  size: '16',
  palette: theme.colors.error,
};

IconWarning.propTypes = {
  isGradient: propTypes.bool,
  palette: propTypes.string,
  size: propTypes.string,
};

export default IconWarning;
