import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getDiscussions = ({ page }) => {
  return request.get(apiRoutes.DISCUSSION.DISCUSSIONS, {
    params: { page },
  });
};

export const getDiscussionsBySearch = ({ name, page }) => {
  return request.get(apiRoutes.DISCUSSION.DISCUSSIONS_SEARCH, {
    params: { name, page },
  });
};
