const IconAmexCard = () => {
  return (
    <svg width="512" viewBox="0 0 512 188" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M511.998 103.272h-26.633c-8.037 0-13.875 1.919-18.01 4.903v-4.903h-39.394c-6.299 0-13.694 1.557-17.191 4.903v-4.903h-70.347v4.903c-5.598-4.027-15.045-4.903-19.405-4.903h-46.401v4.903c-4.429-4.277-14.279-4.903-20.282-4.903h-51.931l-11.884 12.822-11.13-12.822h-77.573v83.775h76.113l12.245-13.025 11.535 13.025 46.917.042v-19.708h4.613c6.225.096 13.567-.153 20.044-2.945v22.609h38.698v-21.834h1.867c2.382 0 2.617.097 2.617 2.471v19.361h117.557c7.464 0 15.265-1.904 19.585-5.361v5.361h37.289c7.759 0 15.337-1.084 21.103-3.861 0-458.359-.002 112.002-.002-79.91Z"
        fill="#006FCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24.59 83.717 5.544-13.332h12.412l5.53 13.332h48.378V73.524l4.319 10.236h25.114l4.318-10.388v10.345h120.231l-.056-21.885h2.326c1.629.056 2.105.206 2.105 2.887v18.998h62.183v-5.095c5.016 2.68 12.817 5.095 23.083 5.095h26.16l5.599-13.332h12.412l5.475 13.332h50.413V71.053l7.634 12.664h40.397V0h-39.98v9.887L442.589 0h-41.024v9.887L396.424 0H341.01c-9.276 0-17.429 1.29-24.016 4.888V0h-38.24v4.888C274.563 1.18 268.851 0 262.501 0H122.795l-9.374 21.623L103.794 0H59.79v9.887L54.956 0H17.428L0 39.804v43.913h24.59Z"
        fill="#006FCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M253.877 155.028c12.579 0 25.06-3.443 25.06-20.121 0-16.628-12.831-19.78-24.252-19.78h-45.983l-18.305 19.68-17.706-19.68h-57.879v60.039h57.002l18.416-19.875 17.72 19.875h27.971v-20.138h17.956Zm-17.956-12.193h18.597c5.53 0 8.969-2.736 8.969-7.928 0-5.25-3.606-7.361-8.802-7.361h-18.764v15.289Zm-71.392 19.82h-35.382v-11.946h31.594v-12.25h-31.594v-10.915h36.079l15.741 17.496-16.438 17.615Zm57.059 7.051-22.095-24.456 22.095-23.679v48.135ZM340.482 152.305c-1.228-1.748-3.847-3.861-7.05-5.04 3.788-1.499 10.198-6.389 10.2-15.972 0-6.849-2.856-10.597-7.398-13.333-4.723-2.512-10.029-2.833-17.315-2.833h-33.225v60.039h14.461v-21.928h15.379c5.25 0 8.455.514 10.558 2.665 2.408 2.513 2.366 7.098 2.333 10.622-.003.391-.007.769-.007 1.13v7.511h14.448v-11.902c-.058-5.292-.352-8.027-2.384-10.959Zm-15.741-12.511c-1.925 1.138-4.306 1.236-7.106 1.236h-17.482v-13.484h17.72c2.562 0 5.126.054 6.868 1.084 1.865.972 2.98 2.832 2.98 5.401s-1.115 4.638-2.98 5.763Z"
        fill="#fff"
      />
      <path
        d="M454.596 143.501c2.802 2.891 4.304 6.541 4.304 12.721 0 12.916-8.094 18.944-22.607 18.944h-28.029v-12.874h27.916c2.73 0 4.666-.36 5.879-1.486a5.32 5.32 0 0 0 1.699-3.916c0-1.749-.768-3.138-1.756-3.971-1.1-.916-2.618-1.332-5.124-1.332-.882-.03-1.777-.055-2.682-.079-12.911-.35-27.63-.749-27.63-18.504 0-8.709 5.488-17.875 20.575-17.875h28.85v12.778h-26.399c-2.617 0-4.318.098-5.766 1.085-1.576.972-2.161 2.415-2.161 4.32 0 2.265 1.339 3.805 3.149 4.472 1.518.526 3.149.68 5.601.68l7.747.208c7.812.191 13.174 1.538 16.434 4.829ZM512 127.904h-26.228c-2.618 0-4.358.097-5.824 1.084-1.518.972-2.102 2.415-2.102 4.32 0 2.265 1.282 3.805 3.147 4.472 1.518.527 3.148.68 5.545.68l7.8.209c7.87.193 13.124 1.542 16.327 4.832.492.387.819.813 1.15 1.246l.089.117.058.074.038.049v22.5c-3.496 5.096-10.307 7.68-19.529 7.68H464.68v-12.875h27.679c2.745 0 4.667-.36 5.823-1.486a5.27 5.27 0 0 0 1.702-3.916c0-1.749-.699-3.138-1.758-3.971-1.044-.916-2.564-1.332-5.07-1.332-.884-.03-1.782-.054-2.689-.079-12.957-.35-27.681-.748-27.681-18.504 0-8.708 5.546-17.875 20.647-17.875h28.665l.002 12.775ZM351.612 115.127h48.03v12.416h-33.699v10.916h32.876v12.25h-32.876v11.945l33.699.055v12.457h-48.03v-60.039Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m46.152 45.937-9.85-23.929-9.793 23.93h19.643ZM263.14 36.41c-1.977 1.197-4.316 1.237-7.118 1.237H238.54V24.313h17.72c2.508 0 5.124.113 6.824 1.083 1.867.874 3.022 2.736 3.022 5.307 0 2.623-1.099 4.734-2.966 5.706Zm124.716 9.528-9.958-23.929-9.905 23.93h19.863ZM155.378 71.838h-14.753l-.054-47.01-20.867 47.01h-12.636L86.147 24.785v47.053h-29.27l-5.53-13.388H21.386l-5.586 13.388H.169l25.77-60.025H47.32l24.475 56.831v-56.83h23.488l18.833 40.719 17.301-40.72h23.959v60.025h.002Zm58.799 0h-48.074V11.813h48.074v12.5h-33.682v10.82h32.874v12.303h-32.874v11.988h33.682v12.414Zm67.782-43.859c0 9.57-6.407 14.515-10.14 16 3.149 1.194 5.838 3.305 7.118 5.054 2.032 2.986 2.383 5.653 2.383 11.014v11.791h-14.515l-.055-7.57c0-3.611.347-8.806-2.271-11.693-2.103-2.111-5.308-2.57-10.489-2.57h-15.449v21.833h-14.389V11.813h33.099c7.355 0 12.774.194 17.426 2.875 4.553 2.682 7.282 6.596 7.282 13.291Zm23.03 43.86h-14.684V11.812h14.684v60.025Zm170.351 0h-20.393l-27.278-45.053v45.052h-29.308l-5.6-13.388h-29.894l-5.433 13.388h-16.84c-6.995 0-15.851-1.542-20.867-6.639-5.057-5.096-7.689-12-7.689-22.915 0-8.902 1.573-17.04 7.758-23.471 4.653-4.79 11.938-7 21.855-7h13.932v12.862h-13.639c-5.252 0-8.218.778-11.074 3.556-2.453 2.527-4.137 7.305-4.137 13.597 0 6.43 1.282 11.067 3.957 14.096 2.216 2.375 6.241 3.096 10.03 3.096h6.463l20.282-47.205h21.562l24.365 56.775V11.815h21.911L460.6 53.62V11.815h14.74v60.023Z"
        fill="#fff"
      />
    </svg>
  );
};

export default IconAmexCard;
