const IconSearch2 = () => {
  return (
    <svg aria-hidden="true" width="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.333 10.667a4.667 4.667 0 1 0 3.238 8.027.671.671 0 0 1 .123-.123 4.667 4.667 0 0 0-3.36-7.904Zm4.688 8.411a6 6 0 1 0-.943.943l2.45 2.45a.667.667 0 1 0 .943-.942l-2.45-2.45Z"
        fill="#000"
      />
      <rect x=".5" y=".5" width="31" height="31" rx="15.5" stroke="#000" />
    </svg>
  );
};

export default IconSearch2;
