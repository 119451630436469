import propTypes from 'prop-types';
import styled from 'styled-components';
import { border, layout, position, space, system } from 'styled-system';

import { Transition } from 'styles/MixinStyle';

const ImgStyles = `
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  opacity: ${props => props.opacity};
`;

const ImgStyled = styled.img`
  ${ImgStyles};
  ${Transition('opacity')};
  ${space};
  ${layout};
  ${border};
  ${position};
  ${system({
    objectFit: true,
  })};
`;

const SourceStyled = styled.source`
  ${ImgStyles};
  ${Transition('opacity')};
  ${space};
  ${layout};
  ${border};
  ${position};
  ${system({
    objectFit: true,
  })};
`;

const PictureStyled = styled.picture`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
  ${position};
`;

const Picture = ({
  alt,
  src,
  srcSetAvif,
  srcSetWebp,
  innerRef,
  positionPicture,
  ...props
}) => {
  return (
    <PictureStyled position={positionPicture}>
      <SourceStyled srcSet={srcSetAvif} type="image/avif" {...props} />
      <SourceStyled srcSet={srcSetWebp} type="image/webp" {...props} />
      <ImgStyled
        ref={innerRef}
        src={src}
        alt={alt}
        loading="lazy"
        decoding="async"
        {...props}
      />
    </PictureStyled>
  );
};

Picture.defaultProps = {
  alt: '',
};

Picture.propTypes = {
  alt: propTypes.string,
  src: propTypes.string,
  srcSetAvif: propTypes.string,
  srcSetWebp: propTypes.string,
  opacity: propTypes.string,
  innerRef: propTypes.any,
  positionPicture: propTypes.string,
};

export default Picture;
