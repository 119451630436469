import { Suspense } from 'react';

import propTypes from 'prop-types';

import lazyWithRetry from 'tools/lazyWithRetry';

import { LoadingSpinner } from 'components/common';

const WaveSurferPlayerContainer = lazyWithRetry(() =>
  import(
    'containers/voiceMessage/WaveSurferPlayerContainer' /* webpackChunkName: "waveSurferPlayer" */
  ),
);

const VoiceMessage = ({ audioUrl, currentVoice, isMessage, messageId }) => {
  return (
    <Suspense fallback={<LoadingSpinner position="relative" />}>
      <WaveSurferPlayerContainer
        currentVoice={currentVoice}
        isMessage={isMessage}
        url={audioUrl}
        messageId={messageId}
      />
    </Suspense>
  );
};

VoiceMessage.defaultProps = {
  isMessage: false,
};

VoiceMessage.propTypes = {
  audioUrl: propTypes.string.isRequired,
  currentVoice: propTypes.bool,
  isMessage: propTypes.bool,
  messageId: propTypes.string,
};

export default VoiceMessage;
