const IconAdd = () => {
  return (
    <svg viewBox="0 0 20 20" width="16" fill="none" aria-hidden="true">
      <g clipPath="url(#IconAdd)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM.833 10a9.167 9.167 0 1 1 18.334 0A9.167 9.167 0 0 1 .833 10ZM10 5.833c.46 0 .833.373.833.834v2.5h2.5a.833.833 0 0 1 0 1.666h-2.5v2.5a.833.833 0 0 1-1.666 0v-2.5h-2.5a.833.833 0 0 1 0-1.666h2.5v-2.5c0-.46.373-.834.833-.834Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="IconAdd">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAdd;
