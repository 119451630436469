import propTypes from 'prop-types';

const IconChatActive = ({ uniqueId }) => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath={`url(#chat${uniqueId})`}>
        <path
          fill={`url(#chat2${uniqueId})`}
          fillRule="evenodd"
          d="M9 2a7 7 0 0 0-4.95 11.95 1 1 0 0 1 0 1.414L3.414 16H9A7 7 0 0 0 9 2ZM0 9a9 9 0 1 1 9 9H1a1 1 0 0 1-.707-1.707l1.675-1.675A8.965 8.965 0 0 1 0 9Z"
          clipRule="evenodd"
        />
        <circle cx="9.103" cy="9" r="5" fill={`url(#chat3${uniqueId})`} />
      </g>
      <defs>
        <linearGradient
          id={`chat2${uniqueId}`}
          x1="18"
          x2="-.512"
          y1="18"
          y2="17.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <linearGradient
          id={`chat3${uniqueId}`}
          x1="14.103"
          x2="3.819"
          y1="14"
          y2="13.698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id={`chat${uniqueId}`}>
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconChatActive.defaultProps = {
  uniqueId: '',
};

IconChatActive.propTypes = {
  uniqueId: propTypes.string,
};

export default IconChatActive;
