import { useSelector } from 'react-redux';

import CurrentUserAvatar from 'containers/CurrentUserAvatar';
import { selectUnreadMessages } from 'ducks/dialog';
import { selectIsAuthenticated } from 'ducks/user';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { routes } from 'constants/routes';

import { MBox, MLogo } from 'components/common';
import { LinkComponent } from 'components/texts';

import { breakpoints, theme } from 'styles/Theme';

import NavigationBar from '../NavigationBar';

const DesktopHeaderStyled = styled.div`
  display: none;
  ${space};

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    align-items: center;
    max-width: ${breakpoints.xxl};
    padding: 0 ${theme.space.defaultSpaceX};
    width: 100%;
    margin: 0 auto ${theme.space.xl};

    li {
      + li {
        margin-left: 8rem;
      }

      &:empty {
        margin-left: 0;
      }
    }
  }
`;

const DesktopHeader = ({ isChecked, isFan, isCreator, ...props }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const unreadMessages = useSelector(selectUnreadMessages);

  return (
    <DesktopHeaderStyled {...props}>
      <MBox mr="xl">
        <MLogo />
      </MBox>
      {isAuthenticated && (
        <NavigationBar
          isMobile={false}
          isFan={isFan}
          isCreator={isCreator}
          unreadMessages={unreadMessages}
        />
      )}
      <LinkComponent to={routes.SETTINGS}>
        <CurrentUserAvatar uniqueIdCheckedIcon="Desktop" />
      </LinkComponent>
    </DesktopHeaderStyled>
  );
};

DesktopHeader.defaultProps = {
  isChecked: false,
};

DesktopHeader.propTypes = {
  isChecked: propTypes.bool,
  isFan: propTypes.bool,
  isCreator: propTypes.bool,
  src: propTypes.string,
};

export default DesktopHeader;
