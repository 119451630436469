const IconEmail2 = () => {
  return (
    <svg width="20" viewBox="0 0 20 15" fill="none" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.632 0h8.736c.67 0 1.224 0 1.675.037.469.038.9.12 1.304.326.627.32 1.137.83 1.456 1.457.2.39.283.805.323 1.254.044.137.052.28.025.417.016.375.016.81.016 1.308V10.2c0 .67 0 1.224-.037 1.675-.038.469-.12.9-.327 1.304a3.333 3.333 0 0 1-1.456 1.457c-.405.206-.835.288-1.304.326-.45.037-1.004.037-1.675.037H5.632c-.67 0-1.224 0-1.675-.037-.469-.038-.9-.12-1.304-.326a3.333 3.333 0 0 1-1.456-1.457c-.206-.405-.289-.835-.327-1.303-.037-.452-.037-1.005-.037-1.676V4.8c0-.499 0-.933.015-1.308a.833.833 0 0 1 .026-.417c.04-.45.124-.864.323-1.254.32-.627.83-1.137 1.456-1.457.405-.206.835-.288 1.304-.326C4.407 0 4.96 0 5.632 0ZM2.5 4.934v5.233c0 .319 0 .593.003.831l3.781-3.415L2.5 4.933Zm5.582 1.873L2.57 2.947c.03-.172.068-.284.113-.37.16-.314.414-.569.728-.729.13-.066.317-.12.683-.15.375-.03.86-.031 1.574-.031h8.666c.714 0 1.2 0 1.575.031.365.03.552.084.682.15.314.16.569.415.728.729.044.086.083.198.113.37l-5.513 3.86a.763.763 0 0 0-.029.02l-.838.587c-.607.425-.736.498-.85.527a.833.833 0 0 1-.403 0c-.113-.029-.242-.102-.85-.527l-.837-.587a.831.831 0 0 0-.029-.02Zm-.397 1.756-4.717 4.261c.127.132.276.243.442.328.13.066.317.12.683.15.375.03.86.031 1.574.031h8.666c.714 0 1.2 0 1.575-.031.365-.03.552-.084.682-.15.166-.085.315-.196.442-.328l-4.717-4.26-.308.215-.096.067c-.454.319-.852.599-1.306.712a2.5 2.5 0 0 1-1.21 0c-.454-.113-.852-.393-1.306-.712a66.945 66.945 0 0 0-.096-.067l-.308-.216Zm6.03-.98 3.782 3.415c.003-.238.003-.512.003-.831V4.934l-3.784 2.649Z"
        fill="#000"
      />
    </svg>
  );
};

export default IconEmail2;
