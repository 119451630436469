const IconApplePay = () => {
  return (
    <svg width="30" viewBox="0 0 30 14" fill="none" aria-hidden="true">
      <path
        d="M5.48 2.43c-.352.417-.914.745-1.477.698-.07-.562.206-1.162.53-1.528.351-.427.965-.731 1.467-.755.056.581-.173 1.158-.52 1.585Zm.51.806c-.815-.047-1.513.464-1.898.464-.394 0-.984-.44-1.631-.427A2.41 2.41 0 0 0 .417 4.516c-.881 1.514-.23 3.75.624 4.982.417.61.914 1.28 1.57 1.257.623-.024.867-.404 1.617-.404.755 0 .975.404 1.631.394.68-.014 1.107-.61 1.524-1.219.473-.693.67-1.364.68-1.401-.015-.014-1.313-.511-1.327-2.011-.014-1.256 1.027-1.852 1.073-1.89-.586-.871-1.5-.965-1.818-.988Zm4.707-1.697v9.136h1.42V7.553h1.964c1.796 0 3.052-1.233 3.052-3.014 0-1.781-1.238-3-3.005-3h-3.431Zm1.42 1.195h1.636c1.233 0 1.936.657 1.936 1.81s-.703 1.818-1.94 1.818h-1.632V2.734Zm7.603 8.011c.891 0 1.716-.45 2.091-1.167h.028v1.097h1.313V6.128c0-1.317-1.055-2.17-2.677-2.17-1.505 0-2.62.862-2.663 2.043h1.28c.108-.562.628-.932 1.34-.932.868 0 1.356.403 1.356 1.148v.506l-1.772.108c-1.646.099-2.536.774-2.536 1.945.004 1.182.923 1.97 2.24 1.97Zm.385-1.083c-.755 0-1.238-.365-1.238-.918 0-.577.464-.91 1.35-.961l1.575-.099V8.2c0 .853-.726 1.462-1.687 1.462Zm4.804 3.497c1.383 0 2.035-.53 2.602-2.128L30 4.047h-1.444l-1.669 5.395h-.028l-1.668-5.395h-1.482l2.4 6.651-.13.403c-.216.685-.568.952-1.196.952-.113 0-.328-.014-.417-.023v1.097c.084.018.436.032.543.032Z"
        fill="#000"
      />
    </svg>
  );
};

export default IconApplePay;
