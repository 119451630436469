import { createSelector } from 'reselect';

export const selectDialogsCollection = state => state.dialog.dialogsCollection;
export const selectDialogsByReceiverId = state =>
  state.dialog.dialogsByReceiverId;
export const selectMySubscribers = state => state.dialog.mySubscribers;
export const selectAwaitingSum = state => state.dialog.awaitingSum;
export const selectDialog = state => state.dialog.dialog;
export const selectIsDialogLoading = state => state.dialog.loading;
export const selectError = state => state.dialog.error;
export const selectDialogsPage = state => state.dialog.dialogsPage;
export const selectHasMore = state => state.dialog.hasMore;
export const selectHasTransferedMedia = state =>
  state.dialog.hasTransferedMedia;
export const selectUnreadMessages = state => state.dialog.unreadMessages;
export const selectSearchState = state => state.dialog.searchState;
export const selectSortState = state => state.dialog.sortState;
export const selectDialogMediaObjects = state =>
  state.dialog.sharedMediaObjects;
export const selectDialogMediaObjectsPage = state =>
  state.dialog.sharedMediaObjectsPage;
export const selectHasMoreDialogMediaObjects = state =>
  state.dialog.hasMoreDialogMediaObjects;

export const selectDialogMediaObjectsPrepared = createSelector(
  selectDialogMediaObjects,
  mediaObjects => mediaObjects.filter(media => media.type !== 'audio'),
);
export const selectDialogId = state => state.dialog.dialog?.id;
export const selectIsOnDialogPage = state => state.dialog.isOnDialogPage;
export const selectIsGuaranteedResponsePending = state =>
  state.dialog.isGuaranteedResponsePending;
export const selectIsVideoResponseRequested = state =>
  state.dialog.isVideoResponseRequested;
export const selectGuaranteedAnswerUntil = state =>
  state.dialog.guaranteedAnswerUntil;
export const selectDialogsSecondCollection = state =>
  state.dialog.dialogsSecondCollection;
export const selectHasMoreSecondDialogs = state =>
  state.dialog.hasMoreSecondDialogs;
export const selectSecondDialogsPage = state => state.dialog.secondDialogsPage;
export const selectIsDialogsFirstLoaded = state =>
  state.dialog.isDialogsFirstLoaded;
export const selectIsDialogRequestFirstLoaded = state =>
  state.dialog.isDialogRequestFirstLoaded;
export const selectIsSecondUserDeleted = state =>
  state.dialog.dialog?.isSecondUserDeleted;
