const IconMasterCard = () => {
  return (
    <svg width="24" fill="none" viewBox="0 0 24 16" aria-hidden="true">
      <g clipPath="url(#IconMasterCard)">
        <path d="M15.238 2.251H8.743V13.92h6.495V2.251Z" fill="#FF5A00" />
        <path
          d="M9.175 8.086A7.443 7.443 0 0 1 12 2.25 7.417 7.417 0 0 0 0 8.085a7.418 7.418 0 0 0 12 5.835 7.407 7.407 0 0 1-2.825-5.834Z"
          fill="#EB001B"
        />
        <path
          d="M24 8.086a7.418 7.418 0 0 1-12 5.834 7.381 7.381 0 0 0 2.825-5.834A7.443 7.443 0 0 0 12 2.25 7.37 7.37 0 0 1 16.576.664C20.68.664 24 4.004 24 8.086Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="IconMasterCard">
          <path fill="#fff" d="M0 0h24v15.6H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconMasterCard;
