import { differenceInDays, format } from 'date-fns';
import { fr } from 'date-fns/esm/locale';

export const messageCreationTimeFormat = date => {
  if (!date) return '';
  return format(new Date(date), 'HH:mm');
};

export const dialogMessageCreationDateFormat = date => {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yy');
};

export const getDialogMessageCreationDate = date => {
  return differenceInDays(new Date(), new Date(date)) > 1
    ? dialogMessageCreationDateFormat(date)
    : messageCreationTimeFormat(date);
};

export const messageCreationDateFormat = date => {
  if (!date) return '';
  return format(new Date(date), 'LLLL, dd, HH:mm', { locale: fr });
};

export const getMessageCreationDate = date => {
  return differenceInDays(new Date(), new Date(date)) > 1
    ? messageCreationDateFormat(date)
    : messageCreationTimeFormat(date);
};
