import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { MBox } from 'components/common';
import { Text } from 'components/texts';

import {
  GradientBorderRadius,
  InputFocusVisible,
  Transition,
} from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const MRadioWrapperStyled = styled.div`
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-column-gap: ${theme.space.primarySm};
`;

const MRadioItemStyled = styled.label`
  position: relative;
  text-align: center;
  width: 100%;
  padding: ${theme.space.sm};
  ${props =>
    props.active
      ? `background: ${gradients.primary}; 
    border-radius: ${theme.radii.xs};
  `
      : `
    ${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.xs}`)};
    border-width: ${theme.borderWidths.xs};
    &:after {
      content: '';
      overflow: hidden;
      background: ${gradients.primary};
      border-radius: ${theme.radii.xs};
      position: absolute;
      background-blend-mode: overlay;
      background-color: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
  }`};
  ${props =>
    props.labelDisabled
      ? `opacity: 0.2;
      cursor: default;
      &:after { content: none; }`
      : `cursor: pointer;`};
  ${Transition('background, color')};
`;

const MRadioInputStyled = styled.input`
  ${InputFocusVisible};
  cursor: default;
`;

const MRadioSecondary = ({
  name,
  items,
  value,
  onChange,
  ariaLabelledby,
  ...props
}) => {
  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );
  return (
    <MBox maxWidth={breakpoints.md} mx="auto" width="100%">
      <MRadioWrapperStyled {...props}>
        {items.map(item => {
          return (
            <MRadioItemStyled
              active={value === item.value}
              htmlFor={`${name}${item.id}`}
              key={item.text}
              labelDisabled={item.disabled}
            >
              <MRadioInputStyled
                onChange={changeHandler}
                id={`${name}${item.id}`}
                type="radio"
                name={name}
                value={item.value}
                aria-labelledby={`${ariaLabelledby}`}
                disabled={item.disabled}
                checked={value === item.value}
              />
              <Text
                position="relative"
                zIndex="1"
                as="span"
                $isGradient={value !== item.value}
                color="white"
                type={value === item.value ? 'subtitle2' : 'label2'}
              >
                {item.text}
              </Text>
            </MRadioItemStyled>
          );
        })}
      </MRadioWrapperStyled>
    </MBox>
  );
};

MRadioSecondary.defaultProps = {
  value: '',
  onChange: noop,
};

MRadioSecondary.propTypes = {
  name: propTypes.string.isRequired,
  ariaLabelledby: propTypes.string.isRequired,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string.isRequired,
      value: propTypes.any.isRequired,
      text: propTypes.string.isRequired,
      disabled: propTypes.bool,
    }).isRequired,
  ).isRequired,
  value: propTypes.any,
  onChange: propTypes.func,
};

export default MRadioSecondary;
