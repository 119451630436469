import { readLastMessageRequest, selectIsOnMessagePage } from 'ducks/message';
import { getCreatorByIdRequest } from 'ducks/user';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { MIN_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';
import { serverEventsTypes } from 'constants/serverEventsTypes';

import noop from 'tools/noop';

import {
  selectIsOnDialogPage,
  selectSearchState,
  selectSortState,
} from './selectors';
import * as services from './services';
import {
  getDialogByCreatorNicknameFailure,
  getDialogByCreatorNicknameRequest,
  getDialogByCreatorNicknameSuccess,
  getDialogByFanNicknameFailure,
  getDialogByFanNicknameRequest,
  getDialogByFanNicknameSuccess,
  getDialogByIdFailure,
  getDialogByIdRequest,
  getDialogByIdSuccess,
  getDialogMediaObjectsFailure,
  getDialogMediaObjectsRequest,
  getDialogMediaObjectsSuccess,
  getDialogsByReceiverIdSuccess,
  getDialogsBySearchFailure,
  getDialogsBySearchRequest,
  getDialogsBySearchSuccess,
  getDialogsFailure,
  getDialogsRequest,
  getDialogsSuccess,
  getDialogsWithFiltersSuccess,
  getNewDialogsBySearchSuccess,
  getSecondDialogsFailure,
  getSecondDialogsNewDataSuccess,
  getSecondDialogsRequest,
  getSecondDialogsSuccess,
  incrementDialogMediaObjectsPage,
  incrementDialogsPage,
  incrementSecondDialogsPage,
  setGuaranteedAnswerUntil,
  setIsGuaranteedResponsePending,
  setIsVideoResponseRequested,
  setUnreadMessages,
  updateDialogFailure,
  updateDialogRequest,
  updateDialogSuccess,
} from './slice';

function* getDialogsSaga({
  payload: {
    receiverId = null,
    page,
    onLoadingFinish,
    sort = 'chronological',
    publicName = '',
  },
}) {
  try {
    const dialogs = yield call(services.getDialogs, {
      receiverId,
      page,
      sort,
      publicName,
    });
    if (!receiverId) {
      yield put(incrementDialogsPage());
      if (page === 1) {
        yield put(getDialogsWithFiltersSuccess(dialogs));
        yield put(setUnreadMessages(dialogs.unreadMessages));
      } else {
        yield put(getDialogsSuccess(dialogs));
      }
    } else {
      yield put(getDialogsByReceiverIdSuccess(dialogs));
      yield put(getCreatorByIdRequest(receiverId));
    }
  } catch (error) {
    yield put(getDialogsFailure());
  }
  if (page === 1 && onLoadingFinish) {
    yield call(onLoadingFinish);
  }
}

function* getDialogsSecondSaga({
  payload: { page, onLoadingFinish, sort = 'income', publicName = '' },
}) {
  try {
    const dialogs = yield call(services.getDialogs, {
      page,
      sort,
      publicName,
    });
    yield put(incrementSecondDialogsPage());
    if (page === 1) {
      yield put(getSecondDialogsNewDataSuccess(dialogs));
    } else {
      yield put(getSecondDialogsSuccess(dialogs));
    }
  } catch (error) {
    yield put(getSecondDialogsFailure());
  }
  if (page === 1 && onLoadingFinish) {
    yield call(onLoadingFinish);
  }
}

function* getDialogsBySearchSaga({
  payload: { name, page, sort = 'chronological' },
}) {
  try {
    const dialogs = yield call(services.getDialogsBySearch, {
      name,
      page,
      sort,
    });
    yield put(incrementDialogsPage());
    if (page === 1) {
      yield put(getNewDialogsBySearchSuccess(dialogs));
    } else {
      yield put(getDialogsBySearchSuccess(dialogs));
    }
  } catch (error) {
    yield put(getDialogsBySearchFailure());
  }
}

function* updateDialogSaga({ payload: { id, body, onSuccess = noop } }) {
  try {
    if (body?.read) {
      yield delay(1000);
    }
    yield call(services.patchDialog, { id, body });
    yield put(updateDialogSuccess());
    yield call(onSuccess);
  } catch (error) {
    yield put(updateDialogFailure());
  }
}

function* getDialogByCreatorNicknameSaga({ payload: { nickname, onSuccess } }) {
  try {
    const dialog = yield call(services.getDialogByCreatorNickname, nickname);
    yield put(getDialogByCreatorNicknameSuccess(dialog));
    yield call(onSuccess);
  } catch (error) {
    yield put(getDialogByCreatorNicknameFailure());
  }
}

function* getDialogByFanNicknameSaga({ payload: { nickname, onSuccess } }) {
  try {
    const dialog = yield call(services.getDialogByFanNickname, nickname);
    yield put(getDialogByFanNicknameSuccess(dialog));
    yield call(onSuccess);
  } catch (error) {
    yield put(getDialogByFanNicknameFailure());
  }
}

function* getDialogByIdSaga({ payload: { id, onSuccess = noop } }) {
  try {
    const dialog = yield call(services.getDialogById, id);
    yield put(getDialogByIdSuccess(dialog));
    if (dialog?.prioritizedMessage?.answerUntil) {
      yield put(setIsGuaranteedResponsePending(true));
      yield put(
        setGuaranteedAnswerUntil(dialog?.prioritizedMessage?.answerUntil),
      );
      if (dialog?.prioritizedMessage?.messageType === 'video_response') {
        yield put(setIsVideoResponseRequested(true));
      }
    }
    yield call(onSuccess, dialog);
  } catch (error) {
    yield put(getDialogByIdFailure());
  }
}

function* receiveDialogMessageSaga() {
  const isOnDialogPage = yield select(selectIsOnDialogPage);
  const isOnMessagePage = yield select(selectIsOnMessagePage);
  const searchState = yield select(selectSearchState);
  const sortState = yield select(selectSortState);
  if (isOnMessagePage) {
    yield put(readLastMessageRequest());
  }
  if (isOnDialogPage) {
    yield delay(1500);
    yield put(
      getDialogsRequest({
        page: 1,
        sort: sortState,
        publicName: searchState,
      }),
    );
  }
}

function* getDialogMediaObjectsSaga({ payload }) {
  try {
    const mediaObjects = yield call(services.getDialogMediaObjects, payload);
    yield put(getDialogMediaObjectsSuccess(mediaObjects));
    if (mediaObjects.length === MIN_ITEMS_PER_PAGE_VALUE) {
      yield put(incrementDialogMediaObjectsPage());
    }
  } catch (error) {
    yield put(getDialogMediaObjectsFailure());
  }
}

export default function* watchDialog() {
  yield all([
    takeLatest(getDialogsRequest, getDialogsSaga),
    takeLatest(getSecondDialogsRequest, getDialogsSecondSaga),
    takeLatest(updateDialogRequest, updateDialogSaga),
    takeLatest(
      getDialogByCreatorNicknameRequest,
      getDialogByCreatorNicknameSaga,
    ),
    takeLatest(getDialogByFanNicknameRequest, getDialogByFanNicknameSaga),
    takeLatest(getDialogByIdRequest, getDialogByIdSaga),
    takeLatest(getDialogsBySearchRequest, getDialogsBySearchSaga),
    takeLatest(serverEventsTypes.DIALOG, receiveDialogMessageSaga),
    takeLatest(getDialogMediaObjectsRequest, getDialogMediaObjectsSaga),
  ]);
}
