import usePasswordValidationBanner from 'hooks/usePasswordValidationBanner';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { MInputForm } from 'components/finalForm/finalFormInputs';
import { MList, Text } from 'components/texts';

import { theme } from 'styles/Theme';

const PasswordSecurityStyled = styled.div`
  background: ${theme.colors.grayCloud};
  padding: ${theme.space.sm};
  border-radius: ${theme.radii.xs};
  margin-top: ${theme.space.primaryMd};
`;

const PasswordSecurity = ({
  inputLabel,
  inputPlaceholder,
  stateStatus,
  errorText,
  helperText,
  showErrorTextOnInput,
  ...props
}) => {
  const itemsList = usePasswordValidationBanner();

  return (
    <div>
      <MInputForm
        type="password"
        label={inputLabel}
        placeholder={inputPlaceholder}
        idLabel="passwordSecurity"
        /* stateStatus={stateStatus} */
        errorText={errorText}
        showErrorTextOnInput={showErrorTextOnInput}
        {...props}
      />
      {/* {(stateStatus === 'ERROR' || showErrorTextOnInput) && errorText && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          id="passwordSecurityError"
          mt="xxs"
          color="error"
        >
          {errorText}
        </Text>
      )} */}
      {helperText && (
        <Text type="body2" color="grayMedium" mt="xxs" lineHeight="sm">
          {helperText}
        </Text>
      )}
      <PasswordSecurityStyled>
        <Text color="grayMedium" type="label3" mb="xs">
          Sécurité du mot de passe :
        </Text>
        <MList bulletMarker items={itemsList} />
      </PasswordSecurityStyled>
    </div>
  );
};

PasswordSecurity.defaultProps = {
  inputLabel: 'Votre mot de passe',
  inputPlaceholder: 'Mot de passe',
  errorText: null,
  helperText: null,
  stateStatus: null,
  showErrorTextOnInput: false,
};

PasswordSecurity.propTypes = {
  inputLabel: propTypes.string,
  inputPlaceholder: propTypes.string,
  errorText: propTypes.string,
  helperText: propTypes.string,
  stateStatus: propTypes.string,
  showErrorTextOnInput: propTypes.bool,
};

export default PasswordSecurity;
