import propTypes from 'prop-types';
import styled from 'styled-components';
import { color, position, space, variant } from 'styled-system';

import { ButtonDisabledStyled } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const ButtonCircleVariant = () =>
  variant({
    prop: 'scale',
    variants: {
      xxxs: {
        size: '2.4rem',
      },
      xxs: {
        size: '2.8rem',
      },
      xs: {
        size: '3.2rem',
      },
      // sm - default scale
      sm: {
        size: '3.6rem',
      },
      md: {
        size: '5rem',
        boxShadow: `${theme.shadows.primary}`,
      },
    },
  });

const ActiveStateStyles = `
  &:active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: blue;
      opacity: 0.3;
      height: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
    }
  }
`;

const PrimaryStyles = `
  background: ${gradients.primary};
`;

const SecondaryStyles = `
  background: ${theme.colors.error};
`;

const ButtonCircleStyled = styled.button`
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.radii.circle};
  flex-shrink: 0;
  ${props =>
    props.primary &&
    props.position &&
    props.position !== 'static' &&
    ActiveStateStyles};
  ${props => props.primary && PrimaryStyles};
  ${props => props.secondary && SecondaryStyles};
  ${props => props.disabled && ButtonDisabledStyled};
  ${props => ButtonCircleVariant(props)};
  ${space};
  ${position};
  ${color};
  &:hover {
    box-shadow: ${theme.shadows.buttonHover};
  }
`;

const ButtonCircle = ({
  children,
  primary,
  secondary,
  type = 'button',
  disabled,
  innerRef,
  onClick,
  ...props
}) => {
  return (
    <ButtonCircleStyled
      type={type}
      primary={primary}
      secondary={secondary}
      ref={innerRef}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonCircleStyled>
  );
};

ButtonCircle.defaultProps = {
  scale: 'sm',
  primary: true,
  disabled: false,
  position: 'relative',
};

ButtonCircle.propTypes = {
  children: propTypes.node.isRequired,
  type: propTypes.string,
  disabled: propTypes.bool,
  scale: propTypes.string,
  primary: propTypes.bool,
  secondary: propTypes.bool,
  onClick: propTypes.func,
  position: propTypes.string,
};

export default ButtonCircle;
