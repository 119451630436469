import propTypes from 'prop-types';

const IconPlay = ({ size }) => {
  return (
    <svg fill="none" viewBox="0 0 74 74" width={size} aria-hidden="true">
      <g filter="url(#iconPlay)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M37 19c-9.941 0-18 8.059-18 18s8.059 18 18 18 18-8.059 18-18-8.059-18-18-18ZM15 37c0-12.15 9.85-22 22-22s22 9.85 22 22-9.85 22-22 22-22-9.85-22-22Zm20.49-9.134.057.036 9.44 6.07c.018.01.035.022.053.033.302.194.632.406.895.611.28.218.72.602.98 1.216a3 3 0 0 1 0 2.336c-.26.614-.7.998-.98 1.215-.263.206-.593.418-.895.612l-.052.033-9.441 6.07-.057.036c-.351.226-.72.464-1.046.633-.328.17-.922.446-1.658.394a3 3 0 0 1-2.188-1.195c-.442-.59-.531-1.24-.565-1.607A14.23 14.23 0 0 1 30 43.136V30.863c0-.417 0-.857.033-1.222.034-.368.123-1.017.566-1.607a3 3 0 0 1 2.187-1.195c.736-.052 1.33.224 1.658.394.325.17.695.407 1.046.633Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <filter
          id="iconPlay"
          width={size}
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_202_10897"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_202_10897"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

IconPlay.defaultProps = {
  size: '74',
};

IconPlay.propTypes = {
  size: propTypes.string,
};

export default IconPlay;
