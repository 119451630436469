const IconStop2 = () => {
  return (
    <svg width="10" viewBox="0 0 12 12" fill="none" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.576.285h4.848c.46 0 .84 0 1.148.026.322.026.617.082.894.224.43.219.78.568 1 .998.14.278.197.573.223.894.025.31.025.69.025 1.15v4.846c0 .46 0 .84-.025 1.15-.026.32-.083.616-.224.893-.22.43-.569.78-.999.999-.277.141-.572.197-.894.224-.309.025-.688.025-1.148.025H3.576c-.46 0-.84 0-1.149-.025-.32-.027-.616-.083-.893-.224-.43-.22-.78-.569-1-.999-.14-.277-.197-.573-.223-.894-.025-.309-.025-.689-.025-1.149V3.576c0-.46 0-.84.025-1.149.026-.32.082-.616.224-.894.219-.43.569-.78.999-.998.277-.142.572-.198.893-.224.31-.026.69-.026 1.15-.026Z"
        fill="url(#IconStop2)"
      />
      <defs>
        <linearGradient
          id="IconStop2"
          x1="11.714"
          y1="11.714"
          x2="-.039"
          y2="11.369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconStop2;
