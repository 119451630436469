import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { gradients, theme } from 'styles/Theme';

import { Text } from '../texts';

const ErrorNotificationStyled = styled.div`
  color: ${theme.colors.error};
  border-radius: ${theme.radii.xs};
  background: ${gradients.error};
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  padding: ${theme.space.sm};
  text-align: center;
  line-height: ${theme.lineHeights.sm};
  margin-bottom: ${theme.space.md};
  display: flex;
  justify-content: center;
  ${space};
`;

const ErrorNotification = ({ text, icon, textProps, ...props }) => {
  return (
    <ErrorNotificationStyled aria-live="polite" role="alert" {...props}>
      {icon}
      <Text type="body2" {...textProps}>
        {text}
      </Text>
    </ErrorNotificationStyled>
  );
};

ErrorNotification.propTypes = {
  text: propTypes.node.isRequired,
};

export default ErrorNotification;
