import { resetIsFileLoading, setIsFileLoading } from 'ducks/mediaObject';
import { store } from 'store';

const saveFile = async (url, fileName) => {
  store.dispatch(setIsFileLoading());
  fetch(url, { method: 'GET', mode: 'cors', cache: 'no-store' }).then(
    response => {
      response
        .blob()
        .then(blob => {
          const a = window.URL.createObjectURL(new Blob([blob]));

          const link = document.createElement('a');
          link.href = a;
          link.download = fileName;

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        })
        .then(() => {
          store.dispatch(resetIsFileLoading());
        });
    },
  );
};

export default saveFile;
