import { showModal } from 'ducks/modal';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { modalNames } from 'constants/modalNames';
import { DEFAULT_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';

import * as services from './services';
import {
  getPaymentsFailure,
  getPaymentsRequest,
  getPaymentsSuccess,
  getRevenueAmountFailure,
  getRevenueAmountRequest,
  getRevenueAmountSuccess,
  getRevenueFailure,
  getRevenueRequest,
  getRevenueSuccess,
  incrementPaymentsPage,
  incrementRevenuePage,
  postPaymentsFailure,
  postPaymentsRequest,
  postPaymentsSuccess,
} from './slice';

function* getPaymentsSaga({ payload: { page } }) {
  try {
    const purchases = yield call(services.getPaymentsList, {
      page,
    });
    if (purchases.length === DEFAULT_ITEMS_PER_PAGE_VALUE) {
      yield put(incrementPaymentsPage());
    }
    yield put(getPaymentsSuccess(purchases));
  } catch (error) {
    yield put(getPaymentsFailure());
  }
}

function* postPaymentsSaga({ payload: { values, onSuccess } }) {
  try {
    const paymentData = yield call(services.postPayment, values);
    yield put(postPaymentsSuccess(paymentData));
    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put(postPaymentsFailure());
    yield put(
      showModal({ modalType: modalNames.DECLINED_PAYMENT, modalProps: {} }),
    );
  }
}

function* getRevenueSaga({ payload: { page, firstDate, lastDate, filter } }) {
  try {
    const revenue = yield call(services.getRevenue, {
      page,
      firstDate,
      lastDate,
      filter,
    });
    yield put(getRevenueSuccess(revenue));
    if (revenue.length === DEFAULT_ITEMS_PER_PAGE_VALUE) {
      yield put(incrementRevenuePage());
    }
  } catch (error) {
    yield put(getRevenueFailure());
  }
}

function* getRevenueAmountSaga({ payload: { firstDate, lastDate } }) {
  try {
    const revenueAmount = yield call(services.getRevenueAmount, {
      firstDate,
      lastDate,
    });
    yield put(getRevenueAmountSuccess(revenueAmount));
  } catch (error) {
    yield put(getRevenueAmountFailure());
  }
}

export default function* watchPayment() {
  yield all([takeLatest(getPaymentsRequest, getPaymentsSaga)]);
  yield all([takeLatest(postPaymentsRequest, postPaymentsSaga)]);
  yield all([takeLatest(getRevenueRequest, getRevenueSaga)]);
  yield all([takeLatest(getRevenueAmountRequest, getRevenueAmountSaga)]);
}
