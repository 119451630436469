/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'report';

export const reportSlice = createSlice({
  name,
  initialState,
  reducers: {
    createReportRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createReportSuccess(state) {
      state.loading = false;
      state.error = false;
    },
    createReportFailure(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { createReportRequest, createReportSuccess, createReportFailure } =
  reportSlice.actions;

export default reportSlice.reducer;
