const IconRevert = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.03 7.72a.75.75 0 0 1 0 1.06l-2.25 2.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 0 1 1.06-1.06l.97.97V5.1c0-.642 0-1.08-.028-1.417-.027-.329-.076-.497-.136-.614a1.5 1.5 0 0 0-.655-.655c-.117-.06-.285-.109-.614-.136-.338-.027-.775-.028-1.417-.028H6.375c-.372 0-.486.002-.57.013a1.5 1.5 0 0 0-1.292 1.291c-.011.085-.013.199-.013.571a.75.75 0 0 1-1.5 0v-.052c0-.298 0-.518.026-.715A3 3 0 0 1 5.608.776 5.717 5.717 0 0 1 6.375.75h4.306c.604 0 1.102 0 1.508.033.421.035.809.109 1.173.294a3 3 0 0 1 1.311 1.311c.185.364.26.752.294 1.173.033.406.033.904.033 1.508v3.62l.97-.97a.75.75 0 0 1 1.06 0Zm-3.543 6.726c.011-.085.013-.199.013-.571a.75.75 0 0 1 1.5 0v.052c0 .298 0 .518-.026.715a3 3 0 0 1-2.582 2.582c-.197.026-.417.026-.715.026H7.319c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.108-1.173-.294a3 3 0 0 1-1.311-1.311c-.185-.364-.26-.752-.294-1.173C3 14.033 3 13.535 3 12.93v-3.62l-.97.97A.75.75 0 0 1 .97 9.22l2.25-2.25a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 1 1-1.06 1.06l-.97-.97v3.59c0 .643 0 1.08.028 1.417.027.329.076.497.135.614a1.5 1.5 0 0 0 .656.655c.117.06.285.109.614.136.338.027.775.028 1.417.028h4.275c.372 0 .486-.002.57-.013a1.5 1.5 0 0 0 1.292-1.291Z"
        fill="url(#IconRevert)"
      />
      <defs>
        <linearGradient
          id="IconRevert"
          x1=".75"
          y1="17.25"
          x2="1.248"
          y2=".281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconRevert;
