import { composeWithDevTools } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createReduxPromiseListener from 'redux-promise-listener';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './root-reducer';
import rootSaga from './root-saga';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const reduxPromiseListener = createReduxPromiseListener();

export const store = createStore(
  createRootReducer(history),
  {},
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      reduxPromiseListener.middleware,
    ),
  ),
);

export const promiseListener = reduxPromiseListener;
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
