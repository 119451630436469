import propTypes from 'prop-types';

import { MBox } from '../../common';
import { Heading4, Text } from '../../texts';

// for empty pages <main> content
const EmptyMainLayout = ({ children, ...props }) => {
  return (
    <MBox textAlign="center" {...props}>
      <MBox my="primaryLg">{props.icon}</MBox>
      <Heading4 as="p" mb="md">
        {props.title}
      </Heading4>
      <Text type="body1" color="grayMedium" lineHeight="md" mb="lg">
        {props.subtitle}
      </Text>
      {children}
    </MBox>
  );
};

EmptyMainLayout.propTypes = {
  children: propTypes.node,
  icon: propTypes.node.isRequired,
  title: propTypes.string.isRequired,
  subtitle: propTypes.node.isRequired,
};

export default EmptyMainLayout;
