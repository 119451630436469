const socialNetworksNames = {
  INSTAGRAM: 'instagram',
  SNAPCHAT: 'snapchat',
  TIKTOK: 'tiktok',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  SPOTIFY: 'spotify',
  PREMIUM: 'premium',
};

export default socialNetworksNames;
