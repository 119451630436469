import { all, call, put, takeLatest } from 'redux-saga/effects';

import { MAX_BANK_ACCOUNTS_PER_PAGE } from 'constants/numericConstants';

import * as services from './services';
import {
  createPaypalAccountFailure,
  createPaypalAccountRequest,
  createPaypalAccountSuccess,
  getPaypalAccountFailure,
  getPaypalAccountRequest,
  getPaypalAccountSuccess,
  getPaypalAccountsCollectionFailure,
  getPaypalAccountsCollectionRequest,
  getPaypalAccountsCollectionSuccess,
  incrementPaypalAccountsPage,
  patchPaypalAccountFailure,
  patchPaypalAccountRequest,
  patchPaypalAccountSuccess,
} from './slice';

function* getPaypalAccountsCollectionSaga({ payload: { page } }) {
  try {
    const paypalAccounts = yield call(
      services.getPaypalAccountsCollection,
      page,
    );
    yield put(getPaypalAccountsCollectionSuccess(paypalAccounts));
    if (paypalAccounts.length === MAX_BANK_ACCOUNTS_PER_PAGE) {
      yield put(incrementPaypalAccountsPage());
    }
  } catch (error) {
    yield put(getPaypalAccountsCollectionFailure());
  }
}

function* getPaypalAccountSaga({ payload: { id } }) {
  try {
    const paypalAccount = yield call(services.getPaypalAccount, id);
    yield put(getPaypalAccountSuccess(paypalAccount));
  } catch (error) {
    yield put(getPaypalAccountFailure());
  }
}

function* createPaypalAccountSaga({ payload: { body, onSuccess } }) {
  try {
    yield call(services.createPaypalAccount, body);
    yield put(createPaypalAccountSuccess());
    yield call(onSuccess);
  } catch (error) {
    yield put(createPaypalAccountFailure());
  }
}

function* patchPaypalAccountSaga({ payload: { id, body, onSuccess } }) {
  try {
    const patchedPaypalAccount = yield call(services.patchPaypalAccount, {
      id,
      body,
    });
    yield put(patchPaypalAccountSuccess(patchedPaypalAccount));
    yield call(onSuccess);
  } catch (error) {
    yield put(patchPaypalAccountFailure());
  }
}

export default function* watchPaypalAccounts() {
  yield all([
    takeLatest(
      getPaypalAccountsCollectionRequest,
      getPaypalAccountsCollectionSaga,
    ),
    takeLatest(getPaypalAccountRequest, getPaypalAccountSaga),
    takeLatest(createPaypalAccountRequest, createPaypalAccountSaga),
    takeLatest(patchPaypalAccountRequest, patchPaypalAccountSaga),
  ]);
}
