import { generatePath } from 'react-router-dom';

import getTimeZone from 'tools/getTimeZone';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getPaymentsList = ({ page }) => {
  return request.get(apiRoutes.PAYMENTS.PAYMENTS_LIST, {
    params: {
      page,
    },
  });
};

export const postPayment = body => {
  return request.post(apiRoutes.PAYMENTS.PAYMENTS_LIST, body);
};

export const getPurchase = id => {
  return request.get(generatePath(apiRoutes.PURCHASE.PURCHASE_DATA, { id }));
};

export const getRevenue = ({ page, firstDate, lastDate, filter }) => {
  return request.get(apiRoutes.REVENUE.REVENUE, {
    params: {
      page,
      firstDate: firstDate ? `${firstDate}${getTimeZone()}` : firstDate,
      lastDate: lastDate ? `${lastDate}${getTimeZone()}` : lastDate,
      filter,
    },
  });
};

export const getMySales = ({ page }) => {
  return request.get(apiRoutes.REVENUE.MY_SALES, {
    params: {
      page,
    },
  });
};

export const getShared = ({ page, firstDate, lastDate }) => {
  return request.get(apiRoutes.REVENUE.SHARED, {
    params: {
      page,
      'first-date': firstDate,
      'last-date': lastDate,
    },
  });
};

export const getShares = ({ page, firstDate, lastDate }) => {
  return request.get(apiRoutes.REVENUE.SHARES, {
    params: {
      page,
      'first-date': firstDate,
      'last-date': lastDate,
    },
  });
};

export const getSharesPeriod = ({ page, firstDate, lastDate }) => {
  return request.get(apiRoutes.REVENUE.SHARES_PERIOD, {
    params: {
      page,
      'first-date': firstDate,
      'last-date': lastDate,
    },
  });
};

export const getRefereesPeriod = ({ page, firstDate, lastDate }) => {
  return request.get(apiRoutes.REVENUE.REFEREES_PERIOD, {
    params: {
      page,
      'first-date': firstDate,
      'last-date': lastDate,
    },
  });
};

export const getRevenueAmount = ({ firstDate, lastDate }) => {
  return request.get(apiRoutes.REVENUE.AMOUNT, {
    params: {
      firstDate: firstDate ? `${firstDate}${getTimeZone()}` : firstDate,
      lastDate: lastDate ? `${lastDate}${getTimeZone()}` : lastDate,
    },
  });
};
