/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { MIN_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';
import { serverEventsTypes } from 'constants/serverEventsTypes';

import { initialState } from './initialState';

const name = 'user';

export const userSlice = createSlice({
  name,
  initialState,
  reducers: {
    getUserRequest(state) {
      state.loading = true;
    },
    getUserSuccess(state, { payload }) {
      state.currentUser = payload;
      state.loading = false;
    },
    getUserFailure(state) {
      state.loading = false;
    },
    deleteCurrentUser(state) {
      state.currentUser = null;
    },
    updateUserRequest(state, { payload: { isSettingsWelcomeAudio } }) {
      if (isSettingsWelcomeAudio) {
        state.isWelcomeAudioLoading = true;
      }
      state.loading = true;
    },
    setInitialLoadingToFalse(state) {
      state.initialLoading = false;
      state.loading = false;
    },
    initializeUser(state) {
      state.loading = true;
    },
    setIsPasswordRecoveredToFalse(state) {
      state.isPasswordRecovered = false;
    },
    getCountriesRequest(state) {
      state.loading = true;
    },
    getCountriesSuccess(state, { payload }) {
      state.loading = false;
      state.countries = payload;
    },
    getCountriesFailure(state) {
      state.loading = false;
    },
    setIsChangingBio(state) {
      state.isChangingBio = true;
    },
    resetIsChangingBio(state) {
      state.isChangingBio = false;
    },
    getCreatorByIdRequest(state) {
      state.loading = true;
    },
    getFanByIdRequest(state) {
      state.loading = true;
    },
    getCreatorByNicknameRequest(state) {
      state.loading = true;
    },
    getFanByNicknameRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getAnotherUserSuccess(state, { payload }) {
      state.anotherUser = payload;
      state.loading = false;
      state.error = false;
    },
    getAnotherUserFailure(state) {
      state.error = true;
      state.loading = false;
    },
    getTopCreatorsRequest(state) {
      state.loading = true;
      state.error = false;
      state.topCreatorsLoading = true;
    },
    getTopCreatorsSuccess(state, { payload }) {
      state.topCreators = payload;
      state.loading = false;
      state.error = false;
      state.topCreatorsLoading = false;
    },
    getTopCreatorsFailure(state) {
      state.loading = false;
      state.error = true;
      state.topCreatorsLoading = false;
    },
    deleteAccountRequest(state) {
      state.loading = true;
    },
    deleteAccountSuccess(state) {
      state.loading = false;
    },
    deleteAccountFailure(state) {
      state.loading = false;
    },
    restoreAccountRequest(state) {
      state.loading = true;
    },
    restoreAccountSuccess(state) {
      state.loading = false;
    },
    restoreAccountFailure(state) {
      state.loading = false;
    },
    clearAnotherUserData(state) {
      state.anotherUser = null;
    },
    getCreatorsBySearchRequest(state) {
      state.isSearchLoading = true;
      state.error = false;
    },
    getCreatorsBySearchSuccess(state, { payload }) {
      state.isSearchLoading = false;
      state.error = false;
      state.creators =
        payload?.length !== 0
          ? [...state.creators, ...payload]
          : state.creators;
      state.hasMore = payload?.length === MIN_ITEMS_PER_PAGE_VALUE;
    },
    getNewCreatorsBySearchSuccess(state, { payload }) {
      state.isSearchLoading = false;
      state.error = false;
      state.newCreators =
        payload?.length !== 0
          ? [...state.newCreators, ...payload]
          : state.newCreators;
      state.hasMoreNewCreators = payload?.length === MIN_ITEMS_PER_PAGE_VALUE;
    },
    getCreatorsBySearchFailure(state) {
      state.isSearchLoading = false;
      state.error = true;
      state.hasMore = false;
    },
    getCreatorsBySearchPaginationResetRequest(state) {
      state.isSearchLoading = true;
      state.creators = [];
      state.newCreators = [];
      state.creatorExplorePage = 1;
      state.newCreatorsPage = 1;
      state.error = false;
    },
    getCreatorsBySearchPaginationResetSuccess(state, { payload }) {
      state.isSearchLoading = false;
      state.error = false;
      state.creators = payload;
      state.hasMore = payload?.length === MIN_ITEMS_PER_PAGE_VALUE;
    },
    clearCreatorsBySearchData(state) {
      state.creators = [];
      state.newCreators = [];
    },
    clearSuggestionsBySearchData(state) {
      state.suggestions = [];
    },
    resetError(state) {
      state.error = null;
    },
    incrementCreatorExplorePage(state) {
      state.creatorExplorePage += 1;
    },
    incrementNewCreatorsPage(state) {
      state.newCreatorsPage += 1;
    },
    decrementCreatorExplorePage(state) {
      state.creatorExplorePage = 1;
      state.newCreatorsPage = 1;
    },
    resetHasMore(state) {
      state.hasMore = true;
    },
    resetTopCreators(state) {
      state.topCreators = null;
    },
    logout() {
      return initialState;
    },
    setIsPaymentMethodAvailable(state, { payload }) {
      state.currentUser.paymentMethodAvailable = !!payload;
    },
    resetIsWelcomeAudioLoading(state) {
      state.isWelcomeAudioLoading = false;
    },
    replaceWelcomeAudioUrl(state, { payload }) {
      state.currentUser.personalInfo.welcomeAudioUrl = payload;
    },
    getMailingSettingsRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getMailingSettingsSuccess(state, { payload }) {
      state.loading = false;
      state.error = false;
      state.currentUser.mailingSettings = payload;
    },
    getMailingSettingsFailure(state) {
      state.loading = false;
      state.error = true;
      state.currentUser.mailingSettings = {};
    },
    patchMailingSettingsRequest(state) {
      state.isMailingSettingsLoading = true;
      state.error = false;
    },
    patchMailingSettingsSuccess(state, { payload }) {
      state.isMailingSettingsLoading = false;
      state.error = false;
      state.currentUser.mailingSettings = payload;
    },
    patchMailingSettingsFailure(state) {
      state.isMailingSettingsLoading = false;
      state.error = true;
    },
    setNewAvatarUrl(state, { payload }) {
      state.newAvatarUrl = payload;
    },
    clearNewAvatarUrl(state) {
      state.newAvatarUrl = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      serverEventsTypes.MODALS,
      (state, { payload: { modalsToShow } }) => {
        state.currentUser.modalsToShow = modalsToShow;
      },
    );
  },
});

export const {
  logout,
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  deleteCurrentUser,
  updateUserRequest,
  setInitialLoadingToFalse,
  initializeUser,
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesFailure,
  setIsChangingBio,
  resetIsChangingBio,
  getCreatorByIdRequest,
  getFanByIdRequest,
  getCreatorByNicknameRequest,
  getFanByNicknameRequest,
  getAnotherUserSuccess,
  getAnotherUserFailure,
  getTopCreatorsRequest,
  getTopCreatorsSuccess,
  getTopCreatorsFailure,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountFailure,
  restoreAccountRequest,
  restoreAccountSuccess,
  restoreAccountFailure,
  clearAnotherUserData,
  getCreatorsBySearchRequest,
  getCreatorsBySearchSuccess,
  getCreatorsBySearchFailure,
  resetError,
  incrementCreatorExplorePage,
  decrementCreatorExplorePage,
  resetHasMore,
  clearCreatorsBySearchData,
  getCreatorsBySearchPaginationResetRequest,
  getCreatorsBySearchPaginationResetSuccess,
  getNewCreatorsBySearchSuccess,
  resetTopCreators,
  incrementNewCreatorsPage,
  setIsPaymentMethodAvailable,
  resetIsWelcomeAudioLoading,
  replaceWelcomeAudioUrl,
  getMailingSettingsFailure,
  getMailingSettingsRequest,
  getMailingSettingsSuccess,
  patchMailingSettingsFailure,
  patchMailingSettingsRequest,
  patchMailingSettingsSuccess,
  setNewAvatarUrl,
  clearNewAvatarUrl,
} = userSlice.actions;

export default userSlice.reducer;
