import { useEffect } from 'react';

import { RECAPTCHA_KEY } from 'config';

const useReCAPTCHA = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
    document.body.appendChild(script);
  }, []);

  return null;
};

export default useReCAPTCHA;
