import propTypes from 'prop-types';

const IconFacebook = ({ palette, size }) => {
  return (
    <svg width={size} viewBox="0 0 20 20" aria-hidden="true" fill="none">
      <path
        fill={palette}
        fillRule="evenodd"
        d="M.879.879C0 1.757 0 3.172 0 6v8c0 2.828 0 4.243.879 5.121C1.757 20 3.172 20 6 20h8c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14V6c0-2.828 0-4.243-.879-5.121C18.243 0 16.828 0 14 0H6C3.172 0 1.757 0 .879.879ZM12.133 20H9.016v-7.745H6.41V9.236h2.606V7.01c0-2.583 1.578-3.99 3.882-3.99 1.104 0 2.053.083 2.33.12v2.7h-1.6c-1.252 0-1.495.596-1.495 1.47v1.926h2.989l-.39 3.019h-2.6V20Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconFacebook.defaultProps = {
  palette: '#1877F2',
  size: '20',
};

IconFacebook.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
};

export default IconFacebook;
