import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const login = body => {
  return request.post(apiRoutes.AUTH.LOGIN, body);
};
export const register = body => {
  return request.post(apiRoutes.USER.USERS, body);
};

export const validateUserData = body => {
  return request.post(apiRoutes.USER.VALIDATE, body);
};

export const sendVerifyEmail = body => {
  return request.post(apiRoutes.VERIFY_EMAIL.SEND, body);
};

export const setVerifyEmail = body => {
  return request.post(apiRoutes.VERIFY_EMAIL.SET, body);
};
