const IconBancairesCard = () => {
  return (
    <svg width="800" viewBox="0 -9 58 58" fill="none" aria-hidden="true">
      <rect
        x=".5"
        y=".5"
        width="57"
        height="39"
        rx="2.5"
        fill="url(#IconBancairesCard)"
        stroke="#F1F1F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.825 19.407h7.853c-.07-1.697-.465-3.447-1.665-4.571C26.584 13.496 24.081 13 21.845 13c-2.328 0-4.902.545-6.338 1.982C14.267 16.218 14 18.21 14 20c0 1.874.524 4.054 1.868 5.31 1.429 1.335 3.744 1.69 5.977 1.69 2.17 0 4.519-.4 5.94-1.669 1.416-1.267 1.906-3.4 1.906-5.331v-.01h-7.866v-.582Zm8.38.584v6.696H41.13v-.01C42.728 26.592 44 25.15 44 23.38c0-1.77-1.272-3.304-2.87-3.392v.003H30.205Zm10.8-6.71c1.558 0 2.795 1.351 2.795 3.057 0 1.615-1.136 2.934-2.58 3.07H30.205V13.27h10.456c.064-.008.139-.003.212.003.046.003.091.006.132.006Z"
        fill="#FEFEFE"
      />
      <defs>
        <linearGradient
          id="IconBancairesCard"
          x1="15.732"
          y1="-5.065"
          x2="1.637"
          y2="38.204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222E72" />
          <stop offset=".592" stopColor="#40CBFF" />
          <stop offset="1" stopColor="#3CB792" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconBancairesCard;
