import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography,
} from 'styled-system';

export const MBox = styled.div`
  ${grid};
  ${flexbox};
  ${space};
  ${position};
  ${layout};
  ${color};
  ${typography};
  ${border};
  ${background};
  ${system({
    gap: true,
  })};
`;
