import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';

import passwordValidationTexts from 'constants/passwordValidationTexts';

const usePasswordValidationBanner = () => {
  const [listItems, setListItems] = useState(passwordValidationTexts);

  const {
    values: { password },
  } = useFormState();

  useEffect(() => {
    if (password) {
      setListItems(prevValue =>
        prevValue.map(item => {
          if (item.id === '1') {
            return { ...item, active: password.length >= 8 };
          }
          if (item.id === '2') {
            return {
              ...item,
              active: /^(?=.*[a-z])(?=.*[A-Z])/u.test(password),
            };
          }
          if (item.id === '3') {
            return {
              ...item,
              active: /^(?=.*?[0-9])/.test(password),
            };
          }
          return {
            ...item,
            active: /^(?=.*?[#?!@$%^&*-])/.test(password),
          };
        }),
      );
    } else {
      setListItems(passwordValidationTexts);
    }
  }, [password]);

  return listItems;
};

export default usePasswordValidationBanner;
