import propTypes from 'prop-types';

import noop from 'tools/noop';

import { Text } from 'components/texts';

import MButton from './MButton';

const MButtonShare = ({
  text,
  active,
  activeText,
  onClick,
  disabled,
  secondary,
  primary,
  icon,
  ...props
}) => {
  return (
    <>
      {active ? (
        <MButton
          disabled={disabled}
          onClick={onClick}
          secondary
          borderRadius={primary ? 'xs' : 'lg'}
          {...props}
        >
          {activeText}
        </MButton>
      ) : (
        <MButton
          disabled={disabled}
          secondary={secondary}
          primary={primary}
          onClick={onClick}
          borderRadius={primary ? 'xs' : 'lg'}
          icon={icon}
          {...props}
        >
          {icon && (
            <Text as="span" mr="1rem" display="flex">
              {icon}
            </Text>
          )}
          {text}
        </MButton>
      )}
    </>
  );
};

MButtonShare.defaultProps = {
  onClick: noop,
};

MButtonShare.propTypes = {
  activeText: propTypes.node.isRequired,
  active: propTypes.bool.isRequired,
  text: propTypes.node.isRequired,
  onClick: propTypes.func,
  disabled: propTypes.bool,
  secondary: propTypes.bool,
  primary: propTypes.bool,
  icon: propTypes.any,
};

export default MButtonShare;
