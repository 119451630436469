export const selectDialogMessages = state => state.message.dialogMessages;
export const selectIsMessagesLoading = state => state.message.loading;
export const selectError = state => state.message.error;
export const selectAdjacentPageWithScrollUpdate = state =>
  state.message.adjacentPageWithScrollUpdate;
export const selectMessagesPage = state => state.message.messagesPage;
export const selectTargetMessagePage = state => state.message.targetMessagePage;
export const selectMessagesPageTop = state => state.message.messagesPageTop;
export const selectMessagesPageBottom = state =>
  state.message.messagesPageBottom;
export const selectHasMore = state => state.message.hasMore;
export const selectHasMoreTop = state => state.message.hasMoreTop;
export const selectHasMoreBottom = state => state.message.hasMoreBottom;
export const selectMessagePrice = state => state.message.messagePrice;
export const selectPaymentData = state => state.message.paymentData;
export const selectReactedMessage = index => state =>
  state.message.reactedMessages[`publication${index}`];
export const selectIsReactedMessageLoading = index => state =>
  state.message.reactedMessages[`loading${index}`];
export const selectMessageData = state => state.message.messageData;
export const selectIsMessageLoading = state =>
  state.message.reactedMessageLoading;
export const selectInterlocutorNickname = state =>
  state.message.interlocutorNickname;
export const selectIsOnMessagePage = state => state.message.isOnMessagePage;
export const selectFirstLoading = state => state.message.firstLoading;
export const selectLastReadMessageId = state => state.message.lastReadMessageId;
