import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import propTypes from 'prop-types';

import { dialogCreatorListValues } from 'constants/dialogListTypes';
import { routes } from 'constants/routes';

import {
  IconAccount,
  IconAccountActive,
  IconChat,
  IconChatActive,
  IconDialog,
  IconDialogActive,
  IconIncome,
  IconIncomeActive,
  IconSearch,
  IconSearchActive,
} from 'components/icons';

import MNavigation from './MNavigation';

const NavBar = ({ isMobile, isFan, isCreator, unreadMessages, ...props }) => {
  const pathToDiscussion = useMemo(() => {
    return generatePath(routes.MESSAGES_CREATOR, {
      tab: dialogCreatorListValues.ALL,
    });
  }, []);

  const pathToDiscussionByIncome = useMemo(() => {
    return generatePath(routes.MESSAGES_CREATOR, {
      tab: dialogCreatorListValues.BY_INCOME,
    });
  }, []);

  const pathToDiscussionByUrgent = useMemo(() => {
    return generatePath(routes.MESSAGES_CREATOR, {
      tab: dialogCreatorListValues.BY_URGENT,
    });
  }, []);

  const MNavigationItems = useMemo(
    () => [
      {
        id: '1',
        icon: <IconSearch />,
        activeIcon: (
          <IconSearchActive uniqueId={isMobile ? 'mobile' : 'desktop'} />
        ),
        to: routes.DISCOVER,
        navText: 'Discover',
      },
      {
        id: '2',
        icon: <IconChat />,
        activeIcon: (
          <IconChatActive uniqueId={isMobile ? 'mobile' : 'desktop'} />
        ),
        to: routes.MESSAGES_FAN,
        navText: 'Discussions',
        isNotify: true,
      },
      {
        id: '3',
        icon: <IconAccount />,
        activeIcon: (
          <IconAccountActive uniqueId={isMobile ? 'mobile' : 'desktop'} />
        ),
        to: routes.SETTINGS,
        navText: 'Profil',
      },
      {
        id: '4',
        icon: <IconDialog />,
        activeIcon: (
          <IconDialogActive uniqueId={isMobile ? 'mobile' : 'desktop'} />
        ),
        secondActivePath: pathToDiscussionByIncome,
        thirdActivePath: pathToDiscussionByUrgent,
        to: pathToDiscussion,
        navText: 'Conversations',
        isNotify: true,
      },
      {
        id: '5',
        icon: <IconIncome />,
        activeIcon: (
          <IconIncomeActive uniqueId={isMobile ? 'mobile' : 'desktop'} />
        ),
        to: routes.REVENUE,
        navText: 'Revenus',
      },
    ],
    [
      isMobile,
      pathToDiscussion,
      pathToDiscussionByIncome,
      pathToDiscussionByUrgent,
    ],
  );

  const itemsToShow = useMemo(() => {
    if (isFan) {
      return [0, 1, 2];
    }
    if (isCreator) {
      return [3, 4, 2];
    }
    return [0, 1, 2];
  }, [isCreator, isFan]);

  return (
    <MNavigation
      itemsList={MNavigationItems}
      itemsToShow={itemsToShow}
      isMobile={isMobile}
      unreadMessages={unreadMessages}
      {...props}
    />
  );
};

NavBar.defaultProps = {
  isMobile: true,
  unreadMessages: 0,
};

NavBar.propTypes = {
  isMobile: propTypes.bool,
  isFan: propTypes.bool,
  isCreator: propTypes.bool,
  unreadMessages: propTypes.number,
};

export default NavBar;
