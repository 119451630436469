import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { theme } from 'styles/Theme';

const IconMicrophoneStyled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconMicrophone = ({ palette, size, isGradient, ...props }) => {
  return (
    <IconMicrophoneStyled
      width={size}
      viewBox="0 0 41 40"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.833 8.333a6.667 6.667 0 0 1 13.334 0V20a6.667 6.667 0 1 1-13.334 0V8.333Zm-5 6.667c.92 0 1.667.746 1.667 1.667V20c0 5.523 4.477 10 10 10s10-4.477 10-10v-3.333a1.667 1.667 0 0 1 3.333 0V20c0 6.8-5.09 12.41-11.666 13.23V35h5a1.667 1.667 0 1 1 0 3.333H13.833a1.667 1.667 0 1 1 0-3.333h5v-1.77C12.256 32.41 7.167 26.8 7.167 20v-3.333c0-.92.746-1.667 1.666-1.667Z"
        fill={isGradient ? 'url(#IconMicrophone)' : palette}
      />
      <defs>
        <linearGradient
          id="IconMicrophone"
          x1="7.167"
          y1="38.925"
          x2="40.822"
          y2="30.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
    </IconMicrophoneStyled>
  );
};

IconMicrophone.defaultProps = {
  palette: theme.colors.white,
  size: '41',
  isGradient: false,
};

IconMicrophone.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
  isGradient: propTypes.bool,
};

export default IconMicrophone;
