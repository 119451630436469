export const initialState = {
  error: false,
  loading: false,
  dialogsCollection: [],
  dialog: null,
  hasMore: true,
  dialogsPage: 1,
  hasTransferedMedia: false,
  mySubscribers: null,
  awaitingSum: 0,
  unreadMessages: null,
  searchState: null,
  sortState: 'chronological',
  sharedMediaObjects: [],
  sharedMediaObjectsPage: 1,
  hasMoreDialogMediaObjects: true,
  dialogsByReceiverId: [],
  isOnDialogPage: false,
  isGuaranteedResponsePending: false,
  guaranteedAnswerUntil: null,
  isVideoResponseRequested: false,
  dialogsSecondCollection: [],
  hasMoreSecondDialogs: true,
  secondDialogsPage: 1,
  isDialogsFirstLoaded: false,
  isDialogRequestFirstLoaded: false,
};
