import propTypes from 'prop-types';

const IconSnapchat = ({ palette, size }) => {
  return (
    <svg width={size} viewBox="0 0 20 20" aria-hidden="true" fill="none">
      <path
        fill={palette}
        fillRule="evenodd"
        d="M10.192 0h-.05L9.8.004c-.444 0-1.333.067-2.287.515a5.082 5.082 0 0 0-1.458 1.028A5.824 5.824 0 0 0 4.835 3.41c-.447 1.066-.34 2.861-.256 4.304v.003c.01.154.02.315.028.473a.743.743 0 0 1-.328.065c-.253 0-.552-.085-.891-.254a.756.756 0 0 0-.34-.075c-.201 0-.414.064-.598.179-.233.145-.383.35-.423.577-.027.15-.026.447.284.749.171.165.422.318.745.454.085.036.186.07.293.106.37.125.93.314 1.077.68.074.186.042.43-.095.725l-.01.024a7.108 7.108 0 0 1-1.007 1.662A5.79 5.79 0 0 1 2.1 14.226a4.316 4.316 0 0 1-1.706.709.492.492 0 0 0-.392.52c.004.072.02.146.048.216v.002c.096.237.318.439.678.617.44.218 1.098.4 1.955.543.044.088.089.308.12.46.032.16.066.324.115.5.052.189.188.416.536.416.132 0 .284-.032.46-.068.258-.054.61-.127 1.05-.127.243 0 .496.023.75.067.49.087.912.405 1.401.772C7.83 19.391 8.64 20 9.877 20c.034 0 .068-.001.101-.004.04.002.092.004.145.004 1.238 0 2.048-.61 2.762-1.147h.001c.49-.368.911-.685 1.401-.772.254-.044.507-.067.75-.067.42 0 .753.057 1.05.118.194.04.344.06.46.06h.023c.255 0 .442-.148.513-.409a7.28 7.28 0 0 0 .115-.495c.03-.141.076-.368.12-.457.857-.143 1.515-.325 1.955-.543.36-.177.58-.378.677-.615a.67.67 0 0 0 .05-.22.492.492 0 0 0-.393-.519c-2.673-.469-3.878-3.39-3.928-3.515a.402.402 0 0 0-.01-.024c-.137-.295-.168-.539-.094-.725.146-.365.706-.554 1.076-.68a4.42 4.42 0 0 0 .293-.105c.365-.153.626-.32.797-.508.206-.225.246-.441.244-.582-.007-.342-.252-.645-.643-.793a1.088 1.088 0 0 0-.435-.09.924.924 0 0 0-.405.089c-.312.155-.593.24-.833.253a.716.716 0 0 1-.276-.065l.024-.413.003-.06c.086-1.444.192-3.241-.255-4.308a5.831 5.831 0 0 0-1.223-1.868A5.094 5.094 0 0 0 12.478.51 5.521 5.521 0 0 0 10.192 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconSnapchat.defaultProps = {
  palette: '#F7EA21',
  size: '20',
};

IconSnapchat.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
};

export default IconSnapchat;
