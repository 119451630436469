import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const resetPasswordCheck = body => {
  return request.post(apiRoutes.RESET_PASSWORD.RESET_PASSWORD_CHECK, body);
};

export const resetPasswordEmail = body => {
  return request.post(apiRoutes.RESET_PASSWORD.RESET_PASSWORD_EMAIL, body);
};

export const resetPasswordSetup = body => {
  return request.post(apiRoutes.RESET_PASSWORD.RESET_PASSWORD_SETUP, body);
};
