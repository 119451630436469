import { GradientBorderRadius } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

// local variables
// states colors
const refundedBg = `background-image: ${gradients.error};`;
const boostLevelBg = `background-image: ${gradients.primary};`;
const guaranteedBg = `background-image: ${gradients.secondary};`;

// isCreatorOutgoingMessage
const creatorOutgoingMessage = `border-bottom-right-radius: ${theme.radii.xxs};
border-bottom-left-radius: ${theme.radii.sm};`;
const notCreatorOutgoingMessage = `border-bottom-right-radius: ${theme.radii.sm};
border-bottom-left-radius: ${theme.radii.xxs};`;

// chat message dialog all block (time, avatar, message)
export const MixMessageStyled = ({ isCreatorOutgoingMessage }) => `
  display: grid;
  grid-template-rows: auto;
  align-items: flex-end;
  grid-row-gap: ${theme.space.xxxs};
  line-height: ${theme.lineHeights.sm};
  + * {
    margin-top: ${theme.space.primaryMd};
  }
  ${
    isCreatorOutgoingMessage
      ? `grid-template-columns: 1fr auto;
      grid-template-areas:
      '. message'
      '. info';
      justify-items: end;
    `
      : `grid-column-gap: ${theme.space.primaryMd};
      grid-template-areas:
      'avatar message'
      '. info';
      grid-template-columns: auto 1fr;
  `
  }
  .lazyload-wrapper {
    border-radius: inherit;
  }
`;

// chat message dialog BODY
export const MixMessageBodyStyled = ({ isCreatorOutgoingMessage }) => `
  border: 1px solid ${theme.colors.grayLighter};
  border-top-left-radius: ${theme.radii.sm};
  border-top-right-radius: ${theme.radii.sm};
  ${
    isCreatorOutgoingMessage
      ? `${creatorOutgoingMessage}
  max-width: ${theme.sizes.maxWidthOutgoingMessage};
  margin-left: auto;
  justify-items: end;`
      : `${notCreatorOutgoingMessage}
  max-width: ${theme.sizes.maxWidthIncomeMessage};
  `
  }
`;

// chat message dialog BODY with STATE
export const MixMessageBodyStateStyled = ({
  isGuaranteed,
  isRefunded,
  isBoostLevel,
}) => `
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: inherit;
    z-index: -1;
  }
  ${
    isGuaranteed &&
    `${GradientBorderRadius(`${gradients.secondary}`, '')};
    border-width: ${theme.borderWidths.xs};
    &:after {${guaranteedBg}}`
  };
  ${
    isRefunded &&
    `${GradientBorderRadius(`${gradients.error}`, '')};
    border-width: ${theme.borderWidths.xs};
    &:after {${refundedBg}}`
  };
  ${
    isBoostLevel &&
    `${GradientBorderRadius(`${gradients.primary}`, '')};
    border-width: ${theme.borderWidths.xs};
    &:after {${boostLevelBg}}`
  };
`;

// chat message dialog FOOTER with STATE
export const MixMessageFooterStyled = ({ isCreatorOutgoingMessage }) => `
${
  isCreatorOutgoingMessage ? creatorOutgoingMessage : notCreatorOutgoingMessage
};
`;

export const MixMessageFooterStateStyled = ({
  isGuaranteed,
  isRefunded,
  isBoostLevel,
}) => `
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.white};
  padding: ${theme.space.md};
  margin: -${theme.borderWidths.xs};
  }
  ${isGuaranteed && guaranteedBg};
  ${isRefunded && refundedBg};
  ${isBoostLevel && boostLevelBg};
`;
