import propTypes from 'prop-types';
import styled from 'styled-components';

import noop from 'tools/noop';

import { Text } from 'components/texts';

import { theme } from 'styles/Theme';

const CloseFullButtonStyled = styled.button`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.lg};
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.semiBold};
  padding: ${theme.space.closeFullButtonSm};
  margin-top: ${theme.space.md};
`;

const CrossStyled = styled.span`
  position: relative;
  width: ${theme.sizes.closeFullButtonCrossSize};
  height: ${theme.sizes.closeFullButtonCrossSize};
  margin-right: ${theme.space.sm};
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    background-color: ${theme.colors.black};
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const CloseFullButton = ({ type, onClick, ...props }) => (
  <CloseFullButtonStyled type={type} onClick={onClick} {...props}>
    <CrossStyled aria-hidden="true" />
    <Text as="span" color="black">
      Fermer
    </Text>
  </CloseFullButtonStyled>
);

CloseFullButton.defaultProps = {
  onClick: noop,
  type: 'button',
};

CloseFullButton.propTypes = {
  onClick: propTypes.func,
  type: propTypes.string,
};

export default CloseFullButton;
