const IconGooglePay = () => {
  return (
    <svg width="30" viewBox="0 0 30 15" fill="none" aria-hidden="true">
      <g clipPath="url(#IconGooglePay)">
        <path
          d="M14.175 7.888v3.487H13.05V2.75h2.925c.712 0 1.387.263 1.912.75.525.45.788 1.125.788 1.838a2.44 2.44 0 0 1-.788 1.837 2.725 2.725 0 0 1-1.912.75l-1.8-.037Zm0-4.088v3h1.875c.412 0 .825-.15 1.087-.45.6-.562.6-1.5.038-2.062l-.038-.038c-.3-.3-.675-.487-1.087-.45h-1.875ZM21.262 5.3c.825 0 1.463.225 1.95.675.488.45.713 1.05.713 1.8v3.6h-1.05v-.825h-.038c-.45.675-1.087 1.013-1.837 1.013-.638 0-1.2-.188-1.65-.563-.413-.375-.675-.9-.675-1.462 0-.6.225-1.088.675-1.463.45-.375 1.087-.525 1.837-.525.675 0 1.2.113 1.613.375v-.262c0-.375-.15-.75-.45-.975a1.634 1.634 0 0 0-1.088-.413c-.637 0-1.125.263-1.462.788l-.975-.6c.6-.788 1.387-1.163 2.437-1.163Zm-1.425 4.275c0 .3.15.563.375.713.263.187.563.3.863.3.45 0 .9-.188 1.237-.525.375-.338.563-.75.563-1.2-.338-.263-.825-.413-1.463-.413-.45 0-.825.113-1.125.338-.3.187-.45.45-.45.787ZM30 5.488 26.288 14h-1.125l1.387-2.962-2.438-5.513h1.2l1.763 4.238h.038l1.724-4.238H30v-.037Z"
          fill="#5F6368"
        />
        <path
          d="M9.712 7.138c0-.338-.037-.675-.075-1.013H4.95v1.913h2.662c-.112.6-.45 1.162-.975 1.5v1.237H8.25c.937-.862 1.462-2.137 1.462-3.638Z"
          fill="#4285F4"
        />
        <path
          d="M4.95 11.975c1.35 0 2.475-.45 3.3-1.2L6.638 9.538c-.45.3-1.013.487-1.688.487-1.275 0-2.4-.862-2.775-2.062H.525v1.275c.863 1.687 2.55 2.737 4.425 2.737Z"
          fill="#34A853"
        />
        <path
          d="M2.175 7.963a2.793 2.793 0 0 1 0-1.913V4.775H.525c-.712 1.388-.712 3.038 0 4.463l1.65-1.275Z"
          fill="#FBBC04"
        />
        <path
          d="M4.95 4.025c.712 0 1.388.263 1.912.75L8.287 3.35c-.9-.825-2.1-1.312-3.3-1.275-1.875 0-3.6 1.05-4.425 2.738l1.65 1.275A2.857 2.857 0 0 1 4.95 4.025Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="IconGooglePay">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h30v14.287H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconGooglePay;
