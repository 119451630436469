import propTypes from 'prop-types';
import styled from 'styled-components';

import { gradients, theme } from 'styles/Theme';

const ProgressBarStyled = styled.progress`
  height: 0.2rem;
  background: ${theme.colors.primary}08;
  width: 100%;
  border: 0;
  border-radius: ${theme.radii.xs};
  position: absolute;

  &::-webkit-progress-bar {
    background: ${theme.colors.primary}08;
  }
  &::-webkit-progress-value {
    border-radius: ${theme.radii.xs};
    background: ${gradients.primary};
  }
  &::-moz-progress-bar {
    border-radius: ${theme.radii.xs};
    background: ${gradients.primary};
  }
`;

const ProgressBar = ({ value }) => (
  <ProgressBarStyled max="100" value={value} />
);

ProgressBar.propTypes = {
  value: propTypes.string.isRequired,
};

export default ProgressBar;
