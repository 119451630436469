import { useCallback, useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import { useDispatch } from 'react-redux';

import TimerWidget from 'containers/widgets/TimerWidget';
import { showModal } from 'ducks/modal';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { modalNames } from 'constants/modalNames';

import { getMessageCreationDate } from 'tools/messageCreationDate';
import saveFile from 'tools/saveFile';

import { Avatar } from 'components/avatar';
import { LoadingSpinner, MBox, Picture } from 'components/common';
import {
  IconCheckInput,
  IconPlay2,
  IconTimer,
  IconVideo,
} from 'components/icons';
import { MList, Text } from 'components/texts';
import { VoiceMessage } from 'components/voiceMessage';

import {
  MixMessageBodyStateStyled,
  MixMessageBodyStyled,
  MixMessageFooterStateStyled,
  MixMessageFooterStyled,
  MixMessageStyled,
} from 'styles/MixinMessageStyle';
import { VisuallyHidden } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import UnreadMessageChat from '../helpers/UnreadMessageChat';

const MessageCreatorStyled = styled.article`
  ${props =>
    MixMessageStyled({
      isCreatorOutgoingMessage: props.isOutgoingMessage,
    })};
  .lazyload-wrapper-image {
    height: ${theme.sizes.loadingMessageSizeCreator};
    width: ${theme.sizes.loadingMessageSizeCreator};
  }
  .lazyload-wrapper-video {
    width: ${theme.sizes.loadingMessageWidthVideo};
    height: ${theme.sizes.loadingMessageHeightVideo};
  }
`;

const MessageCreatorBodyStyled = styled.div`
  ${props =>
    MixMessageBodyStyled({
      isCreatorOutgoingMessage: props.isOutgoingMessage,
    })};
  ${props =>
    props.isGuaranteed &&
    !props.refunded &&
    MixMessageBodyStateStyled({
      isGuaranteed: props.isGuaranteed,
      isRefunded: '',
      isBoostLevel: '',
    })};
  ${props =>
    props.refunded &&
    MixMessageBodyStateStyled({
      isGuaranteed: '',
      isRefunded: props.refunded,
      isBoostLevel: '',
    })};
  ${props =>
    !props.isGuaranteed &&
    props.boostLevel &&
    MixMessageBodyStateStyled({
      isGuaranteed: '',
      isRefunded: '',
      isBoostLevel: props.boostLevel,
    })};
  ${props =>
    !props.isOutgoingMessage &&
    props.messageMediaType &&
    `
  .lazyload-wrapper {
    height: calc(
      ${theme.sizes.loadingMessageSizeCreator} - ${theme.space.primaryMd} * 2
    );
    width: 100%;
  }
  `};
`;

// message FOOTER styled with STATE
const MessageCreatorFooterStyled = styled.footer`
  ${props =>
    MixMessageFooterStyled({
      isCreatorOutgoingMessage: props.isOutgoingMessage,
    })};
  ${props =>
    props.isGuaranteed &&
    !props.refunded &&
    MixMessageFooterStateStyled({
      isGuaranteed: props.isGuaranteed,
      isRefunded: '',
      isBoostLevel: '',
    })};
  ${props =>
    props.refunded &&
    MixMessageFooterStateStyled({
      isGuaranteed: '',
      isRefunded: props.refunded,
      isBoostLevel: '',
    })};
  ${props =>
    props.boostLevel &&
    MixMessageFooterStateStyled({
      isGuaranteed: '',
      isRefunded: '',
      isBoostLevel: props.boostLevel,
    })};
`;

// button for open image/video modal
const MessageModalButtonStyled = styled.button`
  width: 100%;
  display: block;
  border-radius: inherit;
`;

const MessageCreator = ({
  avatarChecked,
  avatarSrc,
  avatarSrcAvif,
  avatarSrcWebp,
  receiverName,
  message,
  isOutgoingMessage,
  isDefaultMessage,
  defaultMessageCreatedAt,
  welcomeAudioUrl,
  isLoadingSpinner,
  isLastReadMessage,
  ...props
}) => {
  const dispatch = useDispatch();

  const url = useMemo(
    () => message?.mediaObject?.originalUrl,
    [message?.mediaObject?.originalUrl],
  );

  const handleDownloadVideoClick = useCallback(() => {
    saveFile(url, `premium-video_${url.substring(url.lastIndexOf('/') + 1)}`);
  }, [url]);

  const handleDownloadImageClick = useCallback(() => {
    saveFile(url, `premium-photo_${url.substring(url.lastIndexOf('/') + 1)}`);
  }, [url]);

  const openVideoModalHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.VIDEO_FULL,
        modalProps: {
          $src: message?.mediaObject?.originalUrl,
          isAutoPlay: true,
          onDownloadVideo: handleDownloadVideoClick,
        },
      }),
    );
  }, [dispatch, handleDownloadVideoClick, message?.mediaObject?.originalUrl]);

  const openImageModalHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.IMAGE_FULL,
        modalProps: {
          $src: message?.mediaObject?.originalUrl,
          $srcSetAvif: message?.mediaObject?.originalAvifUrl,
          $srcSetWebp: message?.mediaObject?.originalWebpUrl,
          onDownloadImage: handleDownloadImageClick,
        },
      }),
    );
  }, [
    dispatch,
    handleDownloadImageClick,
    message?.mediaObject?.originalAvifUrl,
    message?.mediaObject?.originalUrl,
    message?.mediaObject?.originalWebpUrl,
  ]);

  const boostLevelItems = useMemo(
    () => [
      {
        id: '1',
        text: <>€</>,
      },
      {
        id: '2',
        text: <>€</>,
        opacity:
          message?.boostLevel === 2 || message?.boostLevel === 3 ? '1' : '0.08',
      },
      {
        id: '3',
        text: <>€</>,
        opacity: message?.boostLevel === 3 ? '1' : '0.08',
      },
    ],
    [message?.boostLevel],
  );

  return (
    <>
      <MessageCreatorStyled isOutgoingMessage={isOutgoingMessage} {...props}>
        {!isOutgoingMessage && (
          <Avatar
            gridArea="avatar"
            $src={avatarSrc}
            $srcSetAvif={avatarSrcAvif}
            $srcSetWebp={avatarSrcWebp}
            personName={receiverName}
            checked={avatarChecked}
          />
        )}
        <MBox gridArea="message" isOutgoingMessage={isOutgoingMessage}>
          <MessageCreatorBodyStyled
            isDefaultMessage={isDefaultMessage}
            isOutgoingMessage={isOutgoingMessage}
            refunded={message?.refunded}
            boostLevel={message?.boostLevel}
            isGuaranteed={
              message?.type === 'guaranteed_response' ||
              message?.type === 'video_response'
            }
            messageMediaType={
              message?.mediaObject && message?.mediaObject?.type !== 'audio'
            }
          >
            {isLoadingSpinner && (
              <MBox width={theme.sizes.loadingMessageWidthVideo} p="primaryMd">
                <LoadingSpinner size="30" position="relative" />
              </MBox>
            )}
            {!isDefaultMessage ? (
              <>
                {message?.mediaObject &&
                  message?.mediaObject?.type === 'audio' && (
                    <>
                      <VoiceMessage
                        isMessage
                        audioUrl={message?.mediaObject?.originalUrl}
                        messageId={message?.id}
                      />
                    </>
                  )}
                <Text
                  as="div"
                  type="body2"
                  p={message?.body ? 'primaryMd' : null}
                  borderRadius={!message?.body ? 'inherit' : null}
                  wordBreak="break-all"
                >
                  {message?.mediaObject &&
                    message?.mediaObject?.type !== 'audio' && (
                      <LazyLoad
                        placeholder={<LoadingSpinner position="relative" />}
                        className={
                          message?.mediaObject?.type === 'video'
                            ? 'lazyload-wrapper-video'
                            : 'lazyload-wrapper-image'
                        }
                        // throttle={1000000}
                        overflow
                        once
                      >
                        <MessageModalButtonStyled
                          type="button"
                          onClick={
                            message?.mediaObject?.type === 'video'
                              ? openVideoModalHandler
                              : openImageModalHandler
                          }
                          aria-label={
                            message?.mediaObject?.type === 'video'
                              ? 'Ouvrir modal avec vidéo'
                              : 'Ouvrir modal avec photo'
                          }
                          style={{
                            position:
                              message?.mediaObject?.type === 'video' &&
                              'relative',
                            height:
                              message?.mediaObject?.type === 'video' && '100%',
                            width:
                              message?.mediaObject?.type === 'video' && '100%',
                          }}
                        >
                          {message?.mediaObject?.type === 'video' && (
                            <MBox
                              position="absolute"
                              top="0"
                              right="0"
                              height="100%"
                              width="100%"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              as="span"
                              zIndex="99"
                            >
                              <IconPlay2 />
                            </MBox>
                          )}
                          <Picture
                            objectFit="cover"
                            borderRadius={message?.body ? 'xs' : 'inherit'}
                            position={
                              message?.mediaObject?.type === 'video' &&
                              'absolute'
                            }
                            srcSetAvif={message?.mediaObject?.originalAvifUrl}
                            srcSetWebp={message?.mediaObject?.originalWebpUrl}
                            src={message?.mediaObject?.squarePreviewUrl}
                            alt={
                              message?.mediaObject?.type === 'video'
                                ? `Video`
                                : `Photo`
                            }
                            height={
                              message?.mediaObject?.type === 'video'
                                ? '100%'
                                : null
                            }
                          />
                        </MessageModalButtonStyled>
                      </LazyLoad>
                    )}
                  {message?.body && (
                    <Text
                      mt={message?.mediaObject ? 'sm' : null}
                      wordBreak="break-word"
                    >
                      {message?.body}
                    </Text>
                  )}
                </Text>
              </>
            ) : (
              <VoiceMessage isMessage audioUrl={welcomeAudioUrl} />
            )}

            {/* If guaranteed answer audio/video */}
            {!isOutgoingMessage &&
              (message?.type === 'guaranteed_response' ||
                message?.type === 'video_response') && (
                <MessageCreatorFooterStyled
                  refunded={message?.refunded}
                  isGuaranteed={
                    message?.type === 'guaranteed_response' ||
                    message?.type === 'video_response'
                  }
                >
                  <MBox>
                    <Text
                      type="subtitle3"
                      display={
                        message?.type === 'video_response' ? 'flex' : null
                      }
                      alignItems={
                        message?.type === 'video_response' ? 'center' : null
                      }
                    >
                      <Text
                        as="span"
                        textDecoration={
                          message?.refunded ? 'line-through' : null
                        }
                      >
                        {`${message?.price}€`}
                      </Text>
                      {message?.type === 'video_response' && (
                        <IconVideo ml="xxs" />
                      )}
                    </Text>
                    <Text type="subtitle3">
                      {!message?.refunded ? (
                        <>
                          {message?.type !== 'video_response'
                            ? 'Rép. garantie'
                            : 'Rép. vidéo garantie'}
                        </>
                      ) : (
                        'Remboursé'
                      )}
                    </Text>
                  </MBox>
                  <Text display="flex" alignItems="center">
                    {message?.answerUntil || message?.refunded ? (
                      <>
                        <IconTimer palette={theme.colors.white} mr="xxs" />
                        {!message?.refunded ? (
                          <>
                            <VisuallyHidden>réponse &#39;dici</VisuallyHidden>
                            <Text as="time" type="subtitle3">
                              <TimerWidget deadline={message?.answerUntil} />
                            </Text>
                          </>
                        ) : (
                          <Text as="span" type="subtitle3">
                            Délai dépassé
                          </Text>
                        )}
                      </>
                    ) : (
                      <>
                        <VisuallyHidden>a répondu</VisuallyHidden>
                        <IconCheckInput
                          palette={theme.colors.white}
                          isGradient={false}
                        />
                      </>
                    )}
                  </Text>
                </MessageCreatorFooterStyled>
              )}

            {/* If not guaranteed answer audio/video - for boost level - 1,2,3 */}
            {!isOutgoingMessage &&
              message?.type !== 'guaranteed_response' &&
              message?.type !== 'video_response' &&
              !!message?.boostLevel && (
                <MessageCreatorFooterStyled boostLevel={message?.boostLevel}>
                  <MBox>
                    <Text type="subtitle3">{`${message?.price}€`}</Text>
                    <Text type="subtitle3">
                      Message boosté niv {message?.boostLevel}
                    </Text>
                  </MBox>
                  <MBox
                    display="flex"
                    alignItems="center"
                    borderRadius="sm"
                    px="sm"
                    bg="white"
                    aria-hidden="true"
                  >
                    <MList
                      display="grid"
                      gridTemplateColumns="repeat(3, 1fr)"
                      gridColumnGap="0.3rem"
                      textType="subtitle3"
                      $isGradient
                      items={boostLevelItems}
                    />
                  </MBox>
                </MessageCreatorFooterStyled>
              )}
          </MessageCreatorBodyStyled>
        </MBox>
        <MBox gridArea="info">
          <VisuallyHidden>envoyé à </VisuallyHidden>
          {!isDefaultMessage && (
            <Text as="time" color="grayMedium" type="body2">
              {!isDefaultMessage
                ? getMessageCreationDate(message?.createdAt)
                : `${defaultMessageCreatedAt}`}
            </Text>
          )}

          {/* hint text for price messages */}
          {/* for guaranteed answer audio/video and refunded */}
          {!isOutgoingMessage &&
            (message?.answerUntil || message?.refunded) && (
              <Text
                type="body2"
                mt="sm"
                maxWidth="maxWidthIncomeMessage"
                color={message?.refunded ? 'error' : null}
              >
                {!message?.refunded ? (
                  <>
                    {message?.type !== 'video_response'
                      ? 'Si vous ne répondez pas dans le temps imparti, le fan sera remboursé.'
                      : 'Si vous ne répondez pas en vidéo dans le temps imparti, le fan sera remboursé.'}
                  </>
                ) : (
                  'Vous n’avez pas répondu dans le temps imparti. Le fan a été remboursé.'
                )}
              </Text>
            )}
        </MBox>
      </MessageCreatorStyled>
      {isLastReadMessage && <UnreadMessageChat />}
    </>
  );
};

MessageCreator.propTypes = {
  avatarChecked: propTypes.bool,
  avatarSrc: propTypes.string,
  avatarSrcAvif: propTypes.string,
  avatarSrcWebp: propTypes.string,
  receiverName: propTypes.string,
  isOutgoingMessage: propTypes.bool,
  isDefaultMessage: propTypes.bool,
  defaultMessageCreatedAt: propTypes.string,
  message: propTypes.shape({
    id: propTypes.oneOfType([propTypes.string, propTypes.number]),
    body: propTypes.string,
    image: propTypes.string,
    createdAt: propTypes.string,
    read: propTypes.bool,
    answerUntil: propTypes.string, // guaranteed answer
    refunded: propTypes.bool,
    boostLevel: propTypes.number,
    paid: propTypes.bool,
    type: propTypes.string,
    messageType: propTypes.string,
    price: propTypes.number,
    isLoadingSpinner: propTypes.bool,
  }),
  isLastReadMessage: propTypes.bool,
};

export default MessageCreator;
