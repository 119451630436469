const IconCalendarArrow = () => {
  return (
    <svg aria-hidden="true" width="10" viewBox="0 0 10 14" fill="none">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.46967 0.46967C4.76256 0.176777 5.23744 0.176777 5.53033 0.46967L9.28033 4.21967C9.57322 4.51256 9.57322 4.98744 9.28033 5.28033C8.98744 5.57322 8.51256 5.57322 8.21967 5.28033L5 2.06066L1.78033 5.28033C1.48744 5.57322 1.01256 5.57322 0.71967 5.28033C0.426777 4.98744 0.426777 4.51256 0.71967 4.21967L4.46967 0.46967ZM0.71967 8.71967C1.01256 8.42678 1.48744 8.42678 1.78033 8.71967L5 11.9393L8.21967 8.71967C8.51256 8.42678 8.98744 8.42678 9.28033 8.71967C9.57322 9.01256 9.57322 9.48744 9.28033 9.78033L5.53033 13.5303C5.23744 13.8232 4.76256 13.8232 4.46967 13.5303L0.71967 9.78033C0.426777 9.48744 0.426777 9.01256 0.71967 8.71967Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconCalendarArrow;
