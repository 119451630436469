const IconSettings = () => {
  return (
    <svg width="20" viewBox="0 0 20 21" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M15 5.5a1.667 1.667 0 1 0 0 3.334A1.667 1.667 0 0 0 15 5.5Zm-3.228.833a3.335 3.335 0 0 1 6.561.834A3.333 3.333 0 0 1 11.772 8H2.5a.833.833 0 1 1 0-1.667h9.272ZM5 12.168A1.667 1.667 0 1 0 5 15.5a1.667 1.667 0 0 0 0-3.333Zm-3.333 1.667A3.333 3.333 0 0 1 8.228 13H17.5a.833.833 0 0 1 0 1.667H8.228a3.335 3.335 0 0 1-6.561-.833Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconSettings;
