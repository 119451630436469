/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { MIN_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';

import { initialState } from './initialState';

const name = 'share';

export const shareSlice = createSlice({
  name,
  initialState,
  reducers: {
    getShareListRequest(state) {
      state.isShareListLoading = true;
      state.error = false;
    },
    getShareListSuccess(state, { payload }) {
      state.isShareListLoading = false;
      state.error = false;
      state.shareList =
        payload?.length !== 0
          ? [...state.shareList, ...payload]
          : state.shareList;
      state.hasMore = payload?.length === MIN_ITEMS_PER_PAGE_VALUE;
    },
    getShareListPaginationResetRequest(state) {
      state.isShareListLoading = true;
      state.shareList = [];
      state.shareListPage = 1;
      state.error = false;
    },
    getShareListPaginationResetSuccess(state, { payload }) {
      state.isShareListLoading = false;
      state.error = false;
      state.shareList = payload;
      state.hasMore = payload?.length === MIN_ITEMS_PER_PAGE_VALUE;
    },
    getShareListFailure(state) {
      state.loading = false;
      state.error = true;
      state.hasMore = false;
    },
    clearShareList(state) {
      state.shareList = [];
    },
    createShareRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createShareSuccess(state, { payload }) {
      state.loading = false;
      state.error = false;
      state.shareDetails = payload;
    },
    createShareFailure(state) {
      state.loading = false;
      state.error = true;
    },
    resetHasMore(state) {
      state.hasMore = true;
    },
    incrementShareListPage(state) {
      state.shareListPage += 1;
    },
    decrementShareListPage(state) {
      state.shareListPage = 1;
    },
    clearShareDetails(state) {
      state.shareDetails = null;
    },
  },
});

export const {
  getShareListFailure,
  getShareListSuccess,
  getShareListRequest,
  setShareListSearch,
  clearShareList,
  createShareFailure,
  createShareRequest,
  createShareSuccess,
  resetHasMore,
  incrementShareListPage,
  decrementShareListPage,
  clearShareDetails,
  getShareListPaginationResetRequest,
  getShareListPaginationResetSuccess,
} = shareSlice.actions;

export default shareSlice.reducer;
