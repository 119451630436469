import { useSelector } from 'react-redux';

import { selectUnreadMessages } from 'ducks/dialog';
import { selectIsAuthenticated } from 'ducks/user';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from 'styles/Theme';

import NavigationBar from '../NavigationBar';

const MobileNavigationStyled = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9999;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const MobileNavigation = ({ isFan, isCreator }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const unreadMessages = useSelector(selectUnreadMessages);

  return (
    <MobileNavigationStyled>
      {isAuthenticated && (
        <NavigationBar
          isFan={isFan}
          isCreator={isCreator}
          unreadMessages={unreadMessages}
        />
      )}
    </MobileNavigationStyled>
  );
};

MobileNavigation.propTypes = {
  isFan: propTypes.bool,
  isCreator: propTypes.bool,
};

export default MobileNavigation;
