export const numberWithSpaces = x => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatPrice = (value, withSpace = false) => {
  if (withSpace) {
    return `${value} €`.replace('.', ',');
  }
  return `${value}€`.replace('.', ',');
};

export const namePromoCodeFormat = value => {
  return value ? value.toUpperCase().replace(/[^A-Z\d]/g, '') : '';
};

export const discountFormat = value => {
  if (value === '0') return '';
  return value ? value.replace(/[^\d]/g, '') : '';
};

export const phoneNumberFormat = value => {
  const numbers = value ? value.replace(/[^\d]/g, '') : '';
  const plus = value && value[0] === '+' ? '+' : '';
  const sliceNumber = numbers.length > 13 ? numbers.slice(0, 13) : numbers;
  return plus + sliceNumber;
};

export const lowerCaseFormat = value => (value || '').toLowerCase();

export const noEmptySpacesFormat = value => (value || '').replaceAll(' ', '');

export const ibanParse = value =>
  (value || '').toUpperCase().replace(/[^\dA-Z]/g, '');

export const ibanFormat = value => {
  const result = (value || '')
    .toUpperCase()
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, a => {
      return `${a} `;
    });
  if (result[result.length - 1] === ' ') {
    return result.slice(0, -1);
  }
  return result;
};

export const bicFormat = value =>
  (value || '').toUpperCase().replace(/[^\dA-Z]/g, '');
