/* eslint-disable no-nested-ternary */
import { useCallback, useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import { useDispatch } from 'react-redux';

import TimerWidget from 'containers/widgets/TimerWidget';
import { showModal } from 'ducks/modal';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { modalNames } from 'constants/modalNames';

import { getMessageCreationDate } from 'tools/messageCreationDate';
import saveFile from 'tools/saveFile';

import { Avatar } from 'components/avatar';
import { MButton } from 'components/buttons';
import { LoadingSpinner, MBox, Picture } from 'components/common';
import {
  IconBoost,
  IconCheckInput,
  IconPlay2,
  IconTimer,
} from 'components/icons';
import { Text } from 'components/texts';
import { VoiceMessage } from 'components/voiceMessage';

import {
  MixMessageBodyStateStyled,
  MixMessageBodyStyled,
  MixMessageFooterStateStyled,
  MixMessageFooterStyled,
  MixMessageStyled,
} from 'styles/MixinMessageStyle';
import { VisuallyHidden } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import { MessageNotification } from '..';
import UnreadMessageChat from '../helpers/UnreadMessageChat';

const MessageFanWrapperStyled = styled.div`
  + * {
    margin-top: ${theme.space.primaryMd};
  }
  ${props =>
    props.isDefaultMessage &&
    `height: 100%; display: flex; flex-direction: column; justify-content: space-between;`};
`;

const MessageFanStyled = styled.article`
  ${props =>
    MixMessageStyled({
      isCreatorOutgoingMessage: props.isOutgoingMessage,
    })};
  .lazyload-wrapper {
    display: flex;
    align-items: center;
  }
  .lazyload-wrapper-image {
    min-height: ${theme.sizes.loadingMessageSizeFan};
    width: ${theme.sizes.loadingMessageSizeFan};
    height: 100%;
  }
  .lazyload-wrapper-video {
    width: ${theme.sizes.loadingMessageWidthVideo};
    height: ${theme.sizes.loadingMessageHeightVideo};
  }
  ${props =>
    !props.isOutgoingMessage &&
    props.isHasBodyMessage &&
    `
    .lazyload-wrapper-video {
      width: fit-content;
    }
  `};
`;

// isBoostLevel === answerUntil for fan
const MessageFanBodyStyled = styled.div`
  ${props =>
    MixMessageBodyStyled({
      isCreatorOutgoingMessage: props.isOutgoingMessage,
    })};
  ${props =>
    props.boostLevel &&
    !props.refunded &&
    MixMessageBodyStateStyled({
      isGuaranteed: '',
      isRefunded: '',
      isBoostLevel: props.boostLevel,
    })};
  ${props =>
    props.refunded &&
    MixMessageBodyStateStyled({
      isGuaranteed: '',
      isRefunded: props.refunded,
      isBoostLevel: '',
    })};
  ${props =>
    props.messageMediaTypeVideo &&
    `width: ${theme.sizes.loadingMessageWidthVideo};`};
  ${props =>
    !props.isOutgoingMessage &&
    props.isHasBodyMessage &&
    `width: fit-content;
  `};
`;

// message FOOTER styled with STATE
const MessageFanFooterStyled = styled.footer`
  ${props =>
    MixMessageFooterStyled({
      isCreatorOutgoingMessage: props.isOutgoingMessage,
    })};
  ${props =>
    props.isGuaranteed &&
    !props.refunded &&
    MixMessageFooterStateStyled({
      isGuaranteed: '',
      isRefunded: '',
      isBoostLevel: props.isGuaranteed,
    })};
  ${props =>
    props.refunded &&
    MixMessageFooterStateStyled({
      isGuaranteed: '',
      isRefunded: props.refunded,
      isBoostLevel: '',
    })};
  ${props =>
    props.boostLevel &&
    `${MixMessageFooterStateStyled({
      isGuaranteed: '',
      isRefunded: '',
      isBoostLevel: '',
    })};
      padding: 0 ${theme.space.primaryMd} ${theme.space.primaryMd} ${
      theme.space.primaryMd
    };`};
  ${props =>
    props.noPrice &&
    `${MixMessageFooterStateStyled({
      isGuaranteed: '',
      isRefunded: '',
      isBoostLevel: '',
    })};
    background: ${theme.colors.grayTertiary};
    margin: 0;
    border-top: 1px solid ${theme.colors.grayLighter};
    flex-direction: column;`};
`;

// button for open image/video modal
const MessageModalButtonStyled = styled.button`
  display: block;
  width: 100%;
  border-radius: inherit;
`;

const MessageFan = ({
  avatarChecked,
  avatarSrc,
  avatarSrcAvif,
  avatarSrcWebp,
  message,
  isOutgoingMessage,
  isDefaultMessage,
  isOnlyDefaultMessage,
  welcomeAudioUrl,
  isItPossibleToBoost,
  onBoostMessageButton,
  isPaymentLoading,
  isLastReadMessage,
  creatorName,
  ...props
}) => {
  const dispatch = useDispatch();

  const url = useMemo(
    () => message?.mediaObject?.originalUrl,
    [message?.mediaObject?.originalUrl],
  );

  const handleDownloadImageClick = useCallback(() => {
    saveFile(url, `premium-photo_${url.substring(url.lastIndexOf('/') + 1)}`);
  }, [url]);

  const handleDownloadVideoClick = useCallback(() => {
    saveFile(url, `premium-video_${url.substring(url.lastIndexOf('/') + 1)}`);
  }, [url]);

  const openVideoModalHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.VIDEO_FULL,
        modalProps: {
          $src: message?.mediaObject?.originalUrl,
          isAutoPlay: true,
          onDownloadVideo: handleDownloadVideoClick,
        },
      }),
    );
  }, [dispatch, handleDownloadVideoClick, message?.mediaObject?.originalUrl]);

  const openImageModalHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.IMAGE_FULL,
        modalProps: {
          $src: message?.mediaObject?.originalUrl,
          $srcSetAvif: message?.mediaObject?.originalAvifUrl,
          $srcSetWebp: message?.mediaObject?.originalWebpUrl,
          originalUrl: message?.mediaObject?.originalUrl,
          onDownloadImage: handleDownloadImageClick,
        },
      }),
    );
  }, [
    dispatch,
    handleDownloadImageClick,
    message?.mediaObject?.originalAvifUrl,
    message?.mediaObject?.originalUrl,
    message?.mediaObject?.originalWebpUrl,
  ]);

  return (
    <MessageFanWrapperStyled isDefaultMessage={isOnlyDefaultMessage}>
      <MessageFanStyled isOutgoingMessage={isOutgoingMessage} {...props}>
        {!isOutgoingMessage && (
          <Avatar
            gridArea="avatar"
            $src={avatarSrc}
            $srcSetAvif={avatarSrcAvif}
            $srcSetWebp={avatarSrcWebp}
            checked={avatarChecked}
          />
        )}

        {isPaymentLoading ? (
          <MBox gridArea="message">
            <LoadingSpinner position="relative" />
          </MBox>
        ) : (
          <MBox gridArea="message" isOutgoingMessage={isOutgoingMessage}>
            <MessageFanBodyStyled
              isDefaultMessage={isDefaultMessage}
              isOutgoingMessage={isOutgoingMessage}
              answerUntil={message?.answerUntil}
              refunded={message?.refunded}
              boostLevel={!!message?.price && message?.type !== 'ordinary'}
              messageMediaTypeVideo={message?.mediaObject?.type === 'video'}
              isHasBodyMessage={message?.body}
            >
              {!isDefaultMessage ? (
                <>
                  {message?.mediaObject &&
                    message?.mediaObject?.type === 'audio' && (
                      <>
                        <VoiceMessage
                          isMessage
                          audioUrl={message?.mediaObject?.originalUrl}
                          messageId={message?.id}
                        />
                      </>
                    )}
                  <Text
                    as="div"
                    type="body2"
                    p={message?.body ? 'primaryMd' : null}
                    pb={
                      isOutgoingMessage &&
                      !message?.answerUntil &&
                      !!message?.price &&
                      message?.type !== 'ordinary'
                        ? 'sm'
                        : null
                    }
                    pt={
                      message?.mediaObject &&
                      (message?.mediaObject?.type === 'audio' ||
                        (message?.mediaObject?.type === 'video' &&
                          !message?.body))
                        ? '0'
                        : 'primaryMd'
                    }
                    borderRadius={!message?.body ? 'inherit' : null}
                    wordBreak="break-all"
                  >
                    {message?.mediaObject &&
                      message?.mediaObject?.type !== 'audio' && (
                        <LazyLoad
                          placeholder={<LoadingSpinner position="relative" />}
                          className={
                            message?.mediaObject?.type === 'video'
                              ? 'lazyload-wrapper-video'
                              : 'lazyload-wrapper-image'
                          }
                          // throttle={100000}
                          overflow
                          once
                        >
                          <MessageModalButtonStyled
                            type="button"
                            onClick={
                              message?.mediaObject?.type === 'video'
                                ? openVideoModalHandler
                                : openImageModalHandler
                            }
                            aria-label={
                              message?.mediaObject?.type === 'video'
                                ? 'Ouvrir modal avec vidéo'
                                : 'Ouvrir modal avec photo'
                            }
                            style={{
                              position:
                                message?.mediaObject?.type === 'video' &&
                                'relative',
                              height:
                                message?.mediaObject?.type === 'video' &&
                                '100%',
                              width:
                                message?.mediaObject?.type === 'video' &&
                                '100%',
                            }}
                          >
                            {message?.mediaObject?.type === 'video' && (
                              <MBox
                                position="absolute"
                                top="0"
                                right="0"
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                as="span"
                                zIndex="99"
                              >
                                <IconPlay2 />
                              </MBox>
                            )}
                            <Picture
                              objectFit="cover"
                              borderTopRightRadius={
                                !message?.body ? 'inherit' : 'xs'
                              }
                              borderTopLeftRadius={
                                !message?.body ? 'inherit' : 'xs'
                              }
                              borderBottomLeftRadius={
                                !message?.body ? 'inherit' : 'xs'
                              }
                              borderBottomRightRadius={
                                !message?.body && isOutgoingMessage
                                  ? '0'
                                  : !message?.body && !isOutgoingMessage
                                  ? 'inherit'
                                  : 'xs'
                              }
                              position={
                                message?.mediaObject?.type === 'video' &&
                                'absolute'
                              }
                              srcSetAvif={message?.mediaObject?.originalAvifUrl}
                              srcSetWebp={message?.mediaObject?.originalWebpUrl}
                              src={message?.mediaObject?.squarePreviewUrl}
                              alt={
                                message?.mediaObject?.type === 'video'
                                  ? `Video`
                                  : `Photo`
                              }
                              height={
                                message?.mediaObject?.type === 'video'
                                  ? '100%'
                                  : null
                              }
                            />
                          </MessageModalButtonStyled>
                        </LazyLoad>
                      )}
                    {message?.body && (
                      <Text
                        mt={message?.mediaObject ? 'sm' : null}
                        wordBreak="break-word"
                      >
                        {message?.body}
                      </Text>
                    )}
                  </Text>
                </>
              ) : (
                <VoiceMessage isMessage audioUrl={welcomeAudioUrl} />
              )}

              {/* If guaranteed answer for all mediaType */}
              {isOutgoingMessage &&
                (message?.type === 'guaranteed_response' ||
                  message?.type === 'video_response') && (
                  <MessageFanFooterStyled
                    refunded={message?.refunded}
                    isOutgoingMessage={isOutgoingMessage}
                    isGuaranteed={
                      message?.type === 'guaranteed_response' ||
                      message?.type === 'video_response'
                    }
                  >
                    <MBox
                      borderRadius={message?.refunded && 'md'}
                      background={message?.refunded && 'white'}
                      px={message?.refunded && 'sm'}
                      py={message?.refunded && 'xxs'}
                      mr="sm"
                    >
                      <Text
                        type="subtitle3"
                        color={message?.refunded ? 'error' : null}
                      >
                        {!message?.refunded
                          ? 'Message boosté'
                          : 'Message remboursé'}
                      </Text>
                    </MBox>
                    <Text
                      display="flex"
                      alignItems="center"
                      justifyContent={!message?.refunded ? 'flex-end' : null}
                      width={!message?.refunded ? '8.5rem' : null}
                    >
                      {message?.answerUntil || message?.refunded ? (
                        <>
                          <IconTimer palette={theme.colors.white} mr="xxs" />
                          {!message?.refunded ? (
                            <>
                              <VisuallyHidden>réponse d&#39;ici</VisuallyHidden>
                              <Text
                                as="time"
                                type="subtitle3"
                                minWidth="4rem"
                                textAlign="right"
                              >
                                <TimerWidget deadline={message?.answerUntil} />
                              </Text>
                            </>
                          ) : (
                            <Text as="span" type="subtitle3">
                              Délai dépassé
                            </Text>
                          )}
                        </>
                      ) : (
                        <>
                          <VisuallyHidden>a répondu</VisuallyHidden>
                          <IconCheckInput
                            palette={theme.colors.white}
                            isGradient={false}
                          />
                        </>
                      )}
                    </Text>
                  </MessageFanFooterStyled>
                )}

              {/* If not guaranteed answer audio/video - for boost level - 1,2,3 */}
              {isOutgoingMessage &&
                !message?.answerUntil &&
                !!message?.price &&
                message?.type !== 'ordinary' &&
                message?.type !== 'guaranteed_response' &&
                message?.type !== 'video_response' && (
                  <MessageFanFooterStyled
                    boostLevel={!!message?.price}
                    isOutgoingMessage={isOutgoingMessage}
                  >
                    <Text type="subtitle3" $isGradient>
                      Message boosté
                    </Text>
                  </MessageFanFooterStyled>
                )}
              {/* if free message */}
              {isOutgoingMessage &&
                isItPossibleToBoost &&
                message?.type === 'ordinary' &&
                !message.mediaObject &&
                !message.price && (
                  <MessageFanFooterStyled noPrice isOutgoingMessage>
                    <MButton
                      icon
                      primary
                      mb="sm"
                      width="100%"
                      onClick={() =>
                        onBoostMessageButton(message?.id, message?.body)
                      }
                    >
                      <Text
                        as="span"
                        display="flex"
                        justifyContent="center"
                        mr="1rem"
                        height="2rem"
                      >
                        <IconBoost />
                      </Text>
                      Booster ce message
                    </MButton>
                    <Text type="body3" $isGradient textAlign="center">
                      Obtiens une réponse garantie !
                    </Text>
                  </MessageFanFooterStyled>
                )}
              {isOutgoingMessage &&
                isItPossibleToBoost &&
                message?.type === 'ordinary' &&
                message.price &&
                !message.refunded && (
                  <MessageFanFooterStyled noPrice isOutgoingMessage>
                    <MButton
                      icon
                      primary
                      mb="sm"
                      width="100%"
                      onClick={() =>
                        onBoostMessageButton(message?.id, message?.body)
                      }
                    >
                      <Text
                        as="span"
                        display="flex"
                        justifyContent="center"
                        mr="1rem"
                        height="2rem"
                      >
                        <IconBoost />
                      </Text>
                      Booster ce message
                    </MButton>
                    <Text
                      type="body3"
                      textAlign="center"
                      color={theme.colors.error}
                    >
                      Une erreur s&#39;est produite lors du paiement
                    </Text>
                  </MessageFanFooterStyled>
                )}
            </MessageFanBodyStyled>
          </MBox>
        )}
        <MBox gridArea="info">
          <VisuallyHidden>envoyé à </VisuallyHidden>
          <Text
            as="time"
            color="grayMedium"
            type="body2"
            display={
              isOutgoingMessage &&
              (message?.type === 'guaranteed_response' ||
                message?.type === 'video_response')
                ? 'block'
                : null
            }
            textAlign={
              isOutgoingMessage &&
              (message?.type === 'guaranteed_response' ||
                message?.type === 'video_response')
                ? 'right'
                : null
            }
          >
            {!isDefaultMessage && getMessageCreationDate(message?.createdAt)}
          </Text>

          {/* hint text for price messages */}
          {/* for guaranteed answer audio/video and refunded */}
          {isOutgoingMessage && (message?.answerUntil || message?.refunded) && (
            <Text
              type="body2"
              mt="sm"
              maxWidth="maxWidthOutgoingMessage"
              color={message?.refunded ? 'error' : null}
              $isGradient={!message?.refunded}
              display={!message?.refunded ? 'block' : null}
            >
              {!message?.refunded
                ? 'Si vous n’obtenez pas de réponse dans le temps imparti, vous serez entièrement remboursé.'
                : `${creatorName} n’a pas répondu dans le délai imparti. Vous serez entièrement remboursé sous 7 jours.`}
            </Text>
          )}
        </MBox>
      </MessageFanStyled>
      {isOnlyDefaultMessage && (
        <MessageNotification
          title="Engage la conversation !"
          text="Envoie un premier message au créateur pour engager la conversation. "
        />
      )}
      {isLastReadMessage && <UnreadMessageChat />}
    </MessageFanWrapperStyled>
  );
};

MessageFan.propTypes = {
  avatarChecked: propTypes.bool,
  avatarSrc: propTypes.string,
  avatarSrcAvif: propTypes.string,
  avatarSrcWebp: propTypes.string,
  isOutgoingMessage: propTypes.bool,
  isDefaultMessage: propTypes.bool,
  isOnlyDefaultMessage: propTypes.bool,
  isItPossibleToBoost: propTypes.bool,
  onBoostMessageButton: propTypes.func,
  message: propTypes.shape({
    id: propTypes.string,
    body: propTypes.string,
    image: propTypes.string,
    createdAt: propTypes.string,
    read: propTypes.bool,
    answerUntil: propTypes.string, // guaranteed answer
    refunded: propTypes.bool,
    boostLevel: propTypes.number,
    paid: propTypes.bool,
    type: propTypes.string,
    price: propTypes.number,
  }),
  isPaymentLoading: propTypes.bool,
  isLastReadMessage: propTypes.bool,
};

export default MessageFan;
