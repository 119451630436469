const IconPencil = () => {
  return (
    <svg width="15" viewBox="0 0 15 15" fill="none" aria-hidden="true">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.362 1.529a2.552 2.552 0 0 1 3.61 3.61l-1.999 1.998-.002.001-.001.002-6.803 6.803-.033.033a3.126 3.126 0 0 1-.478.42 2 2 0 0 1-.485.233c-.195.064-.398.086-.628.111l-.046.005-2.257.251a.667.667 0 0 1-.736-.736l.25-2.256.006-.047c.025-.23.048-.432.11-.627a2 2 0 0 1 .234-.485c.113-.171.257-.316.42-.479l.034-.033 8.804-8.804ZM8.833 4.943l-6.332 6.333c-.215.215-.255.26-.284.303a.669.669 0 0 0-.078.161c-.016.05-.025.109-.06.41l-.158 1.429 1.428-.159c.302-.033.361-.043.41-.06a.665.665 0 0 0 .163-.077c.043-.028.087-.068.302-.283l6.333-6.333-1.724-1.724Zm2.667.781L9.776 4l1.529-1.528a1.219 1.219 0 0 1 1.724 1.723L11.5 5.725Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconPencil;
