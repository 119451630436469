import propTypes from 'prop-types';

const IconPlay2 = ({ size }) => {
  return (
    <svg width={size} fill="none" viewBox="0 0 40 40" aria-hidden="true">
      <rect width="40" height="40" rx="20" fill="#000" fillOpacity=".5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5C11.716 5 5 11.716 5 20c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15ZM1.667 20C1.667 9.875 9.875 1.667 20 1.667c10.125 0 18.333 8.208 18.333 18.333 0 10.125-8.208 18.333-18.333 18.333C9.875 38.333 1.667 30.125 1.667 20Zm17.075-7.612.047.03 7.868 5.059.043.027c.252.162.527.339.746.51.232.181.6.5.816 1.013a2.5 2.5 0 0 1 0 1.946c-.216.512-.584.832-.816 1.013a10.443 10.443 0 0 1-.79.538L18.79 27.58l-.047.03c-.293.189-.601.387-.872.528-.273.142-.768.372-1.381.328a2.5 2.5 0 0 1-1.824-.995c-.368-.492-.442-1.033-.47-1.34a11.86 11.86 0 0 1-.028-1.018V14.886c0-.348 0-.714.027-1.019.028-.306.103-.847.471-1.339a2.5 2.5 0 0 1 1.824-.995c.613-.044 1.108.186 1.38.328.272.141.58.339.873.527Z"
        fill="#fff"
      />
    </svg>
  );
};

IconPlay2.defaultProps = {
  size: '40',
};

IconPlay2.propTypes = {
  size: propTypes.string,
};

export default IconPlay2;
