const IconAccount = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#iconAccount)">
        <path
          fill="#000"
          d="M12.378 9.638a5.397 5.397 0 1 0-6.675 0A8.996 8.996 0 0 0 .11 16.996a.905.905 0 0 0 1.799.198 7.196 7.196 0 0 1 14.301 0 .9.9 0 0 0 .9.8h.099a.9.9 0 0 0 .791-.989 8.995 8.995 0 0 0-5.621-7.367ZM9.04 9a3.598 3.598 0 1 1 0-7.196A3.598 3.598 0 0 1 9.04 9Z"
        />
      </g>
      <defs>
        <clipPath id="iconAccount">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAccount;
