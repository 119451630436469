import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import {
  createReportFailure,
  createReportRequest,
  createReportSuccess,
} from './slice';

function* createReportSaga({ payload }) {
  try {
    yield call(services.postReport, payload);
    yield put(createReportSuccess());
  } catch (error) {
    yield put(createReportFailure());
  }
}

export default function* watchReport() {
  yield all([takeLatest(createReportRequest, createReportSaga)]);
}
