import { generatePath } from 'react-router-dom';

import { CANCEL } from 'redux-saga';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const postMessage = body => {
  return request.post(apiRoutes.MESSAGE.MESSAGES, body);
};

export const postMassMessage = body => {
  return request.post(apiRoutes.MESSAGE.MASS, body);
};

export const patchMessage = ({ body, id }) => {
  return request.patch(
    generatePath(apiRoutes.MESSAGE.MESSAGE_DATA, {
      id,
    }),
    body,
  );
};

export const getMessageById = id => {
  const controller = new AbortController();
  const promise = request.get(
    generatePath(apiRoutes.MESSAGE.MESSAGE_DATA, id),
    { signal: controller.signal },
  );
  promise[CANCEL] = () => controller.abort();
  return promise;
};

export const getCreatorDialogMessages = ({ nickname, page }) => {
  return request.get(
    generatePath(apiRoutes.MESSAGE.CREATOR_MESSAGES_DATA_BY_NICKNAME, {
      nickname,
    }),
    {
      params: { page },
    },
  );
};

export const getFanDialogMessages = ({ nickname, page }) => {
  return request.get(
    generatePath(apiRoutes.MESSAGE.FAN_MESSAGES_DATA_BY_NICKNAME, {
      nickname,
    }),
    {
      params: { page },
    },
  );
};

export const getDialogMessagesById = ({ id, page, targetMessageId }) => {
  return request.get(
    generatePath(apiRoutes.MESSAGE.MESSAGES_DATA_BY_ID, {
      id,
    }),
    {
      params: { page, targetMessageId },
    },
  );
};

export const getMessagePrices = ({ nickname, promoCode, price }) => {
  return request.get(
    generatePath(apiRoutes.MESSAGE.MESSAGE_PRICE_BY_NICKNAME, {
      nickname,
    }),
    {
      params: { promo_code: promoCode, price },
    },
  );
};

export const postGeneratePayment = ({ id, promoCode }) => {
  return request.post(
    generatePath(apiRoutes.MESSAGE.MESSAGE_GENERATE_PAYMENT, {
      id,
    }),
    { promoCode },
  );
};

export const getMessage = ({ id }) => {
  return request.get(
    generatePath(apiRoutes.MESSAGE.MESSAGE_DATA, {
      id,
    }),
  );
};

export const createMassMessage = ({ body, mediaObjectId, type }) => {
  return request.post(apiRoutes.MESSAGE.MASS, { body, mediaObjectId, type });
};
