const IconGradientBoostIncome = () => {
  return (
    <svg width="20" viewBox="0 0 20 20" fill="none" aria-hidden="true">
      <path
        fill="url(#iconGradientBoostIncome)"
        fillRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C8.67168 20 7.4015 19.7405 6.23921 19.2684C6.13423 19.2258 6.07362 19.2013 6.02887 19.1845C6.02358 19.1825 6.01898 19.1808 6.01505 19.1794C6.01356 19.1796 6.01198 19.1798 6.01029 19.18C5.97339 19.185 5.92309 19.1933 5.82705 19.2093L2.26916 19.8023C2.25966 19.8039 2.25008 19.8055 2.24044 19.8071C2.07981 19.8339 1.90011 19.8639 1.74404 19.8757C1.57386 19.8885 1.30312 19.893 1.017 19.7703C0.663294 19.6186 0.381436 19.3367 0.229729 18.983C0.107009 18.6969 0.111482 18.4261 0.124327 18.256C0.136108 18.0999 0.166118 17.9202 0.192941 17.7596C0.194551 17.7499 0.19615 17.7403 0.197733 17.7308L0.790714 14.173C0.806721 14.0769 0.814996 14.0266 0.819998 13.9897C0.820227 13.988 0.820438 13.9864 0.820633 13.9849C0.819203 13.981 0.817511 13.9764 0.815525 13.9711C0.798718 13.9264 0.774193 13.8658 0.731556 13.7608C0.259521 12.5985 0 11.3283 0 10ZM10 2C5.58172 2 2 5.58172 2 10C2 11.066 2.20795 12.0809 2.58457 13.0082C2.59052 13.0229 2.59665 13.0379 2.6029 13.0532C2.66396 13.2027 2.73657 13.3805 2.77378 13.5466C2.80759 13.6974 2.82219 13.8292 2.82221 13.9837C2.82223 14.1534 2.79331 14.3248 2.76998 14.4632C2.76776 14.4764 2.76559 14.4892 2.7635 14.5017L2.21655 17.7834L5.49825 17.2365C5.51078 17.2344 5.52365 17.2322 5.53681 17.23C5.67516 17.2067 5.84665 17.1778 6.01626 17.1778C6.17082 17.1778 6.30263 17.1924 6.45344 17.2262C6.61947 17.2634 6.79728 17.336 6.94681 17.3971C6.96211 17.4034 6.97711 17.4095 6.99177 17.4154C7.91911 17.792 8.93403 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM5 7.5C5 6.94772 5.44772 6.5 6 6.5H10C10.5523 6.5 11 6.94772 11 7.5C11 8.05228 10.5523 8.5 10 8.5H6C5.44772 8.5 5 8.05228 5 7.5ZM5 11C5 10.4477 5.44772 10 6 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H6C5.44772 12 5 11.5523 5 11Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="iconGradientBoostIncome"
          x1="20"
          y1="20"
          x2="-0.568866"
          y2="19.3968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconGradientBoostIncome;
