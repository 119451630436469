import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectAwaitingSum } from 'ducks/dialog';
import { selectIsCreator } from 'ducks/user';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { dialogCreatorListTypes } from 'constants/dialogListTypes';

import noop from 'tools/noop';

import { ButtonCircle } from 'components/buttons';
import { MBox } from 'components/common';
import { InputSearch, MRadioTertiary } from 'components/forms';
import { IconPencil2, IconSearch2 } from 'components/icons';
import { SmallPrice } from 'components/messenger';
import { DesktopHeader, MobileNavigation } from 'components/navigation';
import { NewMessage, WarningNotification } from 'components/notifications';
import { Heading1 } from 'components/texts';

import { LayoutBase } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

import Header from './helpers/Header';

const MessageLayoutStyled = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  padding-top: ${theme.space.defaultSpaceY};
  max-width: ${breakpoints.xxl};
  margin: 0 auto;
  ${props => props.isEmptyPage && `align-items: center;`};

  @media (max-width: ${breakpoints.lg}) {
    ${props =>
      props.hasNavigation &&
      `padding-bottom: calc(${theme.space.defaultSpaceY} + ${theme.sizes.navMobileHeight})`};
  }

  @media (min-width: ${breakpoints.lg}) {
    ${props => props.hasNavigation && `padding-top: 0;`};
  }
`;

const MHeaderStyled = styled(Header)``;

const MLayoutContent = styled.div`
  ${LayoutBase};
  padding-bottom: 0;
`;

const MessageLayout = ({
  hasNavigation,
  hasSearch,
  hasWidget,
  hasNewMessageButton,
  isWarningNotification,
  textNotification,
  isNotificationLink,
  isEmptyPage,
  ariaLabelledby,
  unreadMessages,
  isCreator,
  onNewMessageButton,
  onChangeRadio,
  searchState,
  onSearchChange,
  ...props
}) => {
  const noticeRef = useRef();
  const [height, setHeight] = useState(0);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const isUserCreator = useSelector(selectIsCreator);
  const awaitingSum = useSelector(selectAwaitingSum);
  const { tab } = useParams();

  useEffect(() => {
    if (!noticeRef?.current?.clientHeight) {
      return;
    }
    setHeight(noticeRef?.current?.clientHeight);
  }, [noticeRef?.current?.clientHeight]);

  return (
    <>
      {isWarningNotification && (
        <WarningNotification
          innerRef={noticeRef}
          isNotificationLink={isNotificationLink}
          text={textNotification}
        />
      )}
      <MessageLayoutStyled
        {...props}
        isEmptyPage={isEmptyPage}
        hasNavigation={hasNavigation}
        aria-labelledby={ariaLabelledby}
        style={
          isWarningNotification
            ? {
                minHeight: `calc(100vh - ${height}px)`,
              }
            : {
                minHeight: `100vh`,
              }
        }
      >
        <MHeaderStyled>
          {hasNavigation && <DesktopHeader isCreator={isUserCreator} />}
          {(props.title || hasSearch) && (
            <MBox mb="lg">
              <MBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                as={MLayoutContent}
              >
                {props.title && (
                  <>
                    <Heading1 id={ariaLabelledby} position="relative">
                      {props.title}
                      {!!unreadMessages && !isEmptyPage && (
                        <NewMessage
                          countMessage={unreadMessages}
                          right="-2rem"
                          top="-1rem"
                        />
                      )}
                    </Heading1>
                  </>
                )}
                {hasSearch && isCreator && (
                  <button
                    type="button"
                    disabled={isEmptyPage}
                    aria-label="Afficher l'entrée de recherche"
                    onClick={() => setShowSearchInput(!showSearchInput)}
                  >
                    <IconSearch2 />
                  </button>
                )}
              </MBox>
            </MBox>
          )}
          {!isEmptyPage && (
            <MBox as={MLayoutContent}>
              {isCreator ? (
                showSearchInput && (
                  <InputSearch
                    autoFocus
                    value={searchState}
                    onChange={onSearchChange}
                    mb="sm"
                  />
                )
              ) : (
                <InputSearch
                  mb="sm"
                  value={searchState}
                  onChange={onSearchChange}
                />
              )}
            </MBox>
          )}
          {hasWidget && (
            <MBox mt="lg" as={MLayoutContent}>
              <SmallPrice price={awaitingSum} />
              <MRadioTertiary
                ariaLabelledby="items"
                value={tab}
                onChange={onChangeRadio}
                name="radio-inputs-test"
                items={dialogCreatorListTypes}
                isThreeItem
              />
            </MBox>
          )}
        </MHeaderStyled>
        <MLayoutContent as="main">{props.main}</MLayoutContent>
        {hasNewMessageButton && (
          <ButtonCircle
            aria-label="Nouvelle discussion"
            disabled={isEmptyPage && isCreator}
            scale="md"
            ml="auto"
            onClick={onNewMessageButton}
            mr="defaultSpaceX"
            position="sticky"
            bottom={[
              `calc(${theme.space.defaultSpaceX} + ${theme.sizes.navMobileHeight})`,
              null,
              `${theme.space.defaultSpaceX}`,
              null,
            ]}
          >
            <IconPencil2 />
          </ButtonCircle>
        )}
      </MessageLayoutStyled>
      {hasNavigation && <MobileNavigation isCreator={isUserCreator} />}
    </>
  );
};

MessageLayout.propTypes = {
  main: propTypes.node.isRequired,
  ariaLabelledby: propTypes.string.isRequired,
  title: propTypes.string,
  hasNavigation: propTypes.bool,
  hasSearch: propTypes.bool,
  hasWidget: propTypes.bool,
  hasNewMessageButton: propTypes.bool,
  isEmptyPage: propTypes.bool,
  isWarningNotification: propTypes.bool,
  isNotificationLink: propTypes.bool,
  textNotification: propTypes.string,
  unreadMessages: propTypes.oneOfType([propTypes.string, propTypes.number]),
  isCreator: propTypes.bool,
  onNewMessageButton: propTypes.func,
  onChangeRadio: propTypes.func,
  searchState: propTypes.string,
  onSearchChange: propTypes.func,
};

MessageLayout.defaultProps = {
  onNewMessageButton: noop,
  onChangeRadio: noop,
  searchState: '',
  onSearchChange: noop,
};

export default MessageLayout;
