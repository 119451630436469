import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { routes } from 'constants/routes';

import { getDialogMessageCreationDate } from 'tools/messageCreationDate';
import messageMediaTypeFormat from 'tools/messageMediaTypeFormat';

import { Avatar } from 'components/avatar';
import { MBox } from 'components/common';
import { IconMicrophone, IconPhotoMedia, IconVideo } from 'components/icons';
import { Text } from 'components/texts';

import {
  MCardLinkStyles,
  MCardStyles,
  VisuallyHidden,
} from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import { NewMessageFan } from '..';

const FanDialogCardStyled = styled.article`
  padding-left: ${theme.space.primaryMd};
  padding-right: ${theme.space.primaryMd};
  margin: 0 -${theme.space.primaryMd};
  max-width: calc(100% + (${theme.space.primaryMd} * 2));
  ${MCardStyles};
  grid-template-columns: max-content minmax(2rem, 1fr) minmax(4.5rem, auto);
  &:first-child {
    margin-top: ${theme.space.primarySm};
  }
`;

const FanDialogCardWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FanDialogMessageStyled = styled.div`
  display: inline-flex;
  margin-bottom: ${theme.space.sm};
`;

const FanDialogCardLinkStyled = styled(Link)`
  color: inherit;
  ${MCardLinkStyles('0')};
`;

const FanDialogCard = ({ person, isIncomingMessage }) => {
  const pathToDialog = useMemo(() => {
    if (person?.id) {
      return generatePath(routes.MESSENGER_DIALOG, {
        id: person?.id,
      });
    }
    return '#';
  }, [person?.id]);

  return (
    <FanDialogCardStyled>
      <Avatar
        $src={person?.receiverAvatar?.squarePreviewUrl}
        $srcSetAvif={person?.receiverAvatar?.squarePreviewAvifUrl}
        $srcSetWebp={person?.receiverAvatar?.squarePreviewWebpUrl}
        uniqueIdCheckedIcon={person?.id}
        type="md"
        isChecked
      />
      <FanDialogCardWrapperStyled>
        <Text
          as="h3"
          type="label1"
          lineHeight="md"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          mb="xxs"
          {...(!person?.read && isIncomingMessage
            ? { color: 'black' }
            : { color: 'grayMedium' })}
        >
          {/* to => pathToDialog */}
          <FanDialogCardLinkStyled
            to={pathToDialog}
            title={person?.receiverName + person?.receiverSurname}
          >
            <VisuallyHidden>message de</VisuallyHidden>
            {person?.receiverName} {person?.receiverSurname}
          </FanDialogCardLinkStyled>
        </Text>
        <FanDialogMessageStyled>
          <Text
            type="body2"
            lineHeight="sm"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            {...(!person?.read && isIncomingMessage
              ? { color: 'black', fontWeight: 'medium' }
              : { color: 'grayMedium' })}
          >
            {!isIncomingMessage && 'Vous : '}
            {person?.lastMessageText ||
              messageMediaTypeFormat(person?.lastMessageMediaType)}
          </Text>
          {person && (
            <>
              <Text
                as="time"
                type="label2"
                {...(!person?.read && isIncomingMessage
                  ? { color: 'black' }
                  : { color: 'grayMedium' })}
              >
                &nbsp;·&nbsp;
                {getDialogMessageCreationDate(person?.lastMessageCreatedAt)}
              </Text>
              <VisuallyHidden>Il y a</VisuallyHidden>
            </>
          )}
        </FanDialogMessageStyled>
      </FanDialogCardWrapperStyled>
      <MBox
        display="flex"
        alignItems="center"
        justifyContent={!person?.read ? 'flex-end' : null}
      >
        {person?.lastMessageMediaType === 'video' && (
          <>
            <IconVideo
              size="20"
              palette={theme.colors.primary}
              mr="primaryMd"
            />
            <VisuallyHidden>message vidéo</VisuallyHidden>
          </>
        )}
        {person?.lastMessageMediaType === 'audio' && (
          <>
            <IconMicrophone
              size="20"
              palette={theme.colors.primary}
              mr="primaryMd"
            />
            <VisuallyHidden>message audio</VisuallyHidden>
          </>
        )}
        {person?.lastMessageMediaType === 'image' && (
          <>
            <IconPhotoMedia
              size="20"
              palette={theme.colors.primary}
              mr="primaryMd"
            />
            <VisuallyHidden>message avec photo</VisuallyHidden>
          </>
        )}
        {!person?.read && <NewMessageFan />}
      </MBox>
    </FanDialogCardStyled>
  );
};

FanDialogCard.propTypes = {
  person: propTypes.shape({
    id: propTypes.string,
    avatar: propTypes.string,
    receiverName: propTypes.string,
    receiverSurname: propTypes.string,
    lastMessageText: propTypes.string,
    lastMessageCreatedAt: propTypes.string,
    read: propTypes.bool,
    lastMessageMediaType: propTypes.string,
  }),
  isIncomingMessage: propTypes.bool,
};

export default FanDialogCard;
