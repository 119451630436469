/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'resetPassword';

export const resetPasswordSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetPasswordCheckRequest(state) {
      state.loading = true;
    },
    resetPasswordCheckSuccess(state) {
      state.isChecked = true;
      state.loading = false;
    },
    resetPasswordCheckFailure(state, { payload }) {
      state.tokenCheckError = payload;
      state.loading = false;
    },
    resetPasswordEmailRequest(state) {
      state.loading = true;
    },
    resetPasswordEmailSuccess(state) {
      state.loading = false;
    },
    resetPasswordEmailFailure(state) {
      state.loading = false;
    },
    resetPasswordSetupRequest(state) {
      state.loading = true;
    },
    resetPasswordSetupSuccess(state) {
      state.isPasswordRecovered = true;
      state.loading = false;
    },
    resetPasswordSetupFailure(state) {
      state.loading = false;
    },
  },
});

export const {
  resetPasswordEmailRequest,
  resetPasswordEmailSuccess,
  resetPasswordEmailFailure,
  resetPasswordSetupSuccess,
  resetPasswordSetupRequest,
  resetPasswordSetupFailure,
  resetPasswordCheckSuccess,
  resetPasswordCheckFailure,
  resetPasswordCheckRequest,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
