import propTypes from 'prop-types';
import styled from 'styled-components';
import {
  background,
  border,
  layout,
  shadow,
  space,
  variant,
} from 'styled-system';

import { LoadingSpinner } from 'components/common';
import { Text } from 'components/texts';

import { ButtonDisabledStyled, GradientBorderRadius } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const ButtonVariant = () =>
  variant({
    prop: 'scale',
    variants: {
      xs: {
        fontSize: `${theme.fontSizes.sm}`,
        padding: '0.8rem 1.6rem',
      },
      sm: {
        fontSize: `${theme.fontSizes.sm}`,
        padding: '1.2rem 1.6rem',
      },
      md: {
        fontSize: `${theme.fontSizes.sm}`,
        padding: '1.2rem 2.4rem',
      },
      // lg - default scale
      lg: {
        fontSize: `${theme.fontSizes.md}`,
        padding: '1.6rem 2.4rem',
      },
    },
  });

const PrimaryStyles = `
  color: ${theme.colors.white};
  background: ${gradients.primary};
  border-radius: ${theme.radii.xs};
  border: ${theme.borderWidths.sm} solid transparent;
  border-left: none;
  border-right: none;
  &:active {
    &:before {
      content: '';
      position: absolute;
      top: -${theme.borderWidths.sm};
      left: 0;
      background: blue;
      opacity: 0.3;
      width: 100%;
      height: calc(100% + 2 * ${theme.borderWidths.sm});
      border-radius: ${theme.radii.xs};
    }
  }
`;

const SecondaryStyles = `
  ${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.xs}`)};
  &:active {
    filter: opacity(50%);
  }
`;

const TertiaryStyles = `
  color: ${theme.colors.error};
  border-radius: ${theme.radii.xs};
  border: ${theme.borderWidths.sm} solid ${theme.colors.error};
  &:active {
    filter: opacity(50%);
  }
`;

const RoundedStyles = `
  color: ${theme.colors.white};
  background: ${gradients.primary};
  border-radius: ${theme.radii.lg};
  &:active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: blue;
      opacity: 0.3;
      width: 100%;
      height: 100%;
      border-radius: ${theme.radii.lg};
    }
  }
`;

const MButtonStyled = styled.button`
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  line-height: ${theme.lineHeights.sm};
  font-weight: ${theme.fontWeights.semiBold};
  ${props => props.primary && PrimaryStyles};
  ${props => props.secondary && SecondaryStyles};
  ${props => props.tertiary && TertiaryStyles};
  ${props => props.rounded && RoundedStyles};
  ${props => (props.disabled || props.loader) && ButtonDisabledStyled};
  ${props => ButtonVariant(props)};
  background-image: ${props => props.$backgroundImage};
  ${layout};
  ${space};
  ${shadow};
  ${border};
  ${background};
  &:hover {
    box-shadow: ${props =>
      props.backgroundImage !== 'none' && theme.shadows.buttonHover};
  }
`;

const MButton = ({
  disabled,
  type = 'button',
  secondary,
  primary,
  tertiary,
  rounded,
  children,
  icon,
  onClick,
  loader,
  ...props
}) => {
  return (
    <MButtonStyled
      type={type}
      disabled={disabled}
      loader={loader}
      rounded={rounded ? 1 : 0}
      secondary={secondary ? 1 : 0}
      primary={primary ? 1 : 0}
      tertiary={tertiary ? 1 : 0}
      onClick={onClick}
      {...props}
    >
      {loader && (
        <LoadingSpinner
          palette={(primary || rounded) && `${theme.colors.white}`}
          top="0"
          right="0"
          size="18"
        />
      )}
      <Text
        display={icon && 'flex'}
        alignItems={icon && 'center'}
        justifyContent={icon && 'center'}
        as="span"
        $isGradient={secondary && !loader}
        color={loader ? 'transparent' : ''}
        mx={secondary && '-2px'}
        px={primary && '1px'}
      >
        {children}
      </Text>
    </MButtonStyled>
  );
};

MButton.defaultProps = {
  scale: 'lg',
};

MButton.propTypes = {
  children: propTypes.node.isRequired,
  type: propTypes.string,
  disabled: propTypes.bool,
  secondary: propTypes.bool,
  primary: propTypes.bool,
  tertiary: propTypes.bool,
  rounded: propTypes.bool,
  scale: propTypes.string,
  icon: propTypes.any,
  onClick: propTypes.func,
  loader: propTypes.bool,
  $backgroundImage: propTypes.string,
};

export default MButton;
