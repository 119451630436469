import propTypes from 'prop-types';
import styled from 'styled-components';

import { IconCheck, IconMicrophone, IconVideo } from 'components/icons';
import { Text } from 'components/texts';

import { VisuallyHidden } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const BadgeStyled = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: ${theme.radii.xs};
  font-weight: ${theme.fontWeights.semiBold};
  font-size: ${theme.fontSizes.xs};
  padding: ${theme.space.sm};
  ${props =>
    props.answerUntil &&
    `background: ${gradients.secondary};
    color: ${theme.colors.white};`};
  ${props =>
    props.notGuaranteed &&
    `background: ${gradients.primary};
    color: ${theme.colors.white};`};
  ${props =>
    props.paid &&
    `background: ${gradients.valid};
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.9);
    color: ${theme.colors.valid};`};
  ${props =>
    props.refunded &&
    `background: ${theme.colors.grayLighter};
    color: ${theme.colors.grayMedium};`};
  ${props =>
    props.unansweredBoost &&
    `background: ${gradients.primary};
    color: ${theme.colors.white};
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.9);`};
`;

const Badge = ({
  children,
  answerUntil,
  video,
  audio,
  notGuaranteed,
  paid,
  refunded,
  unansweredBoost,
  ...props
}) => {
  return (
    <BadgeStyled
      refunded={refunded}
      paid={paid}
      answerUntil={answerUntil}
      notGuaranteed={notGuaranteed}
      unansweredBoost={unansweredBoost}
      {...props}
    >
      <VisuallyHidden>
        {refunded && 'remboursé'}
        {paid && 'payé'}
        {answerUntil &&
          `réponse ${video ? 'vidéo' : ''} ${audio ? 'audio' : ''} garantie`}
        {notGuaranteed && 'réponse non garantie'}
        {unansweredBoost && 'sans réponse boost'}
      </VisuallyHidden>
      {answerUntil && video && <IconVideo mr="sm" />}
      {answerUntil && audio && <IconMicrophone size="16" mr="sm" />}
      {paid && <IconCheck mr="sm" />}
      <Text $isGradient={unansweredBoost}>{children}</Text>
    </BadgeStyled>
  );
};

Badge.propTypes = {
  children: propTypes.node.isRequired,
  answerUntil: propTypes.bool,
  video: propTypes.bool,
  audio: propTypes.bool,
  notGuaranteed: propTypes.bool,
  paid: propTypes.bool,
  refunded: propTypes.bool,
  unansweredBoost: propTypes.bool,
};

export default Badge;
