import styled from 'styled-components';
import { space } from 'styled-system';

const IconVideoGradientStyled = styled.svg`
  ${space};
`;

const IconVideoGradient = ({ ...props }) => {
  return (
    <IconVideoGradientStyled
      width="25"
      viewBox="0 0 25 24"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="url(#IconVideoGradient)"
        fillOpacity=".3"
        fillRule="evenodd"
        d="M7.592 4h5.483c.805 0 1.469 0 2.01.044.562.046 1.079.145 1.564.392a4 4 0 0 1 1.748 1.748c.248.485.346 1.002.392 1.564.041.502.044 1.11.044 1.838l1.954-1.954c.191-.191.379-.379.544-.517.146-.123.456-.37.885-.403a1.5 1.5 0 0 1 1.258.52c.28.328.323.721.34.912.02.214.02.48.02.75v6.212c0 .27 0 .536-.02.75-.017.19-.06.584-.34.911a1.5 1.5 0 0 1-1.258.521c-.429-.034-.739-.28-.885-.403a9.158 9.158 0 0 1-.544-.517l-1.954-1.954c0 .728-.003 1.336-.044 1.838-.046.562-.144 1.079-.392 1.564a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.205.044-2.01.044H7.592c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.391-1.564-.045-.541-.045-1.206-.045-2.01V9.758c0-.805 0-1.47.045-2.01.045-.563.144-1.08.391-1.565a4 4 0 0 1 1.748-1.748c.486-.247 1.002-.346 1.564-.392C6.123 4 6.787 4 7.592 4Zm9.241 5.8c0-.857 0-1.439-.037-1.889-.036-.438-.101-.663-.18-.819a2 2 0 0 0-.875-.874c-.156-.08-.38-.145-.819-.18C14.472 6 13.89 6 13.033 6h-5.4c-.856 0-1.439 0-1.889.038-.438.035-.662.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.144.38-.18.819-.037.45-.038 1.032-.038 1.889v4.4c0 .857.001 1.439.038 1.889.036.438.1.663.18.819a2 2 0 0 0 .874.874c.157.08.38.145.82.18.45.037 1.032.038 1.888.038h5.4c.857 0 1.44 0 1.89-.038.438-.035.662-.1.818-.18a2 2 0 0 0 .874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V9.8Zm2.415 2.2 2.585 2.586V9.414L19.248 12Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="IconVideoGradient"
          x1="23.833"
          x2="1.225"
          y1="20"
          y2="19.088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </IconVideoGradientStyled>
  );
};

export default IconVideoGradient;
