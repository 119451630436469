import { EventSourcePolyfill } from 'event-source-polyfill';

import { WEB_SOCKET_BASE_URL, WEB_SOCKET_TOKEN } from '../config';

export const createNewEventSource = () => {
  const url = `${WEB_SOCKET_BASE_URL}/.well-known/mercure?topic=${window.localStorage.getItem(
    'access_token',
  )}`;

  return new EventSourcePolyfill(url, {
    headers: {
      Authorization: `Bearer ${WEB_SOCKET_TOKEN}`,
    },
  });
};
