import watchShare from 'ducks/share/sagas';
import { fork } from 'redux-saga/effects';

import watchAuth from '../ducks/auth/sagas';
import watchBankAccounts from '../ducks/bankAccounts/sagas';
import watchCategories from '../ducks/categories/sagas';
import watchDialog from '../ducks/dialog/sagas';
import watchDiscussion from '../ducks/discussion/sagas';
import watchMediaObject from '../ducks/mediaObject/sagas';
import watchMessage from '../ducks/message/sagas';
import watchPayment from '../ducks/payment/sagas';
import watchPaymentMethods from '../ducks/paymentMethods/sagas';
import watchPaypalAccounts from '../ducks/paypalAccounts/sagas';
import watchReport from '../ducks/report/sagas';
import watchResetPassword from '../ducks/resetPassword/sagas';
import watchServer from '../ducks/server/sagas';
import watchServerEventsSaga from '../ducks/serverEvents/sagas';
import watchUser from '../ducks/user/sagas';

export default function* rootSaga() {
  yield fork(watchUser);
  yield fork(watchAuth);
  yield fork(watchCategories);
  yield fork(watchShare);
  yield fork(watchResetPassword);
  yield fork(watchDialog);
  yield fork(watchMessage);
  yield fork(watchMediaObject);
  yield fork(watchServerEventsSaga);
  yield fork(watchServer);
  yield fork(watchDiscussion);
  yield fork(watchPayment);
  yield fork(watchReport);
  yield fork(watchBankAccounts);
  yield fork(watchPaymentMethods);
  yield fork(watchPaypalAccounts);
}
