import propTypes from 'prop-types';
import styled from 'styled-components';

import { IconTimer } from 'components/icons';
import { Text } from 'components/texts';

import { breakpoints, theme } from 'styles/Theme';

const SmallPriceStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.md};
  width: 100%;
  border-radius: ${theme.radii.xs};
  background: ${theme.colors.grayLighter2};
  padding: ${theme.space.sm} ${theme.space.primaryMd};
  color: ${theme.colors.black};
  margin-bottom: ${theme.space.primarySm};
`;

const SmallPrice = ({ price, ...props }) => {
  return (
    <SmallPriceStyled {...props}>
      <IconTimer mr="xxxs" />
      <Text type="subtitle2">En attente : {price} €</Text>
    </SmallPriceStyled>
  );
};

SmallPrice.propTypes = {
  price: propTypes.oneOfType([
    propTypes.string.isRequired,
    propTypes.number.isRequired,
  ]),
};

export default SmallPrice;
