import propTypes from 'prop-types';
import styled from 'styled-components';

const IconPlayGradientStyled = styled.svg`
  flex-shrink: 0;
`;

const IconPlayGradient = ({ isGradient, size }) => {
  return (
    <IconPlayGradientStyled
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm10.245-4.567.028.018 4.721 3.035.026.017c.151.097.316.203.448.305.14.109.36.3.49.608a1.5 1.5 0 0 1 0 1.168c-.13.307-.35.499-.49.608a6.27 6.27 0 0 1-.448.305l-.026.017-4.72 3.035-.029.018c-.176.113-.36.232-.523.317-.164.085-.46.223-.829.196a1.5 1.5 0 0 1-1.094-.597c-.22-.295-.265-.62-.282-.803-.017-.183-.017-.403-.017-.612V8.932c0-.21 0-.429.017-.612s.061-.508.282-.803a1.5 1.5 0 0 1 1.094-.597c.368-.027.665.111.829.196.163.085.347.204.523.317Z"
        fill={isGradient ? 'url(#IconPlayGradient)' : 'white'}
      />
      <defs>
        <linearGradient
          id="IconPlayGradient"
          x1="23"
          y1="23"
          x2=".374"
          y2="22.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </IconPlayGradientStyled>
  );
};

IconPlayGradient.defaultProps = {
  isGradient: false,
  size: '26',
};

IconPlayGradient.propTypes = {
  isGradient: propTypes.bool,
  size: propTypes.string,
};

export default IconPlayGradient;
