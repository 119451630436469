const IconHelpCircle = () => {
  return (
    <svg width="20" viewBox="0 0 20 21" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10 3a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM.833 10.5a9.167 9.167 0 1 1 18.334 0 9.167 9.167 0 0 1-18.334 0Zm9.382-3.313a1.667 1.667 0 0 0-1.854 1.09.833.833 0 0 1-1.572-.553 3.333 3.333 0 0 1 6.478 1.11c0 1.276-.946 2.118-1.621 2.568a6.711 6.711 0 0 1-1.405.707l-.03.01-.009.003-.003.001h-.001s-.001.001-.265-.79l.264.791a.833.833 0 0 1-.528-1.58l.013-.005a4.86 4.86 0 0 0 .303-.123c.206-.092.474-.226.736-.4.575-.384.879-.792.879-1.182v-.002a1.666 1.666 0 0 0-1.385-1.645Zm-1.048 7.48c0-.46.373-.833.833-.833h.008a.833.833 0 0 1 0 1.666H10a.833.833 0 0 1-.833-.833Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconHelpCircle;
