const IconTime = () => {
  return (
    <svg viewBox="0 0 37 38" width="37" fill="none" aria-hidden="true">
      <path
        fill="url(#iconTime)"
        fillRule="evenodd"
        d="M18.5 3.99996C10.2157 3.99996 3.49996 10.7157 3.49996 19C3.49996 27.2842 10.2157 34 18.5 34C26.7842 34 33.5 27.2842 33.5 19C33.5 10.7157 26.7842 3.99996 18.5 3.99996ZM0.166626 19C0.166626 8.87474 8.37474 0.666626 18.5 0.666626C28.6252 0.666626 36.8333 8.87474 36.8333 19C36.8333 29.1252 28.6252 37.3333 18.5 37.3333C8.37474 37.3333 0.166626 29.1252 0.166626 19ZM18.5 7.33329C19.4204 7.33329 20.1666 8.07948 20.1666 8.99996V17.9699L25.912 20.8426C26.7353 21.2542 27.069 22.2553 26.6573 23.0786C26.2457 23.9019 25.2446 24.2357 24.4213 23.824L17.7546 20.4907C17.19 20.2083 16.8333 19.6312 16.8333 19V8.99996C16.8333 8.07948 17.5795 7.33329 18.5 7.33329Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="iconTime"
          x1=".167"
          x2="43.904"
          y1="37.924"
          y2="22.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconTime;
