import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import {
  resetPasswordCheckFailure,
  resetPasswordCheckRequest,
  resetPasswordCheckSuccess,
  resetPasswordEmailFailure,
  resetPasswordEmailRequest,
  resetPasswordEmailSuccess,
  resetPasswordSetupFailure,
  resetPasswordSetupRequest,
  resetPasswordSetupSuccess,
} from './slice';

function* resetPasswordEmailSaga({ payload }) {
  try {
    yield call(services.resetPasswordEmail, payload);
    yield put(resetPasswordEmailSuccess());
  } catch (error) {
    yield put(resetPasswordEmailFailure());
  }
}

function* resetPasswordCheckSaga({ payload }) {
  try {
    yield call(services.resetPasswordCheck, payload);
    yield put(resetPasswordCheckSuccess());
  } catch (error) {
    yield put(resetPasswordCheckFailure(error));
  }
}

function* resetPasswordSetupSaga({ payload }) {
  try {
    yield call(services.resetPasswordSetup, payload);
    yield put(resetPasswordSetupSuccess());
  } catch (error) {
    yield put(resetPasswordSetupFailure());
  }
}

export default function* watchResetPassword() {
  yield all([
    takeLatest(resetPasswordEmailRequest, resetPasswordEmailSaga),
    takeLatest(resetPasswordCheckRequest, resetPasswordCheckSaga),
    takeLatest(resetPasswordSetupRequest, resetPasswordSetupSaga),
  ]);
}
