export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REST_COUNTRIES_BASE_URL = 'https://restcountries.com/v3.1';
export const STRIKE_AMAZON_API_BASE_URL =
  'https://strikeapi-master.s3.eu-west-3.amazonaws.com';
export const STRIKE_AMAZON_PREVIEW_API_BASE_URL =
  'https://strikeapi-master-preview.s3.eu-west-3.amazonaws.com';
export const PUBLIC_URL = window.location.origin;
export const WEB_SOCKET_BASE_URL = process.env.REACT_APP_WEB_SOCKET_BASE_URL;
export const WEB_SOCKET_TOKEN = process.env.REACT_APP_WEB_SOCKET_ACCESS_TOKEN;
export const TERMS_OF_CONDITIONS_URL =
  'https://gitlab.noveogroup.com/production-php/marmeladz-strike/uploads/6c99142f7002303aa1edb7a4428a41de/TOC.pdf';
export const STRIPE_PUBLIC_KEY =
  'pk_live_51K6ywKHoRMLFbGl4mLppq3ooEk1BcLmh1ul4H5lzyq63GQiYOSrlaWmoM6xCE2S2CVdoLajfw1hCg8ID3g2JQbPD00tS8MgN3i';
export const RECAPTCHA_KEY = '6LdyQusoAAAAAI7IfmWPNCCJ-Sftakd3dvzZmL82';
