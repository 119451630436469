import { generatePath } from 'react-router-dom';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getBankAccountsCollection = page => {
  return request.get(apiRoutes.BANK_ACCOUNTS.COLLECTION, { params: { page } });
};

export const getBankAccount = id => {
  return request.get(generatePath(apiRoutes.BANK_ACCOUNTS.ID, { id }));
};

export const createBankAccount = ({ ownerName, bic, iban, active }) => {
  return request.post(apiRoutes.BANK_ACCOUNTS.COLLECTION, {
    ownerName,
    bic,
    iban,
    active,
  });
};

export const patchBankAccount = ({
  id,
  body: { ownerName, bic, iban, active },
}) => {
  return request.patch(generatePath(apiRoutes.BANK_ACCOUNTS.ID, { id }), {
    ownerName,
    bic,
    iban,
    active,
  });
};
