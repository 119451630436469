import propTypes from 'prop-types';
import styled from 'styled-components';
import { position } from 'styled-system';

import { TextOverflow, VisuallyHidden } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const NewMessageStyled = styled.span`
  border-radius: ${theme.radii.xs};
  background: ${gradients.secondary};
  font-size: ${theme.fontSizes.xs};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.white};
  padding: ${theme.space.xxxs} ${theme.space.xs};
  position: absolute;
  top: 0.6rem;
  right: 2rem;
  max-width: 65%;
  ${TextOverflow};
  ${position};
`;

const NewMessage = ({ countMessage, ...props }) => {
  return (
    <NewMessageStyled {...props} aria-live="polite" role="alert">
      {countMessage}
      <VisuallyHidden>nouveau message</VisuallyHidden>
    </NewMessageStyled>
  );
};

NewMessage.propTypes = {
  countMessage: propTypes.number.isRequired,
};

export default NewMessage;
