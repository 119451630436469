import { generatePath } from 'react-router-dom';

import strikeAmazonApiRequest from 'helpers/strikeAmazonApiRequest';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const uploadMedia = body => {
  return request.post(apiRoutes.MEDIA_OBJECT.MEDIA_OBJECTS, body);
};

export const amazonMedia = body => {
  return strikeAmazonApiRequest.post(apiRoutes.MEDIA_OBJECT.AMAZON_MEDIA, body);
};

export const getMediaObjectById = id => {
  return request.get(
    generatePath(apiRoutes.MEDIA_OBJECT.MEDIA_OBJECT_DATA, { id }),
  );
};

export const getMediaObjects = ({ usageFilter, search, page }) => {
  return request.get(apiRoutes.MEDIA_OBJECT.MEDIA_OBJECTS, {
    params: { 'filter[usage]': usageFilter, search, page },
  });
};
