const IconCross = () => {
  return (
    <svg aria-hidden="true" width="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 0a10 10 0 1 0 10 10A10.03 10.03 0 0 0 10 0Zm4.634 13.75-.884.884-3.75-3.75-3.75 3.75-.884-.884L9.116 10l-3.75-3.75.884-.884L10 9.116l3.75-3.75.884.884-3.75 3.75 3.75 3.75Z"
        fill="#fff"
      />
    </svg>
  );
};

export default IconCross;
