import { useCallback, useState } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { border, color, space } from 'styled-system';

import { validateStatus } from 'constants/validateStatus';

import { onChangeEventHandler } from 'tools/handlers';

import { LoadingSpinner, MBox } from 'components/common';
import { IconCheckInput } from 'components/icons';
import { Text, TextLabel } from 'components/texts';

import {
  CustomScrollBar,
  GradientBorderRadius,
  InputBaseStyles,
  InputDisabledStyles,
  InputIconStyles,
  VisuallyHidden,
} from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const MTextareaStyled = styled.textarea`
  ${InputBaseStyles};
  padding-left: ${theme.space.primaryMd};
  height: ${theme.sizes.textareaHeight};
  display: block;
  overflow-y: auto;
  ${CustomScrollBar};
  &:focus:not([readonly]) {
    outline: none;
    ${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.xs}`)};
  }
  ${props =>
    props.disabled
      ? InputDisabledStyles
      : `
    background: ${theme.colors.white};
    border-color: ${theme.colors.grayLight};
    color: ${theme.colors.black};
  `};
  ${border};
`;

const MTextareaWrapperStyled = styled.span`
  display: block;
  position: relative;
`;

const MTextareaCheckMarkStyled = styled.span`
  ${InputIconStyles};
  height: auto;
  top: ${theme.space.primaryMd};
  right: ${theme.space.primaryMd};
`;

const LabelStyled = styled.label`
  position: relative;
  display: block;
  ${space};
`;

const CountStyled = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  ${color};
`;

const MTextarea = ({
  isRequired,
  label,
  placeholder,
  maxLength,
  idLabel,
  stateStatus,
  disabled,
  errorText,
  helperText,
  value,
  onChange,
  focusRef,
  ...props
}) => {
  const { SUCCESS, ERROR, LOADING } = validateStatus;
  const getIconStatus = useCallback(
    status => {
      switch (status) {
        case SUCCESS:
          return <IconCheckInput />;
        case ERROR:
          return null;
        case LOADING:
          return <LoadingSpinner my="0" size="16" position="relative" />;
        default:
          return null;
      }
    },
    [ERROR, LOADING, SUCCESS],
  );

  const [isFocused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );

  return (
    <MBox>
      <LabelStyled {...props}>
        {label && (
          <>
            <TextLabel isRequired={isRequired} id={idLabel} maxWidth="85%">
              {label}
              <VisuallyHidden>
                caractères restants {value.length} sur{maxLength}
              </VisuallyHidden>
            </TextLabel>
            <CountStyled
              aria-hidden="true"
              color={isFocused ? 'grayMedium' : 'grayLight'}
            >
              {value.length}/{maxLength}
            </CountStyled>
          </>
        )}
        <MTextareaWrapperStyled>
          <MTextareaStyled
            required={isRequired}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            aria-describedby={`${idLabel} ${idLabel}Error`}
            onFocus={onFocus}
            onBlur={onBlur}
            borderWidth={
              stateStatus === ERROR
                ? `${theme.borderWidths.sm}`
                : `${theme.borderWidths.xs}`
            }
            borderStyle="solid"
            borderColor={stateStatus === ERROR && `${theme.colors.error}`}
            onChange={changeHandler}
            value={value}
            {...(focusRef ? { ref: focusRef } : {})}
            {...props}
          />
          {(stateStatus === SUCCESS || stateStatus === LOADING) &&
            !disabled && (
              <MTextareaCheckMarkStyled
                aria-label={`La valeur d'entrée est ${stateStatus}`}
              >
                {getIconStatus(stateStatus)}
              </MTextareaCheckMarkStyled>
            )}
        </MTextareaWrapperStyled>
      </LabelStyled>
      {stateStatus === ERROR && errorText && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          id={`${idLabel}Error`}
          mt="xxs"
          color="error"
        >
          {errorText}
        </Text>
      )}
      {helperText && (
        <Text type="body2" color="grayMedium" mt="xxs">
          {helperText}
        </Text>
      )}
    </MBox>
  );
};

MTextarea.defaultProps = {
  maxLength: '80',
  isRequired: true,
};

MTextarea.propTypes = {
  label: propTypes.string,
  placeholder: propTypes.string,
  maxLength: propTypes.oneOfType([propTypes.string, propTypes.number]),
  idLabel: propTypes.string.isRequired,
  disabled: propTypes.bool,
  isRequired: propTypes.bool,
  stateStatus: propTypes.string,
  errorText: propTypes.string,
  helperText: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
};

export default MTextarea;
