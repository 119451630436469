import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { validateStatus } from 'constants/validateStatus';

import { Picture } from 'components/common';
import InputUpload from 'components/forms/helpers/InputUpload';
import { IconUpload } from 'components/icons';
import { Text } from 'components/texts';

import {
  AvatarEmptyPersonName,
  AvatarWithoutPhoto,
  ErrorNotification,
  GradientText,
  VisuallyHidden,
} from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const AvatarUploadWrapperStyled = styled.div`
  max-width: ${breakpoints.md};
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AvatarUploadContentStyled = styled.div`
  position: relative;
  width: ${theme.sizes.uploadAvatarSize};
  height: ${theme.sizes.uploadAvatarSize};
`;

const AvatarUploadStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${theme.sizes.uploadAvatarSize};
  height: ${theme.sizes.uploadAvatarSize};
  margin: 0 auto;
  cursor: pointer;
  ${props => !props.$src && AvatarWithoutPhoto};
`;

const ErrorAvatarStyle = {
  borderColor: theme.colors.error,
};

const ButtonPlusStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -0.3rem;
  bottom: 0;
  border-radius: ${theme.radii.circle};
  width: ${theme.sizes.btnCircleSizeMd};
  height: ${theme.sizes.btnCircleSizeMd};
  background: ${gradients.primary};
  appearance: none;
  z-index: 1;
`;

const AvatarEmptyNameStyled = styled.span`
  ${GradientText(`${gradients.secondary}`)};
  ${AvatarEmptyPersonName(`${theme.fontSizes.h1}`)};
`;

const AvatarUpload = ({
  $src,
  $srcSetAvif,
  $srcSetWebp,
  onChange,
  personName,
  stateStatus,
  errorText,
}) => {
  const { ERROR } = validateStatus;
  const onChangePhotoTextClick = useCallback(() => {
    document.getElementById('inputUpload').click();
  }, []);

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === 13) {
      document.getElementById('inputUpload').click();
    }
  }, []);

  return (
    <AvatarUploadWrapperStyled
      style={stateStatus === ERROR ? ErrorNotification : null}
    >
      <AvatarUploadContentStyled>
        <AvatarUploadStyled
          onClick={onChangePhotoTextClick}
          onChange={onChange}
          $src={$src}
          style={stateStatus === ERROR ? ErrorAvatarStyle : null}
        >
          {$src && (
            <Picture
              style={{
                borderRadius: theme.radii.circle,
                objectFit: 'cover',
                height: '100%',
                zIndex: '1',
              }}
              position="absolute"
              top="0"
              src={$src}
              srcSetAvif={$srcSetAvif}
              srcSetWebp={$srcSetWebp}
              alt="Avatar"
            />
          )}
          {!$src && (
            <AvatarEmptyNameStyled>
              {personName}
              <VisuallyHidden>avatar sans photo</VisuallyHidden>
            </AvatarEmptyNameStyled>
          )}
        </AvatarUploadStyled>
        <ButtonPlusStyled tabIndex="0" onKeyDown={handleKeyDown}>
          <IconUpload />
          <InputUpload
            id="inputUpload"
            onChange={onChange}
            accept=".png, .jpeg, .jpg"
            aria-label="Ajoutez votre photo"
            aria-describedby={
              stateStatus === ERROR && errorText && !$src
                ? 'avatarUploadError'
                : null
            }
          />
        </ButtonPlusStyled>
      </AvatarUploadContentStyled>
      {stateStatus === ERROR && errorText && !$src && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          id="avatarUploadError"
          mt="secondaryMd"
          color="error"
        >
          {errorText}
        </Text>
      )}
    </AvatarUploadWrapperStyled>
  );
};

AvatarUpload.propTypes = {
  onChange: propTypes.func,
  $src: propTypes.string,
  $srcSetAvif: propTypes.string,
  $srcSetWebp: propTypes.string,
  personName: propTypes.string,
  stateStatus: propTypes.string,
  errorText: propTypes.string,
};

export default AvatarUpload;
