const IconTrash2 = () => {
  return (
    <svg width="24" viewBox="0 0 24 24" fill="none" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM4.992 5H3a1 1 0 0 0 0 2h1.064l.642 9.625c.05.756.092 1.381.167 1.89.078.53.201 1.014.458 1.465a4 4 0 0 0 1.731 1.62c.468.226.959.317 1.493.36.512.04 1.139.04 1.896.04h3.098c.757 0 1.384 0 1.896-.04.534-.043 1.026-.134 1.493-.36a4 4 0 0 0 1.73-1.62c.258-.451.381-.935.46-1.465.074-.509.116-1.134.166-1.89L19.936 7H21a1 1 0 1 0 0-2H4.992ZM10 9.5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Z"
        fill="#999"
      />
    </svg>
  );
};

export default IconTrash2;
