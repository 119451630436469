const IconBank = () => {
  return (
    <svg width="20" viewBox="0 0 20 21" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9.854 2.193a1.668 1.668 0 0 1 .594.053l.022.005 6.189 1.375c.167.037.333.074.473.116.157.047.335.116.509.24.245.178.438.418.557.695.085.197.113.386.125.55.01.145.01.315.01.486v.98c0 .21 0 .415-.014.589-.015.19-.052.415-.167.641-.16.314-.415.569-.729.729a1.685 1.685 0 0 1-.641.167 3.354 3.354 0 0 1-.115.008v5.013c.04.002.078.004.115.008.19.015.415.052.641.167.314.16.569.415.729.728.115.227.152.451.167.642.015.173.014.378.014.588v.72c0 .21 0 .415-.014.589-.015.19-.052.415-.167.641-.16.314-.415.569-.729.729a1.685 1.685 0 0 1-.641.167 7.644 7.644 0 0 1-.589.014H3.807c-.21 0-.415 0-.589-.014a1.684 1.684 0 0 1-.641-.167 1.667 1.667 0 0 1-.729-.729 1.685 1.685 0 0 1-.167-.641c-.014-.174-.014-.378-.014-.589v-.72c0-.21 0-.415.014-.588.015-.19.052-.415.167-.642.16-.313.415-.568.729-.728.226-.115.45-.152.641-.168l.115-.007V8.827a3.358 3.358 0 0 1-.115-.008 1.685 1.685 0 0 1-.641-.167 1.667 1.667 0 0 1-.729-.729 1.685 1.685 0 0 1-.167-.641c-.014-.174-.014-.378-.014-.589V5.713c0-.17 0-.34.01-.487.012-.163.04-.352.125-.549.12-.277.312-.517.557-.694.174-.125.352-.194.509-.24.14-.043.306-.08.473-.117l.022-.005 6.167-1.37.022-.005c.085-.02.191-.043.302-.053ZM5 8.833v5h2.083v-5H5ZM3.833 7.167a6.823 6.823 0 0 1-.49-.01l-.001-.01a6.83 6.83 0 0 1-.009-.48v-.93a6.141 6.141 0 0 1 .007-.396l.008-.003c.074-.022.179-.046.377-.09l6.167-1.37a4.928 4.928 0 0 1 .11-.023l.107.023 6.166 1.37a6.121 6.121 0 0 1 .385.093 6.11 6.11 0 0 1 .007.396v.93c0 .247 0 .382-.009.48v.01l-.012.001a6.82 6.82 0 0 1-.48.009H3.834ZM8.75 8.833v5h2.5v-5h-2.5Zm4.167 0v5H15v-5h-2.083ZM3.833 15.5a6.845 6.845 0 0 0-.49.01l-.001.01a6.831 6.831 0 0 0-.009.48v.667a6.831 6.831 0 0 0 .01.49l.011.001c.097.008.232.009.48.009h12.333a6.842 6.842 0 0 0 .49-.01l.001-.01c.008-.098.009-.233.009-.48V16c0-.247 0-.382-.009-.48v-.01a6.842 6.842 0 0 0-.49-.01H3.832Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconBank;
