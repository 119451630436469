import propTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, theme } from 'styles/Theme';

import MNavigationButton from './MNavigationButton';

const MNavigationListStyled = styled.ul`
  ${props =>
    props.isMobile
      ? `padding: 0 ${theme.space.primaryMd}; max-width: ${breakpoints.md}; justify-content: space-between;  margin: 0 auto;`
      : `justify-content: flex-end; padding: 0; margin: 0 ${theme.space.xxxl} 0 0;`};
  display: flex;
  list-style-type: none;
`;

const MNavigationList = ({ isMobile, items, indexes, unreadMessages }) => (
  <MNavigationListStyled isMobile={isMobile}>
    {indexes.map(index => (
      <li
        key={items[index].id}
        aria-current={items[index].isActive ? 'page' : null}
      >
        <MNavigationButton
          to={items[index].to}
          secondActivePath={items[index].secondActivePath}
          thirdActivePath={items[index].thirdActivePath}
          isNotify={items[index].isNotify}
          navText={items[index].navText}
          isMobile={isMobile}
          activeChildren={items[index].activeIcon}
          unreadMessages={unreadMessages}
        >
          {items[index].icon}
        </MNavigationButton>
      </li>
    ))}
  </MNavigationListStyled>
);

MNavigationList.defaultProps = {
  isMobile: true,
};

MNavigationList.propTypes = {
  isMobile: propTypes.bool,
  items: propTypes.arrayOf(
    propTypes.shape({
      icon: propTypes.node.isRequired,
      activeIcon: propTypes.node,
      navText: propTypes.string.isRequired,
      id: propTypes.string.isRequired,
      to: propTypes.string.isRequired,
      secondActivePath: propTypes.string,
      thirdActivePath: propTypes.string,
      isNotify: propTypes.bool,
    }),
  ).isRequired,
  indexes: propTypes.arrayOf(propTypes.number).isRequired,
  unreadMessages: propTypes.number,
};

export default MNavigationList;
