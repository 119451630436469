import { onMessageChannel } from 'channels/onMessageChannel';
import { onOpenChannel } from 'channels/onOpenChannel';
import { createNewEventSource } from 'helpers/serverEventsApi';
import humps from 'humps';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { formatResponseData } from 'tools/formatQueryData';

import { selectEventSource } from './selectors';
import { connectServerEvents, setEventSource, setReadyState } from './slice';

function* listenMessagesDataSaga() {
  const eventSource = yield select(selectEventSource);
  const channel = yield call(onMessageChannel, eventSource);

  while (true) {
    const message = yield take(channel);
    yield put(setReadyState(message?.target?.readyState));
    const messageData = humps.camelizeKeys(
      JSON.parse(message.data),
      formatResponseData,
    );
    yield put({
      type: messageData.type,
      payload: messageData.data,
    });
  }
}

function* listenReadyStateSaga() {
  const eventSource = yield select(selectEventSource);
  const channel = yield call(onOpenChannel, eventSource);

  while (true) {
    const message = yield take(channel);
    yield put(setReadyState(message?.target?.readyState));
  }
}

function* connectServerEventsSaga() {
  const eventSource = yield call(createNewEventSource);
  yield put(setEventSource(eventSource));
  yield put(setReadyState(eventSource.readyState));
  yield fork(listenMessagesDataSaga);
  yield fork(listenReadyStateSaga);
}

export default function* watchServerEventsSaga() {
  yield all([takeLatest(connectServerEvents, connectServerEventsSaga)]);
}
