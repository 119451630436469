import propTypes from 'prop-types';

const IconDialogActive = ({ uniqueId }) => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath={`url(#dialog${uniqueId})`}>
        <path
          fill={`url(#dialog2${uniqueId})`}
          d="M15.3 5.4h-.9V2.7A2.7 2.7 0 0 0 11.7 0h-9A2.7 2.7 0 0 0 0 2.7v10.8a.9.9 0 0 0 .558.828.9.9 0 0 0 .981-.189L4.068 11.6H5.4v1.296a2.7 2.7 0 0 0 2.7 2.7h6.228l2.133 2.142a.9.9 0 0 0 1.385-.14.9.9 0 0 0 .154-.5V8.1a2.7 2.7 0 0 0-2.7-2.7ZM5.4 8.1v1.701H3.699a.9.9 0 0 0-.639.261L1.8 11.331v-8.63a.9.9 0 0 1 .9-.9h9a.9.9 0 0 1 .9.9V5.4H8.1a2.7 2.7 0 0 0-2.7 2.7Zm10.8 6.83-.9-.9a.901.901 0 0 0-.639-.27H8.1a.9.9 0 0 1-.9-.9V8.1a.9.9 0 0 1 .9-.9h7.2a.9.9 0 0 1 .9.9v6.83Z"
        />
      </g>
      <defs>
        <linearGradient
          id={`dialog2${uniqueId}`}
          x1="18"
          x2="-.512"
          y1="18"
          y2="17.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id={`dialog${uniqueId}`}>
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconDialogActive.defaultProps = {
  uniqueId: '',
};

IconDialogActive.propTypes = {
  uniqueId: propTypes.string,
};

export default IconDialogActive;
