const IconVoiceMessage = () => {
  return (
    <svg width="14" viewBox="0 0 14 19" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M3.66659 3.66683C3.66659 1.82588 5.15897 0.333496 6.99992 0.333496C8.84087 0.333496 10.3333 1.82588 10.3333 3.66683V9.50016C10.3333 11.3411 8.84087 12.8335 6.99992 12.8335C5.15897 12.8335 3.66659 11.3411 3.66659 9.50016V3.66683ZM6.99992 2.00016C6.07944 2.00016 5.33325 2.74635 5.33325 3.66683V9.50016C5.33325 10.4206 6.07944 11.1668 6.99992 11.1668C7.92039 11.1668 8.66658 10.4206 8.66658 9.50016V3.66683C8.66658 2.74635 7.92039 2.00016 6.99992 2.00016ZM1.16659 7.00016C1.62682 7.00016 1.99992 7.37326 1.99992 7.8335V9.50016C1.99992 12.2616 4.23849 14.5002 6.99992 14.5002C9.76134 14.5002 11.9999 12.2616 11.9999 9.50016V7.8335C11.9999 7.37326 12.373 7.00016 12.8333 7.00016C13.2935 7.00016 13.6666 7.37326 13.6666 7.8335V9.50016C13.6666 12.8998 11.1218 15.7052 7.83325 16.1152V17.0002H10.3333C10.7935 17.0002 11.1666 17.3733 11.1666 17.8335C11.1666 18.2937 10.7935 18.6668 10.3333 18.6668H3.66659C3.20635 18.6668 2.83325 18.2937 2.83325 17.8335C2.83325 17.3733 3.20635 17.0002 3.66659 17.0002H6.16658V16.1152C2.87799 15.7052 0.333252 12.8998 0.333252 9.50016V7.8335C0.333252 7.37326 0.706348 7.00016 1.16659 7.00016Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconVoiceMessage;
