import { useSelector } from 'react-redux';

import { selectNewAvatarUrl, selectUser } from 'ducks/user';

import { Avatar } from 'components/avatar';

const CurrentUserAvatar = ({ ...props }) => {
  const user = useSelector(selectUser);
  const newAvatarUrl = useSelector(selectNewAvatarUrl);

  return (
    <Avatar
      $src={newAvatarUrl || user?.personalInfo?.avatar?.squarePreviewUrl}
      $srcSetAvif={
        newAvatarUrl || user?.personalInfo?.avatar?.squarePreviewAvifUrl
      }
      $srcSetWebp={
        newAvatarUrl || user?.personalInfo?.avatar?.squarePreviewWebpUrl
      }
      personName={user?.accountInfo?.publicName}
      isChecked={user?.certified}
      withoutPhoto={
        !user?.personalInfo?.avatar?.squarePreviewUrl && !newAvatarUrl
      }
      {...props}
    />
  );
};

export default CurrentUserAvatar;
