const IconLightning = () => {
  return (
    <svg width="13" fill="none" viewBox="0 0 13 16" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.432.722c.272.118.433.4.396.694L7.255 6h4.13c.16 0 .324 0 .457.012.125.011.366.041.575.21a1 1 0 0 1 .371.793 1.045 1.045 0 0 1-.207.577c-.075.11-.18.234-.282.358l-5.954 7.144a.667.667 0 0 1-1.173-.51L5.745 10h-4.13c-.16 0-.324 0-.457-.012a1.045 1.045 0 0 1-.575-.21 1 1 0 0 1-.371-.793c.004-.269.135-.473.207-.577.075-.11.18-.234.283-.358l.015-.018L6.655.907a.667.667 0 0 1 .777-.185ZM1.923 8.667H6.5a.667.667 0 0 1 .662.749l-.385 3.077 4.3-5.16H6.5a.667.667 0 0 1-.662-.749l.385-3.077-4.3 5.16Z"
        fill="#000"
      />
    </svg>
  );
};

export default IconLightning;
