import { generatePath } from 'react-router-dom';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getPaypalAccountsCollection = page => {
  return request.get(apiRoutes.PAYPAL_ACCOUNTS.COLLECTION, {
    params: { page },
  });
};

export const getPaypalAccount = id => {
  return request.get(generatePath(apiRoutes.PAYPAL_ACCOUNTS.ID, { id }));
};

export const createPaypalAccount = ({ email, active }) => {
  return request.post(apiRoutes.PAYPAL_ACCOUNTS.COLLECTION, {
    email,
    active,
  });
};

export const patchPaypalAccount = ({ id, body: { email, active } }) => {
  return request.patch(generatePath(apiRoutes.PAYPAL_ACCOUNTS.ID, { id }), {
    email,
    active,
  });
};
