const Icon404 = () => {
  return (
    <svg aria-hidden="true" width="133" viewBox="0 0 133 90" fill="none">
      <g clipPath="url(#icon404-1)">
        <path
          fill="#DCDCDC"
          d="M1.952 40.06v-8.19L25.612.02h10.57v31.5h6.44v8.54h-6.44V50h-9.94v-9.94H1.952Zm25.06-28.49-14.98 19.95h14.98V11.57ZM67.076 50c-12.6 0-18.9-8.377-18.9-25.13 0-16.707 6.3-25.06 18.9-25.06 6.673 0 11.48 2.24 14.42 6.72 2.94 4.48 4.41 10.593 4.41 18.34 0 7.793-1.47 13.93-4.41 18.41-2.94 4.48-7.747 6.72-14.42 6.72Zm7.21-13.37c1.26-2.753 1.89-6.673 1.89-11.76 0-5.04-.63-8.913-1.89-11.62-1.214-2.753-3.617-4.13-7.21-4.13-3.64 0-6.09 1.377-7.35 4.13-1.214 2.707-1.82 6.58-1.82 11.62 0 5.087.606 9.007 1.82 11.76 1.26 2.707 3.71 4.06 7.35 4.06 3.593 0 5.996-1.353 7.21-4.06Zm17.558 3.43v-8.19L115.504.02h10.57v31.5h6.44v8.54h-6.44V50h-9.94v-9.94h-24.29Zm25.06-28.49-14.98 19.95h14.98V11.57Z"
        />
        <path
          fill="url(#icon404-2)"
          d="M102.213 47.931 84.934 65.213a2.731 2.731 0 0 1-3.856 0L63.799 47.932a2.726 2.726 0 0 1 0-3.857l17.279-17.278a2.731 2.731 0 0 1 3.856 0l17.279 17.278a2.728 2.728 0 0 1 0 3.856Z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity=".3"
          strokeWidth="3"
          d="M83 29 66 46l17 17 17-17-17-17Z"
        />
        <path
          fill="#fff"
          d="M85.422 55.357a2.341 2.341 0 1 1-4.682 0 2.341 2.341 0 0 1 4.682 0ZM83.068 34.434c1.655 0 2.49.793 2.39 3.368-.101 2.575-1.317 12.766-2.39 12.766-1.073 0-2.276-10.19-2.39-12.766-.114-2.575.732-3.368 2.39-3.368Z"
        />
        <path
          fill="url(#icon404-3)"
          d="M92.185 86.397H33.812c-1.255 0-.879-3.093-.06-4.066L44.587 71.12a1.47 1.47 0 0 1 1.128-.53h34.594a1.462 1.462 0 0 1 1.127.53l10.816 11.252c.812.946 1.194 4.025-.067 4.025Z"
        />
        <path
          fill="url(#icon404-4)"
          d="M91.124 84.802H34.845a1.49 1.49 0 0 1-1.12-2.456L44.56 69.438a1.462 1.462 0 0 1 1.128-.523h34.594a1.455 1.455 0 0 1 1.127.523L92.25 82.373a1.497 1.497 0 0 1-1.127 2.43Z"
        />
        <path
          fill="url(#icon404-5)"
          d="M62.985 81.117c17.727 0 13.916-8.883 13.916-8.883-4.154-14.245-8.756-31.723-8.756-31.723s-1-2.308-5.16-2.308-5.16 2.308-5.16 2.308-4.602 17.478-8.755 31.723c.006 0-3.811 8.883 13.915 8.883Z"
        />
        <path
          fill="url(#icon404-6)"
          d="M62.985 61.443c-5.401 0-8.528-.873-10.292-2.013-.765 2.757-1.57 5.629-2.376 8.447 1.275 1.925 4.617 3.851 12.668 3.851 8.051 0 11.406-1.925 12.667-3.851-.805-2.818-1.61-5.69-2.375-8.447-1.764 1.14-4.891 2.013-10.292 2.013Z"
        />
        <path
          fill="url(#icon404-7)"
          d="M71.969 54.7a679.132 679.132 0 0 1-1.08-3.966 32.166 32.166 0 0 1-15.808 0c-.335 1.248-.67 2.577-1.08 3.966 1.798.791 4.616 1.342 8.984 1.342s7.186-.55 8.984-1.342Z"
        />
        <path
          fill="url(#icon404-8)"
          d="M62.985 41.652c1.782.04 3.554-.275 5.213-.926l-.053-.215s-1-2.308-5.16-2.308-5.16 2.308-5.16 2.308l-.053.215c1.66.65 3.431.965 5.213.926Z"
        />
      </g>
      <defs>
        <linearGradient
          id="icon404-2"
          x1="63"
          x2="110.727"
          y1="66.655"
          y2="49.328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
        <linearGradient
          id="icon404-3"
          x1="93"
          x2="31.996"
          y1="86.397"
          y2="79.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCB5FF" />
          <stop offset="1" stopColor="#8AA5FF" />
        </linearGradient>
        <linearGradient
          id="icon404-4"
          x1="92.578"
          x2="32.352"
          y1="84.802"
          y2="78.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <linearGradient
          id="icon404-5"
          x1="77.245"
          x2="47.903"
          y1="81.117"
          y2="80.545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCB5FF" />
          <stop offset="1" stopColor="#8AA5FF" />
        </linearGradient>
        <linearGradient
          id="icon404-6"
          x1="75.652"
          x2="49.669"
          y1="71.728"
          y2="70.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <linearGradient
          id="icon404-7"
          x1="71.969"
          x2="53.655"
          y1="56.042"
          y2="54.223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <linearGradient
          id="icon404-8"
          x1="68.198"
          x2="57.55"
          y1="41.655"
          y2="40.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id="icon404-1">
          <path fill="#fff" d="M0 0h133v90H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon404;
