import differenceInYears from 'date-fns/differenceInYears';
import * as services from 'ducks/auth/services';

import phoneWithoutCode from 'constants/phoneWithoutCode';

import translate from './translate';

// Validators should return string error if value is not valid or empty string;
export const emailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const composeValidators =
  (...validators) =>
  (value, allValues, meta) => {
    return validators.reduce(
      (error, validator) => error || validator(value, allValues, meta),
      undefined,
    );
  };

export const noValidators = () => undefined;

export const required = value => (value ? undefined : ' ');

export const validEmail = mail =>
  emailRegex.test(mail) ? undefined : 'Cet email est invalide';

export const confirmPassword = fieldName => (value, allValues) =>
  value && allValues[fieldName] === value
    ? undefined
    : translate('validation.passwordConfirmation');

export const validateMultipleSpaces = currentField => value =>
  /\s\s+/.test(value)
    ? translate('validation.validateMultipleSpaces', { currentField })
    : undefined;

export const isInvalid = (value, allValues, meta) => {
  const {
    data: { error },
  } = meta;

  return error;
};

export const validateSpacesAtStartOrEnd = value =>
  /(^\s+)|(\s+$)/g.test(value)
    ? translate('validation.validateSpacesAtStartOrEnd')
    : undefined;

export const isEmpty = errorText => value => value ? undefined : errorText;

export const isOnlyEmptySpaces = errorText => value =>
  !/^\s+$/.test(value) ? undefined : errorText;

export const isPhoneNumberEmpty = errorText => value =>
  value.split(' ')[1] ? undefined : errorText;

export const validateMinLength = (length, errorText) => value =>
  (value && value.length >= length) || !value ? undefined : errorText;

export const validatePhoneMinLength = (length, errorText) => value => {
  const phone = phoneWithoutCode(value);

  return (phone && phone.length >= length) || !value ? undefined : errorText;
};

export const validateCaseCharacters = errorText => value =>
  !value || /^(?=.*[a-z])(?=.*[A-Z])/u.test(value) ? undefined : errorText;

export const validateDigit = errorText => value =>
  !value || /^(?=.*?[0-9])/.test(value) ? undefined : errorText;

export const validateSpecialCharacters = errorText => value =>
  !value || /^(?=.*?[#?!@$%^&*-])/.test(value) ? undefined : errorText;

export const validateIban = errorText => value =>
  !value || /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/.test(value)
    ? undefined
    : errorText;

export const validateBic = errorText => value =>
  !value || /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value)
    ? undefined
    : errorText;

export const validateAge = text => date => {
  return differenceInYears(Date.now(), date) < 18 ||
    differenceInYears(Date.now(), date) > 99
    ? text
    : undefined;
};

export const validateFieldInDB =
  (field, errorText) =>
  // eslint-disable-next-line consistent-return
  async value => {
    try {
      if (value) {
        await services.validateUserData({
          ...(field === 'phone'
            ? {
                [field]: {
                  code: value.split(' ')[0],
                  number: phoneWithoutCode(value),
                },
              }
            : { [field]: value }),
        });
        return undefined;
      }
    } catch (error) {
      return errorText;
    }
  };

export const validateUsername = errorText => value =>
  !value || /^(?:[\p{L}_#?!@$%^&*\-\d]){1,20}$/u.test(value)
    ? undefined
    : errorText;

export const validatePublicName = errorText => value =>
  !value || /^(?!\.)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9_. ]+$/.test(value)
    ? undefined
    : errorText;

export const validateYoutubeLink = errorText => value => {
  if (!value) {
    return undefined;
  }
  return /https:\/\/(?:www|m)\.youtube\.com\/(?:c\/|channel\/|@)?([a-zA-Z0-9_\-.]{3,30}$)/.test(
    value,
  )
    ? undefined
    : errorText;
};

export const validateFacebookLink = errorText => value => {
  if (!value) {
    return undefined;
  }
  return /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w\-.]+)(?:\/)?/i.test(
    value,
  )
    ? undefined
    : errorText;
};

export const TVAValidator = value =>
  !value || /^(?!.*\s{2,})[a-zA-Z0-9.]+(?:\s[a-zA-Z0-9.]+)*$/.test(value)
    ? undefined
    : 'Le numéro de TVA semble erroné';

export const validateBankAccOwnerName = value =>
  !value || /^((?!.*\s{2,})[a-zA-Z]+(?:\s[a-zA-Z]+)*)$/.test(value)
    ? undefined
    : 'Certains caractères saisis ne sont pas autorisés';
