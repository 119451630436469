import propTypes from 'prop-types';
import styled from 'styled-components';
import { position } from 'styled-system';

const IconCheckAvatarStyled = styled.svg`
  ${position};
`;

const IconCheckAvatar = ({ size, uniqueId, ...props }) => {
  return (
    <IconCheckAvatarStyled
      width={size}
      viewBox="0 0 22 22"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#fff"
        d="M7.046 19.909a.34.34 0 0 0-.193-.08 5.685 5.685 0 0 1-5.215-5.215.34.34 0 0 0-.08-.193 5.685 5.685 0 0 1 0-7.375.34.34 0 0 0 .08-.193 5.685 5.685 0 0 1 5.215-5.215.34.34 0 0 0 .193-.08 5.685 5.685 0 0 1 7.375 0 .34.34 0 0 0 .193.08 5.685 5.685 0 0 1 5.215 5.215.34.34 0 0 0 .08.193 5.685 5.685 0 0 1 0 7.375.34.34 0 0 0-.08.193 5.685 5.685 0 0 1-5.215 5.214.34.34 0 0 0-.193.08 5.685 5.685 0 0 1-7.375 0Z"
      />
      <path
        fill={`url(#IconCheckAvatar${uniqueId})`}
        fillRule="evenodd"
        d="M8.301 3.025a3.798 3.798 0 0 1 4.928 0c.356.304.8.488 1.266.525a3.798 3.798 0 0 1 3.485 3.484c.037.467.22.91.524 1.267a3.798 3.798 0 0 1 0 4.927c-.303.357-.487.8-.524 1.267a3.798 3.798 0 0 1-3.485 3.484c-.466.038-.91.221-1.266.525a3.798 3.798 0 0 1-4.928 0 2.226 2.226 0 0 0-1.267-.525 3.798 3.798 0 0 1-3.484-3.484 2.226 2.226 0 0 0-.524-1.267 3.798 3.798 0 0 1 0-4.927c.303-.357.487-.8.524-1.267A3.798 3.798 0 0 1 7.034 3.55c.467-.037.91-.221 1.267-.525Zm5.771 5.219a.786.786 0 0 1 0 1.111l-3.537 3.537a.786.786 0 0 1-1.112 0L7.851 11.32a.786.786 0 0 1 1.112-1.111l1.016 1.016 2.981-2.981a.786.786 0 0 1 1.112 0Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id={`IconCheckAvatar${uniqueId}`}
          x1="2.118"
          x2="22.747"
          y1="19.69"
          y2="12.201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
    </IconCheckAvatarStyled>
  );
};

IconCheckAvatar.defaultProps = {
  size: '22',
  uniqueId: '',
};

IconCheckAvatar.propTypes = {
  size: propTypes.string,
  uniqueId: propTypes.string,
};

export default IconCheckAvatar;
