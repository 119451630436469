import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import noop from 'tools/noop';

import { MBox } from 'components/common';
import {
  IconAmexCard,
  IconApplePay,
  IconBancairesCard,
  IconCard,
  IconDinersClubCard,
  IconDiscoverCard,
  IconGooglePay,
  IconJCBCard,
  IconMasterCard,
  IconTrash,
  IconUnionPayCard,
  IconVisaCard,
} from 'components/icons';
import { Text } from 'components/texts';

import { InputFocusVisible, VisuallyHidden } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

const CardRadioWrapperStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  gap: ${theme.space.primaryMd};
  margin-bottom: ${theme.space.lg};
  ${space};
`;

const CardRadioLabelStyled = styled.label`
  position: relative;
  display: block;
  border-radius: ${theme.radii.sm};
  border-width: ${theme.borderWidths.xs};
  border-style: solid;
  ${props =>
    props.active
      ? `      
      border-color: ${theme.colors.grayBorder};
      background: ${theme.colors.grayTertiary};
  `
      : `border-color: transparent;
      background: ${theme.colors.white};`};
`;

const CardRadioInputStyled = styled.input`
  ${InputFocusVisible};
`;

const CardRadioTextStyled = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: ${theme.space.md} ${theme.space.xs} ${theme.space.md};
  cursor: default;
`;

const CardRadioButtonStyled = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const CardRadio = ({
  name,
  items,
  ariaLabelledby,
  patchPaymentMethod,
  openDeletePaymentMethodModal,
  ...props
}) => {
  return (
    <CardRadioWrapperStyled {...props}>
      {items.map(item => (
        <li key={item.id}>
          <CardRadioLabelStyled
            htmlFor={`${name}-${item.id}`}
            active={item.default}
          >
            <CardRadioInputStyled
              onChange={() => patchPaymentMethod(item.id)}
              type="radio"
              name={name}
              value={item.value}
              id={`${name}-${item.id}`}
              aria-labelledby={`${ariaLabelledby} ${item.id}`}
            />
            <CardRadioTextStyled>
              <MBox mr="sm" display="flex" as="span">
                <MBox
                  as="span"
                  mr="sm"
                  borderRadius="xxsSecondary"
                  borderColor="grayBorder2"
                  borderStyle="solid"
                  borderWidth={theme.borderWidths.xs}
                  width={theme.sizes.brandCardBoxWidth}
                  height={theme.sizes.brandCardBoxHeight}
                  bg="white"
                  overflow="hidden"
                  display="flex"
                  flexShrink="0"
                  alignItems="center"
                  justifyContent="center"
                  p="xxs"
                  id={`${item.id}`}
                >
                  {item.applePay && (
                    <>
                      <VisuallyHidden>ApplePay carte bancaire</VisuallyHidden>
                      <IconApplePay />
                    </>
                  )}
                  {item.googlePay && (
                    <>
                      <VisuallyHidden>GooglePay carte bancaire</VisuallyHidden>
                      <IconGooglePay />
                    </>
                  )}
                  {(() => {
                    switch (item.brand) {
                      case 'amex':
                        return (
                          <>
                            <VisuallyHidden>
                              American Express carte bancaire
                            </VisuallyHidden>
                            <IconAmexCard />
                          </>
                        );
                      case 'cartes_bancaires':
                        return (
                          <>
                            <VisuallyHidden>
                              Cartes Bancaires carte bancaire
                            </VisuallyHidden>
                            <IconBancairesCard />
                          </>
                        );
                      case 'diners':
                        return (
                          <>
                            <VisuallyHidden>
                              Diners carte bancaire
                            </VisuallyHidden>
                            <IconDinersClubCard />
                          </>
                        );
                      case 'discover':
                        return (
                          <>
                            <VisuallyHidden>
                              Discover carte bancaire
                            </VisuallyHidden>
                            <IconDiscoverCard />
                          </>
                        );
                      case 'jcb':
                        return (
                          <>
                            <VisuallyHidden>JCB carte bancaire</VisuallyHidden>
                            <IconJCBCard />
                          </>
                        );
                      case 'mastercard':
                        return (
                          <>
                            <VisuallyHidden>
                              Master Card carte bancaire
                            </VisuallyHidden>
                            <IconMasterCard />
                          </>
                        );
                      case 'visa':
                        return (
                          <>
                            <VisuallyHidden>
                              Visa carte IconBancairesCard
                            </VisuallyHidden>
                            <IconVisaCard />
                          </>
                        );
                      case 'unionpay':
                        return (
                          <>
                            <VisuallyHidden>
                              UnionPay carte bancaire
                            </VisuallyHidden>
                            <IconUnionPayCard />
                          </>
                        );
                      case 'unknown':
                        return (
                          <>
                            <VisuallyHidden>
                              Unknown carte bancaire
                            </VisuallyHidden>
                            <IconCard />
                          </>
                        );
                      default:
                        return (
                          !item.applePay &&
                          !item.googlePay && (
                            <>
                              <VisuallyHidden>
                                Unknown carte bancaire
                              </VisuallyHidden>
                              <IconCard />
                            </>
                          )
                        );
                    }
                  })()}
                </MBox>
                <MBox
                  display="flex"
                  flexDirection="column"
                  as="span"
                  justifyContent="center"
                >
                  {!item.brand && item.applePay && (
                    <Text type="label2" mb="sm" as="span">
                      Apple Pay
                    </Text>
                  )}
                  {!item.brand && item.googlePay && (
                    <Text type="label2" mb="sm" as="span">
                      Google Pay
                    </Text>
                  )}
                  {(item.cardholderName || item.default) && (
                    <Text type="label2" mb="primarySm" as="b">
                      {item.cardholderName}&nbsp;
                      {item.default ? (
                        <Text as="span" $isGradient type="subtitle3">
                          (Par défaut)
                        </Text>
                      ) : null}
                    </Text>
                  )}
                  <Text type="body2" color="grayMedium" as="span">
                    <span aria-hidden="true">••••</span>&nbsp;
                    <VisuallyHidden>4 derniers chiffres</VisuallyHidden>
                    {item.lastFourDigits} - Exp.&nbsp;
                    <time>{item.expiresAt}</time>
                  </Text>
                </MBox>
              </MBox>
              {!item.applePay && !item.googlePay && (
                <CardRadioButtonStyled
                  type="button"
                  aria-label="Supprimer une carte bancaire"
                  onClick={() => openDeletePaymentMethodModal(item.id)}
                >
                  <IconTrash palette={theme.colors.black} size="20" />
                </CardRadioButtonStyled>
              )}
            </CardRadioTextStyled>
          </CardRadioLabelStyled>
        </li>
      ))}
    </CardRadioWrapperStyled>
  );
};

CardRadio.defaultProps = {
  value: '',
  onChange: noop,
};

CardRadio.propTypes = {
  name: propTypes.string.isRequired,
  ariaLabelledby: propTypes.string,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string.isRequired,
      brand: propTypes.string.isRequired,
      lastFourDigits: propTypes.string.isRequired,
      cardholderName: propTypes.string,
      default: propTypes.bool,
      expiresAt: propTypes.string.isRequired,
      // created_at: propTypes.string,
      value: propTypes.string.isRequired,
      applePay: propTypes.bool,
      googlePay: propTypes.bool,
    }).isRequired,
  ).isRequired,
  value: propTypes.string,
  onChange: propTypes.func,
  openDeletePaymentMethodModal: propTypes.func,
};

export default CardRadio;
