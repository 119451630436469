const IconGradientInstagram = () => {
  return (
    <svg width="41" viewBox="0 0 41 40" fill="none" aria-hidden="true">
      <path
        fill="url(#iconGradientInstagram)"
        fillRule="evenodd"
        d="M20.5 0c-5.432 0-6.113.023-8.246.12-2.129.097-3.583.436-4.855.93a9.803 9.803 0 0 0-3.542 2.307A9.803 9.803 0 0 0 1.55 6.899C1.056 8.171.717 9.625.62 11.754.523 13.887.5 14.568.5 20s.023 6.113.12 8.246c.097 2.129.436 3.583.93 4.855a9.803 9.803 0 0 0 2.307 3.542 9.804 9.804 0 0 0 3.542 2.307c1.272.494 2.726.833 4.855.93 2.133.097 2.814.12 8.246.12s6.113-.023 8.246-.12c2.129-.097 3.583-.436 4.855-.93a9.804 9.804 0 0 0 3.542-2.307 9.804 9.804 0 0 0 2.307-3.542c.494-1.272.833-2.726.93-4.855.097-2.133.12-2.814.12-8.246s-.023-6.113-.12-8.246c-.097-2.129-.436-3.583-.93-4.855a9.804 9.804 0 0 0-2.307-3.542 9.803 9.803 0 0 0-3.542-2.307C32.329.556 30.875.217 28.746.12 26.613.023 25.932 0 20.5 0Zm0 3.604c5.34 0 5.973.02 8.082.116 1.95.09 3.009.415 3.713.689.934.363 1.6.796 2.3 1.496.7.7 1.133 1.366 1.496 2.3.274.704.6 1.763.689 3.713.096 2.11.116 2.742.116 8.082 0 5.34-.02 5.973-.116 8.082-.09 1.95-.415 3.009-.689 3.713-.363.934-.796 1.6-1.496 2.3-.7.7-1.366 1.133-2.3 1.496-.704.274-1.763.6-3.713.689-2.109.096-2.741.116-8.082.116-5.34 0-5.973-.02-8.082-.116-1.95-.09-3.009-.415-3.713-.689-.934-.363-1.6-.796-2.3-1.496-.7-.7-1.133-1.366-1.496-2.3-.274-.704-.6-1.763-.689-3.713-.096-2.11-.116-2.742-.116-8.082 0-5.34.02-5.973.116-8.082.09-1.95.415-3.009.689-3.713.363-.934.796-1.6 1.496-2.3.7-.7 1.366-1.133 2.3-1.496.704-.274 1.763-.6 3.713-.689 2.11-.096 2.742-.116 8.082-.116Zm0 6.126c-5.672 0-10.27 4.598-10.27 10.27s4.598 10.27 10.27 10.27S30.77 25.672 30.77 20 26.172 9.73 20.5 9.73Zm0 16.937a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334ZM33.576 9.324a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="iconGradientInstagram"
          x1=".5"
          x2="48.214"
          y1="40.645"
          y2="23.323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconGradientInstagram;
