const IconShare = () => {
  return (
    <svg width="16" viewBox="0 0 16 16" fill="none" aria-hidden="true">
      <path
        fill="#ffffff"
        fillRule="evenodd"
        d="M6.89155 2.09285C7.08695 1.85309 7.3823 1.71725 7.6915 1.72492C7.96258 1.73164 8.16729 1.86749 8.27038 1.94086C8.38027 2.01908 8.50536 2.12634 8.62942 2.23271C8.6355 2.23792 8.64158 2.24314 8.64766 2.24835L14.2948 7.08876C14.2991 7.09246 14.3035 7.09621 14.3079 7.09999C14.3775 7.15962 14.4584 7.22889 14.5235 7.29525C14.5973 7.37039 14.7005 7.48973 14.7623 7.6618C14.8408 7.88034 14.8408 8.1194 14.7623 8.33794C14.7005 8.51001 14.5973 8.62935 14.5235 8.70449C14.4584 8.77086 14.3775 8.84014 14.3079 8.89977C14.3035 8.90355 14.2991 8.90728 14.2948 8.91098L8.62944 13.767C8.50538 13.8734 8.38028 13.9807 8.27038 14.0589C8.16729 14.1322 7.96258 14.2681 7.6915 14.2748C7.3823 14.2825 7.08695 14.1467 6.89155 13.9069C6.72024 13.6967 6.69016 13.4529 6.67878 13.3268C6.66665 13.1925 6.66668 13.0277 6.6667 12.8643L6.66671 10.7975C6.03857 10.906 5.42671 11.101 4.8489 11.3782C3.94802 11.8104 3.1512 12.4322 2.513 13.201C2.33357 13.4171 2.03791 13.4976 1.77369 13.4022C1.50946 13.3069 1.33337 13.0561 1.33337 12.7752V12.3672C1.33428 10.62 1.9784 8.93402 3.14283 7.63147C4.08444 6.57816 5.31297 5.835 6.66671 5.48555V3.15945C6.66671 3.15145 6.66671 3.14345 6.6667 3.13544C6.66668 2.97202 6.66665 2.80724 6.67878 2.67289C6.69016 2.54687 6.72024 2.30303 6.89155 2.09285ZM8.00004 3.44935V6.02296C8.00004 6.34682 7.76728 6.62384 7.44826 6.67965C6.17239 6.90286 5.00465 7.54938 4.13687 8.5201C3.48358 9.25087 3.03187 10.1301 2.8146 11.0716C3.26688 10.7245 3.75516 10.4241 4.27219 10.1761C5.21919 9.72177 6.24289 9.45266 7.28825 9.38175C7.47248 9.36925 7.65363 9.43372 7.78855 9.55979C7.92346 9.68586 8.00004 9.86224 8.00004 10.0469V12.5504L13.309 7.99987L8.00004 3.44935Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconShare;
