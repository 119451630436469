/* eslint-disable no-nested-ternary */
import { Link, useLocation } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { NewMessage } from 'components/notifications';
import { Text } from 'components/texts';

import { breakpoints, gradients, theme } from 'styles/Theme';

const MNavigationButtonStyled = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.black};
  ${props =>
    props.$isMobile
      ? `padding: ${theme.space.md};
    min-height: ${theme.sizes.navMobileHeight};
    min-width: 8rem;
    width: 100%; flex-direction: column;`
      : `min-height: 8.8rem;   
  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }`};

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border-radius: ${theme.radii.xl};
    ${props => props.$isActive && `background: ${gradients.primary};`};
    ${props =>
      props.$isMobile
        ? `top: 0; height: 0.2rem;`
        : `bottom: 0; height: 0.6rem;`};
  }
`;

const MNavigationButton = ({
  to,
  isMobile,
  isNotify,
  navText,
  children,
  activeChildren,
  secondActivePath,
  thirdActivePath,
  unreadMessages,
}) => {
  const location = useLocation();
  const isActive =
    location.pathname === to ||
    location.pathname === secondActivePath ||
    location.pathname === thirdActivePath;

  return (
    <MNavigationButtonStyled to={to} $isActive={isActive} $isMobile={isMobile}>
      {isActive ? <>{activeChildren}</> : <>{children}</>}

      {isNotify && !!unreadMessages && (
        <NewMessage
          top={!isMobile ? [null, '1.4rem', null, '2.4rem'] : null}
          left={!isMobile ? [null, '2.5rem', null, '-2rem'] : null}
          right={!isMobile ? 'inherit' : null}
          countMessage={unreadMessages}
        />
      )}
      <Text
        as="span"
        $isGradient={isActive}
        type={isMobile ? (isActive ? 'label3' : 'body3') : 'label2'}
        mt={!isMobile ? ['xs', null, null, '0'] : 'xs'}
        ml={!isMobile ? [null, null, null, 'sm'] : null}
      >
        {navText}
      </Text>
    </MNavigationButtonStyled>
  );
};

MNavigationButton.defaultProps = {
  isNotify: false,
  isMobile: true,
};

MNavigationButton.propTypes = {
  isNotify: propTypes.bool,
  isMobile: propTypes.bool,
  to: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  activeChildren: propTypes.node,
  navText: propTypes.string,
  secondActivePath: propTypes.string,
  thirdActivePath: propTypes.string,
  unreadMessages: propTypes.number,
};

export default MNavigationButton;
