import { useCallback, useRef, useState } from 'react';

import noop from 'tools/noop';

const useClickOutside = (defaultOpenState = false, onClickOutside = noop) => {
  const ref = useRef(null);
  const listening = useRef(false);
  const [isOpen, setIsOpen] = useState(defaultOpenState);
  const toggleOpenState = () => setIsOpen(oldValue => !oldValue);

  const onClickHandler = useCallback(
    evt => {
      if (ref.current?.contains(evt.target)) return;
      setIsOpen(false);
      onClickOutside(evt);
    },
    [onClickOutside],
  );

  const listenForOutsideClicks = useCallback(
    element => {
      ref.current = element;
      if (!element) {
        [`click`, `touchstart`].forEach(type => {
          document.removeEventListener(type, onClickHandler);
        });
        return;
      }
      if (listening.current) return;
      listening.current = true;
      [`click`, `touchstart`].forEach(type => {
        document.addEventListener(type, onClickHandler);
      });
    },
    [onClickHandler],
  );

  return [isOpen, toggleOpenState, listenForOutsideClicks];
};

export default useClickOutside;
