import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'requestsErrors';

export const requestsErrors = createSlice({
  name,
  initialState,
  reducers: {
    setError400(state) {
      state.error400 = true;
    },
    setError500(state) {
      state.error500 = true;
    },
    resetRequestErrors(state) {
      state.error400 = false;
      state.error500 = false;
    },
  },
});

export const { setError400, setError500, resetRequestErrors } =
  requestsErrors.actions;

export default requestsErrors.reducer;
