/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';

import { initialState } from './initialState';

const name = 'payment';

export const paymentSlice = createSlice({
  name,
  initialState,
  reducers: {
    getPaymentsRequest(state) {
      state.loading = true;
      state.isPaymentsLoading = true;
      state.error = false;
    },
    getPaymentsSuccess(state, { payload }) {
      state.loading = false;
      state.isPaymentsLoading = false;
      state.error = false;
      state.payments =
        payload.length !== 0 ? [...state.payments, ...payload] : state.payments;
      state.hasMore = payload.length === DEFAULT_ITEMS_PER_PAGE_VALUE;
    },
    getPaymentsFailure(state) {
      state.loading = false;
      state.isPaymentsLoading = false;
      state.error = true;
      state.hasMore = false;
    },
    postPaymentsRequest(state, { payload: { values } }) {
      state.loading = true;
      state.paydMessageId = values.messageId;
    },
    postPaymentsSuccess(state) {
      state.loading = false;
      state.paydMessageId = null;
    },
    postPaymentsFailure(state) {
      state.loading = false;
      state.paydMessageId = null;
    },
    incrementPaymentsPage(state) {
      state.paymentsPage += 1;
    },
    decrementPaymentsPage(state) {
      state.paymentsPage = 1;
    },
    resetPaymentsList(state) {
      state.payments = [];
    },
    resetHasMore(state) {
      state.hasMore = true;
    },
    getRevenueRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getRevenueSuccess(state, { payload }) {
      state.loading = false;
      state.error = false;
      state.revenue =
        payload.length !== 0 ? [...state.revenue, ...payload] : state.revenue;
      state.hasMoreRevenueItems =
        payload.length === DEFAULT_ITEMS_PER_PAGE_VALUE;
    },
    getRevenueFailure(state) {
      state.loading = false;
      state.error = true;
      state.hasMoreRevenueItems = false;
    },
    resetRevenue(state) {
      state.revenue = [];
    },
    incrementRevenuePage(state) {
      state.revenuePage += 1;
    },
    resetRevenuePage(state) {
      state.revenuePage = 1;
    },
    getRevenueAmountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getRevenueAmountSuccess(state, { payload }) {
      state.loading = false;
      state.error = false;
      state.revenueAmount = payload;
    },
    getRevenueAmountFailure(state) {
      state.loading = true;
      state.error = false;
    },
    resetRevenueAmount(state) {
      state.revenueAmount = null;
    },
    resetHasMoreRevenueItems(state) {
      state.hasMoreRevenueItems = true;
    },
  },
});

export const {
  getPaymentsRequest,
  getPaymentsSuccess,
  getPaymentsFailure,
  incrementPaymentsPage,
  decrementPaymentsPage,
  resetPaymentsList,
  resetHasMore,
  getRevenueRequest,
  getRevenueSuccess,
  getRevenueFailure,
  resetRevenue,
  incrementMySalesPage,
  decrementMySalesPage,
  resetRevenueItems,
  incrementRevenuePage,
  resetRevenuePage,
  getRevenueAmountFailure,
  getRevenueAmountRequest,
  getRevenueAmountSuccess,
  resetHasMoreRevenueItems,
  postPaymentsRequest,
  postPaymentsSuccess,
  postPaymentsFailure,
} = paymentSlice.actions;

export default paymentSlice.reducer;
