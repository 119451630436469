import { eventChannel } from 'redux-saga';

export function onMessageChannel(eventSource) {
  const subscribe = emitter => {
    const messageHandler = event => {
      emitter(event);
    };
    eventSource.onmessage = messageHandler;

    return () => {
      eventSource.onmessage = null;
    };
  };

  return eventChannel(subscribe);
}
