import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'config';
import { ThemeProvider } from 'styled-components';

import { Toast } from 'components/toast';

import { theme } from 'styles/Theme';

import App from './App';
import './i18n';
import { history, store } from './store';
import GlobalStyle from './styles/GlobalStyle';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <App stripePromise={stripePromise} />
        </Router>
      </ThemeProvider>
      <Toast />
    </Provider>
  </>,
);
