import { eventChannel } from 'redux-saga';

export function onOpenChannel(eventSource) {
  const subscribe = emitter => {
    const readyStateHandler = event => {
      emitter(event);
    };
    eventSource.onopen = readyStateHandler;

    return () => {
      eventSource.onopen = null;
    };
  };

  return eventChannel(subscribe);
}
