import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { routes } from 'constants/routes';

import { MButton } from 'components/buttons';
import { MLogo } from 'components/common';

import { LayoutBase } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

const AuthorizationBarWrapperStyled = styled.header`
  ${LayoutBase};
  padding: 0 ${theme.space.sm} ${theme.space.primaryMd};
  margin-bottom: ${theme.space.primaryMd};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: ${theme.sizes.authBarUnderlineHeight};
    background: ${theme.colors.grayBorder};
    width: 1000%;
    left: -400%;
    right: -400%;
    bottom: 0;
  }
`;

const AuthorizationBarStyled = styled.div`
  max-width: ${breakpoints.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

const AuthorizationBar = ({ customPathToLogin }) => {
  return (
    <AuthorizationBarWrapperStyled>
      <AuthorizationBarStyled>
        <MLogo />
        <MButton
          as={Link}
          to={customPathToLogin || routes.LOGIN}
          type={null}
          scale="xs"
          primary
          rounded
          m="0"
        >
          Se connecter
        </MButton>
      </AuthorizationBarStyled>
    </AuthorizationBarWrapperStyled>
  );
};

export default AuthorizationBar;
