import { useRef, useState } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { border, layout, space } from 'styled-system';

import { theme } from 'styles/Theme';

import { MBox } from '.';
import { IconPause, IconPlay } from '../icons';
import { Text } from '../texts';

const VideoPlayerStyled = styled.video`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  ${space};
  ${layout};
  ${border};
`;

const VideoPlayerWrapperStyled = styled.div`
  position: relative;
`;

const ProgressBarContainerStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: ${theme.radii.md};
  width: 100%;
  height: 0.7rem;
  margin: 0 0.8rem;
`;

const ProgressBarStyled = styled.div`
  border-radius: ${theme.radii.md};
  background-color: ${theme.colors.white};
  height: 100%;
  width: 0.001rem;
`;

const VideoPlayer = ({ isPlayingVideo, src, ...props }) => {
  // video player
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState('0px');

  const timeId = window.setInterval(() => {
    setCurrentTime(videoRef.current?.currentTime);
    setProgress((videoRef.current?.currentTime / videoTime) * 100);
    clearInterval(timeId);
  }, 1000);

  const videoHandler = control => {
    if (control === 'play') {
      videoRef.current.play();
      setPlaying(true);
      setVideoTime(videoRef.current.duration);
    } else if (control === 'pause') {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  return (
    <VideoPlayerWrapperStyled isPlayingVideo={isPlaying}>
      <VideoPlayerStyled ref={videoRef} src={src} {...props} />
      {!isPlaying ? (
        <MBox
          position="absolute"
          top="0"
          right="0"
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <button
            aria-label="Lire la vidéo"
            type="button"
            onClick={() => videoHandler('play')}
          >
            <IconPlay size="100" />
          </button>
        </MBox>
      ) : (
        <MBox
          display="flex"
          alignItems="center"
          position="absolute"
          bottom="2.6rem"
          width="100%"
          px="1.4rem"
        >
          <button
            aria-label="Mettre la vidéo en pause"
            type="button"
            onClick={() => videoHandler('pause')}
          >
            <IconPause />
          </button>
          <ProgressBarContainerStyled>
            <ProgressBarStyled style={{ width: `${progress}px` }} />
          </ProgressBarContainerStyled>
          <Text type="subtitle2" color="white">
            {`${Math.floor(currentTime / 60)}:${`0${Math.floor(
              currentTime % 60,
            )}`.slice(-2)}`}
          </Text>
        </MBox>
      )}
    </VideoPlayerWrapperStyled>
  );
};

VideoPlayer.propTypes = {
  src: propTypes.string.isRequired,
  isPlayingVideo: propTypes.func,
};

export default VideoPlayer;
