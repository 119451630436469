import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, generatePath } from 'react-router-dom';

import { selectIsCreator } from 'ducks/user';

import { dialogCreatorListValues } from 'constants/dialogListTypes';
import { routes } from 'constants/routes';

const MessengerRedirect = () => {
  const isCreator = useSelector(selectIsCreator);
  if (isCreator) {
    return (
      <Redirect
        to={generatePath(routes.MESSAGES_CREATOR, {
          tab: dialogCreatorListValues.ALL,
        })}
      />
    );
  }
  return <Redirect to={routes.MESSAGES_FAN} />;
};

export default MessengerRedirect;
