export const initialState = {
  error: false,
  loading: false,
  file: null,
  previewUrl: null,
  mediaId: null,
  mediaType: null,
  mediaMessages: [],
  src: null,
  hasMore: true,
  page: 1,
  mediaDetails: null,
  isMediaLoadingStarted: false,
  isMediaObjectUploaded: false,
  debugData: null,
  debugDataSecond: null,
  debugDataThird: null,
  playingVoiceMessageId: null,
  isFileLoading: false,
  audioPlayingNow: null,
};
