import { Link } from 'react-router-dom';

import { routes } from 'constants/routes';

import { MButton } from 'components/buttons';
import { Icon404 } from 'components/icons';
import { EmptyMainLayout, MLayout } from 'components/layouts';
import { Text } from 'components/texts';

import { VisuallyHidden } from 'styles/MixinStyle';

const Page404 = () => {
  return (
    <MLayout
      hasBackButton={false}
      hasNavigation
      ariaLabelledby="page404"
      isCenteredWithHeader
      main={
        <>
          <VisuallyHidden as="h1" id="page404">
            Page d&apos;erreur 404
          </VisuallyHidden>
          <EmptyMainLayout
            title="Page non trouvée"
            subtitle={
              <Text as="span" type="label1">
                Erreur 404
              </Text>
            }
            icon={<Icon404 />}
            my="auto"
          >
            <MButton
              as={Link}
              type={null}
              to={routes.MESSAGES}
              display="inline-block"
              primary
            >
              Retourner aux messages
            </MButton>
          </EmptyMainLayout>
        </>
      }
    />
  );
};

export default Page404;
