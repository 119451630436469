import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS, fr } from 'date-fns/locale';
import isNaN from 'lodash/isNaN';

import translate from 'tools/translate';

export const subscriptionsPageFormat = date =>
  format(new Date(date), 'dd.MM.yyyy');

export const mySalesModalFormat = (date, locale) =>
  formatInTimeZone(
    new Date(date),
    'UTC',
    `dd MMMM yyyy '${translate('at')}' HH'h'mm`,
    {
      locale: locale === 'fr' ? fr : enUS,
    },
  );

export const revenueFormat = date => {
  if (!date) return null;
  if (date instanceof Date && !isNaN(date.getTime())) {
    return format(date, 'yyyy-MM-dd');
  }
  const dateObj = new Date(date);
  if (dateObj instanceof Date && !isNaN(dateObj.getTime())) {
    return format(dateObj, 'yyyy-MM-dd');
  }
  return null;
};

export const revenueDetailFormat = date =>
  formatInTimeZone(date, 'UTC', 'dd/MM/yyyy');

export const revenuePaidDateFormat = date => {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yyyy');
};

export const revenuePaidTimeFormat = date => {
  if (!date) return '';
  return format(new Date(date), 'HH:mm').replace(':', 'h');
};

export const defaultDateFormat = date => format(new Date(date), 'dd.MM.yyyy');

export const paymentMethodFormat = date => {
  return format(date, 'MM/yy');
};
