import { useSelector } from 'react-redux';

import {
  selectDialogsSecondCollection,
  selectHasMoreSecondDialogs,
} from 'ducks/dialog';
import propTypes from 'prop-types';
import styled from 'styled-components';

import InfiniteScroller from 'components/scroller/InfiniteScroller';

import { theme } from 'styles/Theme';

import { MBox } from '../../common';
import { Text } from '../../texts';
import BestFansOfMounthCard from './BestFansOfMounthCard';

const NewMessageCardContentStyled = styled.section`
  ${props =>
    props.multipleFans &&
    `
    article:last-child {
      border-bottom: none;
    }
  `};
`;

const BestFansOfMounthList = ({ loadMoreDialogs, title }) => {
  const hasMoreDialogs = useSelector(selectHasMoreSecondDialogs);
  const dialogsCollection = useSelector(selectDialogsSecondCollection);

  return (
    <>
      <MBox
        bg="grayCloud"
        py="xxs"
        px="primaryMd"
        mt="xs"
        mb="xxs"
        mx={`-${theme.space.primaryMd}`}
      >
        <Text type="subtitle2" as="h3">
          {title}
        </Text>
      </MBox>
      <NewMessageCardContentStyled>
        <InfiniteScroller loadMore={loadMoreDialogs} hasMore={hasMoreDialogs}>
          {dialogsCollection.map(item => (
            <BestFansOfMounthCard item={item} key={item.id} />
          ))}
        </InfiniteScroller>
      </NewMessageCardContentStyled>
    </>
  );
};

BestFansOfMounthList.propTypes = {
  title: propTypes.string,
  loadMoreDialogs: propTypes.func,
};

export default BestFansOfMounthList;
