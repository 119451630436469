import { useMemo } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { grid, space, variant } from 'styled-system';

import { Picture } from 'components/common';
import {
  IconAllFans,
  IconBoost,
  IconCheckAvatar,
  IconCoins,
} from 'components/icons';

import {
  AvatarEmptyPersonName,
  AvatarWithoutPhoto,
  GradientText,
  VisuallyHidden,
} from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const AvatarVariant = () =>
  variant({
    prop: 'type',
    variants: {
      xxs: {
        size: '3.2rem',
      },
      xs: {
        size: '4.2rem',
      },
      // sm - default type
      sm: {
        size: '4.8rem',
      },
      md: {
        size: '6.4rem',
      },
      xl: {
        size: '8rem',
      },
    },
  });

const AvatarStyled = styled.div`
  position: relative;
  flex-shrink: 0;
  ${props => AvatarVariant(props)};
  ${space};
  ${grid};
`;

const AvatarUserStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${theme.radii.circle};
  position: relative;
  ${props =>
    (props.withoutPhoto || !props.$src) &&
    !props.allFans &&
    !props.boostFans &&
    !props.bestFans &&
    AvatarWithoutPhoto};
  ${props =>
    (props.allFans || props.boostFans || props.bestFans) &&
    `background: ${gradients.secondary};`};
`;

const AvatarImgStyled = styled(Picture)`
  object-fit: cover;
  position: absolute;
  top: 0;
  ${props => AvatarVariant(props)};
`;

const AvatarEmptyNameStyled = styled.span`
  ${GradientText(`${gradients.secondary}`)};
  ${props => {
    const personNameSize = () => {
      if (props.type === 'xxs') {
        return '1.2rem';
      }
      if (props.type === 'xs') {
        return '1.4rem';
      }
      if (props.type === 'md') {
        return '2rem';
      }
      if (props.type === 'xl') {
        return '2.4rem';
      }

      return '1.6rem';
    };
    return AvatarEmptyPersonName(`${personNameSize()}`);
  }};
`;

const Avatar = ({
  withoutPhoto,
  isChecked,
  $src,
  $srcSetAvif,
  $srcSetWebp,
  allFans,
  boostFans,
  bestFans,
  personName,
  type,
  uniqueIdCheckedIcon,
  ...props
}) => {
  const avatarIconSize = useMemo(() => {
    if (type === 'xxs') {
      return '14';
    }
    if (type === 'xs') {
      return '16';
    }
    if (type === 'md') {
      return '22';
    }
    if (type === 'xl') {
      return '28';
    }

    return '16';
  }, [type]);

  const avatarIconPositionTop = useMemo(() => {
    if (type === 'xxs') {
      return 'calc(100% - 1rem)';
    }
    if (type === 'md') {
      return 'calc(100% - 2.2rem)';
    }
    if (type === 'xl') {
      return 'calc(100% - 2.6rem)';
    }
    return 'calc(100% - 1.6rem)';
  }, [type]);

  return (
    <AvatarStyled
      {...props}
      withoutPhoto={withoutPhoto}
      $src={$src}
      type={type}
      allFans={allFans}
      boostFans={boostFans}
      bestFans={bestFans}
    >
      <AvatarUserStyled
        withoutPhoto={withoutPhoto}
        allFans={allFans}
        boostFans={boostFans}
        bestFans={bestFans}
        $src={$src}
      >
        {withoutPhoto && (
          <AvatarEmptyNameStyled type={type}>
            {personName}
            <VisuallyHidden>avatar sans photo</VisuallyHidden>
          </AvatarEmptyNameStyled>
        )}

        {allFans && (
          <>
            <IconAllFans size="50%" />
            <VisuallyHidden>avatar tous les fans</VisuallyHidden>
          </>
        )}
        {boostFans && (
          <>
            <IconBoost size="50%" />
            <VisuallyHidden>avatar booste les fans</VisuallyHidden>
          </>
        )}
        {bestFans && (
          <>
            <IconCoins size="60%" palette={theme.colors.white} />
            <VisuallyHidden>avatar meilleurs fans</VisuallyHidden>
          </>
        )}
        {!withoutPhoto &&
          !allFans &&
          !boostFans &&
          !bestFans &&
          ($src ? (
            <AvatarImgStyled
              src={$src}
              srcSetAvif={$srcSetAvif}
              srcSetWebp={$srcSetWebp}
              alt="Avatar de l'utilisateur"
            />
          ) : (
            <AvatarEmptyNameStyled type={type}>
              {personName}
              <VisuallyHidden>avatar sans photo</VisuallyHidden>
            </AvatarEmptyNameStyled>
          ))}
      </AvatarUserStyled>

      {isChecked && (
        <>
          <IconCheckAvatar
            position="absolute"
            size={avatarIconSize}
            top={avatarIconPositionTop}
            right="-0.2rem"
            uniqueId={uniqueIdCheckedIcon}
          />
          <VisuallyHidden>le compte est certifié</VisuallyHidden>
        </>
      )}
    </AvatarStyled>
  );
};

Avatar.defaultProps = {
  type: 'sm',
  uniqueIdCheckedIcon: '',
};

Avatar.propTypes = {
  $src: propTypes.string,
  $srcSetAvif: propTypes.string,
  $srcSetWebp: propTypes.string,
  isChecked: propTypes.bool,
  size: propTypes.string,
  withoutPhoto: propTypes.bool,
  allFans: propTypes.bool,
  boostFans: propTypes.bool,
  bestFans: propTypes.bool,
  type: propTypes.string,
  personName: propTypes.string,
  uniqueIdCheckedIcon: propTypes.string,
};

export default Avatar;
