import propTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, gradients, theme } from 'styles/Theme';

const LandingLayoutStyled = styled.section`
  background: ${gradients.primary};
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  height: fill-available;
  /* stylelint-disable */
  @-moz-document url-prefix() {
    height: 100vh;
  }
`;

const LandingLayoutMainStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: fill-available;
  min-height: 100svh;
  padding: ${theme.space.defaultSpaceY};
  margin: 0 auto;
  max-width: ${breakpoints.xxl};
  width: 100%;
  @-moz-document url-prefix() {
    height: 100%;
  }
`;

const LandingLayout = ({ ariaLabelledby, children, ...props }) => {
  return (
    <LandingLayoutStyled {...props} aria-labelledby={ariaLabelledby}>
      <LandingLayoutMainStyled>{children}</LandingLayoutMainStyled>
    </LandingLayoutStyled>
  );
};

LandingLayout.propTypes = {
  children: propTypes.node.isRequired,
  ariaLabelledby: propTypes.string.isRequired,
};

export default LandingLayout;
