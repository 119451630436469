import { useCallback, useState } from 'react';
import { Range } from 'react-range';

import useClickOutside from 'hooks/useClickOutside';
import propTypes from 'prop-types';
import styled from 'styled-components';

import noop from 'tools/noop';
import { formatPrice } from 'tools/stringFormatter';

import { Heading3, Text } from 'components/texts';

import { MRangeBaseStyled } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const MRangeBoostStyled = styled.div`
  display: grid;
  grid-template-columns: minmax(1.8rem, 1fr) minmax(7rem, auto);
  grid-column-gap: ${theme.space.xs};
  align-items: center;
  line-height: ${theme.lineHeights.lg};
  max-width: ${breakpoints.md};
  width: 93%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.space.secondaryMd};
  position: relative;
  z-index: 101;
`;

const MRangeWrapperStyled = styled.div`
  ${MRangeBaseStyled()};
  .range-track {
    position: relative;
    ${props =>
      (props.guaranteedResponsePrice &&
        props.videoResponsePrice &&
        `background: ${gradients.boost};`) ||
      (props.guaranteedResponsePrice && `background: ${gradients.boost3};`) ||
      (props.videoResponsePrice && `background: ${gradients.boost4};`) ||
      `background: ${gradients.boost2};`};
    border-radius: ${theme.radii.mdSecondary};
    .range-mark {
      &:nth-child(1) {
        background: ${theme.colors.level1};
      }
      &:nth-child(2) {
        background: ${theme.colors.level2};
      }
      &:nth-child(3) {
        background: ${theme.colors.level3};
      }
      ${props =>
        !props.guaranteedResponsePrice &&
        props.videoResponsePrice &&
        `&:nth-child(4) {
          background: ${gradients.secondary};
        }`};
      ${props =>
        !props.videoResponsePrice &&
        props.guaranteedResponsePrice &&
        `&:nth-child(4) {
          background: ${theme.colors.level4};
        }`};
      ${props =>
        props.guaranteedResponsePrice &&
        props.videoResponsePrice &&
        `&:nth-child(4) {
          background: ${theme.colors.level4};
        }
        &:nth-child(5) {
          background: ${gradients.secondary};
        }`};
    }
  }
  .range-thumb {
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      left: ${theme.sizes.sizeRenderThumb};
      background-blend-mode: overlay;
      background-color: rgba(255, 255, 255, 0.5);
    }
    ${props =>
      !props.guaranteedResponsePrice && props.videoResponsePrice
        ? `&[aria-valuenow='3'] {
      div {
        background: ${gradients.secondary};
        &:after {
          background: ${gradients.boostLabeledAfter};
        }
      }
    }`
        : `&[aria-valuenow='4'] {
      div {
        background: ${gradients.secondary};
        &:after {
          background: ${gradients.boostLabeledAfter};
        }
      }
    }`};
  }
`;

// labeled
const MRangeLabeledStyled = styled.div`
  position: absolute;
  bottom: 3.5rem;
  right: calc(-100% - ${theme.sizes.sizeRenderThumb} / 2);
  border-radius: ${theme.radii.sm};
  width: ${theme.sizes.boostNoticeWidth};
  z-index: 99;
  text-align: center;
  background: currentColor;
  padding: 0 ${theme.space.xxs};
  &:after {
    content: '';
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -0.6rem;
    left: 0;
    right: 0;
    margin: auto;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 4px;
    z-index: -1;
  }
`;

const MRangeBoost = ({
  value,
  onChange,
  ariaLabelledbyRenderThumb,
  guaranteedResponsePrice,
  videoResponsePrice,
  boostMessage,
}) => {
  const price = boostMessage[value]?.price;
  const [isLabeled, setLabeled] = useState(false);
  const maxValue =
    (guaranteedResponsePrice && videoResponsePrice && 4) ||
    ((guaranteedResponsePrice || videoResponsePrice) && 3) ||
    2;

  const blurHandler = useCallback(() => {
    setLabeled(false);
  }, []);
  const [, , listenForOutsideClicks] = useClickOutside(false, blurHandler);

  const onMouseDownWrapper = useCallback(
    func => e => {
      func(e);
      setLabeled(true);
    },
    [],
  );

  const onTouchStartWrapper = useCallback(
    func => e => {
      func(e);
      setLabeled(true);
    },
    [],
  );

  return (
    <MRangeBoostStyled>
      <MRangeWrapperStyled
        videoResponsePrice={videoResponsePrice}
        guaranteedResponsePrice={guaranteedResponsePrice}
        ref={listenForOutsideClicks}
      >
        <Range
          step={1}
          min={0}
          max={maxValue}
          values={value}
          onChange={onChange}
          renderTrack={({
            props: { onMouseDown, onTouchStart, style, ...rest },
            children,
          }) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onMouseDown={onMouseDownWrapper(onMouseDown)}
              onTouchStart={onTouchStartWrapper(onTouchStart)}
              className="range-track"
              style={{
                ...style,
                height: `${theme.sizes.heightRenderTrack}`,
                width: '100%',
                borderRadius: `${theme.radii.extraMd}`,
              }}
              {...rest}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              aria-labelledby={ariaLabelledbyRenderThumb}
              className="range-thumb"
              {...props}
              style={{
                position: 'relative',
                height: `${theme.sizes.sizeRenderThumb}`,
                width: `${theme.sizes.sizeRenderThumb}`,
                background: boostMessage[value].bgSlider,
                color: boostMessage[value].colorSlider,
                borderRadius: `${theme.radii.circle}`,
                outline: 'none',
              }}
            >
              {isLabeled && (
                <MRangeLabeledStyled>
                  <Text type="subtitle1" color="white">
                    {formatPrice(price)}
                  </Text>
                </MRangeLabeledStyled>
              )}
            </div>
          )}
          renderMark={({ props: { style, ...rest } }) => (
            <>
              <div
                className="range-mark"
                style={{
                  ...style,
                  height: `${theme.sizes.sizeRangeMark}`,
                  width: `${theme.sizes.sizeRangeMark}`,
                  borderRadius: `${theme.radii.circle}`,
                }}
                {...rest}
              />
            </>
          )}
        />
      </MRangeWrapperStyled>
      <Heading3
        $isGradient
        position="relative"
        textAlign="right"
        id="rangePriceBoost"
      >
        {formatPrice(price)}
      </Heading3>
    </MRangeBoostStyled>
  );
};

MRangeBoost.defaultProps = {
  onChange: noop,
  ariaLabelledbyRenderThumb: 'rangePriceBoost',
};

MRangeBoost.propTypes = {
  value: propTypes.arrayOf(propTypes.number),
  onChange: propTypes.func,
  step: propTypes.number,
  min: propTypes.number,
  max: propTypes.number,
  ariaLabelledbyRenderThumb: propTypes.string,
  guaranteedResponsePrice: propTypes.number,
  videoResponsePrice: propTypes.number,
};

export default MRangeBoost;
