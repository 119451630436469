const MLogo = () => {
  return (
    <svg
      viewBox="0 0 110 17"
      width="110"
      fill="none"
      role="img"
      aria-labelledby="title"
    >
      <title id="title">Logo Premium</title>
      <path
        fill="url(#logo1)"
        d="M0 15.862 2.52 0h7.74c1.98 0 3.405.51 4.275 1.53.885 1.005 1.2 2.332.945 3.982a5.558 5.558 0 0 1-.968 2.408c-.51.705-1.215 1.267-2.114 1.687-.886.405-1.988.608-3.308.608H5.76l-.9 5.647H0Zm6.345-9.337h2.677c.6 0 1.043-.113 1.328-.338.3-.24.48-.57.54-.99.075-.48-.008-.847-.248-1.102-.224-.27-.652-.405-1.282-.405H6.795l-.45 2.835Z"
      />
      <path
        fill="url(#logo2)"
        d="M15.623 15.862 18.143 0H25.5c2.1 0 3.6.465 4.5 1.395.915.915 1.237 2.22.968 3.915-.15.975-.51 1.83-1.08 2.565-.57.72-1.313 1.282-2.228 1.687-.915.405-1.965.608-3.15.608l.045-.653h1.192c.66 0 1.215.195 1.666.585.465.375.825.87 1.08 1.485l1.777 4.275h-5.063l-1.934-4.995c-.09-.225-.285-.382-.585-.472a2.459 2.459 0 0 0-.855-.158h-.45l-.9 5.625h-4.86Zm6.3-9.022h2.182c.69 0 1.215-.128 1.575-.383.36-.255.578-.63.652-1.125.09-.57-.022-.982-.337-1.237-.315-.27-.81-.405-1.485-.405h-2.093l-.495 3.15Z"
      />
      <path
        fill="url(#logo3)"
        d="M31.816 15.862 34.336 0h11.768l-.585 3.69h-7.133l-.36 2.272h6.593l-.585 3.69H37.44l-.405 2.52h7.133l-.585 3.69H31.816Z"
      />
      <path
        fill="url(#logo4)"
        d="M45.573 15.862 48.093 0h4.23l3.105 10.777h-.765L61.188 0h4.23l-2.52 15.862H58.51l1.35-8.572h1.08l-5.4 8.572h-2.677l-2.7-8.572h1.147l-1.35 8.572h-4.387Z"
      />
      <path
        fill="url(#logo5)"
        d="M65.566 15.862 68.086 0h4.86l-2.52 15.862h-4.86Z"
      />
      <path
        fill="url(#logo6)"
        d="M80.552 16.132c-1.5 0-2.783-.255-3.848-.765a4.767 4.767 0 0 1-2.317-2.407c-.465-1.095-.563-2.49-.293-4.185L75.49 0h4.86l-1.44 9.112c-.15.96-.067 1.703.248 2.228.33.51.967.765 1.912.765.855 0 1.5-.263 1.935-.788.45-.525.75-1.29.9-2.295L85.344 0h4.77l-1.462 9.18c-.24 1.515-.675 2.79-1.305 3.825a5.845 5.845 0 0 1-2.588 2.34c-1.11.525-2.512.787-4.207.787Z"
      />
      <path
        fill="url(#logo7)"
        d="M90.155 15.862 92.675 0h4.23l3.105 10.777h-.765L105.77 0H110l-2.52 15.862h-4.387l1.35-8.572h1.08l-5.4 8.572h-2.678l-2.7-8.572h1.147l-1.35 8.572h-4.387Z"
      />
      <defs>
        <linearGradient
          id="logo1"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
        <linearGradient
          id="logo2"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
        <linearGradient
          id="logo3"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
        <linearGradient
          id="logo4"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
        <linearGradient
          id="logo5"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
        <linearGradient
          id="logo6"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
        <linearGradient
          id="logo7"
          x1="-9.44"
          x2="136.012"
          y1="8.192"
          y2="17.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".526" stopColor="#2655F4" />
          <stop offset=".734" stopColor="#611FF3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MLogo;
