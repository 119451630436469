import propTypes from 'prop-types';
import styled from 'styled-components';

import { Heading4, Text } from 'components/texts';

import { breakpoints, gradients, theme } from 'styles/Theme';

const MessageNotificationStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.md};
  width: 100%;
  line-height: ${theme.lineHeights.md};
  border-radius: ${theme.radii.xsTertiary};
  background-image: ${gradients.primary};
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  padding: ${theme.space.primaryMd};
  color: ${theme.colors.black};
`;

const MessageNotification = ({ title, text, ...props }) => {
  return (
    <MessageNotificationStyled {...props}>
      <Heading4 as="h3" mb="sm">
        {title}
      </Heading4>
      <Text type="label2">{text}</Text>
    </MessageNotificationStyled>
  );
};

MessageNotification.propTypes = {
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
};

export default MessageNotification;
