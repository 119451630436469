const IconProfileMenu = () => {
  return (
    <svg width="20" viewBox="0 0 20 21" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M1.667 5.5c0-.46.373-.833.833-.833h15a.833.833 0 0 1 0 1.666h-15a.833.833 0 0 1-.833-.833Zm0 5c0-.46.373-.834.833-.834h15a.833.833 0 0 1 0 1.667h-15a.833.833 0 0 1-.833-.833Zm0 5c0-.46.373-.834.833-.834h10a.833.833 0 0 1 0 1.667h-10a.833.833 0 0 1-.833-.833Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconProfileMenu;
