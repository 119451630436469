import styled from 'styled-components';

import { breakpoints, gradients, theme } from 'styles/Theme';

// visually hidden
const VisuallyHiddenStyled = () => `
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  margin: -0.1rem;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

export const LineClampStyles = lineClamp => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp};
  line-clamp: ${lineClamp};
`;

export const TextOverflow = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LayoutBase = () => `
  padding-bottom: ${theme.space.defaultSpaceY};
  padding-left: ${theme.space.defaultSpaceX};
  padding-right: ${theme.space.defaultSpaceX};
  margin: 0 auto;
  max-width: ${breakpoints.md};
  width: 100%;
`;

// gradients
export const GradientText = gradient => `
  background: ${gradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  // for fix specific line bug in Safari
  border: ${theme.borderWidths.xs} solid transparent;
  background-repeat: no-repeat;
  margin: -1px;
`;

export const GradientBorderRadius = (gradient, borderRadius) => `
  border: ${theme.borderWidths.sm} solid transparent;
  background-clip: padding-box;
  background: linear-gradient(white, white) padding-box, ${gradient} border-box;
  border-radius: ${borderRadius};
`;

export const Transition = properties => `
  transition: ${properties} ease 0.1s;
`;

export const InputIconStyles = () => `
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

// disabled input styles
export const InputDisabledStyles = () => `
  background: ${theme.colors.grayCloud}; 
  border-color: ${theme.colors.grayLighter};
  color: ${theme.colors.grayMedium};
`;

export const InputBaseStyles = () => `
  width: 100%;
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.regular};
  border-radius: ${theme.radii.xs};
  padding-top: ${theme.space.primaryMd};
  padding-bottom: ${theme.space.primaryMd};
  padding-right: ${theme.space.xl};
  cursor: text;
  &::placeholder {
    color: ${theme.colors.grayLight};
    opacity: 1;
  }
`;

// for radio, checkbox components
export const InputFocusVisible = () => `
  &:focus {
    + span, + div {
      outline: 3px solid ${theme.colors.grayLight};
    }
  }

  &:focus:not(:focus-visible) {
    + span, + div {
      outline: none; 
    }
  }

  &:focus-visible {
    + span, + div {
      outline: 3px solid ${theme.colors.grayLight};
    }
  `;

// custom scroll bar
export const CustomScrollBar = () => `
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: ${theme.sizes.scrollbarWidth};
    height: ${theme.sizes.scrollbarHeight};
  }

  &::-webkit-scrollbar-track {
    background: ${theme.colors.grayLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.grayMedium};
  }
`;

export const MCardStyles = ({ twoColumns }) => `
  position: relative;
  display: grid;
  grid-template-columns: ${
    twoColumns
      ? 'max-content minmax(2rem, 1fr)'
      : `max-content minmax(2rem, 1fr) auto`
  };
  gap: ${theme.space.primaryMd};
  align-items: center;
  padding-top: ${theme.space.primaryMd};
  padding-bottom: ${theme.space.primaryMd};
  border-bottom: ${theme.borderWidths.xs} solid ${theme.colors.grayLighter};
`;

export const MCardLinkStyles = borderRadius => `
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
  }

  &:focus-visible {
    &:before {
      outline: 3px solid ${theme.colors.grayLight};
    }
  }
`;

// cards - payment, income
export const PaymentCardStyles = () => `
  border: ${theme.borderWidths.xs} solid ${theme.colors.grayLighter};
  border-radius: ${theme.radii.sm};
  overflow: hidden;

  & + & {
    margin-top: ${theme.space.sm};
  }
`;

export const PaymentTopCardStyles = () => `
  display: grid;
  grid-template-columns: max-content minmax(2rem, 1fr) auto;
  align-items: start;
  gap: ${theme.space.sm};
  padding: ${theme.space.primaryMd} ${theme.space.lg};
`;

export const PaymentBottomCardStyles = columns => `
  display: grid;
  grid-template-columns: repeat(${columns}, minmax(2rem, 1fr));
  background: ${gradients.primary};
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  gap: ${theme.space.md};
  padding: ${theme.space.md} ${theme.space.lg};
  border-top: ${theme.borderWidths.xs} solid ${theme.colors.grayLighter};
`;

export const PaymentCardNameStyles = () => `
  color: ${theme.colors.black};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.medium};
  line-height: ${theme.lineHeights.md};
`;

// avatar
export const AvatarEmptyPersonName = personName => `
  position: relative;
  z-index: 1;
  white-space: nowrap;
  font-size: 0;
  width: 100%;
  text-align: center;
  &:first-letter {
    font-weight: ${theme.fontWeights.semiBold};
    font-size: ${personName};
  }
`;

export const AvatarWithoutPhoto = () => `
  border: ${theme.borderWidths.sm} solid transparent;
  background-clip: padding-box;
  background: linear-gradient(white, white) padding-box, ${gradients.secondary} border-box;
  border-radius: ${theme.radii.circle};
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.6);
  &:after {
    content: '';
    overflow: hidden;
    background: ${gradients.primary};
    border-radius: inherit;
    position: absolute;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
`;

// for modals base
export const BaseModalStyled = () => `
  max-width: ${breakpoints.md}; 
  border-top-left-radius: ${theme.radii.mdSecondary};
  border-top-right-radius: ${theme.radii.mdSecondary};
  background-color: ${theme.colors.white};
  text-align: center;
`;

export const ModalOverlayStyled = () => `
  align-items: flex-end;
  z-index: 99998;
  transition: all 0.3s ease-in-out;
  background-color: ${theme.colors.overlayBg};
`;

// MRange base style
export const MRangeBaseStyled = () => `
  width: 93%;
  margin-left: 5%;
  position: relative;

  .range-thumb {
    &:focus-visible {
      outline: 3px solid ${theme.colors.grayLight} !important;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 160%;
      height: 160%;
      transform: translate(-50%, -50%);
    }
  }
`;

// button disabled
export const ButtonDisabledStyled = () => `
  opacity: 0.3;
  pointer-events: none;
`;

// infinite custom scroller
export const InfiniteScrollerStyled = () => `
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  padding-right: ${theme.space.primaryMd};
`;

export const ErrorNotification = {
  backgroundImage: gradients.error,
  borderRadius: theme.radii.xs,
  backgroundBlendMode: 'overlay',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: theme.space.primarySm,
};

export const VisuallyHidden = styled.span`
  ${VisuallyHiddenStyled};
`;
