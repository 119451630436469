import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { theme } from 'styles/Theme';

const IconPhotoMedia2Styled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconPhotoMedia2 = ({ palette, size, ...props }) => {
  return (
    <IconPhotoMedia2Styled
      width={size}
      viewBox="0 0 16 16"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.372.667H9.628a2 2 0 0 1 2 1.441l.004.015c.031.092.038.111.044.126a.666.666 0 0 0 .578.416c.015.001.038.002.142.002h.037c.21 0 .365 0 .497.013a2.667 2.667 0 0 1 2.39 2.39c.013.132.013.28.013.48V10.828c0 .536 0 .98-.03 1.34-.03.375-.096.72-.26 1.043-.256.501-.664.91-1.166 1.165-.323.165-.668.23-1.043.261-.36.03-.803.03-1.34.03H4.506c-.537 0-.98 0-1.34-.03-.375-.03-.72-.096-1.043-.261a2.666 2.666 0 0 1-1.166-1.165c-.165-.324-.23-.668-.26-1.043-.03-.36-.03-.804-.03-1.34V5.55c0-.2 0-.348.013-.48a2.667 2.667 0 0 1 2.39-2.39c.132-.013.287-.013.496-.013h.038c.104 0 .127 0 .142-.002a.667.667 0 0 0 .578-.416c.006-.015.013-.034.044-.126l.004-.015c.024-.07.044-.13.067-.187A2 2 0 0 1 6.173.67c.061-.004.125-.004.199-.004ZM6.387 2l-.133.001a.667.667 0 0 0-.578.417 2.775 2.775 0 0 0-.044.126l-.005.015a2 2 0 0 1-1.801 1.437C3.767 4 3.7 4 3.622 4h-.017c-.264 0-.345 0-.402.006a1.333 1.333 0 0 0-1.196 1.196A4.697 4.697 0 0 0 2 5.585V10.8c0 .571 0 .96.025 1.26.024.292.067.441.12.545.128.251.332.455.583.583.104.053.254.096.546.12.3.025.688.025 1.26.025h6.933c.57 0 .959 0 1.259-.025.292-.024.442-.067.546-.12.25-.128.455-.332.583-.583.053-.104.096-.253.12-.546.024-.3.025-.688.025-1.259V5.585c0-.25 0-.326-.007-.383a1.333 1.333 0 0 0-1.195-1.196A5.107 5.107 0 0 0 12.396 4h-.017c-.078 0-.145 0-.206-.004a2 2 0 0 1-1.734-1.25c-.023-.057-.043-.117-.067-.187l-.005-.015a2.766 2.766 0 0 0-.043-.126.667.667 0 0 0-.578-.417L9.613 2H6.387ZM8 6.333a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3.333 2a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0Z"
        fill={palette}
      />
    </IconPhotoMedia2Styled>
  );
};

IconPhotoMedia2.defaultProps = {
  size: '16',
  palette: theme.colors.white,
};

IconPhotoMedia2.propTypes = {
  size: propTypes.string,
  palette: propTypes.string,
};

export default IconPhotoMedia2;
