import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

const IconBoostStyled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconBoost = ({ size, ...props }) => {
  return (
    <IconBoostStyled
      width={size}
      viewBox="0 0 23 20"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.368.07a1 1 0 0 1 .623 1.062L16.142 7.5h3.838c.22 0 .45 0 .639.017.185.017.524.062.826.296a1.5 1.5 0 0 1 .582 1.131c.014.382-.145.683-.24.845-.095.163-.228.35-.357.53l-6.616 9.262a1 1 0 0 1-1.805-.713l.849-6.368H10.02c-.22 0-.45 0-.638-.017-.186-.017-.525-.062-.827-.296a1.5 1.5 0 0 1-.582-1.131c-.015-.382.145-.683.24-.845.095-.163.228-.35.357-.53l.02-.027L15.186.419A1 1 0 0 1 16.368.07ZM2 4.5a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM0 10a1 1 0 0 1 1-1h4.5a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Zm1.5 5.5a1 1 0 0 1 1-1H8a1 1 0 1 1 0 2H2.5a1 1 0 0 1-1-1Z"
        fill="#fff"
      />
    </IconBoostStyled>
  );
};

IconBoost.defaultProps = {
  size: '23',
};

IconBoost.propTypes = {
  size: propTypes.string,
};

export default IconBoost;
