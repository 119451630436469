import propTypes from 'prop-types';

import { IconWarning } from 'components/icons';
import { Text } from 'components/texts';

const MessageStatusText = ({ answerUntil, refunded, video, audio }) => {
  return (
    <Text
      type="body3"
      $isGradient={!refunded}
      color={refunded ? 'error' : null}
      display="flex"
      alignItems="center"
    >
      <IconWarning isGradient={!refunded} mr="xs" />
      {answerUntil &&
        `Réponse ${video ? 'video' : ''} ${audio ? 'audio' : ''} requise`}
      {refunded && 'Le fan a été remboursé'}
    </Text>
  );
};

MessageStatusText.propTypes = {
  answerUntil: propTypes.bool,
  refunded: propTypes.bool,
  audio: propTypes.bool,
  video: propTypes.bool,
};

export default MessageStatusText;
