import { Link } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { MBox } from 'components/common';
import { IconArrow } from 'components/icons';
import { Text } from 'components/texts';

import { LineClampStyles } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const WarningNotificationStyled = styled.div`
  background: ${gradients.error};
  color: ${theme.colors.white};
  padding: ${theme.space.sm} ${theme.space.primaryMd};
  line-height: ${theme.lineHeights.sm};
  width: 100%;
  ${LineClampStyles(2)};
`;

const WarningNotification = ({
  isNotificationLink,
  innerRef,
  text,
  ...props
}) => {
  return (
    <WarningNotificationStyled
      aria-live="polite"
      role="alert"
      to={isNotificationLink && '/'}
      as={isNotificationLink ? Link : 'div'}
      ref={innerRef}
      {...props}
    >
      <MBox
        maxWidth={breakpoints.md}
        width="100%"
        mx="auto"
        display="flex"
        alignItems={isNotificationLink && 'center'}
        justifyContent="center"
      >
        <Text type="label3" as="span" mr={isNotificationLink && 'sm'}>
          {text}
        </Text>
        {isNotificationLink && <IconArrow />}
      </MBox>
    </WarningNotificationStyled>
  );
};

WarningNotification.propTypes = {
  text: propTypes.string.isRequired,
  isNotificationLink: propTypes.bool,
  innerRef: propTypes.any,
};

export default WarningNotification;
