import propTypes from 'prop-types';

const IconSearchActive = ({ uniqueId }) => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath={`url(#search${uniqueId})`}>
        <path
          fill={`url(#search2${uniqueId})`}
          fillRule="evenodd"
          d="M8.203 1.8a6.3 6.3 0 1 0 0 12.6 6.3 6.3 0 0 0 0-12.6Zm-8.1 6.3a8.1 8.1 0 1 1 14.429 5.056l3.308 3.308a.9.9 0 0 1-1.273 1.272l-3.308-3.307A8.1 8.1 0 0 1 .103 8.1Zm8.1-4.8a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          id={`search2${uniqueId}`}
          x1="18.103"
          x2="-.409"
          y1="18"
          y2="17.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id={`search${uniqueId}`}>
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconSearchActive.defaultProps = {
  uniqueId: '',
};

IconSearchActive.propTypes = {
  uniqueId: propTypes.string,
};

export default IconSearchActive;
