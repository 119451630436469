import { PaymentElement } from '@stripe/react-stripe-js';

import { AvatarUpload } from 'components/avatar';
import {
  CategoryRadio,
  InputSearch,
  LabelSwitch,
  MDatePicker,
  MInput,
  MRadioPrimary,
  MRadioSecondary,
  MSelect,
  MSwitch,
  MTextarea,
} from 'components/forms';
import PhoneSelect from 'components/forms/PhoneSelect';

import FormItem from './ItemFormWrapper';

export const MInputForm = FormItem(MInput);

export const MRadioPrimaryForm = FormItem(MRadioPrimary);

export const MTextareaForm = FormItem(MTextarea);

export const LabelSwitchForm = FormItem(LabelSwitch);

export const PhoneSelectForm = FormItem(PhoneSelect);

export const CategoryRadioForm = FormItem(CategoryRadio);

export const MSwitchForm = FormItem(MSwitch);

export const MRadioSecondaryForm = FormItem(MRadioSecondary);

export const AvatarUploadForm = FormItem(AvatarUpload);

export const InputSearchForm = FormItem(InputSearch);

export const PaymentElementForm = FormItem(PaymentElement);

export const MSelectForm = FormItem(MSelect);

export const MDatePickerForm = FormItem(MDatePicker);
