import { all, call, put, takeLatest } from 'redux-saga/effects';

import { MAX_BANK_ACCOUNTS_PER_PAGE } from 'constants/numericConstants';

import * as services from './services';
import {
  createBankAccountFailure,
  createBankAccountRequest,
  createBankAccountSuccess,
  getBankAccountFailure,
  getBankAccountRequest,
  getBankAccountSuccess,
  getBankAccountsCollectionFailure,
  getBankAccountsCollectionRequest,
  getBankAccountsCollectionSuccess,
  incrementBankAccountsPage,
  patchBankAccountFailure,
  patchBankAccountRequest,
  patchBankAccountSuccess,
} from './slice';

function* getBankAccountsCollectionSaga({ payload: { page } }) {
  try {
    const bankAccounts = yield call(services.getBankAccountsCollection, page);
    yield put(getBankAccountsCollectionSuccess(bankAccounts));
    if (bankAccounts.length === MAX_BANK_ACCOUNTS_PER_PAGE) {
      yield put(incrementBankAccountsPage());
    }
  } catch (error) {
    yield put(getBankAccountsCollectionFailure());
  }
}

function* getBankAccountSaga({ payload: { id } }) {
  try {
    const bankAccount = yield call(services.getBankAccount, id);
    yield put(getBankAccountSuccess(bankAccount));
  } catch (error) {
    yield put(getBankAccountFailure());
  }
}

function* createBankAccountSaga({ payload: { body, onSuccess } }) {
  try {
    yield call(services.createBankAccount, body);
    yield put(createBankAccountSuccess());
    yield call(onSuccess);
  } catch (error) {
    yield put(createBankAccountFailure());
  }
}

function* patchBankAccountSaga({ payload: { id, body, onSuccess } }) {
  try {
    const patchedBankAccount = yield call(services.patchBankAccount, {
      id,
      body,
    });
    yield put(patchBankAccountSuccess(patchedBankAccount));
    yield call(onSuccess);
  } catch (error) {
    yield put(patchBankAccountFailure());
  }
}

export default function* watchBankAccounts() {
  yield all([
    takeLatest(getBankAccountsCollectionRequest, getBankAccountsCollectionSaga),
    takeLatest(getBankAccountRequest, getBankAccountSaga),
    takeLatest(createBankAccountRequest, createBankAccountSaga),
    takeLatest(patchBankAccountRequest, patchBankAccountSaga),
  ]);
}
