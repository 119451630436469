import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import {
  getServerInfoFailure,
  getServerInfoRequest,
  getServerInfoSuccess,
} from './slice';

function* getServerInfoSaga() {
  try {
    const info = yield call(services.getServerInfo);
    yield put(getServerInfoSuccess(info));
  } catch (error) {
    yield put(getServerInfoFailure());
  }
}

export default function* watchServer() {
  yield all([takeLatest(getServerInfoRequest, getServerInfoSaga)]);
}
