const IconInputSearch = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#iconInputSearch)">
        <path
          fill="#999999"
          fillRule="evenodd"
          d="M9.08325 2.66683C5.53942 2.66683 2.66659 5.53967 2.66659 9.0835C2.66659 12.6273 5.53942 15.5002 9.08325 15.5002C10.812 15.5002 12.3811 14.8165 13.5349 13.7048C13.5591 13.6734 13.5855 13.6432 13.6142 13.6145C13.643 13.5857 13.6731 13.5593 13.7045 13.5352C14.8162 12.3814 15.4999 10.8123 15.4999 9.0835C15.4999 5.53967 12.6271 2.66683 9.08325 2.66683ZM15.5292 14.233C16.6581 12.8217 17.3333 11.0314 17.3333 9.0835C17.3333 4.52715 13.6396 0.833496 9.08325 0.833496C4.5269 0.833496 0.833252 4.52715 0.833252 9.0835C0.833252 13.6398 4.5269 17.3335 9.08325 17.3335C11.0312 17.3335 12.8214 16.6584 14.2328 15.5294L17.6017 18.8983C17.9597 19.2563 18.5401 19.2563 18.8981 18.8983C19.2561 18.5404 19.2561 17.96 18.8981 17.602L15.5292 14.233ZM8.16658 4.50016C8.16658 3.9939 8.57699 3.5835 9.08325 3.5835C12.1208 3.5835 14.5833 6.04593 14.5833 9.0835C14.5833 9.58976 14.1728 10.0002 13.6666 10.0002C13.1603 10.0002 12.7499 9.58976 12.7499 9.0835C12.7499 7.05845 11.1083 5.41683 9.08325 5.41683C8.57699 5.41683 8.16658 5.00642 8.16658 4.50016Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="iconInputSearch">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconInputSearch;
