import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'categories';

export const categoriesSlice = createSlice({
  name,
  initialState,
  reducers: {
    getCategoriesRequest(state) {
      state.loading = true;
    },
    getCategoriesSuccess(state, { payload }) {
      state.categoriesCollection =
        payload.length !== 0
          ? [...state.categoriesCollection, ...payload]
          : state.categoriesCollection;
      state.loading = false;
    },
    getCategoriesFailure(state) {
      state.loading = false;
    },
    incrementCategoriesPage(state) {
      state.categoriesPage += 1;
    },
    resetCategoriesPage(state) {
      state.categoriesPage = 1;
    },
    resetCategories(state) {
      state.categoriesCollection = [];
    },
  },
});

export const {
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailure,
  incrementCategoriesPage,
  resetCategoriesPage,
  resetCategories,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
