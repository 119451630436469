import propTypes from 'prop-types';

import { theme } from 'styles/Theme';

const IconCheckInput = ({ isGradient, palette }) => {
  return (
    <svg width="16" viewBox="0 0 16 16" fill="none" aria-hidden="true">
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
        fill={isGradient ? 'url(#IconCheckInput)' : palette}
      />
      <defs>
        <linearGradient
          id="IconCheckInput"
          x1="16"
          y1="16"
          x2="-0.455093"
          y2="15.5174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

IconCheckInput.defaultProps = {
  isGradient: true,
  palette: theme.colors.white,
};

IconCheckInput.propTypes = {
  isGradient: propTypes.bool,
  palette: propTypes.string,
};

export default IconCheckInput;
