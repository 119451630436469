const IconKey = () => {
  return (
    <svg width="20" viewBox="0 0 20 21" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6.667 8a5.833 5.833 0 1 1 5.05 5.78 12.284 12.284 0 0 0-.429-.051l-.008.007c-.045.04-.103.099-.208.204l-4.259 4.259-.03.03c-.112.112-.252.253-.424.359-.15.091-.312.158-.482.2a2.324 2.324 0 0 1-.553.045H3.807c-.21 0-.415 0-.589-.014a1.682 1.682 0 0 1-.641-.168 1.667 1.667 0 0 1-.729-.728 1.685 1.685 0 0 1-.167-.641c-.014-.174-.014-.379-.014-.589v-1.517c0-.158-.001-.357.046-.553.04-.17.108-.333.2-.482.105-.172.246-.313.358-.424l.03-.03L6.56 9.428a7.178 7.178 0 0 0 .211-.216l-.003-.038a12.114 12.114 0 0 0-.05-.39A5.878 5.878 0 0 1 6.668 8ZM12.5 3.833a4.167 4.167 0 0 0-4.13 4.728l.003.015c.022.168.042.317.054.438.011.115.022.272 0 .433-.032.221-.087.383-.196.578-.115.207-.298.389-.449.538l-.043.043-4.26 4.26a5.076 5.076 0 0 0-.142.144c0 .001 0 .002-.002.003v.003c-.001.04-.002.094-.002.203v1.447a6.823 6.823 0 0 0 .01.491h.01c.098.009.233.01.48.01h1.448c.11 0 .164 0 .203-.003h.003l.002-.002a5.24 5.24 0 0 0 .146-.142l4.258-4.259.044-.043c.15-.15.331-.334.538-.45.195-.108.357-.163.577-.195.162-.023.318-.011.434 0 .12.011.27.031.438.054l.014.002a4.167 4.167 0 1 0 .562-8.296ZM11.667 8c0-.46.373-.833.833-.833h.008a.833.833 0 1 1 0 1.666H12.5A.833.833 0 0 1 11.667 8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconKey;
