import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { InputFocusVisible, TextOverflow } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const MRadioWrapperStyled = styled.div`
  height: ${theme.sizes.radioTertiaryHeight};
  display: flex;
  max-width: ${breakpoints.md};
  width: 100%;
  margin: 0 auto;
  ${space};
`;

const MRadioItemStyled = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.xs};
  line-height: ${theme.lineHeights.md};
  border: ${theme.borderWidths.xs} solid ${theme.colors.grayLighter};
  max-width: ${props => (props.isThreeItem ? '33.3%' : '50%')};
  width: 100%;
  ${props => (props.active ? 'border: none;' : null)};
  ${props =>
    props.active
      ? `color: ${theme.colors.white};
      background: ${gradients.primary};
      font-weight: ${theme.fontWeights.semiBold};`
      : `color: ${theme.colors.black};
      background: ${theme.colors.white};
      font-weight: ${theme.fontWeights.medium};`};

  &:first-child {
    border-radius: ${theme.radii.xs} 0 0 ${theme.radii.xs};
    border-right: none;
  }

  &:last-child {
    border-radius: 0 ${theme.radii.xs} ${theme.radii.xs} 0;
    border-left: none;
  }
`;

const MRadioInputStyled = styled.input`
  ${InputFocusVisible};
`;

const MRadioTextStyled = styled.span`
  padding: ${theme.space.xs};
  ${TextOverflow};
`;

const MRadioTertiary = ({
  name,
  items,
  value,
  onChange,
  isThreeItem,
  ariaLabelledby,
  ...props
}) => {
  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );

  return (
    <MRadioWrapperStyled {...props}>
      {items.map(item => (
        <MRadioItemStyled
          htmlFor={`${name}${item.id}`}
          key={item.text}
          active={value === item.value}
          isThreeItem={isThreeItem}
        >
          <MRadioInputStyled
            id={`${name}${item.id}`}
            type="radio"
            name={name}
            aria-labelledby={`${ariaLabelledby} ${ariaLabelledby}${item.id}`}
            value={item.value}
            onChange={changeHandler}
          />
          <MRadioTextStyled id={`${ariaLabelledby}${item.id}`}>
            {item.text}
          </MRadioTextStyled>
        </MRadioItemStyled>
      ))}
    </MRadioWrapperStyled>
  );
};

MRadioTertiary.defaultProps = {
  value: '',
  onChange: noop,
};

MRadioTertiary.propTypes = {
  name: propTypes.string.isRequired,
  ariaLabelledby: propTypes.string,
  items: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.string.isRequired,
      text: propTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  value: propTypes.string,
  onChange: propTypes.func,
  isThreeItem: propTypes.bool,
};

export default MRadioTertiary;
