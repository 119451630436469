import styled, { css } from 'styled-components';
import { color, layout, position, space, typography } from 'styled-system';

import { GradientText, LineClampStyles } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const HeadingStyled = css`
  font-weight: ${theme.fontWeights.semiBold};
  text-overflow: ${props => props.textOverflow};
  white-space: ${props => props.whiteSpace};
  word-break: ${props => props.wordBreak};
  ${props => props.lineClamp && LineClampStyles(props.lineClamp)};
  ${props => props.$isGradient && GradientText(`${gradients.primary}`)};
  ${space};
  ${typography};
  ${position};
  ${layout};
  ${color};
`;

export const Heading1 = styled.h1`
  font-size: ${theme.fontSizes.h1};
  ${HeadingStyled};
`;

export const Heading2 = styled.h2`
  font-size: ${theme.fontSizes.h2};
  ${HeadingStyled};
`;

export const Heading3 = styled.h3`
  font-size: ${theme.fontSizes.h3};
  ${HeadingStyled};
`;

export const Heading4 = styled.h4`
  font-size: ${theme.fontSizes.h4};
  ${HeadingStyled};
`;
