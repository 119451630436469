import axios from 'axios';
import {
  resetRequestErrors,
  setError400,
  setError500,
} from 'ducks/requestsErrors';
import { connectServerEvents } from 'ducks/serverEvents/slice';
import { logout } from 'ducks/user';
import humps from 'humps';
import qs from 'query-string';
import { store } from 'store';

import { apiRoutes } from 'constants/apiRoutes';
import { routes } from 'constants/routes';

import { formatRequestData, formatResponseData } from 'tools/formatQueryData';
import redirect from 'tools/redirect';
import timeout from 'tools/timeout';

import { API_BASE_URL } from '../config';

const tokenRefresh = {
  isPending: false,
  isUpdated: false,
};

const request = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    });
  },
});

function setJWTLocalStorage(response) {
  switch (true) {
    case new RegExp(`/login$|sso`).test(response.config.url):
    case new RegExp(`verify-email/set$`).test(response.config.url):
    case new RegExp(`/token/refresh$`).test(response.config.url): {
      {
        const { token, refresh_token: refreshToken } = response.data;
        if (token) {
          window.localStorage.setItem('access_token', token);
          store.dispatch(connectServerEvents());
        }
        if (refreshToken) {
          window.localStorage.setItem('refresh_token', refreshToken);
        }
      }
      return response;
    }
    case new RegExp(`/users$`).test(response.config.url): {
      {
        const { token, refresh_token: refreshToken } = response.data.token;
        if (token) {
          window.localStorage.setItem('access_token', token);
          store.dispatch(connectServerEvents());
        }
        if (refreshToken) {
          window.localStorage.setItem('refresh_token', refreshToken);
        }
      }
      return response;
    }
    default:
      return response;
  }
}

function setHeaders(config) {
  const configuration = config;

  if (window.localStorage.getItem('access_token')) {
    configuration.headers.common.Authorization = `Bearer ${window.localStorage.getItem(
      'access_token',
    )}`;
  }

  return configuration;
}

function responseHandler(response) {
  return humps.camelizeKeys(response.data, formatResponseData);
}

function requestMapper(config) {
  const decamelizedData = humps.decamelizeKeys(config.data, formatRequestData);
  delete config.data;
  config.data = decamelizedData;

  return config;
}

async function errorHandler({ response, config }) {
  const newError = {
    statusCode: response.status,
    detail: response,
    ...(response.message && { message: response.message }),
    ...(response.data.message && { message: response.data.message }),
    ...(response.data.detail && { detail: response.data.detail }),
    ...(response.data.violations && { violations: response.data.violations }),
    ...(config.data && { requestData: JSON.parse(config.data) }),
  };

  if (newError.statusCode === 401 && newError.message === 'Expired JWT Token') {
    try {
      window.localStorage.removeItem('access_token');
      tokenRefresh.isUpdated = false;
      if (!tokenRefresh.isPending) {
        tokenRefresh.isPending = true;
        await request.post(apiRoutes.AUTH.TOKEN, {
          refresh_token: window.localStorage.getItem('refresh_token'),
        });
        tokenRefresh.isUpdated = true;
        tokenRefresh.isPending = false;
      } else {
        while (tokenRefresh.isPending) {
          // eslint-disable-next-line no-await-in-loop
          await timeout(1);
        }
      }

      if (tokenRefresh.isUpdated) {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem(
          'access_token',
        )}`;
        return request.request(config);
      }
    } catch (error) {
      console.error(error);
      tokenRefresh.isPending = false;
      store.dispatch(logout());
    }
  }
  if (newError.statusCode === 401 && newError.message !== 'Expired JWT Token') {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
  }
  if (
    newError.statusCode === 401 &&
    newError.message === 'JWT Token not found'
  ) {
    redirect(routes.WELCOME);
  }
  if (
    newError.statusCode === 400 ||
    (newError.statusCode >= 402 && newError.statusCode <= 421) ||
    (newError.statusCode >= 423 && newError.statusCode <= 499)
  ) {
    store.dispatch(setError400());
  }
  if (newError.statusCode >= 500 && newError.statusCode <= 599) {
    store.dispatch(setError500());
  }
  if (newError.statusCode >= 99 && newError.statusCode <= 399) {
    store.dispatch(resetRequestErrors());
  }

  return Promise.reject(newError);
}

request.interceptors.request.use(requestMapper);

request.interceptors.request.use(setHeaders);

request.interceptors.response.use(setJWTLocalStorage);

request.interceptors.response.use(responseHandler, errorHandler);

export default request;
