const IconPlus = () => {
  return (
    <svg width="13" viewBox="0 0 13 12" fill="none" aria-hidden="true">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.167 5V1h-2v4h-4v2h4v4h2V7h4V5h-4Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconPlus;
