const IconIncome = () => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#iconIncome)">
        <path
          fill="#000"
          d="M2.25 9a.9.9 0 0 0-.9.9v7.2a.9.9 0 0 0 1.8 0V9.9a.9.9 0 0 0-.9-.9Zm4.5-9a.9.9 0 0 0-.9.9v16.2a.9.9 0 0 0 1.8 0V.9a.9.9 0 0 0-.9-.9Zm9 12.6a.9.9 0 0 0-.9.9v3.6a.9.9 0 1 0 1.8 0v-3.6a.9.9 0 0 0-.9-.9Zm-4.5-7.2a.9.9 0 0 0-.9.9v10.8a.9.9 0 1 0 1.8 0V6.3a.9.9 0 0 0-.9-.9Z"
        />
      </g>
      <defs>
        <clipPath id="iconIncome">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconIncome;
