import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import {
  getDiscussionsBySearchRequest,
  getDiscussionsFailure,
  getDiscussionsRequest,
  getDiscussionsSuccess,
  getNewDiscussionsSuccess,
  incrementDiscussionsPage,
} from './slice';

function* getDiscussionsSaga({ payload: { page } }) {
  try {
    const discussions = yield call(services.getDiscussions, { page });
    yield put(incrementDiscussionsPage());
    if (page === 1) {
      yield put(getNewDiscussionsSuccess(discussions));
    } else {
      yield put(getDiscussionsSuccess(discussions));
    }
  } catch (error) {
    yield put(getDiscussionsFailure());
  }
}

function* getDiscussionsBySearchSaga({ payload: { name, page } }) {
  try {
    const discussions = yield call(services.getDiscussionsBySearch, {
      name,
      page,
    });
    yield put(incrementDiscussionsPage());
    if (page === 1) {
      yield put(getNewDiscussionsSuccess(discussions));
    } else {
      yield put(getDiscussionsSuccess(discussions));
    }
  } catch (error) {
    yield put(getDiscussionsFailure());
  }
}

export default function* watchDiscussion() {
  yield all([
    takeLatest(getDiscussionsRequest, getDiscussionsSaga),
    takeLatest(getDiscussionsBySearchRequest, getDiscussionsBySearchSaga),
  ]);
}
