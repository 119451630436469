/* eslint-disable no-param-reassign */
import axios from 'axios';
import humps from 'humps';
import qs from 'query-string';

import { REST_COUNTRIES_BASE_URL } from '../config';

const restCountriesRequest = axios.create({
  baseURL: REST_COUNTRIES_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    });
  },
});

function restCountriesResponseHandler(response) {
  return humps.camelizeKeys(response.data);
}

function restCountriesRequestMapper(config) {
  const decamelizedData = humps.decamelizeKeys(config.data);
  delete config.data;
  config.data = decamelizedData;

  return config;
}

function restCountriesErrorHandler({ response }) {
  const newError = {
    statusCode: response.status,
    ...(response.message && { message: response.message }),
    ...(response.detail && { detail: response.detail }),
    ...(response.violations && { violations: response.violations }),
  };

  return Promise.reject(newError);
}

restCountriesRequest.interceptors.request.use(restCountriesRequestMapper);

restCountriesRequest.interceptors.response.use(
  restCountriesResponseHandler,
  restCountriesErrorHandler,
);

export default restCountriesRequest;
