export const selectFile = state => state.mediaObject.file;
export const selectPreviewUrl = state => state.mediaObject.previewUrl;
export const selectMediaId = state => state.mediaObject.mediaId;
export const selectMediaType = state => state.mediaObject.mediaType;
export const selectMediaMessages = state => state.mediaObject.mediaMessages;
export const selectMediaSrc = state => state.mediaObject.src;
export const selectMediaMessagesHasMore = state => state.mediaObject.hasMore;
export const selectMediaMessagesPage = state => state.mediaObject.page;
export const selectMediaDetails = state => state.mediaObject.mediaDetails;
export const selectMediaIsLoading = state => state.mediaObject.loading;
export const selectMediaIsError = state => state.mediaObject.error;
export const selectIsMediaLoadingStarted = state =>
  state.mediaObject.isMediaLoadingStarted;
export const selectIsMediaObjectUploaded = state =>
  state.mediaObject.isMediaObjectUploaded;
export const selectMedia = state => ({
  type: state.mediaObject.mediaType,
  id: state.mediaObject.mediaId,
  preview: state.mediaObject.previewUrl,
  blurredPreview: state.mediaObject.blurredPreviewUrl,
  mediaObject: state.mediaObject.src,
});
export const selectPlayingVoiceMessageId = state =>
  state.mediaObject.playingVoiceMessageId;
export const selectDebugData = state => state.mediaObject.debugData;
export const selectDebugData2 = state => state.mediaObject.debugDataSecond;
export const selectDebugData3 = state => state.mediaObject.debugDataThird;
export const selectDebugData4 = state => state.mediaObject.debugData4;
export const selectIsFileLoading = state => state.mediaObject.isFileLoading;
export const selectAudioPlayingNow = state => state.mediaObject.audioPlayingNow;
