const IconDownload = () => {
  return (
    <svg aria-hidden="true" width="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9.667c.46 0 .833.373.833.833v9.655l3.578-3.578a.833.833 0 1 1 1.178 1.179l-5 5a.833.833 0 0 1-1.178 0l-5-5a.833.833 0 0 1 1.178-1.179l3.578 3.578V10.5c0-.46.373-.833.833-.833ZM9.667 25.5c0-.46.373-.833.833-.833h15a.833.833 0 0 1 0 1.666h-15a.833.833 0 0 1-.833-.833Z"
        fill="#000"
      />
    </svg>
  );
};

export default IconDownload;
