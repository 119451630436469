import { Link } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';
import {
  color,
  position,
  space,
  system,
  typography,
  variant,
} from 'styled-system';

import { ButtonDisabledStyled, GradientText } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const TextVariant = () =>
  variant({
    prop: 'type',
    variants: {
      default: {
        fontSize: `${theme.fontSizes.md}`,
      },
      sm: {
        fontSize: `${theme.fontSizes.sm}`,
        textDecorationLine: 'underline',
        textDecorationColor: `${theme.colors.primary}`,
      },
      color: {
        fontSize: `${theme.fontSizes.xs}`,
        color: `${theme.colors.grayMedium}`,
        textDecorationLine: 'underline',
        textDecorationColor: `${theme.colors.grayMedium}`,
        lineHeight: `${theme.lineHeights.md}`,
      },
    },
  });

const LinkComponent = styled(Link)`
  font-weight: ${theme.fontWeights.medium};
  text-align: ${props => props.$textAlign};
  ${props =>
    props.$isGradient &&
    props.type !== 'color' &&
    GradientText(`${gradients.primary}`)};
  ${props => TextVariant(props)};
  ${space};
  ${typography};
  ${color};
  ${position};
  ${system({
    textDecoration: true,
  })};
  ${props => props.disabled && ButtonDisabledStyled};
`;

LinkComponent.defaultProps = {
  $isGradient: true,
  type: 'default',
};

LinkComponent.propTypes = {
  $isGradient: propTypes.bool,
  $textAlign: propTypes.string,
  type: propTypes.string,
};

export default LinkComponent;
