import propTypes from 'prop-types';

const IconYoutube = ({ palette, size }) => {
  return (
    <svg width={size} viewBox="0 0 20 15" fill="none" aria-hidden="true">
      <path
        fill={palette}
        fillRule="evenodd"
        d="M7.935 10.03V4.37l5.403 2.84-5.403 2.82ZM19.8 3.39s-.196-1.389-.795-2c-.76-.802-1.613-.806-2.004-.853-2.799-.204-6.997-.204-6.997-.204h-.008s-4.199 0-6.997.204c-.392.047-1.243.05-2.005.853-.599.612-.794 2-.794 2S0 5.02 0 6.65v1.528c0 1.63.2 3.26.2 3.26s.195 1.387.794 1.998c.762.803 1.761.778 2.206.861 1.6.155 6.8.203 6.8.203s4.203-.006 7.001-.21c.39-.047 1.243-.051 2.004-.854.6-.61.795-1.999.795-1.999s.2-1.63.2-3.26V6.65c0-1.63-.2-3.26-.2-3.26Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconYoutube.defaultProps = {
  palette: '#CD201F',
  size: '20',
};

IconYoutube.propTypes = {
  palette: propTypes.string,
  size: propTypes.string,
};

export default IconYoutube;
