import { useSelector } from 'react-redux';

import { selectIsCreator } from 'ducks/user';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { AuthorizationBar } from 'components/authorization';
import { ButtonGoBack } from 'components/buttons';
import { MBox } from 'components/common';
import { DesktopHeader, MobileNavigation } from 'components/navigation';
import { Heading1, LinkComponent, Text } from 'components/texts';

import { LayoutBase } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

import Header from './helpers/Header';

const MLayoutStyled = styled.section`
  @media (max-width: ${breakpoints.lg}) {
    ${props =>
      props.hasNavigation &&
      `padding-bottom: calc(${theme.space.defaultSpaceY} + ${theme.sizes.navMobileHeight})`};
  }

  @media (min-width: ${breakpoints.lg}) {
    ${props => props.hasNavigation && `padding-top: 0;`};
  }
  ${props =>
    props.hasBackButton || !props.isAuthorized
      ? `padding-top: ${theme.space.defaultSpaceX};`
      : `padding-top: ${theme.space.defaultSpaceY};`};
  ${props =>
    props.isCenteredWithHeader &&
    `min-height: 100vh; min-height: 100svh; display: grid; /* stylelint-disable-line */
    grid-template-rows: auto 1fr auto;
      & > main {
        display: flex;
        flex-direction: column;
      }
  `};
`;

const MHeaderStyled = styled(Header)``;

const MLayoutContent = styled.div`
  ${LayoutBase};
`;

const MLayout = ({
  hasBackButton,
  hasNavigation,
  ariaLabelledby,
  onGoBackButtonClick,
  linkTo,
  isAuthorized,
  isCenteredWithHeader,
  customPathToLogin,
  ...props
}) => {
  const isUserCreator = useSelector(selectIsCreator);
  return (
    <>
      <MLayoutStyled
        {...props}
        aria-labelledby={ariaLabelledby}
        hasBackButton={hasBackButton}
        hasNavigation={hasNavigation}
        isAuthorized={isAuthorized}
        isCenteredWithHeader={isCenteredWithHeader}
      >
        {!isAuthorized && (
          <AuthorizationBar customPathToLogin={customPathToLogin} />
        )}
        {(hasNavigation || hasBackButton) && (
          <MHeaderStyled>
            {hasNavigation && <DesktopHeader isCreator={isUserCreator} />}
            {hasBackButton && (
              <MBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb="md"
                as={MLayoutContent}
              >
                <ButtonGoBack onClick={onGoBackButtonClick} />
                {props.link && (
                  <LinkComponent $textAlign="right" to={linkTo}>
                    {props.link}
                  </LinkComponent>
                )}
              </MBox>
            )}
          </MHeaderStyled>
        )}
        <MLayoutContent as="main">
          {props.title && (
            <Heading1 id={ariaLabelledby}>{props.title}</Heading1>
          )}
          {props.subtitle && (
            <Text color="grayMedium" lineHeight="md">
              {props.subtitle}
            </Text>
          )}
          {props.main}
        </MLayoutContent>
      </MLayoutStyled>
      {hasNavigation && <MobileNavigation isCreator={isUserCreator} />}
    </>
  );
};

MLayout.defaultProps = {
  hasBackButton: true,
  isAuthorized: true,
};

MLayout.propTypes = {
  main: propTypes.node.isRequired,
  ariaLabelledby: propTypes.string.isRequired,
  link: propTypes.string,
  title: propTypes.string,
  subtitle: propTypes.string,
  hasBackButton: propTypes.bool,
  hasNavigation: propTypes.bool,
  onGoBackButtonClick: propTypes.func,
  linkTo: propTypes.string,
  isAuthorized: propTypes.bool,
  isCenteredWithHeader: propTypes.bool,
};

export default MLayout;
