import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { theme } from 'styles/Theme';

const IconTimerStyled = styled.svg`
  ${space};
`;

const IconTimer = ({ palette, ...props }) => {
  return (
    <IconTimerStyled
      width="17"
      viewBox="0 0 17 16"
      aria-hidden="true"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1.333c0-.368.299-.666.667-.666h2.666a.667.667 0 1 1 0 1.333h-.666v.701a6.334 6.334 0 1 1-1.334 0V2h-.666a.667.667 0 0 1-.667-.667Zm-2.081.924c.26.26.26.682 0 .942l-.5.5-.5.5a.667.667 0 1 1-.943-.942l.5-.5.5-.5c.26-.26.682-.26.943 0Zm8.162 0c.26-.26.683-.26.943 0l1 1a.667.667 0 1 1-.943.942l-1-1a.667.667 0 0 1 0-.942ZM8.501 4a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 1.667c.367 0 .666.298.666.666v2.29l1.343.805a.667.667 0 1 1-.686 1.144l-1.667-1A.667.667 0 0 1 7.833 9V6.333c0-.368.299-.666.667-.666Z"
        fill={palette}
      />
    </IconTimerStyled>
  );
};

IconTimer.defaultProps = {
  palette: theme.colors.black,
};

IconTimer.propTypes = {
  palette: propTypes.string,
};

export default IconTimer;
