import propTypes from 'prop-types';
import styled from 'styled-components';
import { layout, space } from 'styled-system';

import { theme } from 'styles/Theme';

const TextLabelStyled = styled.span`
  display: block;
  margin-bottom: ${theme.space.xs};
  font-size: ${theme.fontSizes.sm};
  line-height: ${theme.lineHeights.md};
  color: ${theme.colors.dark};
  ${layout};
  ${space};
`;

const TextLabel = ({ isRequired, children, ...props }) => {
  return (
    <TextLabelStyled {...props}>
      {children}
      {!isRequired && ' (optionnel)'}
    </TextLabelStyled>
  );
};

TextLabel.defaultProps = {
  isRequired: true,
};

TextLabel.propTypes = {
  children: propTypes.node.isRequired,
  isRequired: propTypes.bool,
};

export default TextLabel;
