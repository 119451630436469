import { Suspense, useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';

import lazyWithRetry from 'tools/lazyWithRetry';

import { LoadingSpinner, MBox } from 'components/common';
import { IconCheckInput } from 'components/icons';
import { Text, TextLabel } from 'components/texts';

import { InputIconStyles } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const Select = lazyWithRetry(() =>
  import('react-select' /* webpackChunkName: "reactSelect" */),
);

const MSelectCheckMarkStyled = styled.span`
  ${InputIconStyles};
  right: ${theme.space.xxxl};
`;

const MSelect = ({
  options,
  placeholder,
  idLabel,
  label,
  isRequired,
  stateStatus,
  errorText,
  ...props
}) => {
  const getIconStatus = useCallback(status => {
    switch (status) {
      case 'SUCCESS':
        return <IconCheckInput />;
      case 'ERROR':
        return null;
      default:
        return null;
    }
  }, []);

  const colourStyles = {
    control: (styles, { isFocused }) => {
      const style = {
        ...styles,
        backgroundColor: `${theme.colors.white}`,
        padding: `0 ${theme.space.primaryMd}`,
        height: `${theme.sizes.inputHeight}`,
        cursor: 'pointer',
        border:
          stateStatus === 'ERROR'
            ? `${theme.borderWidths.sm} solid ${theme.colors.error}`
            : `${theme.borderWidths.xs} solid ${theme.colors.grayLight}`,
        transition: '0.1s',
        boxShadow: 'none',
        outline: 'none',
        borderRadius: `${theme.radii.xs}`,
        ':hover': {
          borderWidth: `${theme.borderWidths.sm}`,
          border: `${theme.borderWidths.sm} solid transparent`,
          background: `linear-gradient(white, white) padding-box, ${gradients.primary} border-box`,
        },
      };
      if (isFocused) {
        style.background = `linear-gradient(white, white) padding-box, ${gradients.primary} border-box`;
        style.border = `${theme.borderWidths.sm} solid transparent`;
        style.borderWidth = `${theme.borderWidths.sm}`;
      }
      return style;
    },
    option: (styles, { isSelected }) => ({
      ...styles,
      fontWeight: `${theme.fontWeights.regular}`,
      color: isSelected ? `${theme.colors.white}` : `${theme.colors.black}`,
      background: isSelected ? `${gradients.primary}` : `${theme.colors.white}`,
      ':hover': {
        background: `${gradients.primary}`,
        color: `${theme.colors.white}`,
        cursor: 'pointer',
      },
      ':active': {
        background: `${gradients.primary}`,
        color: `${theme.colors.white}`,
      },
    }),
    menu: styles => ({
      ...styles,
      zIndex: 10,
    }),
    dropdownIndicator: (styles, { isFocused }) => {
      const style = {
        ...styles,
        transition: 'transform 0.3s ease-out',
        color: `${theme.colors.black}`,
        ':hover': {
          color: `${theme.colors.black}`,
        },
      };
      if (isFocused) {
        style.transform = 'rotate(180deg)';
      }
      return style;
    },
    indicatorsContainer: styles => ({
      ...styles,
      div: {
        padding: '0',
      },
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    clearIndicator: styles => ({ ...styles, display: 'none' }),
    valueContainer: styles => ({
      ...styles,
      padding: '0',
      fontWeight: `${theme.fontWeights.regular}`,
    }),
    input: styles => ({
      ...styles,
      padding: '0',
      border: 'none',
    }),
    placeholder: styles => ({
      ...styles,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: `${theme.colors.grayLight}`,
      fontSize: `${theme.fontSizes.md}`,
      fontWeight: `${theme.fontWeights.regular}`,
      lineHeight: `${theme.lineHeights.md}`,
    }),
  };

  return (
    <>
      <div>
        <TextLabel id="selectOption" isRequired={isRequired}>
          {label}
        </TextLabel>
        <MBox position="relative">
          <Suspense fallback={<LoadingSpinner position="relative" />}>
            <Select
              required={isRequired}
              options={options}
              placeholder={placeholder}
              styles={colourStyles}
              name={idLabel}
              isSearchable={false}
              aria-labelledby="selectOption"
              {...props}
            />
          </Suspense>
          {stateStatus === 'SUCCESS' && (
            <MSelectCheckMarkStyled
              aria-label={`La valeur sélectionnée est ${stateStatus}`}
            >
              {getIconStatus(stateStatus)}
            </MSelectCheckMarkStyled>
          )}
        </MBox>
      </div>
      {stateStatus === 'ERROR' && errorText && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          mt="xxs"
          color="error"
        >
          {errorText}
        </Text>
      )}
    </>
  );
};

MSelect.defaultProps = {
  options: null,
  label: null,
  isRequired: true,
  stateStatus: null,
  errorText: null,
};

MSelect.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string,
    }),
  ),
  placeholder: propTypes.string,
  label: propTypes.string,
  idLabel: propTypes.string,
  isRequired: propTypes.bool,
  stateStatus: propTypes.string,
  errorText: propTypes.string,
};

export default MSelect;
