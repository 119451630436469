import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { Picture } from 'components/common';
import { IconPlay } from 'components/icons';

import { theme } from 'styles/Theme';

const MediaStyled = styled.button`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: ${theme.radii.sm};
  ${space};
`;

const MediaIconWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MediaImage = ({ isVideo, $src, $srcSetAvif, $srcSetWebp, ...props }) => {
  return (
    <MediaStyled
      {...props}
      isVideo={isVideo}
      aria-label={`Ouvrir modal avec ${isVideo ? 'vidéo' : 'photo'}`}
      type="button"
    >
      <Picture
        src={$src}
        srcSetAvif={$srcSetAvif}
        srcSetWebp={$srcSetWebp}
        positionPicture="absolute"
        height="100%"
        objectFit="cover"
        position="absolute"
        top="0"
      />
      {isVideo && (
        <MediaIconWrapper>
          <IconPlay size="80" />
        </MediaIconWrapper>
      )}
    </MediaStyled>
  );
};

MediaImage.propTypes = {
  $src: propTypes.string.isRequired,
  $srcSetAvif: propTypes.string,
  $srcSetWebp: propTypes.string,
  isVideo: propTypes.bool,
};

export default MediaImage;
