import { revenueFormat } from 'constants/dateFormatters';

export const updateUserPayloadMapper = payload => {
  const result = payload;
  if (result?.personalInfo?.dateOfBirth) {
    result.personalInfo.dateOfBirth = revenueFormat(
      result.personalInfo.dateOfBirth,
    );
  }
  return result;
};
