import { connectRouter } from 'connected-react-router';
import authReducer from 'ducks/auth/slice';
import bankAccountsReducer from 'ducks/bankAccounts/slice';
import categoriesReducer from 'ducks/categories/slice';
import dialogReducer from 'ducks/dialog/slice';
import discussionReducer from 'ducks/discussion/slice';
import mediaObjectReducer from 'ducks/mediaObject/slice';
import messageReducer from 'ducks/message/slice';
import modalReducer from 'ducks/modal/slice';
import paymentReducer from 'ducks/payment/slice';
import paymentMethodsReducer from 'ducks/paymentMethods/slice';
import paypalAccountsReducer from 'ducks/paypalAccounts/slice';
import reportReducer from 'ducks/report/slice';
import requestsErrorsReducer from 'ducks/requestsErrors/slice';
import resetPasswordReducer from 'ducks/resetPassword/slice';
import serverReducer from 'ducks/server/slice';
import serverEventsReducer from 'ducks/serverEvents/slice';
import shareReducer from 'ducks/share/slice';
import userReducer from 'ducks/user/slice';
import { combineReducers } from 'redux';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    modal: modalReducer,
    auth: authReducer,
    categories: categoriesReducer,
    mediaObject: mediaObjectReducer,
    share: shareReducer,
    resetPassword: resetPasswordReducer,
    dialog: dialogReducer,
    message: messageReducer,
    serverEvents: serverEventsReducer,
    server: serverReducer,
    discussion: discussionReducer,
    payment: paymentReducer,
    report: reportReducer,
    bankAccounts: bankAccountsReducer,
    paymentMethods: paymentMethodsReducer,
    paypalAccounts: paypalAccountsReducer,
    requestsErrors: requestsErrorsReducer,
  });
