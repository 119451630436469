import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import TitlesContainer from 'containers/TitlesContainer';
import { selectError400, selectError500 } from 'ducks/requestsErrors';
import {
  selectIsAuthenticated,
  selectIsCreator,
  selectIsFan,
  selectUser,
  selectUserIsVerifiedEmail,
} from 'ducks/user';
import Page404 from 'pages/errors/Page404';
import Page500 from 'pages/errors/Page500';
import propTypes from 'prop-types';

import { routes } from 'constants/routes';

const PrivateRoute = ({ component: Component, stripePromise, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isVerifiedEmail = useSelector(selectUserIsVerifiedEmail);
  const isUserCreator = useSelector(selectIsCreator);
  const isUserFan = useSelector(selectIsFan);
  const user = useSelector(selectUser);
  const error400 = useSelector(selectError400);
  const error500 = useSelector(selectError500);

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (error400) return <Page404 />;
        if (error500) return <Page500 />;
        if (isUserCreator && !isVerifiedEmail) {
          return (
            <Redirect
              to={{
                pathname: routes.SUBSCRIBE,
                state: { from: routeProps.location },
              }}
            />
          );
        }
        if (isUserFan && !isVerifiedEmail) {
          return (
            <Redirect
              to={{
                pathname: routes.SIGNUP_FAN,
                state: { from: routeProps.location },
              }}
            />
          );
        }
        if (
          isUserCreator &&
          (!user?.personalInfo ||
            !user?.accountInfo ||
            !user?.accountOptions ||
            !user?.personalInfo?.welcomeAudioUrl)
        ) {
          return (
            <Redirect
              to={{
                pathname: routes.SIGNUP_CREATOR,
                state: { from: routeProps.location },
              }}
            />
          );
        }
        if (isAuthenticated)
          return (
            <>
              <TitlesContainer pathname={routeProps.location.pathname} />
              <Component
                {...(stripePromise ? { stripePromise } : {})}
                {...routeProps}
              />
            </>
          );

        return (
          <Redirect
            to={{
              pathname: routes.WELCOME,
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: propTypes.elementType.isRequired,
  stripePromise: propTypes.instanceOf(Promise),
};

export default PrivateRoute;
