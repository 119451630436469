import propTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from 'styles/Theme';

const ProgressVideoStyled = styled.svg`
  position: absolute;
  width: calc(100% + ${theme.sizes.borderWidthProgressVideo});
  height: calc(100% + ${theme.sizes.borderWidthProgressVideo});
  stroke-dasharray: 300;
  stroke-linecap: round;
  animation: progressTimeVideo ${props => props.progressTime}s forwards linear;

  @keyframes progressTimeVideo {
    0% {
      stroke-dashoffset: 300;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const ProgressVideo = ({ progressTime }) => {
  return (
    <ProgressVideoStyled
      width="104"
      viewBox="0 0 100 100"
      progressTime={progressTime}
    >
      <defs>
        <linearGradient id="progressVideoSvg">
          <stop offset="0%" stopColor="#F6C52B" />
          <stop offset="33.33%" stopColor="#FF6E6E" />
          <stop offset="66.66%" stopColor="#F484D5" />
          <stop offset="100%" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
      <circle
        cx="50"
        cy="50"
        r="47"
        stroke="url(#progressVideoSvg)"
        strokeWidth={theme.sizes.borderWidthProgressVideo}
        fill="none"
        transform="rotate(-90 50 50)"
      />
    </ProgressVideoStyled>
  );
};

ProgressVideo.propTypes = {
  progressTime: propTypes.number.isRequired,
};

export default ProgressVideo;
