import styled from 'styled-components';

const IconArrowStyled = styled.svg`
  flex-shrink: 0;
`;

const IconArrow = () => {
  return (
    <IconArrowStyled
      viewBox="0 0 16 16"
      width="16"
      fill="none"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.529 2.862c.26-.26.682-.26.942 0l4.667 4.666c.26.26.26.683 0 .943l-4.667 4.667a.667.667 0 1 1-.942-.943l3.528-3.529H3.333a.667.667 0 1 1 0-1.333h7.724L7.53 3.805a.667.667 0 0 1 0-.943Z"
        fill="#fff"
      />
    </IconArrowStyled>
  );
};

export default IconArrow;
