import propTypes from 'prop-types';

import SearchField from './SearchField';

const InputSearch = ({ value, onChange, autoFocus, ...props }) => {
  return (
    <SearchField
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      {...props}
    />
  );
};

InputSearch.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  autoFocus: propTypes.bool,
};

export default InputSearch;
