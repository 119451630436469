const IconUserCircle = () => {
  return (
    <svg width="20" viewBox="0 0 20 21" fill="none" aria-hidden="true">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10 3a7.5 7.5 0 0 0-5.718 12.354 4.158 4.158 0 0 1 3.218-1.52h5c1.297 0 2.454.592 3.218 1.52A7.5 7.5 0 0 0 10 3Zm6.142 14.305A9.167 9.167 0 1 0 3.858 3.695a9.167 9.167 0 0 0 12.284 13.61ZM14.5 16.5a2.496 2.496 0 0 0-2-1h-5c-.817 0-1.544.392-2 1A7.467 7.467 0 0 0 10 18a7.466 7.466 0 0 0 4.5-1.5ZM10 5.917a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.167 2.5a4.167 4.167 0 1 1 8.334 0 4.167 4.167 0 0 1-8.334 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconUserCircle;
