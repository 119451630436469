import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import {
  deletePaymentMethodFailure,
  deletePaymentMethodRequest,
  deletePaymentMethodSuccess,
  getPaymentMethodFailure,
  getPaymentMethodRequest,
  getPaymentMethodSuccess,
  getPaymentMethodsCollectionFailure,
  getPaymentMethodsCollectionRequest,
  getPaymentMethodsCollectionSuccess,
  patchPaymentMethodFailure,
  patchPaymentMethodRequest,
  patchPaymentMethodSuccess,
  savePaymentMethodFailure,
  savePaymentMethodRequest,
  savePaymentMethodSuccess,
  setupPaymentMethodFailure,
  setupPaymentMethodRequest,
  setupPaymentMethodSuccess,
} from './slice';

function* getPaymentMethodsCollectionSaga() {
  try {
    const paymentMethods = yield call(services.getPaymentMethodsCollection);
    yield put(getPaymentMethodsCollectionSuccess(paymentMethods));
  } catch (error) {
    yield put(getPaymentMethodsCollectionFailure());
  }
}

function* getPaymentMethodSaga({ payload: { id } }) {
  try {
    const paymentMethod = yield call(services.getPaymentMethod, id);
    yield put(getPaymentMethodSuccess(paymentMethod));
  } catch (error) {
    yield put(getPaymentMethodFailure());
  }
}

function* savePaymentMethodSaga({ payload: { paymentMethodId, onSuccess } }) {
  try {
    yield call(services.savePaymentMethod, paymentMethodId);
    const paymentMethods = yield call(services.getPaymentMethodsCollection);
    yield put(savePaymentMethodSuccess(paymentMethods));
    yield call(onSuccess);
  } catch (error) {
    yield put(savePaymentMethodFailure());
  }
}

function* setupPaymentMethodSaga() {
  try {
    const { clientSecret } = yield call(services.setupPaymentMethod);
    yield put(setupPaymentMethodSuccess(clientSecret));
  } catch (error) {
    yield put(setupPaymentMethodFailure());
  }
}

function* patchPaymentMethodSaga({ payload: { id, isDefault } }) {
  try {
    const patchedPaymentMethod = yield call(services.patchPaymentMethod, {
      id,
      isDefault,
    });
    yield put(patchPaymentMethodSuccess({ id, patchedPaymentMethod }));
  } catch (error) {
    yield put(patchPaymentMethodFailure());
  }
}

function* deletePaymentMethodSaga({ payload: { id, onSuccess } }) {
  try {
    yield call(services.deletePaymentMethod, id);
    const paymentMethods = yield call(services.getPaymentMethodsCollection);
    yield put(deletePaymentMethodSuccess(paymentMethods));
    yield call(onSuccess);
  } catch (error) {
    yield put(deletePaymentMethodFailure());
  }
}

export default function* watchPaymentMethods() {
  yield all([
    takeLatest(
      getPaymentMethodsCollectionRequest,
      getPaymentMethodsCollectionSaga,
    ),
    takeLatest(getPaymentMethodRequest, getPaymentMethodSaga),
    takeLatest(savePaymentMethodRequest, savePaymentMethodSaga),
    takeLatest(setupPaymentMethodRequest, setupPaymentMethodSaga),
    takeLatest(patchPaymentMethodRequest, patchPaymentMethodSaga),
    takeLatest(deletePaymentMethodRequest, deletePaymentMethodSaga),
  ]);
}
