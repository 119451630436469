import React from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';

const InputUploadStyled = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const InputUpload = ({ onChange, onKeyUp, accept, disabled, ...props }) => {
  return (
    <InputUploadStyled
      disabled={disabled}
      type="file"
      onChange={onChange}
      onKeyUp={onKeyUp}
      accept={accept}
      aria-label="Téléchargez vos médias"
      {...props}
    />
  );
};

InputUpload.defaultProps = {
  disabled: false,
};

InputUpload.propTypes = {
  onChange: propTypes.func,
  onKeyUp: propTypes.func,
  accept: propTypes.string,
  disabled: propTypes.bool,
};

export default InputUpload;
