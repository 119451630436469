const IconEmail = () => {
  return (
    <svg viewBox="0 0 37 30" width="37" fill="none" aria-hidden="true">
      <path
        fill="url(#iconEmail)"
        fillRule="evenodd"
        d="M9.764 0h17.471c1.342 0 2.45 0 3.351.074.937.076 1.798.24 2.607.653a6.667 6.667 0 0 1 2.914 2.913c.397.78.564 1.61.644 2.509.09.272.105.56.052.834.03.75.03 1.618.03 2.615v10.804c0 1.342 0 2.45-.073 3.351-.077.937-.241 1.798-.653 2.607a6.667 6.667 0 0 1-2.914 2.913c-.809.413-1.67.577-2.607.653-.902.074-2.009.074-3.35.074H9.763c-1.341 0-2.448 0-3.35-.074-.937-.076-1.798-.24-2.607-.653A6.667 6.667 0 0 1 .893 26.36C.481 25.55.317 24.69.24 23.753c-.073-.902-.073-2.01-.073-3.35V9.597c0-.997 0-1.865.03-2.615a1.666 1.666 0 0 1 .052-.834c.08-.9.247-1.728.644-2.509A6.667 6.667 0 0 1 3.807.727C4.616.314 5.477.15 6.414.074 7.316 0 8.423 0 9.764 0ZM3.5 9.868v10.465c0 .639 0 1.186.006 1.663l7.562-6.83L3.5 9.868Zm11.164 3.746L3.638 5.895c.06-.345.137-.568.225-.742.32-.627.83-1.137 1.457-1.456.26-.133.634-.241 1.365-.301.75-.061 1.72-.063 3.148-.063h17.334c1.427 0 2.398.002 3.148.063.73.06 1.104.168 1.365.3.627.32 1.137.83 1.457 1.457.088.174.166.397.225.742l-11.026 7.719-.057.04-1.676 1.173c-1.215.85-1.473.998-1.7 1.055a1.669 1.669 0 0 1-.806 0c-.227-.057-.485-.204-1.7-1.055l-1.676-1.173a1.828 1.828 0 0 0-.057-.04Zm-.794 3.513-9.435 8.521c.255.265.554.487.885.655.26.133.634.241 1.365.301.75.061 1.72.063 3.148.063h17.334c1.427 0 2.398-.002 3.148-.063.73-.06 1.104-.168 1.365-.3.331-.17.63-.391.885-.655l-9.435-8.522-.616.43-.192.136c-.908.637-1.705 1.197-2.613 1.423a4.999 4.999 0 0 1-2.418 0c-.908-.226-1.705-.786-2.613-1.423l-.192-.135-.616-.431Zm12.062-1.961 7.562 6.83c.006-.477.006-1.024.006-1.663V9.868l-7.568 5.298Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="iconEmail"
          x1=".167"
          x2="41.526"
          y1="30.484"
          y2="12.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconEmail;
