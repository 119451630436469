// ********* in theme styled-system *********
export const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    // gray
    dark: '#2B2B2B',
    gray: '#CCCCCC',
    grayMedium: '#999999',
    grayLight: '#DCDCDC',
    grayLighter: '#EAEAEA',
    grayLighter2: '#F9F9F9',
    grayCloud: '#F8F8F8',
    graySecodary: '#9B9FAB',
    grayTertiary: '#F5F5F5',
    grayBorder: '#EFEFEF',
    grayBorder2: '#CBCBCD',
    graySilver: '#B8B8B8',
    overlayBg: 'rgba(0, 0, 0, 0.5)',
    // primary
    primary: '#2655F4',
    primaryLight: '#eef1fe',
    primaryDark: '#611FF3',
    // seconadry
    yellow: '#F6C52B',
    red: '#FF6E6E',
    pink: '#F484D5',
    pinkGradient: '#F484D5', // use for gradient
    secondaryGradient: '#5F5EF9', // use for gradient
    // tertiary
    error: '#DE107F',
    errorGradient: '#EFA2CC', // use for gradient
    valid: '#229822',
    validGradient: '#7FDA8E', // use for gradient
    tertiaryValidGradient: '#0FBA00', // use for gradient
    // boost colors
    level1: '#F8B438',
    level2: '#FB9153',
    level3: '#F979A4',
    level4: '#8369F8',
  },

  shadows: {
    primary: '0 8px 16px 0 rgba(0, 0, 0, 0.12)',
    secondary: '0 2px 7px 0 rgba(0, 0, 0, 0.08)',
    buttonHover: '0 0 16px 5px rgba(38, 86, 244, 0.26)',
  },

  fonts: {
    main: '"Poppins", sans-serif',
  },

  fontSizes: {
    xs: '1.2rem',
    sm: '1.4rem',
    md: '1.6rem',
    h4: '1.8rem',
    h3: '2rem',
    h2: '2.4rem',
    h1: '2.8rem',
    desktop: '3.2rem',
    desktopXl: '4rem',
  },

  fontWeights: {
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },

  // margin, padding - from styled system
  space: {
    xxxs: '0.2rem',
    xxs: '0.4rem',
    xs: '0.6rem',
    sm: '0.8rem',
    primarySm: '1rem',
    md: '1.2rem',
    primaryMd: '1.6rem',
    primaryMd2: '1.8rem',
    secondaryMd: '2rem',
    lg: '2.4rem',
    primaryLg: '3rem',
    secondaryLg: '3.4rem',
    xl: '4rem',
    xxl: '4.2rem',
    xxxl: '5rem',
    extraXl: '7rem',
    extraXxl: '9rem',
    defaultSpaceX: '1.6rem', // default padding(x) for mobile from figma
    defaultSpaceY: '2.4rem', // default padding(y) for mobile from figma
    closeFullButtonSm: '0.8rem 1.6rem',
  },

  sizes: {
    inputHeight: '5.4rem',
    navMobileHeight: '6.4rem',
    textareaHeight: '11.1rem',
    textareaMessageHeightMax: '9.2rem',
    textareaMessageHeight: '2.2rem',
    scrollbarWidth: '0.6rem',
    scrollbarHeight: '0.6rem',
    radioPrimaryInput: '5.4rem',
    radioPrimaryInputSmall: '4rem',
    uploadAvatarSize: '9.6rem',
    btnCircleSizeMd: '3.2rem',
    switchWidth: '4.6rem',
    switchMaxHeight: '2.4rem',
    switchCircleSize: '2rem',
    radioTertiaryHeight: '3.2rem',
    mediaUploadCardWidth: '10.4rem',
    mediaUploadCardHeight: '15.6rem',
    crossButtonSize: '1.8rem',
    crossButtonSizeSm: '1.6rem',
    crossButtonIconHeight: '0.8rem',
    crossButtonIconWidth: '0.15rem',
    closeFullButtonCrossSize: '2rem',
    socialShareSize: '2.4rem',
    authBarUnderlineHeight: '0.1rem',
    newMessageFanSize: '1.2rem',
    maxWidthIncomeMessage: '22.5rem',
    maxWidthOutgoingMessage: '28.3rem',
    loadingMessageSizeCreator: '24.5rem',
    loadingMessageSizeFan: '24.9rem',
    loadingMessageWidthVideo: '12rem',
    loadingMessageHeightVideo: '21.4rem',
    sizeRenderThumb: '2rem', // MRange
    heightRenderTrack: '.4rem', // MRange
    boostNoticeWidth: '7.8rem', // MRange
    sizeRangeMark: '0.8rem', // MRange
    boostRadioHeight: '5.6rem',
    miniMediaUploadedSize: '8rem',
    swipeModalHeaderHeight: '2.8rem',
    swipeModalHeaderElemWidth: '4.8rem',
    recordVideoBtnSize: '8rem', // CustomVideoRecordContainer
    recordVideoCircleSize: '5.6rem', // CustomVideoRecordContainer
    recordVideoRevertBtnSize: '4.2rem', // CustomVideoRecordContainer
    borderWidthProgressVideo: '0.4rem', // CustomVideoRecordContainer
    mediaFullModalWidth: '100%',
    incomeDetailListElemHeight: '6.4rem',
    incomeIconSize: '4rem',
    buttonPeriodHeight: '5.4rem',
    toastDesktopMinWidth: '41.5rem',
    toastMaxHeight: '8rem',
    toastMinHeight: '5.6rem',
    sliderArrowWidth: '1.2rem',
    brandCardBoxWidth: '4.4rem',
    brandCardBoxHeight: '2.8rem',
    boostRadioWrapperWidth: '29.5rem',
    datePickerDayWidth: '2.7rem',
    cropperModalContentHeight: '60vh',
    stickyChatHeader: '6.4rem',
  },

  lineHeights: {
    xxs: '1',
    xs: '1.2',
    sm: '1.3',
    md: '1.4',
    lg: '1.5',
  },

  radii: {
    circle: '50%',
    xxs: '4px',
    xxsSecondary: '6px',
    xs: '8px',
    xsSecondary: '12px',
    xsTertiary: '10px',
    sm: '16px',
    md: '24px',
    mdSecondary: '32px',
    lg: '39px',
    xl: '100px',
  },

  borderWidths: {
    xs: '1px',
    sm: '2px',
  },
};

// breakpoints
export const breakpoints = ['23.4375em', '40em', '48em', '64em', '78.125em']; // 375px, 640px, 768px, 1024px, 1250px
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

// *********  not in styled system *********
export const gradients = {
  primary: `linear-gradient(90deg, ${theme.colors.primary} 0%, ${theme.colors.primaryDark} 100%)`,
  secondary: `linear-gradient(to right top, ${theme.colors.yellow} 0%, ${theme.colors.red} 34.54%, ${theme.colors.pinkGradient} 68.27%, ${theme.colors.secondaryGradient} 100%)`,
  boost: `linear-gradient(to right, ${theme.colors.level1} 0%, ${theme.colors.level2} 25%, ${theme.colors.level3} 50%, ${theme.colors.level4} 75%, ${theme.colors.level1} 100%)`,
  boost2: `linear-gradient(to right, ${theme.colors.level1} 0%, ${theme.colors.level2} 50%, ${theme.colors.level3} 100%)`,
  boost3: `linear-gradient(to right, ${theme.colors.level1} 0%, ${theme.colors.level2} 33%, ${theme.colors.level3} 67%, ${theme.colors.level4} 100%)`,
  boost4: `linear-gradient(to right, ${theme.colors.level1} 0%, ${theme.colors.level2} 33%, ${theme.colors.level3} 67%, ${theme.colors.level1} 100%)`,
  boostLabeledAfter: `linear-gradient(to right, #FA9D50 0%, #FE7669 50%, #FB7693 100%);`,
  boostLevel2: `linear-gradient(to right, ${theme.colors.level1} 0%, ${theme.colors.level2} 100%)`,
  boostLevel3: `linear-gradient(to right, #fa7e96 0%, #e06f9c 100%)`,
  boostLevel4: `linear-gradient(to right, #9c6ce6 0%, #8469f8 50%, #9c6ce6 100%)`,
  webCamBlur: `linear-gradient(272deg, rgba(97, 31, 243, 0.08) 0%, rgba(38, 85, 244, 0.08) 100%)`,
  error: `linear-gradient(90deg, ${theme.colors.error} 0%, ${theme.colors.errorGradient} 100%)`,
  valid: `linear-gradient(90deg, ${theme.colors.tertiaryValidGradient} 0%, ${theme.colors.validGradient} 100%)`,
};
