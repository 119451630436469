import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { theme } from './Theme';

const GlobalStyle = createGlobalStyle`
  ${normalize} /* stylelint-disable value-no-vendor-prefix */
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
  
  * {
    -webkit-tap-highlight-color: transparent;
  }
  
  body, html {
    height: 100%;
    height: fill-available;
    scroll-behavior: smooth;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.md};
    font-weight: 500;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    &.no-scroll {
      touch-action: none;
      overscroll-behavior: contain;
      overflow: hidden;
    }
    
    * {

      &:focus {
        outline: 3px solid ${theme.colors.grayLight};
      }

      &:focus:not(:focus-visible) {
        outline: none;
      }

      &:focus-visible {
        outline: 3px solid ${theme.colors.grayLight};
      }
    }
  }

  .grecaptcha-badge {  /* stylelint-disable-line block-opening-brace-newline-after */
    visibility: hidden;
  }

  button, a {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;
    overflow-wrap: break-word;
    appearance: none !important;
  }

  button[disabled] {
    cursor: default;
  }

  input[type='checkbox'], input[type='radio'] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    &:not([disabled]) {
      cursor: pointer;
    }
  }

  input[type='text'], input[type='number'], input[type='tel'], textarea {
    text-overflow: ellipsis;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'], input[type='tel'] {
    -moz-appearance: textfield;
  }

  input, textarea {
    appearance: none;
    overflow: hidden;
  }

  textarea {
    resize: none;
    &[readonly] {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
  }

  svg {
    display: inline-block;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  p, h1, h2, h3, h4 {
    margin: 0;
    overflow-wrap: break-word;
  }
`;

export default GlobalStyle;
