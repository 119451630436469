export const modalNames = {
  WAITING_VERIFICATION: 'WAITING_FOR_VERIFICATION',
  WAITING_VERIFICATION_WITH_BUTTON: 'WAITING_VERIFICATION_WITH_BUTTON',
  WELCOME: 'WELCOME',
  PROFILE_DONE: 'PROFILE_DONE',
  PROFILE_DONE_2: 'PROFILE_DONE_2',
  MESSAGE: 'MESSAGE',
  BRAVO_CERTIFICATION: 'BRAVO_CERTIFICATION',
  AGE_RESTRICTIONS: 'AGE_RESTRICTIONS',
  DELETE_PROMO_CODE: 'DELETE_PROMO_CODE',
  SUCCESS_PROMO_CODE: 'SUCCESS_PROMO_CODE',
  SETTINGS_DELETE: 'SETTINGS_DELETE',
  PUBLICATION_CONFIRMATION: 'PUBLICATION_CONFIRMATION',
  PUBLICATION_RULES: 'PUBLICATION_RULES',
  PUBLICATION_CANCEL: 'PUBLICATION_CANCEL',
  PUBLICATION_STRIKE: 'PUBLICATION_STRIKE',
  SETTINGS_UNSUBSCRIBE: 'SETTINGS_UNSUBSCRIBE',
  PROFILE_EXPLANATION: 'PROFILE_EXPLANATION',
  PROFILE_ALERT: 'PROFILE_ALERT',
  PUBLICATION_BRAVO: 'PUBLICATION_BRAVO',
  SUBSCRIPTION_CHANGE_PLAN: 'SUBSCRIPTION_CHANGE_PLAN',
  REACTIONS: 'REACTIONS',
  PAYMENTS_SUCCESS: 'PAYMENTS_SUCCESS',
  PAYMENTS_PROBLEM: 'PAYMENTS_PROBLEM',
  NEW_DISCUSSIONS: 'NEW_DISCUSSIONS',
  REFUSE_MEDIA_MODAL: 'REFUSE_MEDIA_MODAL',
  EMAIL_UNSUBSCRIBE: 'EMAIL_UNSUBSCRIBE',
  SETTINGS_OPTIONS: 'SETTINGS_OPTIONS',
  FILTER: 'FILTER',
  MY_SALES: 'MY_SALES',
  SHARE: 'SHARE',
  SHARE_CREATOR: 'SHARE_CREATOR',
  MY_SHARES: 'MY_SHARES',
  SHARED_BY_OTHERS: 'SHARED_BY_OTHERS',
  SHARED_BY_REFERRALS: 'SHARED_BY_REFERRALS',
  CROPPER: 'CROPPER',
  CREATE_CONTENT: 'CREATE_CONTENT',
  VIDEO_FULL: 'VIDEO_FULL',
  IMAGE_FULL: 'IMAGE_FULL',
  VIDEO_RECORD: 'VIDEO_RECORD',
  NEW_CONVERSATION_FAN: 'NEW_CONVERSATION_FAN',
  NEW_CONVERSATION_CREATOR: 'NEW_CONVERSATION_CREATOR',
  BOOST_MESSAGE: 'BOOST_MESSAGE',
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD: 'DELETE_PAYMENT_METHOD',
  DECLINED_PAYMENT: 'DECLINED_PAYMENT',
  DELETE_ACCOUNT_CREATOR: 'DELETE_ACCOUNT_CREATOR',
  DELETE_ACCOUNT_FAN: 'DELETE_ACCOUNT_FAN',
  MESSAGE_IS_STILL_LOADING: 'MESSAGE_IS_STILL_LOADING',
};
