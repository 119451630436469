import React, { memo, useCallback } from 'react';
import { useField, useForm } from 'react-final-form';

import propTypes from 'prop-types';

import { validateStatus } from 'constants/validateStatus';

import { noValidators } from 'tools/validationRules';

const { ERROR, SUCCESS, EMPTY, LOADING } = validateStatus;

const FormItem = Item => {
  const WrapperComponent = ({
    fieldName,
    validators,
    onSubmit,
    type,
    parse,
    format,
    validateFields,
    onChange,
    disableFormOnChange,
    stateStatus,
    withInitialState,
    errorText,
    showErrorTextOnInput,
    ...props
  }) => {
    const field = useField(fieldName, {
      validate: validators,
      onSubmit,
      type,
      validateFields,
      format,
      ...(parse ? { parse } : { parse: value => value }),
    });
    const form = useForm();

    const finalFormOnChange = field?.input?.onChange;

    const changeHandler = useCallback(
      (...rest) => {
        if (!disableFormOnChange) {
          finalFormOnChange(...rest);
        }
        if (onChange) {
          onChange({ event: [...rest], form });
        }
      },
      [disableFormOnChange, finalFormOnChange, form, onChange],
    );

    if (!field) return null;

    const {
      input,
      meta: {
        error,
        touched,
        submitFailed,
        validating,
        submitting,
        modifiedSinceLastSubmit,
        submitError,
        valid,
        initial,
        active,
      },
    } = field;

    const isError =
      (error && touched) ||
      (error && submitFailed) ||
      (error && !!initial) ||
      (submitError && !modifiedSinceLastSubmit);

    const status = () => {
      if (stateStatus !== undefined) {
        return (!active && stateStatus) || EMPTY;
      }
      if (stateStatus === undefined && input.value && !withInitialState) {
        return (
          (validating && LOADING) ||
          (!active &&
            !submitting &&
            ((isError && ERROR) ||
              ((touched || valid) && input.value !== initial && SUCCESS))) ||
          EMPTY
        );
      }
      if (stateStatus === undefined && input.value && withInitialState) {
        return (
          (validating && LOADING) ||
          (!active &&
            !submitting &&
            ((isError && ERROR) || ((touched || valid) && SUCCESS))) ||
          EMPTY
        );
      }
      return (!active && isError && ERROR) || EMPTY;
    };

    return (
      <Item
        {...input}
        onChange={changeHandler}
        stateStatus={status()}
        errorText={
          errorText ||
          (showErrorTextOnInput &&
            (touched || active) &&
            (typeof error === 'string' ? error : error?.text)) ||
          (isError && !active
            ? (typeof error === 'string' ? error : error?.text) ||
              (typeof submitError === 'string'
                ? submitError
                : submitError?.text)
            : '')
        }
        {...props}
        {...(type === 'checkbox' ? {} : { showErrorTextOnInput })}
      />
    );
  };
  WrapperComponent.propTypes = {
    fieldName: propTypes.string.isRequired,
    validators: propTypes.oneOfType([propTypes.func, propTypes.bool]),
    onSubmit: propTypes.func,
    type: propTypes.string,
    parse: propTypes.func,
    format: propTypes.func,
    validateFields: propTypes.arrayOf(propTypes.string),
    onChange: propTypes.func,
    disableFormOnChange: propTypes.bool,
    stateStatus: propTypes.string,
    withInitialState: propTypes.bool,
    errorText: propTypes.string,
  };

  WrapperComponent.defaultProps = {
    validators: noValidators,
    onSubmit: noValidators,
    validateFields: [],
    withInitialState: true,
  };

  const component = memo(WrapperComponent);
  component.displayName = `${Item?.name}FormWrapped`;
  return component;
};

FormItem.propTypes = {
  Item: propTypes.node.isRequired,
};

export default FormItem;
