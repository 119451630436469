import { generatePath } from 'react-router-dom';

import { apiRoutes } from '../../constants/apiRoutes';
import request from '../../helpers/requests';

export const getDialogs = ({ receiverId, page, sort, publicName }) => {
  return request.get(apiRoutes.DIALOG.DIALOGS, {
    params: { receiverId, page, sort, publicName },
  });
};

export const getDialogsBySearch = ({ name, page, sort }) => {
  return request.get(apiRoutes.DIALOG.DIALOGS_SEARCH, {
    params: { name, page, sort },
  });
};

export const patchDialog = ({ id, body }) => {
  return request.patch(
    generatePath(apiRoutes.DIALOG.DIALOG_DATA, {
      id,
    }),
    body,
  );
};

export const getDialogByCreatorNickname = nickname => {
  return request.get(
    generatePath(apiRoutes.DIALOG.DIALOG_DATA_BY_CREATOR_NICKNAME, {
      nickname,
    }),
  );
};

export const getDialogByFanNickname = nickname => {
  return request.get(
    generatePath(apiRoutes.DIALOG.DIALOG_DATA_BY_FAN_NICKNAME, {
      nickname,
    }),
  );
};

export const getDialogById = id => {
  return request.get(
    generatePath(apiRoutes.DIALOG.DIALOG_DATA, {
      id,
    }),
  );
};

export const getDialogMediaObjects = ({ id, origin, page, type }) => {
  return request.get(generatePath(apiRoutes.DIALOG.MEDIA_OBJECTS, { id }), {
    params: { origin, page, type },
  });
};
