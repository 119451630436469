const passwordValidationTexts = [
  {
    id: '1',
    text: <>Minimum 8 caractères</>,
  },
  {
    id: '2',
    text: <>Comporte une minuscule et une majuscule</>,
  },
  {
    id: '3',
    text: <>Comporte un chiffre</>,
  },
  {
    id: '4',
    text: <>Comporte un caractère spécial</>,
  },
];

export default passwordValidationTexts;
