import propTypes from 'prop-types';
import styled from 'styled-components';
import { layout, shadow, space } from 'styled-system';

import { theme } from 'styles/Theme';

import MNavigationList from './MNavigationList';

const MNavigationIndexes = [0, 1, 2];

const MNavigationStyled = styled.nav`
  background-color: ${theme.colors.white};
  ${props =>
    props.isMobile
      ? `border-top: 1px solid ${theme.colors.grayBorder}; padding-bottom: env(safe-area-inset-bottom);`
      : `width: 100%;`};
  ${layout};
  ${space};
  ${shadow};
`;

const MNavigation = ({
  itemsList,
  itemsToShow,
  isMobile,
  unreadMessages,
  ...props
}) => (
  <MNavigationStyled isMobile={isMobile} {...props}>
    <MNavigationList
      isMobile={isMobile}
      items={itemsList}
      indexes={itemsToShow}
      unreadMessages={unreadMessages}
    />
  </MNavigationStyled>
);

MNavigation.defaultProps = {
  isMobile: true,
  itemsToShow: MNavigationIndexes,
};

MNavigation.propTypes = {
  isMobile: propTypes.bool,
  itemsList: propTypes.arrayOf(
    propTypes.shape({
      icon: propTypes.node.isRequired,
      activeIcon: propTypes.node,
      id: propTypes.string.isRequired,
      to: propTypes.string.isRequired,
      otherActivePath: propTypes.string,
      isActive: propTypes.bool,
      isNotify: propTypes.bool,
    }),
  ),
  itemsToShow: propTypes.arrayOf(propTypes.number),
  unreadMessages: propTypes.number,
};

export default MNavigation;
