const IconDialog2 = () => {
  return (
    <svg aria-hidden="true" width="41" viewBox="0 0 41 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.833 16.667c0-8.295 6.766-15 15.088-15s15.088 6.705 15.088 15c0 1.87-.345 3.663-.976 5.318-.061.16-.097.253-.121.322l-.008.023v.007c.005.055.015.13.032.273l.677 5.497c.031.253.067.541.075.791.01.278-.002.708-.209 1.16a2.5 2.5 0 0 1-1.285 1.256 2.536 2.536 0 0 1-1.164.182c-.25-.014-.537-.057-.79-.094l-.045-.007-5.31-.778a11.245 11.245 0 0 0-.289-.04l-.023.008c-.071.025-.168.06-.333.123a15.137 15.137 0 0 1-6.21.933c-1.59 3.914-5.363 6.692-9.81 6.692-1.213 0-2.382-.208-3.47-.592a22.526 22.526 0 0 0-.411-.142h-.01a12.17 12.17 0 0 0-.279.037l-4.991.682a1.666 1.666 0 0 1-1.853-2.011l1.02-4.603a12.755 12.755 0 0 0 .067-.32 11.028 11.028 0 0 1-.688-3.884c0-4.377 2.556-8.181 6.258-9.883-.02-.315-.03-.631-.03-.95Zm3.334.052c.346-.035.697-.052 1.052-.052 5.9 0 10.614 4.888 10.614 10.833 0 .28-.01.558-.03.833h.118a11.804 11.804 0 0 0 4.22-.774c.236-.088.514-.193.768-.246.232-.05.43-.07.667-.072.259-.001.522.038.734.07l.06.008 4.875.715-.617-5.017a4.868 4.868 0 0 1-.059-.768c.005-.228.029-.419.078-.643.055-.244.157-.513.245-.74l.026-.07c.49-1.281.758-2.672.758-4.13C35.675 10.235 30.422 5 23.92 5c-6.502 0-11.754 5.234-11.754 11.667v.052Zm8.101 12.67A7.739 7.739 0 0 0 20.5 27.5c0-4.18-3.297-7.5-7.28-7.5-3.984 0-7.281 3.32-7.281 7.5 0 .84.133 1.644.378 2.394l.03.09c.093.286.2.609.242.88.04.256.05.452.035.71-.016.274-.079.555-.13.785l-.014.065-.502 2.268 2.621-.358.06-.009c.207-.028.46-.063.705-.062.23.002.402.02.629.065.24.047.528.15.784.24l.082.03c.738.26 1.532.402 2.36.402 3.333 0 6.186-2.324 7.029-5.531l.02-.08Z"
        fill="url(#iconDialog2)"
      />
      <defs>
        <linearGradient
          id="iconDialog2"
          x1="2.167"
          y1="38.925"
          x2="46.065"
          y2="22.912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C52B" />
          <stop offset=".345" stopColor="#FF6E6E" />
          <stop offset=".683" stopColor="#F484D5" />
          <stop offset="1" stopColor="#5F5EF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconDialog2;
