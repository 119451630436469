import styled from 'styled-components';

import { VisuallyHidden } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const NewMessageFanStyled = styled.div`
  width: ${theme.sizes.newMessageFanSize};
  height: ${theme.sizes.newMessageFanSize};
  border-radius: ${theme.radii.circle};
  background: ${gradients.primary};
`;

const NewMessageFan = ({ ...props }) => {
  return (
    <NewMessageFanStyled {...props}>
      <VisuallyHidden>nouveau message</VisuallyHidden>
    </NewMessageFanStyled>
  );
};

export default NewMessageFan;
