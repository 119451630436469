import propTypes from 'prop-types';
import styled from 'styled-components';
import { border, layout, position, space, system } from 'styled-system';

import { Transition } from 'styles/MixinStyle';

const ImgStyled = styled.img`
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  opacity: ${props => props.opacity};
  ${Transition('opacity')};
  ${space};
  ${layout};
  ${border};
  ${position};
  ${system({
    objectFit: true,
  })};
`;

const Image = ({ alt, src, ...props }) => {
  return <ImgStyled src={src} alt={alt} loading="lazy" {...props} />;
};

Image.defaultProps = {
  alt: '',
};

Image.propTypes = {
  alt: propTypes.string,
  src: propTypes.string,
  opacity: propTypes.string,
};

export default Image;
