const IconUpload = () => {
  return (
    <svg width="15" viewBox="0 0 15 15" fill="none" aria-hidden="true">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.167.667c.368 0 .666.298.666.666v1.334h1.334a.667.667 0 1 1 0 1.333h-1.334v1.333a.667.667 0 1 1-1.333 0V4h-1.333a.667.667 0 1 1 0-1.333H11.5V1.333c0-.368.299-.666.667-.666Zm-7.096.666h2.762a.667.667 0 0 1 0 1.334H5.1c-.758 0-1.286 0-1.697.034-.404.033-.635.094-.811.184a2 2 0 0 0-.874.874c-.09.175-.15.407-.184.81-.033.412-.034.94-.034 1.698v3.466c0 .758 0 1.286.034 1.698.033.403.095.635.184.81a2 2 0 0 0 .874.874c.176.09.407.151.81.184.412.034.94.034 1.698.034h4.8c.758 0 1.286 0 1.697-.034.404-.033.635-.094.811-.184a2 2 0 0 0 .874-.874c.09-.175.15-.407.184-.81.034-.412.034-.94.034-1.698V7.667a.667.667 0 0 1 1.333 0v2.095c0 .723 0 1.305-.038 1.777-.04.486-.124.913-.325 1.308a3.333 3.333 0 0 1-1.457 1.456c-.395.202-.821.285-1.307.325-.472.039-1.055.039-1.777.039H5.07c-.722 0-1.305 0-1.777-.039-.486-.04-.912-.123-1.307-.325A3.333 3.333 0 0 1 .53 12.847c-.201-.395-.285-.822-.325-1.308-.038-.472-.038-1.054-.038-1.777V6.238c0-.722 0-1.305.038-1.777.04-.486.124-.913.325-1.308.32-.627.83-1.137 1.457-1.456.395-.201.821-.285 1.307-.325.472-.039 1.055-.039 1.777-.039ZM7.5 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM4.167 8a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconUpload;
