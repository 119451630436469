import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { theme } from 'styles/Theme';

const IconVideoStyled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconVideo = ({ palette, isGradient, size, ...props }) => {
  return (
    <IconVideoStyled
      width={size}
      viewBox="0 0 22 16"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.759 0h5.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564.04.502.044 1.11.044 1.838l1.954-1.954c.19-.191.379-.379.543-.517.147-.123.457-.37.885-.403a1.5 1.5 0 0 1 1.259.52c.279.328.323.721.34.912.02.214.019.48.019.75v6.212c0 .27 0 .536-.019.75-.017.19-.061.584-.34.911a1.5 1.5 0 0 1-1.259.521c-.428-.034-.738-.28-.885-.403a9.158 9.158 0 0 1-.543-.517L17 10.414c0 .728-.003 1.336-.044 1.838-.046.562-.145 1.079-.392 1.564a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H5.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C0 11.71 0 11.046 0 10.242V5.758c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565A4 4 0 0 1 2.184.436C2.669.189 3.186.09 3.748.044 4.29 0 4.954 0 5.758 0Z"
        fill={isGradient ? 'url(#IconVideo)' : palette}
      />
      <defs>
        <linearGradient
          id="IconVideo"
          x1="22"
          y1="16"
          x2="-.608"
          y2="15.088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </IconVideoStyled>
  );
};

IconVideo.defaultProps = {
  size: '16',
  palette: theme.colors.white,
  isGradient: false,
};

IconVideo.propTypes = {
  size: propTypes.string,
  palette: propTypes.string,
  isGradient: propTypes.bool,
};

export default IconVideo;
