const IconPencil2 = () => {
  return (
    <svg width="18" viewBox="0 0 18 18" fill="none" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.345 1.345a2.34 2.34 0 1 1 3.31 3.31l-7.172 7.172-.044.045c-.215.216-.406.406-.635.546-.201.124-.42.215-.65.27-.261.063-.53.062-.836.062H6a.75.75 0 0 1-.75-.75v-1.318c0-.306 0-.575.062-.836.055-.23.146-.449.27-.65.14-.23.33-.42.546-.635l.045-.044 7.172-7.172Zm2.25 1.06a.841.841 0 0 0-1.19 0L7.233 9.577c-.284.285-.337.344-.372.402a.75.75 0 0 0-.09.217c-.016.066-.021.146-.021.548v.506h.506c.402 0 .482-.005.548-.02a.751.751 0 0 0 .217-.09c.058-.036.117-.089.402-.373l7.172-7.172a.841.841 0 0 0 0-1.19ZM5.069 2.25H8.25a.75.75 0 1 1 0 1.5H5.1c-.642 0-1.08 0-1.417.028-.329.027-.497.076-.614.135a1.5 1.5 0 0 0-.656.656c-.06.117-.108.285-.135.614-.027.338-.028.775-.028 1.417v6.3c0 .642 0 1.08.028 1.417.027.329.076.497.135.614a1.5 1.5 0 0 0 .656.655c.117.06.285.109.614.136.338.027.775.028 1.417.028h6.3c.642 0 1.08 0 1.417-.028.329-.027.497-.076.614-.136a1.5 1.5 0 0 0 .655-.655c.06-.117.109-.285.136-.614.027-.338.028-.775.028-1.417V9.75a.75.75 0 0 1 1.5 0v3.181c0 .604 0 1.102-.033 1.508-.035.421-.109.809-.294 1.173a3 3 0 0 1-1.311 1.311c-.364.186-.752.26-1.173.294-.406.033-.904.033-1.508.033H5.069c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.108-1.173-.294a3 3 0 0 1-1.311-1.311c-.185-.364-.26-.752-.294-1.173C.75 14.033.75 13.535.75 12.93V6.569c0-.604 0-1.102.033-1.508.035-.421.109-.809.294-1.173a3 3 0 0 1 1.311-1.311c.364-.185.752-.26 1.173-.294.406-.033.904-.033 1.508-.033Z"
        fill="#fff"
      />
    </svg>
  );
};

export default IconPencil2;
