import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import CrossButton from 'components/buttons/CrossButton';
import IconInputSearch from 'components/icons/IconInputSearch';

import MInput from './MInput';

const SearchFieldStyled = styled.div`
  ${space};
`;

const SearchField = ({ value, onChange, autoFocus, ...props }) => {
  const clearHandler = useCallback(() => {
    if (value) {
      onChange('');
    }
  }, [onChange, value]);

  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );
  return (
    <SearchFieldStyled role="search" {...props}>
      <MInput
        idLabel={null}
        value={value}
        onClear={clearHandler}
        placeholder="Rechercher un créateur"
        searchInput
        onChange={changeHandler}
        autoFocus={autoFocus}
        icon={value.length !== 0 ? <CrossButton /> : <IconInputSearch />}
      />
    </SearchFieldStyled>
  );
};

SearchField.defaultProps = {
  value: '',
  onChange: noop,
};

SearchField.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func,
  autoFocus: propTypes.bool,
};

export default SearchField;
