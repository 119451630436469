import { Suspense } from 'react';

import propTypes from 'prop-types';

import lazyWithRetry from 'tools/lazyWithRetry';
import noop from 'tools/noop';

import { LoadingSpinner } from 'components/common';

const WaveSurferPlayerContainer = lazyWithRetry(() =>
  import(
    'containers/voiceMessage/WaveSurferPlayerContainer' /* webpackChunkName: "waveSurferPlayer" */
  ),
);

const VoiceRecorder = ({
  isMessage,
  buttonRecordText,
  currentVoice,
  isRegistration,
  handleButtonClick,
  disabledVoiceButton,
  isLoading,
  audioFileName,
  handleAudioFileCreation,
  handleAudioFileDeletion,
  isCustomButton,
  ...props
}) => {
  return (
    <Suspense fallback={<LoadingSpinner position="relative" />}>
      <WaveSurferPlayerContainer
        currentVoice={currentVoice}
        withRecording
        isMessage={isMessage}
        buttonRecordText={buttonRecordText}
        isRegistration={isRegistration}
        handleButtonClick={handleButtonClick}
        disabledVoiceButton={!!disabledVoiceButton}
        isLoading={isLoading}
        audioFileName={audioFileName}
        handleAudioFileCreation={handleAudioFileCreation}
        handleAudioFileDeletion={handleAudioFileDeletion}
        isCustomButton={isCustomButton}
        {...props}
      />
    </Suspense>
  );
};

VoiceRecorder.defaultProps = {
  isRegistration: false,
  handleButtonClick: noop,
  handleAudioFileCreation: noop,
  handleAudioFileDeletion: noop,
};

VoiceRecorder.propTypes = {
  isMessage: propTypes.bool,
  currentVoice: propTypes.bool,
  buttonRecordText: propTypes.string,
  isRegistration: propTypes.bool,
  handleButtonClick: propTypes.func,
  disabledVoiceButton: propTypes.any,
  isLoading: propTypes.bool,
  audioFileName: propTypes.string,
  handleAudioFileCreation: propTypes.func,
  handleAudioFileDeletion: propTypes.func,
};

export default VoiceRecorder;
