import propTypes from 'prop-types';
import styled from 'styled-components';

import { CrossButton } from 'components/buttons';
import { Image } from 'components/common';

import { theme } from 'styles/Theme';

const MiniMediaUploadedStyled = styled.div`
  position: relative;
  width: ${theme.sizes.miniMediaUploadedSize};
  height: ${theme.sizes.miniMediaUploadedSize};
  overflow: hidden;
  border-radius: ${theme.radii.sm};
  margin-right: ${theme.space.primarySm};
`;

const MiniMediaUploaded = ({ $src, onClick, ...props }) => {
  return (
    <MiniMediaUploadedStyled {...props} aria-label="Image téléchargée">
      <Image
        src={$src}
        objectFit="cover"
        height="100%"
        position="absolute"
        top="0"
        left="0"
      />
      <CrossButton
        onClick={onClick}
        secondary
        position="absolute"
        top="sm"
        left="sm"
      />
    </MiniMediaUploadedStyled>
  );
};

MiniMediaUploaded.propTypes = {
  $src: propTypes.string.isRequired,
  onClick: propTypes.func,
};

export default MiniMediaUploaded;
