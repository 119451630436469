import { Range, getTrackBackground } from 'react-range';

import propTypes from 'prop-types';
import styled from 'styled-components';

import noop from 'tools/noop';

import { MRangeBaseStyled } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const MRangeWrapperStyled = styled.div`
  ${MRangeBaseStyled()};
`;

const MRange = ({
  value,
  onChange,
  step,
  min,
  max,
  ariaLabelledbyRenderThumb,
}) => {
  return (
    <MRangeWrapperStyled>
      <Range
        step={step}
        min={min}
        max={max}
        values={value}
        onChange={onChange}
        renderTrack={({
          props: { onMouseDown, onTouchStart, style, ...rest },
          children,
        }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="range-track"
            style={{
              ...style,
              height: `${theme.sizes.heightRenderTrack}`,
              width: '100%',
              borderRadius: `${theme.radii.extraMd}`,
              background: getTrackBackground({
                colors: [
                  `${theme.colors.primary}`,
                  `${theme.colors.grayLight}`,
                ],
                min,
                max,
                values: value,
              }),
            }}
            {...rest}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            aria-labelledby={ariaLabelledbyRenderThumb}
            className="range-thumb"
            {...props}
            style={{
              position: 'relative',
              height: `${theme.sizes.sizeRenderThumb}`,
              width: `${theme.sizes.sizeRenderThumb}`,
              background: `${gradients.primary}`,
              borderRadius: `${theme.radii.circle}`,
              outline: 'none',
            }}
          />
        )}
      />
    </MRangeWrapperStyled>
  );
};

MRange.defaultProps = {
  onChange: noop,
  step: 1,
  min: 0,
  max: 100,
};

MRange.propTypes = {
  value: propTypes.arrayOf(propTypes.number),
  onChange: propTypes.func,
  step: propTypes.number,
  min: propTypes.number,
  max: propTypes.number,
  ariaLabelledbyRenderThumb: propTypes.string,
};

export default MRange;
