import propTypes from 'prop-types';
import styled from 'styled-components';
import { flexbox } from 'styled-system';

import { ButtonGoBack } from 'components/buttons';
import { MBox } from 'components/common';
import { ProgressBar } from 'components/progressbar';
import { Heading1, Text } from 'components/texts';

import { LayoutBase, VisuallyHidden } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import Header from './helpers/Header';

const SignUpLayoutStyled = styled.section`
  display: grid;
  ${props =>
    props.hasHeader ? 'grid-template-rows: auto 1fr' : 'align-content: center'};
  padding-top: ${theme.space.defaultSpaceY};
  min-height: 100vh;
  min-height: 100svh; /* stylelint-disable-line */
  ${LayoutBase};
  ${flexbox};
`;

const MHeaderStyled = styled(Header)`
  position: relative;
`;

const SignUpLayout = ({
  ariaLabelledby,
  progressLineValue,
  progressStage,
  children,
  hasBackButton,
  onBackButtonClick,
  hasHeader,
  ...props
}) => {
  return (
    <SignUpLayoutStyled
      {...props}
      hasHeader={hasHeader}
      aria-labelledby={ariaLabelledby}
    >
      {hasHeader && (
        <MHeaderStyled>
          <Heading1 id={ariaLabelledby}>
            <VisuallyHidden>Sign Up Premium</VisuallyHidden>
          </Heading1>
          <MBox display="flex" alignItems="center">
            {hasBackButton && <ButtonGoBack onClick={onBackButtonClick} />}
            <MBox
              display="flex"
              flexDirection="column"
              ml={hasBackButton && 'secondaryMd'}
            >
              {props.subtitle && (
                <Text as="h2" type="subtitle1" mb="xxxs">
                  {props.subtitle}
                </Text>
              )}
              <Text type="body2" color="grayMedium" mb="sm">
                Etape {progressStage}
              </Text>
            </MBox>
          </MBox>
          <ProgressBar value={progressLineValue} />
        </MHeaderStyled>
      )}
      <main>{children}</main>
    </SignUpLayoutStyled>
  );
};

SignUpLayout.defaultProps = {
  hasHeader: true,
};

SignUpLayout.propTypes = {
  children: propTypes.node.isRequired,
  ariaLabelledby: propTypes.string.isRequired,
  subtitle: propTypes.string,
  progressLineValue: propTypes.string,
  progressStage: propTypes.string,
  hasBackButton: propTypes.bool,
  onBackButtonClick: propTypes.func,
  hasHeader: propTypes.bool,
};

export default SignUpLayout;
