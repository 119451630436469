import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import TimerWidget from 'containers/widgets/TimerWidget';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { routes } from 'constants/routes';

import { getDialogMessageCreationDate } from 'tools/messageCreationDate';
import messageMediaTypeFormat from 'tools/messageMediaTypeFormat';
import { formatPrice } from 'tools/stringFormatter';

import { Avatar } from 'components/avatar';
import { Badge } from 'components/badge';
import { MBox } from 'components/common';
import { Text } from 'components/texts';

import {
  MCardLinkStyles,
  MCardStyles,
  VisuallyHidden,
} from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import { MessageStatusText, NewMessageCreator } from '..';

const CreatorDialogCardStyled = styled.article`
  padding-left: ${theme.space.primaryMd};
  padding-right: ${theme.space.primaryMd};
  margin: 0 -${theme.space.primaryMd};
  max-width: calc(100% + (${theme.space.primaryMd} * 2));
  ${MCardStyles};
  &:first-child {
    margin-top: ${theme.space.primarySm};
  }
`;

const CreatorDialogCardWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CreatorDialogMessageStyled = styled.div`
  display: inline-flex;
  margin-bottom: ${theme.space.sm};
`;

const CreatorDialogCardLinkStyled = styled(Link)`
  color: inherit;
  ${MCardLinkStyles('0')};
`;

const CreatorDialogCard = ({ person, isIncomingMessage }) => {
  const pathToDialog = useMemo(() => {
    if (person?.id) {
      return generatePath(routes.MESSENGER_DIALOG, {
        id: person?.id,
      });
    }
    return '#';
  }, [person?.id]);

  return (
    <CreatorDialogCardStyled>
      <Avatar
        $src={person?.receiverAvatar?.squarePreviewUrl}
        $srcSetAvif={person?.receiverAvatar?.squarePreviewAvifUrl}
        $srcSetWebp={person?.receiverAvatar?.squarePreviewWebpUrl}
        uniqueIdCheckedIcon={person?.id}
        personName={person?.receiverName}
        isChecked={person?.certified}
      />
      {!person?.read && <NewMessageCreator />}
      <CreatorDialogCardWrapperStyled>
        <Text
          as="h3"
          type="label1"
          lineHeight="md"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          mb="xxs"
          {...(!person?.read && isIncomingMessage
            ? { color: 'black' }
            : { color: 'grayMedium' })}
        >
          {/* to => pathToDialog */}
          <CreatorDialogCardLinkStyled
            to={pathToDialog}
            title={person?.receiverName}
          >
            <VisuallyHidden>message de</VisuallyHidden>
            {person?.receiverName} {person?.receiverSurname}
          </CreatorDialogCardLinkStyled>
        </Text>
        <CreatorDialogMessageStyled>
          <Text
            color="grayMedium"
            type="body2"
            lineClamp="2"
            wordBreak="break-all"
            lineHeight="sm"
          >
            {!isIncomingMessage && 'Vous : '}
            {person?.lastMessageText ||
              messageMediaTypeFormat(person?.lastMessageMediaType)}
          </Text>
          {person && (
            <>
              <Text as="time" color="grayMedium" type="label2">
                &nbsp;·&nbsp;
                {getDialogMessageCreationDate(person?.lastMessageCreatedAt)}
              </Text>
              <VisuallyHidden>Il y a</VisuallyHidden>
            </>
          )}
        </CreatorDialogMessageStyled>
        {/* MESSAGE STATUS TEXT for answer required */}
        {person?.prioritizedMessage?.answerUntil &&
          person?.mediaType !== 'audio' &&
          person?.prioritizedMessage?.messageType !== 'video_response' && (
            <MessageStatusText answerUntil />
          )}
        {person?.prioritizedMessage?.messageType === 'video_response' &&
          person?.prioritizedMessage?.answerUntil && (
            <MessageStatusText answerUntil video />
          )}
        {person?.mediaType === 'audio' &&
          person?.prioritizedMessage?.answerUntil && (
            <MessageStatusText answerUntil audio />
          )}
        {/* MESSAGE STATUS TEXT for refunded */}
        {person?.prioritizedMessage?.refunded && <MessageStatusText refunded />}
      </CreatorDialogCardWrapperStyled>
      {person?.prioritizedMessage?.price && (
        <MBox display="flex" flexDirection="column" alignItems="flex-end">
          {/* BADGE for answer required */}
          {person?.prioritizedMessage?.answerUntil &&
            person?.mediaType !== 'audio' &&
            person?.prioritizedMessage?.messageType !== 'video_response' && (
              <Badge answerUntil>
                {formatPrice(person?.prioritizedMessage?.price)}
              </Badge>
            )}
          {person?.prioritizedMessage?.messageType === 'video_response' &&
            person?.prioritizedMessage?.answerUntil && (
              <Badge answerUntil video>
                {formatPrice(person?.prioritizedMessage?.price)}
              </Badge>
            )}
          {person?.mediaType === 'audio' &&
            person?.prioritizedMessage?.answerUntil && (
              <Badge answerUntil audio>
                {formatPrice(person?.prioritizedMessage?.price)}
              </Badge>
            )}
          {person?.prioritizedMessage?.messageType === 'boost' && (
            <Badge notGuaranteed>
              {formatPrice(person?.prioritizedMessage?.price)}
            </Badge>
          )}
          {person?.prioritizedMessage?.refunded && (
            <>
              <Badge refunded>
                {formatPrice(person?.prioritizedMessage?.price)}
              </Badge>
              <Text color="error" type="subtitle3" aria-hidden="true" mt="xs">
                Remboursé
              </Text>
            </>
          )}
          {person?.prioritizedMessage?.price &&
            !person?.prioritizedMessage?.refunded &&
            !person?.prioritizedMessage?.answerUntil &&
            person?.prioritizedMessage?.messageType !== 'boost' && (
              <Badge paid>
                {formatPrice(person?.prioritizedMessage?.price)}
              </Badge>
            )}
          {/* TIMER for answer required */}
          {person?.prioritizedMessage?.answerUntil && (
            <>
              <VisuallyHidden>answer until</VisuallyHidden>
              <Text as="time" type="subtitle2" color="red" mt="sm">
                <TimerWidget
                  deadline={person?.prioritizedMessage?.answerUntil}
                />
              </Text>
            </>
          )}
        </MBox>
      )}
    </CreatorDialogCardStyled>
  );
};

CreatorDialogCard.propTypes = {
  person: propTypes.shape({
    id: propTypes.string,
    avatar: propTypes.string,
    certified: propTypes.bool,
    receiverName: propTypes.string,
    receiverSurname: propTypes.string,
    lastMessageText: propTypes.string,
    lastMessageCreatedAt: propTypes.string,
    read: propTypes.bool,
    answerUntil: propTypes.string, // guaranteed answer
    notGuaranteed: propTypes.bool,
    paid: propTypes.bool,
    mediaType: propTypes.string,
    messageType: propTypes.string,
    price: propTypes.shape({
      price: propTypes.string,
      currency: propTypes.string,
    }),
    prioritizedMessage: propTypes.shape({
      answerUntil: propTypes.string,
      authorId: propTypes.string,
      messageId: propTypes.string,
      messageType: propTypes.string,
      price: propTypes.number,
      receiverId: propTypes.string,
      refunded: propTypes.bool,
    }),
  }),
  isIncomingMessage: propTypes.bool,
};

export default CreatorDialogCard;
