import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'serverEvents';

export const serverEventsSlice = createSlice({
  name,
  initialState,
  reducers: {
    connectServerEvents() {},
    setEventSource(state, { payload }) {
      state.eventSource = payload;
    },
    setReadyState(state, { payload }) {
      state.readyState = payload;
    },
  },
});

export const { connectServerEvents, setReadyState, setEventSource } =
  serverEventsSlice.actions;

export default serverEventsSlice.reducer;
