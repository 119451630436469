import styled from 'styled-components';

import { VisuallyHidden } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const NewMessageCreatorStyled = styled.div`
  position: absolute;
  top: -${theme.borderWidths.xs};
  bottom: 0;
  width: 0.4rem;
  height: calc(100% + (${theme.borderWidths.xs} * 2));
  left: 0;
  background: ${gradients.primary};
`;

const NewMessageCreator = ({ ...props }) => {
  return (
    <NewMessageCreatorStyled {...props}>
      <VisuallyHidden>nouveau message</VisuallyHidden>
    </NewMessageCreatorStyled>
  );
};

export default NewMessageCreator;
