/* eslint-disable prettier/prettier */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import TitlesContainer from 'containers/TitlesContainer';
import { selectError400, selectError500 } from 'ducks/requestsErrors';
import { selectIsAuthenticated } from 'ducks/user';
import Page404 from 'pages/errors/Page404';
import Page500 from 'pages/errors/Page500';
import propTypes from 'prop-types';

import { routes } from 'constants/routes';

const PublicRoute = ({
  component: Component,
  isNotAuthenticatedOnly,
  stripePromise,
  ...rest
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const error400 = useSelector(selectError400);
  const error500 = useSelector(selectError500);
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (error400) return <Page404 />;
        if (error500) return <Page500 />;
        return isAuthenticated && isNotAuthenticatedOnly ? (
          <Redirect
            to={{
              pathname: routes.MESSAGES,
              state: { from: routeProps.location },
            }}
          />
        ) : (
          <>
            <TitlesContainer pathname={routeProps.location.pathname} />
            <Component
              {...(stripePromise ? { stripePromise } : {})}
              {...routeProps}
            />
          </>
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: propTypes.elementType.isRequired,
  isNotAuthenticatedOnly: propTypes.bool,
  stripePromise: propTypes.instanceOf(Promise),
};

PublicRoute.defaultProps = {
  isNotAuthenticatedOnly: false,
};

export default PublicRoute;
