import styled from 'styled-components';

import { Text } from 'components/texts';

import { theme } from 'styles/Theme';

const UnreadMessageChatStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.space.primaryMd};
`;

const UnreadMessageTextStyled = styled(Text)`
  background-color: ${theme.colors.gray};
  border-radius: ${theme.radii.sm};
  padding: ${theme.space.xxs} ${theme.space.sm};
`;

const UnreadMessageChat = ({ ...props }) => {
  return (
    <UnreadMessageChatStyled {...props}>
      <UnreadMessageTextStyled color="white" type="subtitle2">
        Messages non lus
      </UnreadMessageTextStyled>
    </UnreadMessageChatStyled>
  );
};

export default UnreadMessageChat;
