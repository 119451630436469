import styled from 'styled-components';
import { space } from 'styled-system';

const IconCheckStyled = styled.svg`
  flex-shrink: 0;
  ${space};
`;

const IconCheck = ({ ...props }) => {
  return (
    <IconCheckStyled
      width="16"
      fill="none"
      viewBox="0 0 16 16"
      aria-hidden="true"
      {...props}
    >
      <g clipPath="url(#IconCheck)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.442 2.52A6 6 0 1 0 14 8.002v-.612a.667.667 0 0 1 1.333 0v.613a7.335 7.335 0 0 1-9.874 6.875 7.333 7.333 0 1 1 5.526-13.577.667.667 0 0 1-.543 1.217Zm4.696-.325c.26.26.26.682 0 .943L8.472 9.81a.667.667 0 0 1-.943 0l-2-2a.667.667 0 1 1 .942-.942L8 8.397l6.195-6.201c.26-.261.682-.261.943-.001Z"
          fill="#229822"
        />
      </g>
      <defs>
        <clipPath id="IconCheck">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconCheckStyled>
  );
};

export default IconCheck;
