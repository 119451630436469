const IconVisaCard = () => {
  return (
    <svg width="24" viewBox="0 0 24 8" fill="none" aria-hidden="true">
      <path
        d="M17.445.623a4.991 4.991 0 0 0-1.74-.301c-1.92 0-3.275.968-3.285 2.355-.012 1.02.964 1.594 1.701 1.936.757.35 1.01.57 1.008.885-.005.477-.605.693-1.162.693-.766 0-1.186-.107-1.831-.375l-.239-.111-.271 1.598c.466.195 1.306.362 2.175.375 2.041 0 3.375-.961 3.391-2.439.016-.813-.51-1.43-1.621-1.938-.674-.33-1.094-.551-1.094-.886 0-.296.359-.612 1.109-.612a3.56 3.56 0 0 1 1.455.273l.18.081.271-1.544-.047.01Zm4.983-.17h-1.5c-.467 0-.816.127-1.021.591l-2.885 6.534h2.041l.408-1.07 2.49.002c.061.25.24 1.068.24 1.068H24L22.428.453ZM9.66.393h1.943l-1.215 7.129H8.444L9.66.391v.002ZM4.721 4.322l.202.99L6.824.453h2.059L5.822 7.568H3.768l-1.68-6.026a.398.398 0 0 0-.18-.237A8.211 8.211 0 0 0 0 .598l.025-.15h3.131c.424.016.766.15.883.604l.682 3.273v-.003Zm15.308.727.775-1.994c-.01.02.16-.412.258-.68l.133.615.449 2.057h-1.615v.002Z"
        fill="#000"
      />
    </svg>
  );
};

export default IconVisaCard;
