import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'paypalAccounts';

export const paypalAccountsSlice = createSlice({
  name,
  initialState,
  reducers: {
    getPaypalAccountsCollectionRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getPaypalAccountsCollectionSuccess(state, { payload }) {
      state.paypalAccountsCollection =
        payload.length !== 0
          ? [...state.paypalAccountsCollection, ...payload]
          : state.paypalAccountsCollection;
      state.loading = false;
      state.error = false;
    },
    getPaypalAccountsCollectionFailure(state) {
      state.loading = false;
      state.error = true;
    },
    getPaypalAccountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getPaypalAccountSuccess(state, { payload }) {
      state.paypalAccount = payload;
      state.loading = false;
      state.error = false;
    },
    getPaypalAccountFailure(state) {
      state.loading = false;
      state.error = true;
    },
    patchPaypalAccountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    patchPaypalAccountSuccess(state, { payload }) {
      state.patchedPaypalAccount = payload;
      state.loading = false;
      state.error = false;
    },
    patchPaypalAccountFailure(state) {
      state.loading = false;
      state.error = true;
    },
    createPaypalAccountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createPaypalAccountSuccess(state) {
      state.loading = false;
      state.error = false;
    },
    createPaypalAccountFailure(state) {
      state.loading = false;
      state.error = true;
    },
    incrementPaypalAccountsPage(state) {
      state.paypalAccountsPage += 1;
    },
    resetPaypalAccountsPage(state) {
      state.paypalAccountsPage = 1;
    },
    resetPaypalAccounts(state) {
      state.paypalAccountsCollection = [];
    },
  },
});

export const {
  getPaypalAccountsCollectionRequest,
  getPaypalAccountsCollectionSuccess,
  getPaypalAccountsCollectionFailure,
  getPaypalAccountRequest,
  getPaypalAccountSuccess,
  getPaypalAccountFailure,
  patchPaypalAccountRequest,
  patchPaypalAccountSuccess,
  patchPaypalAccountFailure,
  createPaypalAccountRequest,
  createPaypalAccountSuccess,
  createPaypalAccountFailure,
  incrementPaypalAccountsPage,
  resetPaypalAccountsPage,
  resetPaypalAccounts,
} = paypalAccountsSlice.actions;

export default paypalAccountsSlice.reducer;
