import propTypes from 'prop-types';

const IconAllFans = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 22 20"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0ZM13.573.916a1 1 0 0 1 1.302-.552 5.001 5.001 0 0 1 0 9.272 1 1 0 1 1-.75-1.854 3.001 3.001 0 0 0 0-5.564 1 1 0 0 1-.552-1.302ZM6.964 12h3.072c.901 0 1.629 0 2.22.04.61.042 1.148.13 1.657.34a5 5 0 0 1 2.706 2.707c.212.51.3 1.048.34 1.656.041.592.041 1.32.041 2.222V19a1 1 0 1 1-2 0c0-.946 0-1.605-.036-2.12-.034-.508-.099-.803-.192-1.028a3 3 0 0 0-1.624-1.624c-.225-.093-.52-.158-1.027-.192C11.605 14 10.946 14 10 14H7c-.946 0-1.605 0-2.12.036-.507.034-.803.099-1.028.192a3 3 0 0 0-1.624 1.624c-.093.225-.158.52-.192 1.027C2 17.395 2 18.054 2 19a1 1 0 1 1-2 0v-.035c0-.902 0-1.63.04-2.222.042-.608.13-1.147.34-1.656a5 5 0 0 1 2.707-2.706c.51-.212 1.048-.3 1.656-.34C5.335 12 6.063 12 6.964 12Zm10.068.877a1 1 0 0 1 1.217-.72A5.002 5.002 0 0 1 22 17v2a1 1 0 1 1-2 0v-2a3.002 3.002 0 0 0-2.25-2.905 1 1 0 0 1-.718-1.218Z"
        fill="#fff"
      />
    </svg>
  );
};

IconAllFans.defaultProps = {
  size: '22',
};

IconAllFans.propTypes = {
  size: propTypes.string,
};

export default IconAllFans;
