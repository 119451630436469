import propTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from 'styles/Theme';

const IconSendStyled = styled.svg`
  flex-shrink: 0;
`;

const IconSend = ({ size, palette, isGradient }) => {
  return (
    <IconSendStyled
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.072 2.836 4.04 2.82c-.233-.105-.466-.21-.663-.278-.18-.062-.543-.177-.944-.065a1.5 1.5 0 0 0-.995.898c-.152.388-.076.76-.032.946.048.202.128.445.209.688l.011.034 1.293 3.724c.237.684.356 1.026.565 1.291a2 2 0 0 0 .689.556c.303.149.662.192 1.38.28l4.983.61c.573.082.573.908 0 .99l-4.98.61c-.718.087-1.078.131-1.38.28a2 2 0 0 0-.69.556c-.209.265-.327.607-.564 1.292l-1.3 3.75a8.674 8.674 0 0 0-.211.69c-.044.185-.122.558.03.947.171.44.54.773.994.9.403.112.766-.002.947-.065.196-.068.43-.173.664-.278l16.778-7.55c.228-.103.457-.206.635-.306.166-.094.486-.287.669-.656a1.5 1.5 0 0 0 0-1.33c-.183-.369-.503-.562-.669-.655a8.476 8.476 0 0 0-.635-.306L4.073 2.835Z"
        fill={isGradient ? 'url(#IconSend)' : palette}
      />
      <defs>
        <linearGradient
          id="IconSend"
          x1="22.282"
          y1="21.567"
          x2=".755"
          y2="20.877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
      </defs>
    </IconSendStyled>
  );
};

IconSend.defaultProps = {
  palette: theme.colors.white,
  isGradient: false,
  size: '24',
};

IconSend.propTypes = {
  palette: propTypes.string,
  isGradient: propTypes.bool,
  size: propTypes.string,
};

export default IconSend;
