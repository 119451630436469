import propTypes from 'prop-types';

const IconAccountActive = ({ uniqueId }) => {
  return (
    <svg aria-hidden="true" width="18" viewBox="0 0 18 18" fill="none">
      <g clipPath={`url(#account${uniqueId})`}>
        <path
          fill={`url(#account2${uniqueId})`}
          fillRule="evenodd"
          d="M12.378 9.638a5.397 5.397 0 1 0-6.675 0A8.995 8.995 0 0 0 .11 16.996a.905.905 0 1 0 1.799.198 7.196 7.196 0 0 1 14.301 0 .9.9 0 0 0 .9.8h.099a.9.9 0 0 0 .791-.989 8.996 8.996 0 0 0-5.621-7.367ZM9.04 9a3.598 3.598 0 1 1 0-7.196A3.598 3.598 0 0 1 9.04 9Zm5.37 8.005c.099.543-.355.995-.908.995H4.497c-.553 0-1.006-.452-.907-.995a5.502 5.502 0 0 1 10.82 0ZM9.04 7.402a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          id={`account2${uniqueId}`}
          x1="18.005"
          x2="-.406"
          y1="18"
          y2="17.463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611FF3" />
          <stop offset="1" stopColor="#2655F4" />
        </linearGradient>
        <clipPath id={`account${uniqueId}`}>
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconAccountActive.defaultProps = {
  uniqueId: '',
};

IconAccountActive.propTypes = {
  uniqueId: propTypes.string,
};

export default IconAccountActive;
