/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'discussion';

export const discussionSlice = createSlice({
  name,
  initialState,
  reducers: {
    getDiscussionsRequest(state) {
      state.loading = true;
    },
    getDiscussionsBySearchRequest(state) {
      state.loading = true;
    },
    getDiscussionsSuccess(state, { payload }) {
      state.loading = false;
      if (payload?.subscribers) {
        state.subscribersCount = payload?.subscribersCount;
        state.totalMoney = payload?.totalMoney;
        state.discussionsCollection =
          payload?.subscribers.length !== 0
            ? [...state.discussionsCollection, ...payload?.subscribers]
            : state.discussionsCollection;
      } else {
        state.discussionsCollection =
          payload?.length !== 0
            ? [...state.discussionsCollection, ...payload]
            : state.discussionsCollection;
      }
      state.hasMore = payload?.subscribers
        ? payload?.subscribers.length !== 0
        : payload?.length !== 0;
    },
    getNewDiscussionsSuccess(state, { payload }) {
      state.loading = false;
      if (payload?.subscribers) {
        state.subscribersCount = payload?.subscribersCount;
        state.totalMoney = payload?.totalMoney;
        state.discussionsCollection = payload?.subscribers;
      } else {
        state.discussionsCollection = payload;
      }
      state.hasMore = payload?.subscribers
        ? payload?.subscribers.length !== 0
        : payload?.length !== 0;
    },
    getDiscussionsFailure(state) {
      state.loading = false;
      state.hasMore = false;
    },
    clearCurrentDiscussionsData(state) {
      state.discussionsCollection = [];
      state.subscribersCount = null;
      state.totalMoney = null;
    },
    resetDiscussionState: () => initialState,
    resetError(state) {
      state.error = null;
    },
    incrementDiscussionsPage(state) {
      state.discussionsPage += 1;
    },
    decrementDiscussionsPage(state) {
      state.discussionsPage = 1;
    },
    resetHasMore(state) {
      state.hasMore = true;
    },
  },
});

export const {
  getDiscussionsRequest,
  getDiscussionsSuccess,
  getDiscussionsFailure,
  clearCurrentDiscussionsData,
  resetError,
  incrementDiscussionsPage,
  decrementDiscussionsPage,
  resetHasMore,
  getDiscussionsBySearchRequest,
  getNewDiscussionsSuccess,
  resetDiscussionState,
} = discussionSlice.actions;

export default discussionSlice.reducer;
