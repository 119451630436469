import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { GradientText, InputFocusVisible } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const CategoryRadioWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${breakpoints.md};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${space};
`;

const CategoryRadioLabelStyled = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${theme.space.xxs};
  margin-right: ${theme.space.xxs};
  margin-bottom: ${theme.space.primaryMd};
  border-radius: ${theme.radii.xl};
  border: ${theme.borderWidths.sm} solid transparent;
  background: ${gradients.primary};

  &:after {
    content: '';
    overflow: hidden;
    border-radius: ${theme.radii.xl};
    position: absolute;
    background: ${gradients.primary};
    ${props =>
      !props.active &&
      `background-blend-mode: overlay; background-color: rgba(255, 255, 255, 0.9);`};
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
`;

const CategoryRadioInputStyled = styled.input`
  ${InputFocusVisible};
`;

const CategoryRadioTextStyled = styled.span`
  display: block;
  position: relative;
  z-index: 1;
  padding: ${theme.space.md} ${theme.space.lg};
  border-radius: inherit;
  white-space: nowrap;
  cursor: pointer;
  font-weight: ${theme.fontWeights.regular};
  ${props =>
    props.active
      ? `color: ${theme.colors.white}; text-shadow: 0 0 1px currentColor;`
      : GradientText(`${gradients.primary}`)};
`;

const CategoryRadio = ({
  name,
  items,
  value,
  onChange,
  ariaLabelledby,
  ...props
}) => {
  const changeHandler = useCallback(
    event => onChangeEventHandler(event, onChange),
    [onChange],
  );
  return (
    <CategoryRadioWrapperStyled {...props}>
      {items.map(item => (
        <CategoryRadioLabelStyled active={value === item.value} key={item.text}>
          <CategoryRadioInputStyled
            onChange={changeHandler}
            type="radio"
            name={name}
            value={item.value}
            aria-labelledby={`${ariaLabelledby}`}
          />
          <CategoryRadioTextStyled active={value === item.value}>
            {item.text}
          </CategoryRadioTextStyled>
        </CategoryRadioLabelStyled>
      ))}
    </CategoryRadioWrapperStyled>
  );
};

CategoryRadio.defaultProps = {
  value: '',
  onChange: noop,
};

CategoryRadio.propTypes = {
  name: propTypes.string.isRequired,
  items: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.string.isRequired,
      text: propTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  value: propTypes.string,
  onChange: propTypes.func,
  ariaLabelledby: propTypes.string,
};

export default CategoryRadio;
