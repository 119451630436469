import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'bankAccounts';

export const bankAccountsSlice = createSlice({
  name,
  initialState,
  reducers: {
    getBankAccountsCollectionRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getBankAccountsCollectionSuccess(state, { payload }) {
      state.bankAccountsCollection =
        payload.length !== 0
          ? [...state.bankAccountsCollection, ...payload]
          : state.bankAccountsCollection;
      state.loading = false;
      state.error = false;
    },
    getBankAccountsCollectionFailure(state) {
      state.loading = false;
      state.error = true;
    },
    getBankAccountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getBankAccountSuccess(state, { payload }) {
      state.bankAccount = payload;
      state.loading = false;
      state.error = false;
    },
    getBankAccountFailure(state) {
      state.loading = false;
      state.error = true;
    },
    patchBankAccountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    patchBankAccountSuccess(state, { payload }) {
      state.patchedBankAccount = payload;
      state.loading = false;
      state.error = false;
    },
    patchBankAccountFailure(state) {
      state.loading = false;
      state.error = true;
    },
    createBankAccountRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createBankAccountSuccess(state) {
      state.loading = false;
      state.error = false;
    },
    createBankAccountFailure(state) {
      state.loading = false;
      state.error = true;
    },
    incrementBankAccountsPage(state) {
      state.bankAccountsPage += 1;
    },
    resetBankAccountsPage(state) {
      state.bankAccountsPage = 1;
    },
    resetBankAccounts(state) {
      state.bankAccountsCollection = [];
    },
  },
});

export const {
  getBankAccountsCollectionRequest,
  getBankAccountsCollectionSuccess,
  getBankAccountsCollectionFailure,
  getBankAccountRequest,
  getBankAccountSuccess,
  getBankAccountFailure,
  patchBankAccountRequest,
  patchBankAccountSuccess,
  patchBankAccountFailure,
  createBankAccountRequest,
  createBankAccountSuccess,
  createBankAccountFailure,
  incrementBankAccountsPage,
  resetBankAccountsPage,
  resetBankAccounts,
} = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;
